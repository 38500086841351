import { render, staticRenderFns } from "./ConfigEdit.vue?vue&type=template&id=3ae21684&"
import script from "./ConfigEdit.vue?vue&type=script&lang=js&"
export * from "./ConfigEdit.vue?vue&type=script&lang=js&"
import style0 from "./ConfigEdit.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../../shared/frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports