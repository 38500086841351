<template>
  <div class="page">
    <HeaderTitle />
    <div class="d-flex flex-column">
      <div class="input-group without-separator">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <font-awesome-icon icon="search" style="color:gray" />
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Поиск объектов по названию"
          aria-label="Поиск объектов по названию"
          :value="searchText"
          @input="searchFacility"
        >
      </div>
    </div>
    <div class="pt-2">
      <a
        href="/facility/exchange"
        class="input-group-btn btn btn-info m-2"
        style="float: right"
      >
        <font-awesome-icon class="text-white" icon="arrows-alt-h" />
        Списание между объектами
      </a>
      <a
        href="/facility/create"
        class="input-group-btn btn btn-success m-2"
        style="float: right"
      >
        <font-awesome-icon class="text-white" icon="plus" />
        Добавить объект
      </a>
      <a
        href="/stock/create"
        class="input-group-btn btn btn-success m-2"
        style="float: right"
      >
        <font-awesome-icon class="text-white" icon="plus" />
        Добавить предмет
      </a>
    </div>
    <br>
    <table class="table table-hover w-100 mt-3 d-none d-md-table">
      <TableSort
        class="sticky-2"
        :sorts="sorts"
        :zeroingOtherParams="true"
        @sortsUpdate="loadWithSort"
      />
      <tbody>
        <tr v-for="facility of facilities" :key="facility.id" @click="$router.push('/facility/' + facility.id)">
          <td>
            <TextHighlight class="highlighter" :queries="searchText">
              {{ facility.name }}
            </TextHighlight>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-for="facility of facilities" :key="facility.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none w-100">
      <table class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Название</td>
            <td style="border-top: none">
              <TextHighlight class="highlighter" :queries="searchText">
                {{ facility.name }}
              </TextHighlight>
            </td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="`/facility/${ facility.id }`"
        class="btn btn-primary btn-md create-fines ml-auto"
      >
        Список предметов
      </router-link>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'FacilityList',
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'Название',
          storeName: 'facility-name',
          values: ['', 'name', '-name'],
          order: 0
        },
      ],
      searchedText: null,
      interval: null,
    }
  },
  computed: {
    ...mapState('facility/facility', {
      facilities: state => state.items,
      pageCount: state => state.pageCount,
      searchText: state => state.searchText,
      isLoading: state => state.isLoading
    })
  },
  mounted () {
    this.setTitle([
      {
        title: 'Объекты'
      }
    ])
    if (!this.interval) {
      this.loadWithSearch()
    }
  },
  beforeDestroy () {
    this.abortLoading()
    clearTimeout(this.interval)
    this.interval = null
  },
  methods: {
    ...mapActions('facility/facility', [
      'loadItems',
      'setSearchText',
      'setSearch',
      'abortLoading',
      'setSorts'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    searchFacility (e) {
      this.setSearchText(e.target.value)
      this.setSearch({ key: 'name', value: e.target.value })
    },
    loadWithSearch () {
      if (!this.isLoading && this.searchedText !== this.searchText) {
        this.searchedText = this.searchText
        return this.loadItems({ page: Number(this.page)})
          .then(() => {
            this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
          })
      }
      this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
    },
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page)})
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding-top: 1rem;
  height: 100%;
  overflow-y: auto;
}
@media screen and (max-width: 1199px) {
  .page {
    padding: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .page {
    padding: 0.5rem;
  }
}
.highlighter ::v-deep .text__highlight {
  border-radius: 0;
  padding: 0.2em 0 0.2em 0;
  background: #fff2ae;
}
</style>
