import Vue from 'vue'
import dayjs from 'dayjs'

Vue.filter('formatDateTime', date => dayjs(date).format('DD.MM.YYYY HH:mm:ss'))
Vue.filter('formatDate', date => dayjs(date).format('DD.MM.YYYY'))

Vue.filter('formatDateTimeShort', function (date) {
  const isCurrentDay = dayjs(date).isSame(dayjs(), 'day')
  if (isCurrentDay) {
    return dayjs(date).format('HH:mm:ss')
  }
  return dayjs(date).format('DD.MM.YYYY HH:mm:ss')
})
