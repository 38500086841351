import Page from '@/modules/logs/views/Page.vue'

export default [
  {
    path: '/logs',
    component: Page,
    props: true,
    meta: {
      title: 'Логи',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
