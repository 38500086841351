<template>
  <div>
    <table v-if="driverCharacteristics.length" class="table">
      <tbody>
        <tr
          v-for="boolC of boolCharacteristics"
          :key="`bool-${boolC.id}`"
        >
          <td> {{ boolC.title }} </td>
          <td> <font-awesome-icon icon="exclamation" /> </td>
        </tr>
        <tr
          v-for="numberC of numberCharacteristics"
          :key="`number-${numberC.id}`"
        >
          <td> {{ numberC.title }} </td>
          <td> {{ numberC.value }} </td>
        </tr>
        <tr
          v-for="textC of textCharacteristics"
          :key="`text-${textC.id}`"
        >
          <td> {{ textC.title }} </td>
          <td class="long-text"> {{ textC.value }} </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'CharacteristicView',
  props: {
    driverCharacteristics: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    boolCharacteristics () {
      return this.driverCharacteristics.filter(ch => ch.type == 'bool')
    },
    numberCharacteristics () {
      return this.driverCharacteristics.filter(ch => ch.type == 'number')
    },
    textCharacteristics () {
      return this.driverCharacteristics.filter(ch => ch.type == 'string')
    }
  }
}

</script>
<style scoped>
  .long-text {
    word-break: break-word;
  }
</style>
