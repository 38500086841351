<template>
  <div class="show-form-cars">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Пользователь</td>
          <td>{{ proxy.user.last_name }} {{ proxy.user.first_name }} {{ proxy.user.middle_name }}</td>
        </tr>
        <tr>
          <td>В лице:</td>
          <td>{{ proxy.representative }}</td>
        </tr>
        <tr>
          <td>На основании:</td>
          <td>{{ proxy.based }}</td>
        </tr>
        <tr>
          <td>Должность для подписи:</td>
          <td>{{ proxy.position }}</td>
        </tr>
        <tr>
          <td>ФИО для подписи:</td>
          <td>{{ proxy.signature }}</td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4">
  </div>
</template>

<script>

export default {
  name: 'ProxyShowForm',
  props: {
    proxy: {
      type: Object,
      default: null
    },
  },
}
</script>

<style scoped>
  .label-col {
    width: 25%;
  }
</style>
