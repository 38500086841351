<template>
  <form class="edit-contracts">
    <div class="form-row row">
      <div class="form-group col">
        <label for="selectorTemplate">Шаблон документа</label>
        <VSelect
          id="selectorTemplate"
          v-model="config.contract.file_id"
          :options="config.templates.contracts"
          :reduce="template => template.id"
          label="nameWithDate"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="selectorTemplate">Шаблон акта возврата</label>
        <VSelect
          id="selectorTemplate"
          v-model="config.contract.receive_list_id"
          :options="config.templates.receiveLists"
          :reduce="template => template.id"
          label="nameWithDate"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="selectorDriver">Водитель</label>
        <VSelect
          id="selectorDriver"
          v-model="config.contract.driver_id"
          :options="config.drivers"
          :reduce="driver => driver.id"
          label="fullName"
          :disabled="config.contract.status !== 'draft'"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="selectorCar">Автомобиль</label>
        <VSelect
          id="selectorCar"
          v-model="config.contract.car_id"
          :options="filterAvilible(config.cars)"
          :reduce="car => car.id"
          label="displayedName"
          :disabled="config.contract.status !== 'draft'"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="selectorCompany">Организация</label>
        <VSelect
          id="selectorCompany"
          v-model="config.contract.company_id"
          :options="config.companies"
          :reduce="company => company.id"
          label="name"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label>Доверенное лицо</label>
        <VSelect
          v-model="config.contract.proxy_id"
          :options="proxies"
          :reduce="proxy => proxy.id"
          label="fullName"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="inputSRDate">Дата/ время передачи автомобиля</label>
        <input
          id="inputStartRDate"
          v-model="config.contract.transfer_datetime"
          type="datetime-local"
          class="form-control"
          min="2010-06-01T08:30"
          max="2050-06-30T16:30"
          :disabled="config.contract.status !== 'draft'"
        >
      </div>
      <div class="form-group col">
        <label for="inputEndRDate">Дата/ время возврата автомобиля</label>
        <input
          id="inputEndRDate"
          v-model="config.contract.receiving_datetime"
          type="datetime-local"
          class="form-control"
          min="2010-06-01T08:30"
          max="2050-06-30T16:30"
          :disabled="config.contract.status !== 'draft'"
        >
      </div>
    </div>
    <div v-if="config.contract.archived_at" class="form-row row">
      <div class="form-group col">
        <label for="inputEndRDateFact">Дата/ время принятия автомобиля</label>
        <input
          id="inputEndRDateFact"
          v-model="config.contract.archived_at"
          type="datetime-local"
          class="form-control"
          min="2010-06-01T08:30"
          max="2050-06-30T16:30"
        >
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="input-r-spot">Адрес выдачи</label>
        <dadata-suggestions
          v-model="config.contract.transfer_spot"
          type="ADDRESS"
          class="form-control"
          :disabled="config.contract.status !== 'draft'"
        />
      </div>
      <div class="form-group col">
        <label for="input-er-spot">Адрес возврата</label>
        <dadata-suggestions
          v-model="config.contract.receiving_spot"
          type="ADDRESS"
          class="form-control"
          :disabled="config.contract.status !== 'draft'"
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="inputPrice">Арендная плата</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span id="currency-addon" class="input-group-text">&#8381;</span>
          </div>
          <IMaskComponent
            id="inputPrice"
            v-model="config.contract.price"
            type="text"
            :mask="Number"
            :min="0"
            :max="10000"
            thousandsSeparator=" "
            :unmask="true"
            :lazy="false"
            placeholder="Цена аренды"
            class="form-control"
            required
            addon="currency"
            :disabled="config.contract.status !== 'draft'"
          />
        </div>
      </div>
      <div class="form-group col">
        <label for="inputPeriod">Период оплаты</label>
        <select
          id="inputPeriod"
          v-model="config.contract.payment_period"
          class="form-control"
          :disabled="config.contract.status !== 'draft'"
        >
          <option value="day">Посуточно</option>
          <option value="month">Помесячно</option>
        </select>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'ContractRentEdit',
  inject: ['config'],
  computed: {
    filterAvilible () {
      return cars => cars.filter(car => !car.isServe || car.id === this.config.contract.car_id)
    },
    proxies () {
      return this.config.proxies.filter(proxy => {
        return proxy.company_id === this.config.contract.company_id
      })
    }
  },
  watch: {
    'config.contract.car_id': {
      handler (carId) {
        if (carId && !this.config.contract.company_id) {
          const car = this.config.cars.find((car) => car.id === carId)
          if (car.owner_id) {
            this.config.contract.company_id = car.owner_id
          }
        }
      },
      immediate: true
    },
    'config.contract.company_id': {
      handler (companyId, companyIdOld) {
        if (companyIdOld && companyId !== companyIdOld) {
          this.config.contract.proxy_id = null
        }
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
  .edit-contracts {
    margin-top: 4rem;
  }
</style>
