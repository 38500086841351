/* eslint-disable camelcase */

import configs from './configs'
import configComponentDefinition from './configComponentDefinition'

export default {
  namespaced: true,
  modules: {
    configs,
    configComponentDefinition
  }
}
