<template>
  <div class="page">
    <HeaderTitle />
    <div class="float-right">
      <router-link v-if="car && car.id" :to="`/maintenance/car/${car.id}/create`" class="btn btn-primary">
        <font-awesome-icon class="text-white" icon="plus" />
        Добавить ТО
      </router-link>
    </div>
    <ServiceShow
      :car="car"
      :countStocks="countStocks"
      :sumStocks="sumStocks"
      :showPriceAndSum="showPriceAndSum"
    />
    <ul class="nav nav-tabs justify-content-left">
      <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#summary">Сводка</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#history">Выполненные работы</a>
      </li>
      <span class="tab-bar" />
    </ul>
    <div class="tab-content">
      <div id="summary" class="tab-pane fade show active summary-tab">
        <table class="table plan-items-table d-none d-md-table">
          <thead>
            <th>Вид работы</th>
            <th>Дата/время последнего ТО</th>
            <th>Пробег на момент ТО, км</th>
            <th>Пробег после ТО, км</th>
            <th>Интервал, км</th>
            <th>Следующее ТО</th>
          </thead>
          <tbody v-if="car && car.servicePlan">
            <tr v-for="planItem in car.servicePlan" :key="planItem.name">
              <td>{{ planItem.name }}</td>
              <td>{{ toHumanFormat(fromUTCtranslate(planItem.datetime)) }}</td>
              <td>{{ planItem.total_odometer_stamp || '-' }}</td>
              <td>{{ afterService(planItem) }}</td>
              <td>{{ planItem.interval ? planItem.interval : 'не указан' }}</td>
              <td><ServiceState :distance="leftBeforeNext(planItem)" :afterService="afterService(planItem)" :interval="planItem.interval" /></td>
            </tr>
          </tbody>
        </table>
        <div v-if="car && car.servicePlan">
          <div v-for="planItem in car.servicePlan" :key="planItem.name" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
            <table class="table borderless">
              <tbody>
                <tr>
                  <td style="border-top: none">Вид работы</td>
                  <td style="border-top: none">{{ planItem.name }}</td>
                </tr>
                <tr>
                  <td>Дата/время последнего ТО</td>
                  <td>{{ toHumanFormat(fromUTCtranslate(planItem.datetime)) }}</td>
                </tr>
                <tr>
                  <td>Пробег на момент ТО, км</td>
                  <td>{{ planItem.total_odometer_stamp || '-' }}</td>
                </tr>
                <tr>
                  <td>Пробег после ТО, км</td>
                  <td>{{ afterService(planItem) }}</td>
                </tr>
                <tr>
                  <td>Интервал, км</td>
                  <td>{{ planItem.interval ? planItem.interval : 'не указан' }}</td>
                </tr>
                <tr>
                  <td>Следующее ТО</td>
                  <td><ServiceState :distance="leftBeforeNext(planItem)" :afterService="afterService(planItem)" :interval="planItem.interval" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ul id="history" class="tab-pane history-pane fade loader">
        <li v-for="service in carServices" :key="service.id" class="list-group-item history-item">
          <span><b>Пробег:</b> {{ service.total_odometer_stamp }} км</span>
          <span class="float-right d-flex align-items-center" style="margin-right: 5px;">
            {{ service.datetime | diffForHumans }}
            <router-link
              role="button"
              tabindex="0"
              :to="'/maintenance/car/' + carId + '/edit-service/' + service.id"
            >
              <font-awesome-icon icon="pen" class="m-2 text-secondary" />
            </router-link>
            <span
              :style="{ 'cursor': service.canDelete ? 'pointer' : 'default'}"
              :title="service.canDelete ? '' : `Для удаления ТО старше ${daysToDelete} дней воспользуйтесь обратной связью`"
              role="button"
              @click="removeService(service)"
            >
              <font-awesome-icon icon="trash-alt" class="m-2 text-secondary" />
            </span>
          </span>
          <div v-if="service.serviceWorks && service.serviceWorks.length > 0">
            <b>Работы:</b>
            <ul>
              <li v-for="work in service.serviceWorks" :key="work.id">
                {{ work.name }}
              </li>
            </ul>
          </div>
          <div v-if="service.notes" style="white-space: pre-line">
            <b>Прочие работы:</b>
            <div class="ml-4">{{ service.notes }}</div>
          </div>
          <div v-if="service.recommendations" style="white-space: pre-line">
            <b>Рекомендации:</b>
            <div class="ml-4">{{ service.recommendations }}</div>
          </div>
          <div v-if="service.stocks && service.stocks.length > 0">
            <b>Запчасти со склада:</b>
            <ul>
              <li v-for="(stock, key) in service.stocks" :key="key">
                {{ stock.title }}: {{ stock.quantity }}
              </li>
            </ul>
            <b v-if="showPriceAndSum" style="color:#0597f2">
              Со склада списано запчастей на сумму: {{ service.stockSum }} руб
            </b>
          </div>
          <div v-if="service.consumable && service.consumable.length > 0">
            <b>Сторонние запчасти:</b>
            <ul>
              <li v-for="(consumableItem, key) in service.consumable" :key="key">
                {{ consumableItem.name }}: {{ consumableItem.quantity }}
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { djsMixin } from '@/mixins/djsMixin'
import ServiceShow from './ServiceShow'
import serviceApi from '../../api/service'
import serviceWorkApi from '../../api/serviceWork'
import ServiceState from '../../components/ServiceState'
import Swal from 'sweetalert2'

export default {
  name: 'ServicePage',
  components: {
    ServiceShow,
    ServiceState
  },
  mixins: [djsMixin],
  props: {
    carId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      carServices: [],
      car: null
    }
  },
  computed: {
    countStocks () {
      if (!this.showPriceAndSum) {
        return 0
      }
      return this.carServices.reduce(function (count, service) {
        return count + parseFloat(service.stockCount)
      }, 0)
    },
    sumStocks () {
      if (!this.showPriceAndSum) {
        return 0
      }
      return this.carServices.reduce(function (count, service) {
        return count + parseFloat(service.stockSum)
      }, 0)
    },
    ...mapGetters('configs/configs', [
      'showPriceAndSum',
      'daysToDelete'
    ]),
    ...mapState('maintenance/serviceCar', {
      isLoading: state => state.isLoading
    }),
  },
  watch: {
    car: {
      handler (car, carOld) {
        if (car?.id && car?.id !== carOld?.id) {
          this.setTitle([
            {
              title: 'Тех. обслуживание',
              url: '/maintenance'
            }, {
              title: car.brand + ' ' + car.model + ' ' + car.registration_plate
            }
          ])
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    this.loadConfigs()
  },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions('maintenance/serviceCar', [
      'loadItem'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    loadData () {
      serviceWorkApi.getActiveServiceWorks()
        .then(serviceWorks => {
          this.loadItem({
            id: this.carId,
            expand: [
              'servicePlan',
              'lastState',
              'nextServiceRecord'
            ]
          })
            .then(response => {
              this.car = {
                ...response,
                servicePlan: serviceWorks.map(serviceWork => {
                  const searchedIndex = response.servicePlan.findIndex(el => el.id == serviceWork.id)
                  return searchedIndex === -1 ? serviceWork : response.servicePlan[searchedIndex]
                })
              }
            })
        })
      serviceApi.getItems({
        expand: 'serviceWorks, stocks, stockCount, stockSum, canDelete',
        extra: {
          key: 'filter[and][][car_id]',
          value: this.carId
        },
        sort: '-datetime'
      })
        .promise
        .then(response => {
          this.carServices = response.items
        })
    },
    afterService (planItem) {
      if (!this.car.lastState || !this.car.lastState.totalOdometer ||
        !this.car.lastState.totalOdometer.value) {
        return 'н/д о пробеге'
      }

      if (!planItem.total_odometer_stamp) {
        return 'н/д'
      }

      return (Math.ceil(this.car.lastState.totalOdometer.value / 1000) - planItem.total_odometer_stamp).toString()
    },
    leftBeforeNext (planItem) {
      if (!this.car.lastState || !this.car.lastState.totalOdometer ||
        !this.car.lastState.totalOdometer.value) {
        return null
      }

      if (!planItem.total_odometer_stamp) {
        return null
      }

      return planItem.interval - (Math.round(this.car.lastState.totalOdometer.value / 1000) - planItem.total_odometer_stamp)
    },
    removeService (service) {
      if (!service.canDelete) {
        Swal.fire({
            icon: 'info',
            title: 'Действие запрещено',
            text: `Для удаления ТО старше ${this.daysToDelete} дней воспользуйтесь обратной связью`
          })
        return
      }
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите безвозвратно удалить ТО? <br> Удаление приведет к возврату списанных предметов и изменению пробега.',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          serviceApi.removeItem(service.id)
            .then(() => {
              this.carServices = this.carServices.filter(x => x.id !== service.id)
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    ...mapActions('configs/configs', {
      loadConfigs: 'loadItems'
    })
  }
}
</script>

<style scoped>
  .history-pane {
    padding-left: 0;
    padding-right: 0;
    flex-shrink: 1;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .tab-content {
    padding: 1rem 1rem 1rem 1rem;
    display: flex;
  }
  .history-item {
    padding-left: min(1.60rem, 3%);
    padding-right: 2%;
    width: 100%;
  }
  .summary-tab {
    width: 100%;
    flex-shrink: 0;
  }
</style>
