<template>
  <div>
    <label>Сторонний расходный материал</label>
    <div class="row align-items-end">
      <div class="col-6 d-none d-md-block">
        <label>Предмет</label>
        <input
          v-model="addConsumable"
          class="form-control"
          placeholder="Предмет"
        >
      </div>
      <div class="col-12 mobile-table-view d-md-none">
        <label>Предмет</label>
        <input
          v-model="addConsumable"
          class="form-control"
          placeholder="Предмет"
        >
      </div>
      <div class="col-5 d-none d-md-block">
        <label>Количество</label>
        <input
          v-model="quantity"
          type="number"
          class="form-control"
          placeholder="Кол-во"
        >
      </div>
      <div class="col-10 mobile-table-view d-md-none">
        <label>Количество</label>
        <input
          v-model="quantity"
          type="number"
          class="form-control"
          placeholder="Кол-во"
        >
      </div>
      <button class="btn btn-success col-auto" @click="pushConsumable">
        <font-awesome-icon class="text-white" icon="plus" />
      </button>
    </div>
    <table v-if="consumable.length" class="table w-100 mt-3 d-none d-md-table">
      <TableSort :sorts="sorts" />
      <tbody>
        <tr v-for="(consumableItem, key) in consumable" :key="key">
          <td>{{ consumableItem.name }}</td>
          <td>{{ consumableItem.quantity }}</td>
          <td class="d-flex justify-content-end">
            <button class="btn btn-danger col-auto" @click="removeConsumable(consumableItem)">
              <font-awesome-icon class="text-white" icon="trash-alt" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="consumable.length">
      <div v-for="(consumableItem, key) in consumable" :key="key" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
        <table class="table borderless">
          <tbody>
            <tr>
              <td>Название</td>
              <td>{{ consumableItem.name }}</td>
              <td class="mobile-remove-btn" rowspan="2">
                <button class="btn btn-danger col-auto" @click="removeConsumable(consumableItem)">
                  <font-awesome-icon class="text-white" icon="trash-alt" />
                </button>
              </td>
            </tr>
            <tr>
              <td>Количество</td>
              <td>{{ consumableItem.quantity }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'

export default {
  name: 'ConsumableEdit',
  props: {
    consumable: {
      type: Array,
      default: Array
    },
  },
  data () {
    return {
      sorts: [
        {
          name: 'Название',
          storeName: 'stock-title',
          values: ['', 'title', '-title'],
          order: 0,
          disabled: true
        },
        {
          name: 'Количество',
          storeName: 'stock-quantity',
          values: ['', 'quantity', '-quantity'],
          order: 1,
          disabled: true
        },
        {
          name: '',
          storeName: '',
          values: ['', '', ''],
          disabled: true
        },
      ],
      addConsumable: null,
      quantity: null
    }
  },
  methods: {
    pushConsumable () {
      if (!this.addConsumable || this.addConsumable === '') {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Предмет не выбран!'
        })
        return false
      }
      this.quantity = parseFloat(this.quantity)
      if (!this.quantity || this.quantity <= 0) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Не введено количество товара!'
        })
        return false
      }

      this.consumable.push({
        name: this.addConsumable,
        quantity: this.quantity
      })
      this.addConsumable = null
      this.quantity = null
    },
    removeConsumable (consumable) {
      this.consumable.forEach((consumableItem, index) => {
        if (consumableItem.name === consumable.name) {
          this.consumable.splice(index, 1)
        }
      })
    }
  }
}
</script>

<style scoped>
  .mobile-remove-btn {
    vertical-align: middle;
    text-align: center;
  }
</style>
