/**
 * Форматирование разницы между двумя timestamp
 *
 * @param {*} diff Разница в секудах
 * @returns {string} Результат в формате "X ч. Y мин."
 */
function formatDifference (diff) {
  const totalMinutes = Math.round(diff / 60)
  const totalHours = parseInt(totalMinutes / 60)
  let result = ''

  if (totalHours) {
    result += totalHours + 'ч '
  }
  if (totalMinutes) {
    const minutes = totalMinutes - totalHours * 60
    result += minutes ? minutes + 'мин' : ''
  }

  if (!totalHours && !totalMinutes) {
    result = '0 мин'
  }

  return result
}

export { formatDifference }
