<template>
  <div v-if="count" :class="`alert ${stateClass} alert-state text-center`" role="alert">
    {{ count }} штрафов
  </div>
</template>

<script>
export default {
  name: 'FinesCountState',
  props: {
    count: {
      type: Number,
      default: () => 0
    },
    dangerCount: {
      type: Number,
      default: () => 1
    },
    warningCount: {
      type: Number,
      default: () => 0
    }
  },
  computed: {
    stateClass () {
      if (this.count > this.dangerCount) {
        return 'alert-danger'
      }
      if (this.count > this.warningCount) {
        return 'alert-warning'
      }
      return 'alert-success'
    },
  }
}
</script>

<style scoped>
  .alert-state {
    margin: 0;
    height: 30px;
    width: fit-content;
    height: fit-content;
    line-height: 30px;
    padding: 0px 15px;
    min-width: 10rem;
    border-radius: 15px;
    border: none;
  }
  .alert-danger {
    color: #BF543B;
    background-color: rgba(191, 84, 59, 0.15);
  }
  .alert-success {
    color: #92BF4E;
    background-color: rgba(146, 191, 78, 0.15);
  }
  .alert-warning {
    color: #F2B950;
    background-color: rgba(242, 185, 80, 0.15);
  }
</style>
