import StockCreate from './views/Create'
import StockPage from './views/Page'

export default [
  {
    path: '/stock/create',
    component: StockCreate,
    name: 'StockCreate',
    props: true,
    meta: {
      title: 'Список',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/stock/:id',
    component: StockPage,
    name: 'StockPage',
    props: true,
    meta: {
      title: 'Список',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
