<template>
  <div class="edit-vehicle-passport">
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>Номер ПТС</label>
        <input
          v-model="data.number"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mb-2">
        <label>Наименование организации выдавшей ПТС</label>
        <textarea
          v-model="data.authority"
          class="form-control"
          placeholder
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mb-2">
        <label>Дата выдачи</label>
        <input
          v-model="data.issue_date"
          type="date"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <hr>
    <div class="row">
      <div class="col-md-8 mb-2">
        <label>VIN</label>
        <input
          v-model="data.vin"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mb-2">
        <label>Тип ТС</label>
        <input
          v-model="data.vehicle_type"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-2 mb-2">
        <label>Номер кузова</label>
        <input
          v-model="data.body_number"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
      <div class="col-md-6 mb-2">
        <label>Номер двигателя</label>
        <input
          v-model="data.engine_number"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-2 mb-2">
        <label>Номер шасси (рамы)</label>
        <input
          v-model="data.chassis_number"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
      <div class="col-md-6 mb-2">
        <label>Цвет</label>
        <input
          v-model="data.color"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <hr class="mb-4">
  </div>
</template>

<script>
const type = 'vehicle_passport'

export default {
  name: 'DocumentVehiclePassportEdit',
  props: {
    documentData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      data: {
        vin: '',
        vehicle_type: 'ЛЕГКОВОЙ',
        body_number: '',
        engine_number: '',
        chassis_number: 'ОТСУТСТВУЕТ',
        color: 'БЕЛЫЙ',
        number: '',
        authority: '',
        issue_date: ''
      }
    }
  },
  watch: {
    data: {
      handler () {
        this.update()
      },
      deep: true
    }
  },
  mounted () {
    if (this.documentData) {
      this.data = this.documentData
    }
    this.update()
  },
  methods: {
    update () {
      this.$emit('update', {
        type: type,
        data: this.data
      })
    }
  }
}
</script>

<style scoped>
  .edit-vehicle-passport input {
    margin-bottom: 5px;
  }
  .edit-vehicle-passport textarea {
    margin-bottom: 5px;
  }
</style>
