<template>
  <div>
    <Multiselect
      v-if="localValue && localOptions"
      v-model="localValue"
      placeholder="Нажмите для выбора"
      no-options="Список пуст"
      label="name"
      track-by="code"
      class="multiselect"
      :options="localOptions"
      :multiple="true"
      :hide-selected="true"
      :searchable="false"
    >
      <span slot="noOptions">Список пуст</span>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'Mselect',
  components: {
    Multiselect
  },
  props: {
    options: {
      type: Array,
      default: Array
    },
    value: {
      type: Array,
      default: Array
    },
    optionLabel: {
      type: String,
      default: null
    },
    optionKey: {
      type: String,
      default: null
    },
  },
  data () {
    return {
      component: {
        options: [],
        value: []
      }
    }
  },
  computed: {
    localValue: {
      get () {
        return this.component.value
      },
      set (value) {
        this.component.value = value
        this.$emit('input', value.map(el => el.code))
      }
    },
    localOptions () {
      return this.options.map(option => ({
        name: option[this.optionLabel],
        code: option[this.optionKey]
      }))
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.component.value = value.map(val => ({
          name: this.localOptions.find(option => option.code === val).name,
          code: val
        }))
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
