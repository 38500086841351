<template>
  <div class="show-form-cars">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Фамилия</td>
          <td>{{ driver.last_name }}</td>
        </tr>
        <tr>
          <td>Имя</td>
          <td>{{ driver.first_name }}</td>
        </tr>
        <tr>
          <td>Отчество</td>
          <td>{{ driver.middle_name }}</td>
        </tr>
        <tr>
          <td>Телефон</td>
          <td>{{ driver.phone }}</td>
        </tr>
        <tr>
          <td>Адрес проживания</td>
          <td>{{ driver.address }}</td>
        </tr>
        <tr v-if="driver.notes">
          <td>Заметки</td>
          <td>
            {{ driver.notes }}
          </td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4">
    <div v-if="driver.relative_full_name || driver.relative_relationship || driver.relative_phone">
      <h5>Контакты родственника</h5>
      <table class="table">
        <tbody>
          <tr v-if="driver.relative_full_name">
            <td class="label-col">ФИО</td>
            <td>{{ driver.relative_full_name }}</td>
          </tr>
          <tr v-if="driver.relative_relationship">
            <td>Родство</td>
            <td>{{ driver.relative_relationship }}</td>
          </tr>
          <tr v-if="driver.relative_phone">
            <td>Контактный телефон</td>
            <td>{{ driver.relative_phone }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr class="mb-4">
    <div v-if="driver.is_blacklisted">
      <h5>Черный список</h5>
      <table class="table">
        <tbody>
          <tr>
            <td>Находится в ЧС</td>
            <td><font-awesome-icon icon="check" /></td>
          </tr>
          <tr>
            <td>Причина</td>
            <td>{{ driver.blacklist_reason }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DriverView',
  props: {
    id: {
      type: Number,
      default: null
    },
    driver: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped>
  .show-form-cars {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
</style>
