<template>
  <div>
    <SpeedPolyline
      v-if="speedMode"
      :lat-lngs="trackPath"
      :color="color"
      :speeds="track.map((point) => point[2])"
      :color-config="speedColors"
    />
    <LPolyline v-else :lat-lngs="trackPath" :color="color" />
    <LFeatureGroup>
      <LMarker :lat-lng="trackPath[0]">
        <LIcon
          :iconSize="[20, 40]"
          :iconAnchor="[12, 20]"
        >
          <div class="edge-icon" :style="{'border-color': color, color: color}">
            A
          </div>
        </LIcon>
      </LMarker>
      <VMarkerCluster
        ref="clusterRefHistory"
        :options="{
          spiderfyOnMaxZoom: false,
          disableClusteringAtZoom: 18
        }"
      >
        <LMarker
          v-for="(point, key) in trackMarkers"
          :key="key"
          :lat-lng="point.coords"
        >
          <LTooltip
            :options="{
              permanent: false,
              interactive: true,
              opacity: 0.8
            }"
          >
            <div class="selected-marker-tooltop">
              <ul>
                <li>Скорость: {{ Math.round(point.speed) }} км/ч</li>
                <li>Пробег: {{ Math.round(point.odometer / 1000) }} км</li>
                <li>Дата/время: {{ toHumanFormat(point.timestamp) }}</li>
                <li>Направление: {{ getDirection(point.angle) }}</li>
              </ul>
            </div>
          </LTooltip>
          <LIcon
            :iconSize="[20, 40]"
            :iconAnchor="[10, 28]"
          >
            <svg
              viewBox="0 0 92.53 122.88"
            >
              <path
                fill-rule="evenodd"
                :fill="color"
                d="M46.27,18.21A28.62,28.62,0,1,1,17.64,46.83,28.62,28.62,0,0,1,46.27,18.21Zm1.36,97.49A82.87,82.87,0,0,0,64,101.81,85.44,85.44,0,0,0,84.56,62.23c2.81-13.67,1.5-27-4.75-37.34a36.74,36.74,0,0,0-6.63-8.06C65.62,9.93,57,6.64,48.23,6.56,39.07,6.49,29.77,10,21.55,16.5a38.54,38.54,0,0,0-8.63,9.56C7.15,35.15,5.41,46.43,7.31,58.24c1.94,12,7.66,24.61,16.77,36A102.46,102.46,0,0,0,47.63,115.7Zm21.24-9.46a89.32,89.32,0,0,1-19.33,16,3.28,3.28,0,0,1-3.71.13,109.25,109.25,0,0,1-26.9-24c-9.8-12.31-16-26-18.1-39.1C-1.33,45.89.7,33,7.36,22.53a45.3,45.3,0,0,1,10.1-11.18C26.85,3.87,37.6-.09,48.29,0,58.6.09,68.79,3.92,77.6,12a43.1,43.1,0,0,1,7.82,9.52c7.15,11.8,8.71,26.83,5.57,42.05a92.2,92.2,0,0,1-22.12,42.7ZM47.3,30.14a3.86,3.86,0,0,1,4.19,4.2,4.53,4.53,0,0,1-1.91,3.55,6.92,6.92,0,0,1-4.48,1.56A4.39,4.39,0,0,1,42,38.38a3.67,3.67,0,0,1-1.16-2.83,4.84,4.84,0,0,1,1.9-3.81,6.77,6.77,0,0,1,4.54-1.6Zm-9.17,27.3h2.36V46.05H37.33c0-4.31,7.91-1.52,13.73-3V57.44H54v4.63H38.13V57.44Z"
              />
            </svg>
          </LIcon>
        </LMarker>
      </VMarkerCluster>

      <LMarker :lat-lng="trackPath[trackPath.length - 1]">
        <LIcon
          :iconSize="[20, 40]"
          :iconAnchor="[12, 20]"
        >
          <div class="edge-icon" :style="{'border-color': color, color: color}">
            B
          </div>
        </LIcon>
      </LMarker>
    </LFeatureGroup>
  </div>
</template>

<script>
import {
  LIcon,
  LMarker,
  LTooltip,
  LPolyline,
  LFeatureGroup
} from 'vue2-leaflet'
import { mapState, mapGetters } from 'vuex'
import SpeedPolyline from './SpeedPolyline'
import { djsMixin } from '@/mixins/djsMixin'
import VMarkerCluster from 'vue2-leaflet-markercluster'

export default {
  components: {
    LIcon,
    LMarker,
    LTooltip,
    LPolyline,
    SpeedPolyline,
    LFeatureGroup,
    VMarkerCluster
  },
  mixins: [djsMixin],
  props: {
    trackPath: {
      type: Array,
      default: Array
    },
    color: {
      type: String,
      default: '#0597f2'
    },
    track: {
      type: Array,
      default: Array
    },
    zoom: {
      type: Number,
      default: 11
    }
  },
  computed: {
    trackMarkers () {
      if (this.zoom < 15) {
        return []
      }
      const filteredTrack = this.track.filter((point, key, arr) => key != 0 && key != arr.length - 1 && key % 3 != 0)

      return filteredTrack.map((el) => ({
        coords: [el[0], el[1]],
        speed: el[2],
        odometer: el[3],
        timestamp: el[4],
        angle: el[5]
      }))
    },
    ...mapState('taxi/mapCars', {
      speedMode: state => state.speedMode
    }),
    ...mapGetters('configs/configs', [
      'speedColors'
    ])
  },
  methods: {
    getDirection (angle) {
      if (angle >= 337.5 || angle < 22.5) {
        return 'Север'
      } else if (angle >= 22.5 && angle < 67.5) {
        return 'Северо-восток'
      } else if (angle >= 67.5 && angle < 112.5) {
        return 'Восток'
      } else if (angle >= 112.5 && angle < 157.5) {
        return 'Юго-восток'
      } else if (angle >= 157.5 && angle < 202.5) {
        return 'Юг'
      } else if (angle >= 202.5 && angle < 247.5) {
        return 'Юго-запад'
      } else if (angle >= 247.5 && angle < 292.5) {
        return 'Запад'
      } else if (angle >= 292.5 && angle < 337.5) {
        return 'Северо-запад'
      } else {
        return 'Не определено'
      }
    }
  }
}
</script>
<style scoped lang="scss">
  @import "~leaflet/dist/leaflet.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

  .edge-icon {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border-width: 2px;
    border-style: solid;
    background: #fff;
    text-align: center;
    font-size: 19px;
    font-weight: bold;
  }
  .selected-marker-tooltop ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
    margin-bottom: 4px;
    margin-right: 0;
  }
  @media screen and (max-width: 576px) {
    .selected-marker-tooltop ul {
      font-size: 6px;
    }
  }
</style>
