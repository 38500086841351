<template>
  <div class="page">
    <HeaderTitle />
    <div class="pt-2">
      <router-link to="/configs/create" class="btn btn-primary w-100 mb-2">Добавить конфиг</router-link>
    </div>
    <div v-if="configs && configs.length > 0">
      <table class="table table-hover w-100 mt-3 d-none d-md-table">
        <TableSort class="sticky-1" :sorts="sorts" @sortsUpdate="loadWithSort" />
        <tbody>
          <tr v-for="config of configs" :key="config.id" @click="$router.push(`/configs/${config.id}`)">
            <td>{{ config.title }}</td>
            <td>
              <json-viewer
                :value="config.filter ? config.filter : []"
                :expand-depth="5"
                copyable
                expanded
                sort
              />
            </td>
            <td>
              <div v-if="config.author">
                {{ config.author.last_name }} {{ config.author.first_name }}
              </div>
            </td>
            <td>{{ config.created_at | formatDateTimeShort }}</td>
            <td>
              <div v-if="config.modifier">
                {{ config.modifier.last_name }} {{ config.modifier.first_name }}
              </div>
            </td>
            <td>
              <div v-if="config.updated_at">
                {{ config.updated_at | formatDateTimeShort }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-for="config of configs" :key="config.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
        <table class="table borderless">
          <tbody>
            <tr>
              <td style="border-top: none">Компонент</td>
              <td>{{ config.title }}</td>
            </tr>
            <tr>
              <td>Фильтр</td>
              <td>
                <json-viewer
                  :value="config.filter ? config.filter : []"
                  :expand-depth="5"
                  copyable
                  expanded
                  sort
                />
              </td>
            </tr>
            <tr>
              <td>Автор</td>
              <td>
                <div v-if="config.author">
                  {{ config.author.first_name }} {{ config.author.last_name }} {{ config.author.middle_name }}
                </div>
              </td>
            </tr>
            <tr>
              <td>Дата создания</td>
              <td>{{ config.created_at | formatDateTimeShort }}</td>
            </tr>
            <tr>
              <td>Автор последнего изменения</td>
              <td>
                <div v-if="config.modifier">
                  {{ config.modifier.first_name }} {{ config.modifier.last_name }} {{ config.modifier.middle_name }}
                </div>
              </td>
            </tr>
            <tr>
              <td>Дата последнего изменения</td>
              <td>{{ config.updated_at | formatDateTimeShort }}</td>
            </tr>
          </tbody>
        </table>
        <router-link
          :to="{ name: 'configPage', params: { configId: String(config.id) } }"
          class="btn btn-primary btn-md create-fines ml-auto"
        >
          Подробно
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import JsonViewer from 'vue-json-viewer'

export default {
  name: 'ConfigList',
  components: {
    JsonViewer
  },
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'Компонент',
          storeName: 'configs-title',
          values: ['', 'title', '-title'],
          order: 0
        },
        {
          name: 'Фильтр',
          storeName: 'configs-filter',
          values: ['', 'filter', '-filter'],
          order: 1
        },
        {
          name: 'Автор',
          storeName: 'configs-created_by',
          values: ['', 'created_by', '-created_by'],
          order: 2
        },
        {
          name: 'Дата создания',
          storeName: 'configs-created_at',
          values: ['', 'created_at', '-created_at'],
          order: 3
        },
        {
          name: 'Автор последнего изменения',
          storeName: 'configs-updated_by',
          values: ['', 'updated_by', '-updated_by'],
          order: 4
        },
        {
          name: 'Дата последнего изменения',
          storeName: 'configs-updated_at',
          values: ['', 'updated_at', '-updated_at'],
          order: 5
        }
      ],
      searchedText: null,
      interval: null
    }
  },
  computed: {
    ...mapState('configs/configs', {
      configs: state => state.items,
      pageCount: state => state.pageCount,
      searchText: state => state.searchText,
      isLoading: state => state.isLoading
    }),
  },
  mounted () {
    this.setTitle([
      {
        title: 'Настройки'
      }
    ])
    if (!this.interval) {
      this.loadWithSort(this.sorts)
    }
  },
  methods: {
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('configs/configs', [
      'loadItems',
      'setSorts'
    ]),
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page) })
    },
  }
}
</script>
