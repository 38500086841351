var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.speedMode)?_c('SpeedPolyline',{attrs:{"lat-lngs":_vm.trackPath,"color":_vm.color,"speeds":_vm.track.map(function (point) { return point[2]; }),"color-config":_vm.speedColors}}):_c('LPolyline',{attrs:{"lat-lngs":_vm.trackPath,"color":_vm.color}}),_c('LFeatureGroup',[_c('LMarker',{attrs:{"lat-lng":_vm.trackPath[0]}},[_c('LIcon',{attrs:{"iconSize":[20, 40],"iconAnchor":[12, 20]}},[_c('div',{staticClass:"edge-icon",style:({'border-color': _vm.color, color: _vm.color})},[_vm._v(" A ")])])],1),_c('VMarkerCluster',{ref:"clusterRefHistory",attrs:{"options":{
        spiderfyOnMaxZoom: false,
        disableClusteringAtZoom: 18
      }}},_vm._l((_vm.trackMarkers),function(point,key){return _c('LMarker',{key:key,attrs:{"lat-lng":point.coords}},[_c('LTooltip',{attrs:{"options":{
            permanent: false,
            interactive: true,
            opacity: 0.8
          }}},[_c('div',{staticClass:"selected-marker-tooltop"},[_c('ul',[_c('li',[_vm._v("Скорость: "+_vm._s(Math.round(point.speed))+" км/ч")]),_c('li',[_vm._v("Пробег: "+_vm._s(Math.round(point.odometer / 1000))+" км")]),_c('li',[_vm._v("Дата/время: "+_vm._s(_vm.toHumanFormat(point.timestamp)))]),_c('li',[_vm._v("Направление: "+_vm._s(_vm.getDirection(point.angle)))])])])]),_c('LIcon',{attrs:{"iconSize":[20, 40],"iconAnchor":[10, 28]}},[_c('svg',{attrs:{"viewBox":"0 0 92.53 122.88"}},[_c('path',{attrs:{"fill-rule":"evenodd","fill":_vm.color,"d":"M46.27,18.21A28.62,28.62,0,1,1,17.64,46.83,28.62,28.62,0,0,1,46.27,18.21Zm1.36,97.49A82.87,82.87,0,0,0,64,101.81,85.44,85.44,0,0,0,84.56,62.23c2.81-13.67,1.5-27-4.75-37.34a36.74,36.74,0,0,0-6.63-8.06C65.62,9.93,57,6.64,48.23,6.56,39.07,6.49,29.77,10,21.55,16.5a38.54,38.54,0,0,0-8.63,9.56C7.15,35.15,5.41,46.43,7.31,58.24c1.94,12,7.66,24.61,16.77,36A102.46,102.46,0,0,0,47.63,115.7Zm21.24-9.46a89.32,89.32,0,0,1-19.33,16,3.28,3.28,0,0,1-3.71.13,109.25,109.25,0,0,1-26.9-24c-9.8-12.31-16-26-18.1-39.1C-1.33,45.89.7,33,7.36,22.53a45.3,45.3,0,0,1,10.1-11.18C26.85,3.87,37.6-.09,48.29,0,58.6.09,68.79,3.92,77.6,12a43.1,43.1,0,0,1,7.82,9.52c7.15,11.8,8.71,26.83,5.57,42.05a92.2,92.2,0,0,1-22.12,42.7ZM47.3,30.14a3.86,3.86,0,0,1,4.19,4.2,4.53,4.53,0,0,1-1.91,3.55,6.92,6.92,0,0,1-4.48,1.56A4.39,4.39,0,0,1,42,38.38a3.67,3.67,0,0,1-1.16-2.83,4.84,4.84,0,0,1,1.9-3.81,6.77,6.77,0,0,1,4.54-1.6Zm-9.17,27.3h2.36V46.05H37.33c0-4.31,7.91-1.52,13.73-3V57.44H54v4.63H38.13V57.44Z"}})])])],1)}),1),_c('LMarker',{attrs:{"lat-lng":_vm.trackPath[_vm.trackPath.length - 1]}},[_c('LIcon',{attrs:{"iconSize":[20, 40],"iconAnchor":[12, 20]}},[_c('div',{staticClass:"edge-icon",style:({'border-color': _vm.color, color: _vm.color})},[_vm._v(" B ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }