<template>
  <div class="logout-toggle">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col text-center">
            <ul>
              <li class="menu-item"><div>{{ user.last_name }} {{ user.first_name }}</div></li>
              <li class="menu-item"><div class="italic">{{ user.username }}</div></li>
              <li class="divider" />
              <li class="menu-item clicable-item" @click="$emit('hide-logout')">
                <router-link :to="`/users/${user.id}`"><div>Профиль</div></router-link>
              </li>
              <li class="divider" />
              <li class="menu-item clicable-item"><a href="/back/site/logout"><div>Выход</div></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'UserContextMenu',
  computed: {
    ...mapState('taxi/user', {
      user: state => state.items.find(user => user.id === state.authedId)
    })
  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/globals.scss";
  .logout-toggle {
    width: 14rem;
    position: fixed;
    top: $bwlayuotHeader + 4;
    right: 18px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    padding-top: 20px;
    padding: 0;
    font-size: 14px;
    font-weight: 400;
  }
  .logout-toggle .card {
    background-color: white;
    width: 100%;
    border-radius: 5px;
  }
  .logout-toggle hr {
    margin: 1rem 0 0 0;
  }
  .logout-toggle .card .card-body {
    padding: 0;
  }
  .divider {
    height: 1px;
    margin: 0.25rem 0;
    padding: 0;
    background-color: #dfdfdf;
  }
  .logout-toggle ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
    margin-bottom: 4px;
    margin-right: 0;
  }
  .clicable-item div {
    padding: 4px 0 4px 0;
    width: 100%;
  }
  .clicable-item {
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    cursor: pointer;
  }
  .menu-item a {
    color: black;
    text-decoration: none;
  }
  .clicable-item:hover {
    background-color: rgb(239, 239, 239);
  }
  .menu-item div {
    padding-left: 12px;
    width: 100%;
  }
  .menu-item .italic {
    color: #4f4f4f;
    font-style: italic;
  }
  .logout-toggle .row {
    margin: 0;
  }
  .logout-toggle .col {
    padding: 0;
  }
</style>
