<template>
  <div style="display: none;">
    <slot v-if="ready" />
  </div>
</template>

<script>
import { optionsMerger, propsBinder, findRealParent } from 'vue2-leaflet/src/utils/utils.js'
import PolylineMixin from 'vue2-leaflet/src/mixins/Polyline.js'
import Options from 'vue2-leaflet/src/mixins/Options.js'
import L, { DomEvent } from 'leaflet'
import 'Leaflet.MultiOptionsPolyline'

export default {
  name: 'SpeedPolyline',
  mixins: [PolylineMixin, Options],
  props: {
    latLngs: {
      type: Array,
      default: Array
    },
    speeds: {
      type: Array,
      default: Array
    },
    colorConfig: {
      type: Array,
      default: Array
    },
  },
  data () {
    return {
      ready: false,
      defaultColors: [{
        color: '#00FF00',
        from: 0,
        to: 30
      }, {
        color: '#FFFF00',
        from: 30,
        to: 90
      }, {
        color: '#FF0000',
        from: 90,
        to: 1000
      }]
    }
  },
  computed: {
    colors () {
      return (this.colorConfig && this.colorConfig.length)
        ? this.colorConfig
        : this.defaultColors
    }
  },
  mounted() {
    const options = optionsMerger(this.polyLineOptions, this)
    this.mapObject = L.multiOptionsPolyline(this.latLngs, {
        multiOptions: {
          optionIdxFn: function (latLng, prevLatLng, index) {
            // берем скорость из массива скоростей по соответствующему индексу
            let speed = this.speeds[index]

            let colorIndex = this.colors.findIndex((el) => speed >= el.from && speed < el.to)
            return colorIndex >= 0 ? colorIndex : 0
          },
          fnContext: this,
          options: this.colors.map((el) => ({color: el.color}))
        },
        smoothFactor: 1,
        ...options
      })
    DomEvent.on(this.mapObject, this.$listeners)
    propsBinder(this, this.mapObject, this.$options.props)
    this.ready = true
    this.parentContainer = findRealParent(this.$parent)
    this.parentContainer.addLayer(this, !this.visible)
    this.$nextTick(() => {
      /**
       * Triggers when the component is ready
       * @type {object}
       * @property {object} mapObject - reference to leaflet map object
       */
      this.$emit('ready', this.mapObject)
    })
  },
}
</script>
