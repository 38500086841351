<template>
  <div class="page" @scroll="onScroll">
    <HeaderTitle />
    <div class="d-flex flex-column sticky-1">
      <div class="input-group without-separator">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <font-awesome-icon icon="search" style="color:gray" />
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Поиск по марке, модели, рег. номеру"
          aria-label="Поиск по марке, модели, рег. номеру"
          :value="searchText"
          @input="searchFines"
        >
      </div>
    </div>
    <table class="table table-hover w-100 mt-3 d-none d-md-table">
      <TableSort class="sticky-2" :sorts="sorts" @sortsUpdate="loadWithSort" />
      <tbody>
        <tr v-for="car of cars" :key="car.id" @click="$router.push(`/fines/car/${car.id}`)">
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.brand }} {{ car.model }} {{ car.registration_plate }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ getDriver(car) }}</TextHighlight>
          </td>
          <td><FinesCountState :count="car.activeFines.length" /></td>
          <td><FinesCountState :count="getMonthFines(car)" /></td>
          <td><FinesCountState :count="getYearFines(car)" /></td>
          <td>
            <div :class="`alert ${stateClass(car)} alert-state`">{{ car.check_fines ? 'Включена' : 'Выключена' }}</div>
          </td>
          <td>
            <TextHighlight v-if="car.last_check_fines" :queries="searchText">{{ car.last_check_fines | formatDate }}</TextHighlight>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-for="car of cars" :key="car.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
      <table v-if="cars.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Автомобиль</td>
            <td style="border-top: none">
              <TextHighlight class="highlighter" :queries="searchText">{{ car.brand }} {{ car.model }} {{ car.registration_plate }}</TextHighlight>
            </td>
          </tr>
          <tr v-if="car.driver">
            <td>Водитель</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ getDriver(car) }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Активных штрафов</td>
            <td><FinesCountState :count="car.activeFines.length" :danger-count="0" :warning-count="0" /></td>
          </tr>
          <tr>
            <td>Кол-во штрафов за месяц</td>
            <td><FinesCountState :count="getMonthFines(car)" :danger-count="1" :warning-count="0" /></td>
          </tr>
          <tr>
            <td>Кол-во штрафов за год</td>
            <td><FinesCountState :count="getYearFines(car)" :danger-count="1" :warning-count="0" /></td>
          </tr>
          <tr>
            <td>Проверка штрафов</td>
            <td>
              <div :class="`alert ${stateClass(car)} alert-state`">{{ car.check_fines ? 'Включена' : 'Выключена' }}</div>
            </td>
          </tr>
          <tr>
            <td>Дата проверки</td>
            <td>
              <TextHighlight v-if="car.last_check_fines" :queries="searchText">{{ car.last_check_fines | formatDate }}</TextHighlight>
            </td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="{ name: 'finesPage', params: { carId: String(car.id) } }"
        class="btn btn-primary btn-md create-fines ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { djsMixin } from '@/mixins/djsMixin'
import dayjs from 'dayjs'
import FinesCountState from '../components/FinesCountState'

export default {
  name: 'FinesList',
  components: {FinesCountState},
  mixins: [djsMixin],
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'Автомобиль',
          storeName: 'fines-car-brand',
          values: ['', 'brand', '-brand'],
          order: 0
        },
        {
          name: 'Водитель',
          storeName: 'fines-driver',
          values: ['', 'brand', '-brand'],
          order: 1
        },
        {
          name: 'Кол-во штрафов',
          storeName: 'fines-amount',
          values: ['', 'nextActiveFines', '-nextActiveFines'],
          order: 2
        },
        {
          name: 'Кол-во штрафов за месяц',
          storeName: 'fines-amount-month',
          values: ['', 'lastMonthFines', '-lastMonthFines'],
          order: 3
        },
        {
          name: 'Кол-во штрафов за год',
          storeName: 'fines-amount-year',
          values: ['', 'lastYearFines', '-lastYearFines'],
          order: 4
        },
        {
          name: 'Проверка штрафов',
          storeName: 'check-fines',
          values: ['', 'check_fines', '-check_fines'],
          order: 5
        },
        {
          name: 'Дата проверки',
          storeName: 'last_check_fines',
          values: ['', 'last_check_fines', '-last_check_fines'],
          order: 6
        }
      ],
      searchedText: null,
      interval: null,
      scrollToOldValue: true
    }
  },
  computed: {
    ...mapState('fines/fines', {
      stateClass () {
        return car => {
          if (car && car.check_fines) {
            return 'alert-success'
          }
          return 'alert-danger'
        }
      },
      cars: state => state.items,
      pageCount: state => state.pageCount,
      searchText: state => state.searchText,
      isLoading: state => state.isLoading
    }),
    getDriver () {
      return (car) => {
        if (!car.driver) {
          return ''
        }
        return car.driver.last_name + ' ' + car.driver.first_name + ' ' + car.driver.middle_name
      }
    },
    getMonthFines () {
      return (car) => {
        return car.fines.filter(fine => {
          const date = dayjs(fine.date)
          const dateMonthAgo = dayjs().subtract(1, 'month')
          return date > dateMonthAgo
        }).length
      }
    },
    getYearFines () {
      return (car) => {
        return car.fines.filter(fine => {
          const date = dayjs(fine.date)
          const dateYearAgo = dayjs().subtract(1, 'year')
          return date > dateYearAgo
        }).length
      }
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'штрафы ГИБДД'
      }
    ])
    if (!this.interval) {
      this.loadWithSearch()
    }
  },
  beforeDestroy () {
    this.abortLoading()
    clearTimeout(this.interval)
    this.interval = null
  },
  methods: {
    ...mapActions('fines/fines', [
      'loadItems',
      'setSearchText',
      'setSearch',
      'abortLoading',
      'setSorts'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    loadWithSearch () {
      if (!this.isLoading && this.searchedText !== this.searchText) {
        this.searchedText = this.searchText
        return this.loadItems({ page: Number(this.page) })
          .then(() => {
            this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
          })
      }
      this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
    },
    searchFines (e) {
      this.setSearchText(e.target.value)
      this.setSearch({ key: 'brand', value: e.target.value })
      this.setSearch({ key: 'model', value: e.target.value })
      this.setSearch({ key: 'reg_plate_number', value: e.target.value })
    },
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page) })
        .then(() => {
          if (this.scrollToOldValue) {
            this.scrollToOldValue = false
            this.$el.scrollTo(0, localStorage.finesSrollPositions)
          }
        })
    },
    onScroll (e) {
      if (e.target.scrollTop) {
        localStorage.finesSrollPositions = e.target.scrollTop
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding-top: 1rem;
  height: 100%;
  overflow-y: auto;
}
@media screen and (max-width: 1199px) {
  .page {
    padding: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .page {
    padding: 0.5rem;
  }
}
.highlighter ::v-deep .text__highlight {
  border-radius: 0px;
  padding: 0.2em 0 0.2em 0;
  background: #fff2ae;
}
.fixed-search {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
  background: white;
}
.alert-state {
  margin: 0;
  width: fit-content;
  height: fit-content;
  line-height: 30px;
  padding: 0 15px;
  min-width: 10rem;
  text-align: center;
  border-radius: 15px;
  border: none;
}
.alert-danger {
  color: #BF543B;
  background-color: rgba(191, 84, 59, 0.15);
}
.alert-success {
  color: #92BF4E;
  background-color: rgba(146, 191, 78, 0.15);
}
</style>
