<template>
  <div class="edit-form-users">
    <div class="form-group">
      <table class="table">
        <tbody>
          <tr>
            <td class="label-col">Фамилия</td>
            <td>
              <input
                v-model="component.user.last_name"
                type="text"
                class="form-control form-control-sm"
                placeholder
                required
              >
            </td>
          </tr>
          <tr>
            <td class="label-col">Имя</td>
            <td>
              <input
                v-model="component.user.first_name"
                type="text"
                class="form-control form-control-sm"
                placeholder
                required
              >
            </td>
          </tr>
          <tr>
            <td class="label-col">Отчество</td>
            <td>
              <input
                v-model="component.user.middle_name"
                type="text"
                class="form-control form-control-sm"
                placeholder
                required
              >
            </td>
          </tr>
          <tr>
            <td class="label-col">Email</td>
            <td>
              <input
                v-model="component.user.email"
                type="text"
                class="form-control form-control-sm"
                placeholder
                required
              >
            </td>
          </tr>
          <tr>
            <td class="label-col">Логин</td>
            <td>
              <input
                v-model="component.user.username"
                type="text"
                class="form-control form-control-sm"
                placeholder
                required
              >
            </td>
          </tr>
          <tr>
            <td class="label-col">Роли</td>
            <td>
              <Mselect
                v-model="component.user.selectedRoles"
                :options="component.user.accessibleRoles"
                option-key="name"
                option-label="name"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <hr class="mb-4">
  </div>
</template>

<script>
export default {
  name: 'UserEdit',
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      component: {
        user: {}
      }
    }
  },
  watch: {
    value: {
      handler () {
        this.component.user = this.value
      },
      immediate: true
    },
    component: {
      handler () {
        this.$emit('input', this.component.user)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .edit-form-users {
    margin-top: 2rem;
  }
  .label-col {
    width: 25%;
  }
</style>
