<template>
  <div v-if="document" class="card">
    <div class="card-header">
      <ul class="nav nav-tabs justify-content-left">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" :href="`#data${document.id}`">{{ documentType.tabs.title }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" :href="`#scans${document.id}`">{{ documentType.tabs.scansTittle }}</a>
        </li>
        <span class="tab-bar" />
      </ul>
    </div>
    <div>
      <div class="card-body">
        <div class="tab-content">
          <div :id="`data${document.id}`" :ref="`data${document.id}`" class="tab-pane fade show active">
            <component
              :is="documentType.view.name"
              :id="document.id"
              :documentData="document.data"
            />
            <div class="float-right btn-group">
              <button class="btn btn-link" @click="$emit('actionDelte', document.id)">Удалить</button>
              <button class="btn btn-link" @click="$emit('actionUpdate', document.id)">Изменить</button>
            </div>
          </div>
          <div :id="`scans${document.id}`" class="tab-pane fade loader">
            <ScansGallery :documentId="document.id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScansGallery from './scans/ScansGallery'
import carDocumentTypes from '@/views/carDocuments/types'
import driverDocumentTypes from '@/views/driverDocuments/types'

const components = {
  ScansGallery
}
for (const typeName in carDocumentTypes) {
  components[carDocumentTypes[typeName].view.name] = carDocumentTypes[typeName].view.component
}
for (const typeName in driverDocumentTypes) {
  components[driverDocumentTypes[typeName].view.name] = driverDocumentTypes[typeName].view.component
}

export default {
  name: 'Document',
  components,
  props: {
    document: {
      type: Object,
      default: null
    },
  },
  computed: {
    documentType () {
      return carDocumentTypes[this.document.type] || driverDocumentTypes[this.document.type]
    }
  }
}
</script>

<style scoped>
  .card {
    width: 100%;
    margin-top: 1rem;
  }
  .tab-pane {
    padding: 20px;
    position: relative;
  }
  .card-header {
    padding-bottom: 0;
    background-color: white !important;
  }
</style>
