<template>
  <div class="page page-404">
    <h1 class="h2">404</h1>
    <h2 class="h3">Страница не найдена</h2>
    <router-link to="/">На главную</router-link>
  </div>
</template>

<script>
export default {
  name: 'Http404'
}
</script>

<style scoped>
  .page-404 {
    max-width: 420px;
    width: 100%;
    position: fixed;
    left: 50%;
    top: 33%;
    transform: translate(-50%, -33%);
    padding: 10px;
  }
</style>
