import moduleTemplate from '@/store/moduleTemplate'
import characteristic from '@/api/characteristics'

const template = moduleTemplate(characteristic)

template.types = {
  ...template.types,
  setSearchText: 'setSearchText'
}
template.mutations = {
  ...template.mutations,
  [template.types.setSearchText] (state, value) {
    state.searchText = value
  }
}
template.state = {
  ...template.state,
  searchText: '',
}

template.actions = {
  ...template.actions,
  setSearchText ({ commit }, searchText) {
    commit(template.types.setSearchText, searchText)
  }
}
export default template
