import APITemplate from '@/api/APITemplate'

const template = APITemplate('/api/service-car')
export default {
    ...template,
    getAnalysis(config) {
      const params = new URLSearchParams()
      params.append('startDateTime', config.startDateTime.toString())
      params.append('endDateTime', config.endDateTime.toString())
      if (config && config.page !== undefined && config.pageSize !== undefined) {
        params.append('pagination[page]', (config.page - 1).toString())
        params.append('pagination[pageSize]', config.pageSize)
      }
      if (config && config.sort) {
        params.append('sort', config.sort.toString())
      }
      return fetch(`/api/service-car/analysis?${params}`, {
          method: 'PUT',
          credentials: 'include',
          redirect: 'error',
          headers: template.headers,
      }).then(response => response.json())
    },
}
