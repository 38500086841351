const headers = {
  accept: 'application/json',
  'content-type': 'application/json'
}

const errorHandler = response => {
  if (response.status >= 400) {
    return response.json()
      .then(error => {
        if (error) {
          throw new Error(`${error.message}`)
        }
      })
  }
  return response.json()
}

class Command {
  carId
  method = ''// [lockIgnition, unlockIgnition, fastConfig, directCommand]
  // params.remainingAttempts
  params = null
}

export default {
  /**
   * Отправка команды на машину.
   * @param {Command} request команда для сервера
   * @returns {Promise<object>} ответ от сервера
   */
  sendCommand (request) {
    return fetch('/api/device/send-command', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      headers,
      body: JSON.stringify(request)
    })
      .then(response => errorHandler(response))
  },

  /**
   * Отмена задачи на отправку команды.
   * request.id
   * @param {object} request параметры запроса
   * @returns {Promise<object>} результат отмены задачи
   */
  taskReject (request) {
    return fetch('/api/device/task-reject', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      headers,
      body: JSON.stringify(request)
    })
      .then(response => errorHandler(response))
  }
}
