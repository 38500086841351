<template>
  <div v-if="component" class="page">
    <HeaderTitle />
    <ActionBar
      :isEditing="isEditing"
      @changeAction="changeComponent"
      @cancelAction="cancelComponentChanges"
      @deleteAction="deleteComponent"
      @saveAction="updateComponent"
    />
    <br>
    <ComponentShow v-if="!isEditing" :component="component" />
    <ComponentEdit v-else :component="component" />
  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import ConfigComponentDefinitionAPI from '../../api/configComponentDefinition'
import deepAssign from 'assign-deep'
import ComponentEdit from './ComponentEdit'
import Swal from 'sweetalert2'
import ComponentShow from './ComponentShow'

export default {
  name: 'ComponentPage',
  components: {ComponentShow, ComponentEdit},
  props: {
    componentId: {
      type: String,
      default: () => null
    }
  },
  data () {
    return {
      isEditing: false,
      component: null,
      prevComponent: null,
      isUpdating: false
    }
  },
  computed: {
    ...mapState('configs/configComponentDefinition', {
      configComponentDefinitions: state => state.items,
    }),
  },
  mounted () {
    this.loadData()
    this.loadItems()
  },
  methods: {
    ...mapActions('configs/configComponentDefinition', [
      'loadItems'
    ]),
    ...mapActions('configs/configs', [
      'abortLoading'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    loadData () {
      ConfigComponentDefinitionAPI.getItem({
        id: this.componentId,
      })
        .then(response => {
          this.component = response
          if (!response) {
            return false
          }
          this.setTitle([
            {
              title: 'Настройки',
              url: '/components'
            }, {
              title: response.title ?? response.component_name
            }
          ])
        })
    },
    changeComponent () {
      this.prevComponent = deepAssign({}, this.component)
      this.isEditing = true
    },
    deleteComponent () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить компонент?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          ConfigComponentDefinitionAPI.removeItem(this.component.id)
            .then(() => {
              this.$router.push('/components')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    cancelComponentChanges () {
      this.component = this.prevComponent
      this.isEditing = false
    },
    updateComponent () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      ConfigComponentDefinitionAPI.updateItem(this.component)
        .then(component => {
          this.component = { ...this.component, ...component.model }
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
  }
}
</script>
