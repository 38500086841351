<template>
  <div>
    <button class="btn btn-primary w-100" @click="goAddPage()">Добавить</button>
    <table class="table table-hover w-100 mt-3 d-none d-md-table">
      <thead>
        <tr>
          <th>Пользователь</th>
          <th>Должность</th>
          <th>В лице</th>
          <th>На основании</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="proxy of proxies"
          :key="proxy.id"
          class="tablerow"
          @click="chooseProxy(proxy)"
        >
          <td>{{ proxy.user.last_name }} {{ proxy.user.first_name }} {{ proxy.user.middle_name }}</td>
          <td>{{ proxy.position }}</td>
          <td>{{ proxy.representative }}</td>
          <td>{{ proxy.based }}</td>
        </tr>
      </tbody>
    </table>
    <div v-for="proxy of proxies" :key="proxy.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
      <table v-if="proxies.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Пользователь</td>
            <td style="border-top: none">{{ proxy.user.last_name }} {{ proxy.user.first_name }} {{ proxy.user.middle_name }}</td>
          </tr>
          <tr>
            <td>Должность</td>
            <td>{{ proxy.position }}</td>
          </tr>
          <tr>
            <td>В лице</td>
            <td>{{ proxy.representative }}</td>
          </tr>
          <tr>
            <td>На основании</td>
            <td>{{ proxy.based }}</td>
          </tr>
        </tbody>
      </table>
      <button
        class="btn btn-primary btn-md create-car ml-auto"
        @click="chooseProxy(proxy)"
      >
        Подробно
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ProxiesList',
  props: {
    proxies: {
      type: Array,
      default: Array
    }
  },
  methods: {
    chooseProxy (proxy) {
      this.$emit('chooseProxy', proxy)
    },
    goAddPage () {
      this.$emit('goAddPage')
    }
  }
}
</script>

<style scoped>
  .tablerow {
    cursor: pointer;
  }
</style>
