import LlcEdit from './llc/Edit'
import LlcView from './llc/View'
import IeEdit from './ie/Edit'
import IeView from './ie/View'

export default {
  ie: {
    title: 'ИП',
    edit: {
      name: 'IeEdit',
      component: IeEdit
    },
    view: {
      name: 'IeView',
      component: IeView
    },
    tabs: {
      title: 'ИП',
    }
  },
  llc: {
    title: 'ООО',
    edit: {
      name: 'LlcEdit',
      component: LlcEdit
    },
    view: {
      name: 'LlcView',
      component: LlcView
    },
    tabs: {
      title: 'ООО',
    }
  },
}
