<template>
  <div style="display: contents">
    <tr style="opacity: 0.6">
      <td colspan="2">
        Общая информация
      </td>
    </tr>
    <tr>
      <td class="label-col">Наименование</td>
      <td>
        <input
          v-model="company.name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </td>
    </tr>
    <tr>
      <td class="label-col">ИНН</td>
      <td>
        <input
          v-model="company.inn"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </td>
    </tr>
    <tr>
      <td class="label-col">Юр. адрес</td>
      <td>
        <textarea
          v-model="company.address"
          class="form-control"
          placeholder
          required
        />
      </td>
    </tr>
    <tr>
      <td class="label-col">Расч. счет</td>
      <td>
        <input
          v-model="company.rs_number"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </td>
    </tr>
    <tr>
      <td class="label-col">Банк расч. счета</td>
      <td>
        <textarea
          v-model="company.rs_bank_name"
          class="form-control"
          placeholder
          required
        />
      </td>
    </tr>
    <tr>
      <td class="label-col">Кор. счет</td>
      <td>
        <input
          v-model="company.ks"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </td>
    </tr>
    <tr>
      <td class="label-col">БИК</td>
      <td>
        <input
          v-model="company.bik"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </td>
    </tr>
    <tr>
      <td class="label-col">ФИО для подписи</td>
      <td>
        <input
          v-model="company.sign_name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </td>
    </tr>
    <tr>
      <td class="label-col">Компания, принимающая оплату</td>
      <td>
        <VSelect
          id="selectorCar"
          v-model="company.recipient_company_id"
          :options="companies"
          :reduce="companyItem => companyItem.id"
          label="name"
        />
      </td>
    </tr>
  </div>
</template>

<script>
export default {
  name: 'CommonEdit',
  props: {
    company: {
      type: Object,
      default: () => null
    },
    companies: {
      type: Array,
      default: null
    },
  },
}
</script>
