import APITemplate from '@/api/APITemplate'

const url = '/api/accident'

const template = APITemplate(url)
export default {
  ...template,
  getScans (accidentId) {
    const request = '/api/accident/scans?id=' + accidentId
    return fetch(request, {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(template.errorHandler)
  }
}
