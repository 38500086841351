<template>
  <div class="day-wrapper">
    <button
      class="btn btn-default summary"
      data-toggle="collapse"
      :data-target="'#' + dayListId"
      :aria-expanded="standAlone"
      :aria-controls="dayListId"
    >
      <span v-if="date && !standAlone"> {{ date }}</span>
      <TripsTotal :trips="trips" />
    </button>
    <div
      :id="dayListId"
      class="collapse"
      :class="{show: standAlone}"
    >
      <br>
      <ul class="list-group">
        <li
          v-for="(trip, i) in trips"
          :key="i"
          class="list-group-item trip-card"
          role="button"
          tabindex="0"
          @click="selectTrack(trip)"
        >
          <div class="trip-card-body">
            <div class="col-trip">
              <font-awesome-icon
                :icon="trip.type === 'parked' ? 'parking' : 'car'"
                :style="{
                  color: trip.type === 'parked' ? '#62ADBF' : '#92BF4E',
                  width: '30px',
                  height: '30px',
                }"
              />
            </div>
            <div class="trip-data">
              <template v-if="trip.type === 'parked'">
                <div class="parked">
                  <span>{{ formatTime(trip.startTime) }}</span>
                  <hr
                    width="15%"
                    style="border-top-width: 3px; border-top-color: grey;"
                  >
                  <span>{{ formatTime(trip.endDate) }}</span>
                </div>
              </template>
              <template v-else>
                <div class="traveled">
                  <span>{{ Math.round((factMileage ? trip.startOdometerCorrected : trip.startOdometer) / 1000) }} км.</span>
                  <span>{{ Math.round((factMileage ? trip.endOdometerCorrected : trip.endOdometer) / 1000) }} км.</span>
                </div>
                <div class="traveled-path">
                  <div class="edge-icon" :style="{'border-color': getColor(trip), color: getColor(trip)}">
                    A
                  </div>
                  <hr width="55%" :style="{'border-color': getColor(trip)}">
                  <div class="edge-icon" :style="{'border-color': getColor(trip), color: getColor(trip)}">
                    B
                  </div>
                </div>
                <div class="traveled">
                  <span>{{ formatTime(trip.startTime) }}</span>
                  <span>{{ formatTime(trip.endDate) }}</span>
                </div>
              </template>
            </div>
            <div class="spaced-items">
              <div class="col-trip">
                <font-awesome-icon
                  icon="clock"
                  :style="{ color: '#55606a', 'margin-right': '0.1rem' }"
                />
                <span>{{ formatDuration(trip.dailyDuration) }}</span>
              </div>
              <div
                class="col-trip"
                :class="{ undisplayed: trip.type === 'parked' }"
              >
                <font-awesome-icon
                  icon="road"
                  :style="{
                    color: '#55606a',
                    'margin-right': '0.1rem'
                  }"
                />
                <span>{{ trip.deltaOdometer ? Math.round(trip.deltaOdometer / 1000) : 0 }} км</span>
              </div>
              <div
                class="col-trip"
                :class="{ undisplayed: trip.type === 'parked' }"
              >
                <font-awesome-icon
                  icon="tachometer-alt"
                  :style="{ color: '#55606a', 'margin-right': '0.1rem' }"
                />
                <span class="icon-text">{{ trip.maxSpeed || 0 }} км/ч</span>
              </div>
            </div>
            <font-awesome-icon
              icon="route"
              class="track-icon"
              :class="{ 'selected-trip-icon': isSelectedTrack(trip) }"
              :style="{
                color: 'rgba(64, 64, 64, 0.2)',
                width: '30px',
                height: '30px',
                'margin-left': 'auto',
                transition: 'visibility 0.05s linear,opacity 0.05s linear !important',
                visibility: 'hidden',
                opacity: '0'
              }"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState, mapActions } from 'vuex'
import { formatDifference } from './functions'
import TripsTotal from './Totals'

export default {
  name: 'TripsDay',
  components: {
    TripsTotal
  },
  props: {
    car: {
      type: Object,
      default: null
    },
    date: {
      type: String,
      default: null
    },
    trips: {
      type: Array,
      default: Array
    },
    standAlone: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    formatTime () {
      return dateString => {
        if (!dateString) {
          return null
        }
        const date = new Date(dateString)
        return String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0')
      }
    },
    dayListId () {
      return 'day-list-' + dayjs(this.date, ['DD.MM.YYYY', 'DD MMMM'], 'ru').format('DDMMYYYY')
    },
    ...mapState('taxi/mapCars', {
      tracks: state => state.tracks,
      factMileage: state => state.factMileage
    }),
  },
  beforeDestroy () {
    this.clearTracks()
  },
  methods: {
    formatDuration (diff) {
      return formatDifference(diff)
    },
    selectTrack (trip) {
      if (this.isSelectedTrack(trip)) {
        this.removeFromTracks({carId: this.car.id, timeFrom: trip.startTime})
        return
      }
      this.addToTracks({
        id: this.car.id,
        timeFrom: trip.startTime,
        timeTo: trip.endTime
      })
    },
    isSelectedTrack (trip) {
      return this.tracks.findIndex(el => el.carId == this.car.id && el.timeFrom == trip.startTime) != -1
    },
    getColor (trip) {
      return this.tracks.find(el => el.carId == this.car.id && el.timeFrom == trip.startTime)?.color ?? '#495057'
    },
    ...mapActions('taxi/mapCars', [
      'addToTracks',
      'clearTracks',
      'removeFromTracks',
    ]),
  },
}
</script>
<style lang="scss" scoped>
  .summary {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .trip-card-body {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.25rem;
    justify-content: space-evenly;
  }
  .col-trip {
    display: flex;
    align-content: center;
    flex-direction: column;
    margin: 0 0.4rem;
    flex-flow: nowrap;
    align-items: center;
  }
  .spaced-items {
    display: flex;
    flex-direction: column;
    width: 100%;
    width: fit-content;
    padding: 0 1.25rem;
    flex-wrap: wrap;
  }
  .spaced-items .col-trip {
    flex-grow: 1;
  }
  .trip-card {
    padding: 0;
    width: auto;
  }
  .undisplayed {
    display: none;
  }
  .hidden {
    visibility: hidden;
  }
  .list-group-item:hover  {
    .track-icon {
      visibility: visible !important;
      opacity: 1 !important;
    }
    border-radius: 5px;
    box-shadow: 3px 2px 4px 0px rgba(64, 64, 64, 0.6);
    z-index: 999;
  }
  .selected-trip-icon {
    visibility: visible !important;
    opacity: 1 !important;
    color: rgb(146, 191, 78) !important;
  }
  .parked {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .traveled {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .traveled-path {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .traveled-path > hr {
    margin-right: 0;
    margin-left: 0;
    border-top-style: dashed;
    border-top-width: 3px;
  }
  .trip-data {
    display: flex;
    flex-direction: column;
    width: 45%;
  }
  .edge-icon {
    border-radius: 50%;
    width: 25px;
    height: 25px;
    border-width: 2px;
    border-style: solid;
    background: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .day-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
</style>
