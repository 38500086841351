<template>
  <div class="page">
    <HeaderTitle />
    <ComponentEdit :component="component" />
    <button class="btn btn-primary float-right" @click="createContract()">Добавить</button>
    <router-link to="/components" class="float-right mr-2">
      <button class="btn btn-secondary ml-2">Отменить</button>
    </router-link>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import ConfigComponentDefinitionAPI from '../../api/configComponentDefinition'
import { mapActions } from 'vuex'
import ComponentEdit from './ComponentEdit'

export default {
  name: 'CreateRent',
  components: {ComponentEdit},
  data () {
    return {
      isCreating: false,
      component: {}
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'Компоненты',
        url: '/components'
      },
      {
        title: 'Добавить'
      }
    ])
  },
  provide () {
    return { config: this.config }
  },
  methods: {
    createContract () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      ConfigComponentDefinitionAPI.createItem(this.component)
        .then(component => {
          this.isCreating = false
          this.$router.push(`/components/?=${component.id}`)
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
