<template>
  <div>
    <table class="table borderless">
      <tbody>
        <tr>
          <td style="border-top: none">Машинное имя</td>
          <td style="border-top: none">{{ component.name }}</td>
        </tr>
        <tr>
          <td>Название</td>
          <td>{{ component.title }}</td>
        </tr>
        <tr>
          <td>Подсказки</td>
          <td>
            <json-viewer
              :value="component.hints ? component.hints : []"
              :expand-depth="5"
              copyable
              boxed
              sort
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import JsonViewer from 'vue-json-viewer'

export default {
  name: 'ComponentShow',
  components: {
    JsonViewer
  },
  props: {
    component: {
      type: Object,
      default: () => null
    }
  }
}
</script>
