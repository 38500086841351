<template>
  <div>
    <h5>Ответ пользователю</h5>
    <table class="table borderless">
      <tbody>
        <tr>
          <td>Статус</td>
          <td>
            <VSelect
              v-model="feedback.status"
              class="col p-0"
              :options="['created', 'in_progress', 'closed', 'rejected']"
            >
              <template v-slot:option="option">
                <FeedbackStatus :status="option.label" />
              </template>
              <template v-slot:selection>
                {{ selection }}
                <FeedbackStatus :status="selection.label" />
              </template>
            </VSelect>
          </td>
        </tr>
        <tr>
          <td>Комментарий</td>
          <td>
            <textarea
              v-model="feedback.comment"
              rows="5"
              type="text"
              class="form-control"
              placeholder
              required
            />
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-primary w-100" @click="$refs.filesAdmin.click()">Загрузить файлы</button>
    <input
      ref="filesAdmin"
      type="file"
      style="display:none"
      multiple
      @change="addFilesFromDialog($event, true)"
    >
    <h5>Прикрепленные файлы</h5>
    <FileListViewer :files="feedback.adminFiles" @archivateFile="archivateFile" />
    <br>
    <h5>Данные с формы</h5>
    <table class="table borderless">
      <tbody>
        <tr>
          <td>Просмотревший</td>
          <td>
            <VSelect
              v-model="feedback.seen_by_user_id"
              :options="users"
              :reduce="user => user.id"
              label="fullName"
            />
          </td>
        </tr>
        <tr>
          <td>Пользователь</td>
          <td>
            <VSelect
              v-model="feedback.user_id"
              :options="users"
              :reduce="user => user.id"
              label="fullName"
            />
          </td>
        </tr>
        <tr>
          <td>ФИО</td>
          <td>
            <input
              v-model="feedback.fullname"
              type="text"
              class="form-control form-control-sm"
              placeholder
              required
            >
          </td>
        </tr>
        <tr>
          <td>Эл.почта</td>
          <td>
            <input
              v-model="feedback.email"
              type="text"
              class="form-control form-control-sm"
              placeholder
            >
          </td>
        </tr>
        <tr>
          <td>Телефон</td>
          <td>
            <input
              v-model="feedback.phone"
              type="text"
              class="form-control form-control-sm"
              placeholder
            >
          </td>
        </tr>
        <tr>
          <td>Сообщение</td>
          <td>
            <textarea
              v-model="feedback.message"
              rows="5"
              type="text"
              class="form-control"
              placeholder
              required
            />
          </td>
        </tr>
        <tr>
          <td>IP-адрес</td>
          <td>
            <input
              v-model="feedback.ip"
              type="text"
              class="form-control form-control-sm"
              placeholder
            >
          </td>
        </tr>
        <tr>
          <td>Дата создания</td>
          <td>
            <input
              v-model="date"
              type="datetime-local"
              class="form-control form-control-sm"
              placeholder
            >
          </td>
        </tr>
        <tr>
          <td>Ссылка на страницу</td>
          <td>
            <input
              v-model="feedback.url"
              type="text"
              class="form-control form-control-sm"
              placeholder
            >
          </td>
        </tr>
        <tr>
          <td>Разрешение окна браузера</td>
          <td>
            <input
              v-model="feedback.resolution"
              type="text"
              class="form-control form-control-sm"
              placeholder
            >
          </td>
        </tr>
        <tr>
          <td>User Agent</td>
          <td>
            <input
              v-model="feedback.user_agent"
              type="text"
              class="form-control form-control-sm"
              placeholder
            >
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-primary w-100" @click="$refs.files.click()">Загрузить файлы</button>
    <input
      ref="files"
      type="file"
      style="display:none"
      multiple
      @change="addFilesFromDialog($event)"
    >
    <h5>Прикрепленные файлы</h5>
    <FileListViewer :files="feedback.files" @archivateFile="archivateFile" />
  </div>
</template>

<script>

import dayjs from 'dayjs'
import FileListViewer from '@/components/FileListViewer'
import FeedbackAPI from '@/api/feedback'
import FeedbackStatus from '@/modules/feedback/components/FeedbackStatus'

export default {
  name: 'FeedbackAdminEdit',
  components: {FeedbackStatus, FileListViewer},
  props: {
    feedback: {
      type: Object,
      default: () => null
    },
    users: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    date: {
      get () {
        return this.feedback.created_at ? dayjs(this.feedback.created_at).format('YYYY-MM-DDTHH:mm'): null
      },
      set (dateTime) {
        this.feedback.created_at = dayjs(dateTime, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm:ss')
      }
    },
  },
  methods: {
    addFilesFromDialog (e, isAdminFiles = false) {
      let files = e.target.files
      const formData = new FormData()
      files.forEach (file => {
        formData.append('File[file][]', file)
      })
      formData.append('id', this.feedback.id)
      if (isAdminFiles) {
        formData.append('isAdminFiles', true)
      }
      FeedbackAPI.uploadFiles(formData).then(() => {
        if (isAdminFiles) {
          this.$refs.filesAdmin.value = null
        } else {
          this.$refs.files.value = null
        }
        this.$emit('loadData')
      })
    },
    archivateFile () {
      this.$emit('loadData')
    }
  }
}
</script>
