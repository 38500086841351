<template>
  <div class="show-form-cars">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Фамилия</td>
          <td>
            <input
              v-model="driver.last_name"
              type="text"
              class="form-control form-control-sm"
              placeholder
              required
            >
          </td>
        </tr>
        <tr>
          <td>Имя</td>
          <td>
            <input
              v-model="driver.first_name"
              type="text"
              class="form-control form-control-sm"
              placeholder
              required
            >
          </td>
        </tr>
        <tr>
          <td>Отчество</td>
          <td>
            <input
              v-model="driver.middle_name"
              type="text"
              class="form-control form-control-sm"
              placeholder
              required
            >
          </td>
        </tr>
        <tr>
          <td>Телефон</td>
          <td>
            <IMaskComponent
              v-model="driver.phone"
              type="text"
              name="phone"
              class="form-control form-control-sm"
              :mask="'+{7} (000) 000-00-00'"
              :unmask="false"
              placeholder="+7 (000) 000-00-00"
            />
          </td>
        </tr>
        <tr>
          <td>Пользователь</td>
          <td>
            <VSelect
              v-model="driver.user_id"
              :options="users"
              :reduce="user => user.id"
              label="fullName"
              @option:selected="selectedUser"
            />
          </td>
        </tr>
        <tr>
          <td>Адрес проживания</td>
          <td>
            <dadata-suggestions
              v-model="driver.address"
              type="ADDRESS"
              class="form-control form-control-sm"
            />
          </td>
        </tr>
        <tr>
          <td>Заметки</td>
          <td>
            <textarea
              v-model="driver.notes"
              rows="5"
              type="text"
              class="form-control"
              placeholder
              required
            >></textarea>
          </td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4">
    <h5>Контакты родственника</h5>
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">ФИО</td>
          <td>
            <input
              v-model="driver.relative_full_name"
              type="text"
              class="form-control form-control-sm"
              placeholder
              required
            >
          </td>
        </tr>
        <tr>
          <td>Родство</td>
          <td>
            <input
              v-model="driver.relative_relationship"
              type="text"
              class="form-control form-control-sm"
              placeholder
              required
              maxlength="100"
            >
          </td>
        </tr>
        <tr>
          <td>Контактный номер</td>
          <td>
            <IMaskComponent
              v-model="driver.relative_phone"
              type="text"
              name="phone"
              class="form-control form-control-sm"
              :mask="'+{7} (000) 000-00-00'"
              :unmask="false"
              placeholder="+7 (000) 000-00-00"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4">
    <h5>Черный список</h5>
    <table class="table">
      <tbody>
        <tr>
          <td>В черном списке</td>
          <td>
            <input
              v-model="driver.is_blacklisted"
              type="checkbox"
              class="form-control form-control-sm"
              required
            >
          </td>
        </tr>
        <tr>
          <td>Причина добавления в ЧС</td>
          <td>
            <input
              v-model="driver.blacklist_reason"
              type="text"
              class="form-control form-control-sm"
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'DriverEdit',
  props: {
    id: {
      type: Number,
      default: null
    },
    driver: {
      type: Object,
      default: null
    },
    users: {
      type: Array,
      default: null
    }
  },
  methods: {
    selectedUser (user) {
      if (user) {
        this.driver.last_name = user.last_name
        this.driver.middle_name = user.middle_name
        this.driver.first_name = user.first_name
      }
    }
  }
}
</script>

<style scoped>
  .show-form-cars {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
</style>
