<template>
  <div v-if="feedback" class="page">
    <HeaderTitle />
    <ActionBar
      :isEditing="isEditing"
      @changeAction="changeFeedback"
      @cancelAction="cancelFeedbackChanges"
      @deleteAction="deleteFeedback"
      @saveAction="updateFeedback"
    />
    <br>
    <FeedbackAdminShow
      v-if="!isEditing"
      :feedback="feedback"
      :users="users"
    />
    <FeedbackAdminEdit
      v-else
      :feedback="feedback"
      :users="users"
      @loadData="loadData"
    />
  </div>
</template>

<script>

import Swal from 'sweetalert2'
import {mapActions, mapState} from 'vuex'
import deepAssign from 'assign-deep'
import FeedbackAPI from '@/modules/feedback/api/feedback'
import FeedbackAdminShow from '@/modules/feedback/views/admin/Show'
import FeedbackAdminEdit from '@/modules/feedback/views/admin/Edit'

export default {
  name: 'FeedbackAdminPage',
  components: {
    FeedbackAdminEdit,
    FeedbackAdminShow,
  },
  props: {
    feedbackId: {
      type: String,
      default: () => null
    }
  },
  data () {
    return {
      isEditing: false,
      feedback: null,
      prevFeedback: null,
      isUpdating: false
    }
  },
  computed: {
    ...mapState('taxi/user', {
      users: state => state.items,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading,
      authedUser: state => state.items.find(user => user.id === state.authedId)
    }),
  },
  mounted () {
    this.loadData()
    this.loadUsers({ expand: ['fullName'] })
  },
  methods: {
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('taxi/user', {
      loadUsers: 'loadItems',
      abortLoading: 'abortLoading',
      loadAuthedUser: 'loadAuthedUser'
    }),
    loadData() {
      FeedbackAPI.getItem({
        id: this.feedbackId,
        queryWith: ['files', 'adminFiles']
      })
        .then(response => {
          this.feedback = response
          if (!response) {
            return false
          }
          this.setTitle([
            {
              title: 'Обратная связь',
              url: '/feedback'
            }
          ])
        })
    },
    changeFeedback () {
      this.prevFeedback = deepAssign({}, this.feedback)
      this.isEditing = true
    },
    deleteFeedback () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить обратную связь?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          FeedbackAPI.removeItem(this.feedback.id)
            .then(() => {
              this.$router.push('/feedback-admin')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    cancelFeedbackChanges () {
      this.feedback = this.prevFeedback
      this.isEditing = false
    },
    updateFeedback () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true

      FeedbackAPI.updateItem(this.feedback)
        .then(feedback => {
          this.feedback = { ...this.feedback, ...feedback.model }
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
  }
}
</script>
