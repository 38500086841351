<template>
  <component :is="layoutComponent">
    <router-view />
  </component>
</template>

<script>
export default {
  name: 'App',
  computed: {
    layoutComponent () {
      return this.$route.meta.layout || 'div'
    }
  }
}
</script>
