<template>
  <div class="page page-drivers">
    <HeaderTitle />
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>Фамилия</label>
        <input
          v-model="driver.last_name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
      <div class="col-md-3 mb-2">
        <label>Имя</label>
        <input
          v-model="driver.first_name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
      <div class="col-md-4 mb-2">
        <label>Отчество</label>
        <input
          v-model="driver.middle_name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-5">
        <label>Телефон</label>
        <IMaskComponent
          v-model="driver.phone"
          type="text"
          name="phone"
          class="form-control"
          :mask="'+{7} (000) 000-00-00'"
          :unmask="false"
          placeholder="+7 (000) 000-00-00"
        />
      </div>
      <div class="col-md-4 mb-6">
        <label>Адрес проживания</label>
        <dadata-suggestions
          v-model="driver.address"
          type="ADDRESS"
          class="form-control"
        />
      </div>
      <div class="col-md-4 mb-6">
        <label>Привязать к пользователю</label>
        <VSelect
          v-model="driver.user_id"
          :options="users"
          :reduce="user => user.id"
          label="fullName"
          @option:selected="selectedUser"
        />
      </div>
    </div>
    <hr class="mb-4">
    <h5>Контакты родственника</h5>
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">ФИО</td>
          <td>
            <input
              v-model="driver.relative_full_name"
              type="text"
              class="form-control form-control-sm"
              placeholder
              required
            >
          </td>
        </tr>
        <tr>
          <td>Родство</td>
          <td>
            <input
              v-model="driver.relative_relationship"
              type="text"
              class="form-control form-control-sm"
              placeholder
              required
              maxlength="100"
            >
          </td>
        </tr>
        <tr>
          <td>Контактный номер</td>
          <td>
            <IMaskComponent
              v-model="driver.relative_phone"
              type="text"
              name="phone"
              class="form-control form-control-sm"
              :mask="'+{7} (000) 000-00-00'"
              :unmask="false"
              placeholder="+7 (000) 000-00-00"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4">
    <h5>Черный список</h5>
    <table class="table">
      <tbody>
        <tr>
          <td>В черном списке</td>
          <td>
            <input
              v-model="driver.is_blacklisted"
              type="checkbox"
              class="form-control form-control-sm"
              required
            >
          </td>
        </tr>
        <tr>
          <td>Причина добавления в ЧС</td>
          <td>
            <input
              v-model="driver.blacklist_reason"
              type="text"
              class="form-control form-control-sm"
            >
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-primary mr-2" @click="createDriver()">Добавить</button>
    <router-link to="/drivers" class="btn btn-secondary">Отменить</router-link>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import DriversAPI from '@/api/drivers'
import {mapActions, mapState} from 'vuex'

export default {
  name: 'CreateDriver',
  data () {
    return {
      isCreating: false,
      driver: {
        last_name: '',
        middle_name: '',
        first_name: '',
        phone: '',
        address: ''
      }
    }
  },
  computed: {
    ...mapState('taxi/user', {
      users: state => state.items,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading,
      authedUser: state => state.items.find(user => user.id === state.authedId)
    }),
  },
  mounted () {
    this.loadItems()
    this.setTitle([
      {
        title: 'Водители',
        url: '/drivers'
      },
      {
        title: 'Добавить'
      }
    ])
  },
  methods: {
    createDriver () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      DriversAPI.createItem(this.driver)
        .then(() => {
          this.isCreating = false
          this.$router.push('/drivers')
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    selectedUser (user) {
      if (user) {
        this.driver.last_name = user.last_name
        this.driver.middle_name = user.middle_name
        this.driver.first_name = user.first_name
      }
    },
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('taxi/user', [
      'loadItems',
      'abortLoading',
      'loadAuthedUser'
    ]),
  }
}
</script>

<style scoped>
  .page-drivers h3 {
    margin-bottom: 3rem;
  }
</style>
