import Drivers from '@/views/drivers/Drivers'
import DriverPage from '@/views/drivers/DriverPage'
import CreateDriver from '@/views/drivers/CreateDriver'
import CreateDocument from '@/views/driverDocuments/CreateDocument'
import UpdateDocument from '@/views/driverDocuments/UpdateDocument'

export default [
  {
    path: '/drivers/:driverId/docs/:documentId/update',
    component: UpdateDocument,
    props: true,
    meta: {
      title: 'Водители',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/drivers/:driverId/docs/create',
    component: CreateDocument,
    props: true,
    meta: {
      title: 'Водители',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/drivers',
    component: Drivers,
    meta: {
      title: 'Водители',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/drivers/page/:page',
    component: Drivers,
    props: true,
    meta: {
      title: 'Водители',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/drivers/create',
    component: CreateDriver,
    meta: {
      title: 'Водители',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/drivers/:id',
    component: DriverPage,
    name: 'driverPage',
    props: true,
    meta: {
      title: 'Водители',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
