<template>
  <div :class="`alert ${stateClass} alert-state text-center`" role="alert">
    {{ archived_at ? 'Оплачено' : 'Активный' }}
  </div>
</template>

<script>
export default {
  name: 'StatusState',
  props: {
    // eslint-disable-next-line vue/prop-name-casing
    archived_at: {
      type: String,
      default: () => null
    }
  },
  computed: {
    stateClass () {
      if (!this.archived_at) {
        return 'alert-danger'
      }
      return 'alert-success'
    },
  }
}
</script>

<style scoped>
  .alert-state {
    margin: 0;
    height: 30px;
    width: fit-content;
    height: fit-content;
    line-height: 30px;
    padding: 0px 15px;
    min-width: 10rem;
    border-radius: 15px;
    border: none;
  }
  .alert-danger {
    color: #BF543B;
    background-color: rgba(191, 84, 59, 0.15);
  }
  .alert-success {
    color: #92BF4E;
    background-color: rgba(146, 191, 78, 0.15);
  }
</style>
