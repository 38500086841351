<template>
  <div>
    <h3>{{ facility.name }}</h3>
    <div class="d-flex flex-column w-100">
      <div class="input-group without-separator">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <font-awesome-icon icon="search" style="color:gray" />
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Поиск предметов по названию и поставщику"
          aria-label="Поиск предметов по названию и поставщику"
          :value="searchText"
          @input="searchServe"
        >
      </div>
    </div>
    <table class="table table-hover w-100 mt-3 d-none d-md-table">
      <TableSort
        class="sticky-2"
        :sorts="sorts"
        :zeroingOtherParams="true"
        @sortsUpdate="loadWithSort"
      />
      <tbody>
        <tr v-for="stock of stocksFacility" :key="stock.id" @click="$router.push(`/stock/${stock.id}`)">
          <td>
            <TextHighlight class="highlighter" :queries="searchText">
              {{ stock.title }}
            </TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">
              {{ stock.receipt }}
            </TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">
              {{ stock.quantity }}
            </TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">
              {{ stock.supplier }}
            </TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">
              {{ stock.date }}
            </TextHighlight>
          </td>
          <template v-if="showPriceAndSum">
            <td>
              <TextHighlight class="highlighter" :queries="searchText">
                {{ stock.price }}
              </TextHighlight>
            </td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">
                {{ stock.stockSum }}
              </TextHighlight>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
    <div v-for="stock of stocksFacility" :key="stock.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
      <table v-if="stocksFacility.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Название</td>
            <td style="border-top: none">
              <TextHighlight class="highlighter" :queries="searchText">
                {{ stock.title }}
              </TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Поступление</td>
            <td>
              <TextHighlight :queries="searchText">
                {{ stock.receipt }}
              </TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Количество</td>
            <td>
              <TextHighlight :queries="searchText">
                {{ stock.quantity }}
              </TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Поставщик</td>
            <td>
              <TextHighlight :queries="searchText">
                {{ stock.supplier }}
              </TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Дата производства</td>
            <td>
              <TextHighlight :queries="searchText">
                {{ stock.date }}
              </TextHighlight>
            </td>
          </tr>
          <template v-if="showPriceAndSum">
            <tr>
              <td>Стоимость 1 шт</td>
              <td>
                <TextHighlight :queries="searchText">
                  {{ stock.price }}
                </TextHighlight>
              </td>
            </tr>
            <tr>
              <td>Сумма</td>
              <td>
                <TextHighlight :queries="searchText">
                  {{ stock.stockSum }}
                </TextHighlight>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'FacilityShow',
  props: {
    page: {
      type: String,
      default: () => '1'
    },
    facility: {
      type: Object,
      default: () => null
    },
    showPriceAndSum: {
      type: Boolean,
      default: () => false
    }
  },
  data () {
    const sorts = [
      {
        name: 'Название',
        storeName: 'stock-title',
        values: ['', 'title', '-title'],
        order: 0
      },
      {
        name: 'Поступление',
        storeName: 'stock-receipt',
        values: ['', 'receipt', '-receipt'],
        order: 1
      },
      {
        name: 'Количество',
        storeName: 'stock-quantity',
        values: ['', 'quantity', '-quantity'],
        order: 1
      },
      {
        name: 'Поставщик',
        storeName: 'stock-supplier',
        values: ['', 'supplier', '-supplier'],
        order: 2
      },
      {
        name: 'Дата производства',
        storeName: 'stock-date',
        values: ['', 'date', '-date'],
        order: 2
      }
    ]
    if (this.showPriceAndSum) {
      sorts.push({
        name: 'Стоимость 1 шт',
        storeName: 'stock-price',
        values: ['', 'price', '-price'],
        order: 3
      },
      {
        name: 'Сумма',
        storeName: 'stock-sum',
        values: ['', 'sum', '-sum'],
        order: 4
      })
    }
    return {
      sorts: sorts,
      searchedText: null,
      interval: null
    }
  },
  computed: {
    stocksFacility () {
      return this.stocks.filter(e => e.facility_id === this.facility.id)
    },
    ...mapState('stock/stock', {
      stocks: state => state.items,
      pageCount: state => state.pageCount,
      searchText: state => state.searchText,
      isLoading: state => state.isLoading
    })
  },
  mounted () {
    this.loadItems()
    if (!this.interval) {
      this.loadWithSearch()
    }
  },
  beforeDestroy () {
    this.abortLoading()
    clearTimeout(this.interval)
    this.interval = null
  },
  methods: {
    ...mapActions('stock/stock', [
      'loadItems',
      'setSearchText',
      'setSearch',
      'abortLoading',
      'setSorts'
    ]),
    searchServe (e) {
      this.setSearchText(e.target.value)
      this.setSearch({ key: 'title', value: e.target.value })
      this.setSearch({ key: 'supplier', value: e.target.value })
    },
    loadWithSearch () {
      if (!this.isLoading && this.searchedText !== this.searchText) {
        this.searchedText = this.searchText
        return this.loadItems({ page: Number(this.page)})
          .then(() => {
            this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
          })
      }
      this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
    },
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page)})
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding-top: 1rem;
  height: 100%;
  overflow-y: auto;
}
@media screen and (max-width: 1199px) {
  .page {
    padding: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .page {
    padding: 0.5rem;
  }
}
.highlighter ::v-deep .text__highlight {
  border-radius: 0;
  padding: 0.2em 0 0.2em 0;
  background: #fff2ae;
}
.fixed-search {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
  background: white;
}
</style>
