<template>
  <div class="page page-cars">
    <HeaderTitle />
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#data">Основные данные</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#documents">Документы</a>
      </li>
      <li v-if="hasFuelModule && car.activeDriver" class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#driver">Водитель</a>
      </li>
      <li v-if="hasPermission('/rent')" class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#rent">Аренда</a>
      </li>
      <li v-if="hasFinesModule && hasPermission('/fines')" class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#fines">Штрафы ГИБДД</a>
      </li>
      <li v-if="hasAccidentModule && hasPermission('/accidents')" class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#carAccident">ДТП</a>
      </li>
      <li v-if="hasFuelModule && hasPermission('/fueling')" class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#waybills">Путевые листы</a>
      </li>
    </ul>
    <div class="tab-content">
      <div id="data" class="tab-pane fade show active">
        <div class="float-right">
          <router-link
            :to="'/watch/' + car.id"
            class="btn btn-secondary mx-1"
            role="button"
          >
            <font-awesome-icon class="text-white" icon="external-link-alt" />
            Отслеживание
          </router-link>
          <button v-if="!isEditing" class="btn btn-danger mx-1" @click="deleteCar()">
            <font-awesome-icon class="text-white" icon="trash-alt" />
            Удалить
          </button>
          <button v-if="!isEditing" class="btn btn-primary mx-1" @click="changeCar()">
            <font-awesome-icon class="text-white" icon="edit" />
            Изменить
          </button>
          <button v-if="isEditing" class="btn btn-secondary mx-1" @click="cancelCarChanges()">Отменить</button>
          <button v-if="isEditing" class="btn btn-primary mx-1" @click="updateCar()">Сохранить</button>
        </div>
        <br>
        <CarEditForm
          v-if="isEditing"
          :car="car"
          :trackingDevicesList="trackingDevicesList"
          :prev-car="prevCar"
        />
        <CarShowForm v-else :car="car" />
        <div class="car-equipments">
          <EquipmentsView
            :equipments="car.equipments"
            @actionEdit="$router.push(`/cars/${carId}/equipments`)"
          />
        </div>
      </div>
      <div id="documents" class="tab-pane fade p-3">
        <router-link :to="`/cars/${carId}/docs/create`" class="btn btn-primary float-right mb-3">
          <font-awesome-icon class="text-white" icon="plus" />
          Добавить
        </router-link>
        <br>
        <LightBox
          v-if="showDetailImage"
          :scan="currentScan"
          @actionClose="showDetailImage = false"
        />
        <Document
          v-for="document of car.documents"
          :key="document.id"
          :document="document"
          @actionDetailImage="detailImage(currentScan)"
          @actionDelte="deleteDocument(document.id)"
          @actionUpdate="$router.push(`/cars/${carId}/docs/${document.id}/update`)"
        />
      </div>
      <div v-if="hasFuelModule && car.activeDriver" id="driver" class="tab-pane fade p-3">
        <h5>Текущий водитель</h5>
        <table class="table">
          <tbody>
            <tr>
              <td class="label-col">Водитель</td>
              <td>
                <router-link
                  class="text-decoration-none text-dark"
                  :to="`/drivers/${car.activeDriver.id}`"
                >
                  {{ car.activeDriver.last_name }} {{ car.activeDriver.first_name }} {{ car.activeDriver.middle_name }}
                  <font-awesome-icon class="text-secondary" icon="external-link-alt" />
                </router-link>
              </td>
            </tr>
            <tr>
              <td class="label-col">Дата аренды</td>
              <td>{{ car.activeRent.transfer_datetime | formatDate }}</td>
            </tr>
          </tbody>
        </table>
        <hr class="mb-4">
      </div>
      <div v-if="hasPermission('/rent')" id="rent" class="tab-pane fade p-3">
        <div v-if="activeRent">
          <h5>Активная аренда</h5>
          <RentShowForm :contract="activeRent" :companies="companies" class="mt-4" />
          <hr>
          <h5>Сканы договора</h5>
          <ScansGallery :documentId="activeRent.id" />
          <PhotoControl :contract="activeRent" />
        </div>
        <div v-if="closedRents.length">
          <h5>Завершенные аренды</h5>
          <ClosedRents :closed-contracts="closedRents" />
        </div>
      </div>
      <div v-if="hasFinesModule && hasPermission('/fines')" id="fines" class="tab-pane fade p-3">
        <FinesCarShow :car-id="carId" />
      </div>
      <div v-if="hasAccidentModule && hasPermission('/accidents')" id="carAccident" class="tab-pane fade p-3">
        <AccidentListView :car-id="car.id" />
      </div>
      <div v-if="hasFuelModule && hasPermission('/fueling')" id="waybills" class="tab-pane fade p-3">
        <div class="text-right">
          <button
            v-if="!car.activeDriver || car.activeDriver.id !== userDriverId"
            class="btn btn-success mx-1"
            :disabled="!user || !user.driver"
            data-tooltip="Чтобы взять машину, ваша учетная запись должна быть привязана к данным водителя"
            @click="createRentCar"
          >
            <font-awesome-icon class="text-white" icon="id-card" />
            Взять машину
          </button>
          <button
            v-else-if="car.activeDriver.id === userDriverId"
            class="btn btn-danger mx-1"
            @click="unRentCar"
          >
            <font-awesome-icon class="text-white" icon="id-card" />
            Вернуть машину
          </button>
          <br>
        </div>
        <div v-if="car.activeDriver">
          <h5>Текущий водитель</h5>
          <table class="table">
            <tbody>
              <tr>
                <td class="label-col">Водитель</td>
                <td>
                  <router-link
                    class="text-decoration-none text-dark"
                    :to="`/drivers/${car.activeDriver.id}`"
                  >
                    {{ car.activeDriver.last_name }} {{ car.activeDriver.first_name }} {{ car.activeDriver.middle_name }}
                    <font-awesome-icon class="text-secondary" icon="external-link-alt" />
                  </router-link>
                </td>
              </tr>
              <tr>
                <td class="label-col">Дата аренды</td>
                <td>{{ car.activeRent.transfer_datetime | formatDate }}</td>
              </tr>
            </tbody>
          </table>
          <hr class="mb-4">
        </div>
        <FuelStatistic v-if="car.id && hasPermission('/watch')" :car-model="car" />
      </div>
    </div>
    <div v-if="isEnterOdometer">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div
              class="btn float-right text-secondary"
              role="button"
              tabindex="0"
              @click="isEnterOdometer = false"
            >
              <font-awesome-icon icon="times" />
            </div>
            <h4>Корректировка пробега</h4>
            <hr>
            <div class="row">
              <div class="col-12">
                <label>Пробег на момент передачи</label>
                <input
                  v-model="odometerCorrection.actual_odometer"
                  type="text"
                  class="form-control"
                  placeholder
                >
              </div>
            </div>
            <hr class="mb-4">
            <div class="text-right">
              <button class="btn btn-secondary mr-2" @click="$router.go(0)">Пропустить</button>
              <button class="btn btn-primary" @click="createOdometerCorrection()">Корректировать</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import Swal from 'sweetalert2'
import { djsMixin } from '@/mixins/djsMixin'
import CarsAPI from '@/api/cars'
import CompaniesAPI from '@/api/companies'
import userApi from '@/api/user'
import DocumentsAPI from '@/api/car-documents'
import OdometerCorrectionApi from '@/api/odometerCorrection'
import deepAssign from 'assign-deep'
import EquipmentsView from './EquipmentsView'
import {mapMutations, mapActions, mapGetters, mapState} from 'vuex'
import ScansGallery from '../../components/common/scans/ScansGallery'
import ClosedRents from '../../components/contracts/rent/ClosedRents'
import FinesCarShow from '../../modules/fines/components/Show'
import FuelStatistic from '../../modules/fuel/components/FuelStatistics'
import CarDriverApi from '../../modules/fuel/api/carDriver'
import dayjs from 'dayjs'
import PhotoControl from '../../components/contracts/rent/PhotoControl'
import AccidentListView from '../../modules/accidents/components/AccidentListView'

export default {
  name: 'CarPage',
  components: {
    AccidentListView,
    PhotoControl,
    FuelStatistic,
    FinesCarShow,
    ClosedRents,
    EquipmentsView,
    ScansGallery
  },
  mixins: [djsMixin],
  props: {
    carId: {
      type: String,
      default: null
    },
  },
  data () {
    return {
      isUpdating: false,
      user: null,
      isEnterOdometer: false,
      car: {
        brand: '',
        model: '',
        reg_plate_number: '',
        reg_plate_region: '',
        manufacturing_year: '',
        radio_code: '',
        data: ''
      },
      odometerCorrection: {
        tracking_device_id: null,
        actual_odometer: null,
        timestamp: this.now(),
        device_odometer: null,
        car_id: null,
        author_id: null
      },
      trackingDevicesList: [],
      showDetailImage: false,
      isEditing: false,
      prevCar: null,
      currentScan: null,
      companies: [],
      permissions: []
    }
  },
  computed: {
    ...mapGetters('taxi/contracts', [
      'activeContracts',
      'closedContracts',
      'draftContracts'
    ]),
    ...mapState('taxi/trackDevice', {
      devices: state => state.items
    }),
    activeRent () {
      return this.activeContracts.find(contract => contract.car_id == this.carId)
    },
    closedRents () {
      return this.closedContracts.filter(contract => contract.car_id == this.carId)
    },
    hasFuelModule () {
      return process.env.VUE_APP_MODULE_FUEL === 'true'
    },
    hasFinesModule () {
      return process.env.VUE_APP_MODULE_FINES === 'true'
    },
    hasAccidentModule () {
      return process.env.VUE_APP_MODULE_ACCIDENT === 'true'
    },
    hasPermission () {
      return (link) => {
        return (this.permissions && (this.permissions[link.url] || this.permissions['/*']))
      }
    },
    userDriverId () {
      return this.user?.driver?.id ?? null
    }
  },
  watch: {
    car: {
      handler (car, carOld) {
        if (car?.id && car?.id !== carOld?.id) {
          this.setTitle([
            {
              title: 'Автомобили',
              url: '/cars'
            }, {
              title: car.brand + ' ' + car.model + ' ' + car.registration_plate
            }
          ])
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    userApi.getFrontendPermission()
      .then(permissions => {
        this.permissions = permissions
        if (this.hasPermission('/rent')) {
          this.loadActiveRents()
          this.loadClosedRents()
        }
      })
    userApi.getCurrentUser()
      .then(user => {
        this.user = user
      })
      .catch(err => {
        console.error(err)
        this.isCreating = false
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: err.message
        })
      })
  },
  mounted () {

    this.loadDeviceItems()
    this.loadData()
    this.loadItems()
      .then(cars => {
        this.loadDeviceItems().then(result => {
          this.trackingDevicesList = result.filter(device => {
            if (this.car.tracking_device_id === device.id || !cars.find(car => car.tracking_device_id === device.id)) {
              return device
            }
          })
        })
      })
    CompaniesAPI.getItems().promise.then(companies => {
      this.companies = companies.items
    })
  },
  methods: {
    loadData () {
      CarsAPI.getItem({
        id: this.carId,
        queryWith: ['documents', 'isServe', 'equipments', 'trackingDevice', 'lastState', 'activeDriver', 'activeRent']
      })
        .then(response => {
          this.car = response
          this.loadScans()
        })
    },
    loadScans () {
      this.clearItems()
      this.setModel('CarDocument')
      this.car.documents.forEach(element => {
        DocumentsAPI.getScans(element.id).then(result => {
          element.scans = result
          this.pushItems({ scans: result, documentId: element.id })
        })
          .catch(err => {
            console.error(err)
            Swal.fire({
              icon: 'error',
              title: 'Ошибка',
              text: err.message
            })
          })
      })
    },
    changeCar () {
      this.prevCar = deepAssign({}, this.car)
      this.isEditing = true
    },
    deleteCar () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить автомобиль?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          CarsAPI.removeItem(this.carId)
            .then(() => {
              this.$router.push('/cars')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    cancelCarChanges () {
      this.car = this.prevCar
      this.isEditing = false
    },
    updateCar () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      if (!CarsAPI.validateRegistrationPlate(this.car.reg_plate_region, this.car.reg_plate_number)) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Регистрационный номер не соответствует формату'
        })
        this.isUpdating = false
        return
      }
      CarsAPI.updateItem(this.car)
        .then(car => {
          this.car = { ...this.car, ...car.model }
          this.car.tracking_device_id = parseInt(this.car.tracking_device_id)
          this.car.trackingDevice = this.trackingDevicesList.find(e => {
            return e.id === this.car.tracking_device_id
          })
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    createRentCar () {
      if (!this.car.id) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Не найдена машина'
        })
      }
      if (!this.user.driver.id) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Не найдет прикрепленный за пользователем водитель'
        })
      }
      CarDriverApi.createItem({
        car_id: this.car.id,
        driver_id: this.user.driver.id,
        transfer_datetime: dayjs().utc().format('YYYY-MM-DDT00:00:00'),
        receiving_datetime: null,
      })
        .then(() => {
          this.isEnterOdometer = true
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    unRentCar () {
      CarDriverApi.unRentItem(this.car.id)
        .then(() => {
          this.$router.go(0)
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    createOdometerCorrection () {
      if (this.odometerCorrection.actual_odometer) {
        let trackingDevice = this.devices.find(device => device.id === this.car.tracking_device_id)
        if (!trackingDevice) {
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            text: 'Не найдено устройство геопозиционирования'
          })
          this.$router.go(0)
        }
        this.odometerCorrection.author_id = this.user.id
        this.odometerCorrection.car_id = this.car.id
        this.odometerCorrection.tracking_device_id = trackingDevice.id
        OdometerCorrectionApi.createItem({
          ...this.odometerCorrection,
          timestamp: this.toUTCtranslate(dayjs().utc().format('YYYY-MM-DDT00:00:00'))
        })
        this.isEnterOdometer = false
        this.odometerCorrection.actual_odometer = null
      }
    },
    deleteDocument (documentId) {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить документ?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          DocumentsAPI.removeItem(documentId)
            .then(() => {
              this.loadData()
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    ...mapActions('taxi', ['setTitle']),
    ...mapActions('taxi/cars', ['loadItems']),
    ...mapActions('taxi/trackDevice', {
      loadDeviceItems: 'loadItems'
    }),
    ...mapActions('taxi/trackDevice', {
      loadDeviceItems: 'loadItems'
    }),
    ...mapMutations('taxi/scans', [
      'pushItems',
      'clearItems',
      'setModel'
    ]),
    ...mapActions('taxi/contracts', [
      'loadActiveRents',
      'loadClosedRents'
    ]),
  }
}
</script>

<style scoped>
  .page-cars {
    width: 98%;
    padding-bottom: 4rem;
    padding-top: 2.5rem;
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }
  .logs-container {
    max-height: 300px;
    overflow-y: auto;
    white-space: pre-wrap
  }
  [data-tooltip]:disabled {
    position: relative;
  }
  [data-tooltip]:disabled::after {
    content: attr(data-tooltip);
    position: absolute;
    width: 300px;
    right: 0; top: 0;
    background: #d9d9d9;
    color: #1e1e1e;
    padding: 0.5em;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    pointer-events: none;
    opacity: 0;
    transition: 1s;
  }
  [data-tooltip]:disabled:hover::after {
    opacity: 1;
    top: 3em;
  }
</style>
