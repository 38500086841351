<template>
  <div>
    <table class="table borderless">
      <tbody>
        <tr>
          <td>Автомобиль</td>
          <td>
            <VSelect
              v-model="accident.car_id"
              :options="cars"
              :reduce="car => car.id"
              label="displayedName"
            />
          </td>
        </tr>
        <tr>
          <td>Водитель</td>
          <td>
            <VSelect
              v-model="accident.driver_id"
              :options="drivers"
              :reduce="template => template.id"
              label="fullName"
            />
          </td>
        </tr>
        <tr>
          <td>Описание</td>
          <td>
            <textarea
              v-model="accident.description"
              rows="5"
              type="text"
              class="form-control"
              placeholder
              required
            />
          </td>
        </tr>
        <tr>
          <td>Дата ДТП</td>
          <td>
            <input
              v-model="date"
              type="datetime-local"
              class="form-control"
              placeholder
              required
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import dayjs from 'dayjs'

export default {
  name: 'AccidentEdit',
  props: {
    accident: {
      type: Object,
      default: () => null
    },
    cars: {
      type: Array,
      default: () => []
    },
    drivers: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    date: {
      get () {
        return this.accident.date ? dayjs(this.accident.date).format('YYYY-MM-DDTHH:mm'): null
      },
      set (dateTime) {
        this.accident.date = dayjs(dateTime, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DDTHH:mm:ss')
      }
    },
  }
}
</script>
