<template>
  <div v-if="document" :class="`alert ${stateClass} alert-state text-center`" role="alert">
    {{ stateMessage }}
  </div>
</template>

<script>
export default {
  name: 'DocumentState',
  props: {
    document: {
      type: Object,
      default: null
    },
    days: {
      type: Number,
      default: 0
    }
  },
  computed: {
    stateClass () {
      if (!this.document || !this.document.data.date_end) {
        return 'alert-secondary'
      } else if (this.days <= 7) {
        return 'alert-danger'
      } else if (this.days <= 30) {
        return 'alert-warning'
      }
      return 'alert-success'
    },
    stateMessage () {
      if (!this.document) {
        return 'Отсутствует'
      }
      if (!this.days) {
        return 'Не указана дата окончания'
      }
      if (this.days < 0) {
        return 'просрочено на ' + Math.abs(this.days) + ' д.'
      }
      if (this.days === 0) {
        return 'просрочено сегодня'
      }
      return 'осталось ' + this.days + ' д.'
    }
  }
}
</script>

<style scoped>
  .alert-state {
    margin: 0;
    height: 30px;
    width: fit-content;
    height: fit-content;
    line-height: 30px;
    padding: 0px 15px;
    min-width: 10rem;
    border-radius: 15px;
    border: none;
  }
  .alert-danger {
    color: #BF543B;
    background-color: rgba(191, 84, 59, 0.15);
  }
  .alert-success {
    color: #92BF4E;
    background-color: rgba(146, 191, 78, 0.15);
  }
  .alert-warning {
    color: #F2B950;
    background-color: rgba(242, 185, 80, 0.15);
  }
  .alert-info {
    color: #62ADBF;
    background-color: rgba(98, 173, 191, 0.15);
  }
  .alert-secondary {
    color: #414A35;
    background-color: rgba(65, 74, 53, 0.15);
  }
</style>
