import PhotocontrolList from './views/List'
import PhotocontrolPage from './views/View'

export default [
  {
    path: '/photocontrol',
    component: PhotocontrolList,
    name: 'PhotocontrolList',
    meta: {
      title: 'Фотоконтроль',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/photocontrol/:carId',
    component: PhotocontrolPage,
    name: 'PhotocontrolPage',
    props: true,
    meta: {
      title: 'Фотоконтроль',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
