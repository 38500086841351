import { render, staticRenderFns } from "./DocumentState.vue?vue&type=template&id=3f3b424a&scoped=true&"
import script from "./DocumentState.vue?vue&type=script&lang=js&"
export * from "./DocumentState.vue?vue&type=script&lang=js&"
import style0 from "./DocumentState.vue?vue&type=style&index=0&id=3f3b424a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../shared/frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f3b424a",
  null
  
)

export default component.exports