<template>
  <table class="table">
    <tbody>
      <tr>
        <td class="table-col">VIN</td>
        <td>{{ documentData.vin }}</td>
      </tr>
      <tr>
        <td>Тип ТС</td>
        <td>{{ documentData.vehicle_type }}</td>
      </tr>
      <tr>
        <td>Номер кузова</td>
        <td>{{ documentData.body_number }}</td>
      </tr>
      <tr>
        <td>Номер двигателя</td>
        <td>{{ documentData.engine_number }}</td>
      </tr>
      <tr>
        <td>Номер шасси (рамы)</td>
        <td>{{ documentData.chassis_number }}</td>
      </tr>
      <tr>
        <td>Цвет</td>
        <td>{{ documentData.color }}</td>
      </tr>
      <tr>
        <td>Номер ПТС</td>
        <td>{{ documentData.number }}</td>
      </tr>
      <tr>
        <td>Наименование организации выдавшей ПТС</td>
        <td>{{ documentData.authority }}</td>
      </tr>
      <tr>
        <td>Дата выдачи</td>
        <td>{{ documentData.issue_date | diffForHumans }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { djsMixin } from '@/mixins/djsMixin'

export default {
  name: 'DocumentVehiclePassportView',
  mixins: [djsMixin],
  props: {
    documentData: {
      type: Object,
      default: null
    }
  },
}
</script>

<style scoped>
  .table-col {
    width: 50%;
  }
</style>
