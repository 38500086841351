import moduleTemplate from '@/store/moduleTemplate'
import fines from '../api/fines'

const template = moduleTemplate(fines)

template.types = {
  ...template.types,
  setSearchText: 'setSearchText'
}
template.mutations = {
  ...template.mutations,
  [template.types.setSearchText] (state, value) {
    state.searchText = value
  }
}
template.state = {
  ...template.state,
  searchText: '',
  expandAllItems: ['fines', 'activeFines']
}

template.actions = {
  ...template.actions,
  setSearchText ({ commit }, searchText) {
    commit(template.types.setSearchText, searchText)
  }
}
export default template
