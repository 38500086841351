<template>
  <CardSelected v-if="selectedCar" @findCar="$emit('findCar')" />
  <div v-else style="padding: 0 5px 10px 5px">
    <div class="input-group map-search px-2">
      <div class="input-group-prepend">
        <span id="map-search-icon-addon" class="input-group-text" style="background:white">
          <font-awesome-icon icon="search" :style="{ color: 'gray' }" />
        </span>
      </div>
      <input
        v-model="filter"
        type="text"
        class="form-control"
        placeholder="Поиск по автомобилю, номеру"
        aria-label="Поиск по автомобилю, номеру"
        aria-describedby="map-search-icon-addon"
      >
    </div>
    <div v-if="firstIsLoading" class="w-100 text-center p-2">
      <div class="spinner-border spinner-border-sm" role="status">
        <span class="sr-only">Загрузка...</span>
      </div>
    </div>
    <div v-else class="row w-100 m-0 p-2 justify-content-between">
      <div>
        <font-awesome-icon
          icon="filter"
          :style="{
            color: 'gray',
            cursor: 'pointer'
          }"
        />
        <span
          class="filter-item"
          role="button"
          tabindex="0"
          @click="filterFlag = 0"
        >Всего: {{ filtredCars.length }} </span>
        <span
          class="filter-item mx-1"
          role="button"
          tabindex="0"
          @click="filterFlag = 1"
        >Не на связи: {{ badCars.length }} </span>
        <span
          class="filter-item"
          role="button"
          tabindex="0"
          @click="filterFlag = 2"
        >Не отслеживается: {{ untrackedCars.length }}</span>
      </div>
      <div class="col-auto">
        <font-awesome-icon
          icon="sort"
          :style="{
            color: 'gray',
            cursor: 'pointer'
          }"
        />
        <span
          class="filter-item mx-1"
          role="button"
          tabindex="0"
          @click="showSortsItems = !showSortsItems"
        >
          Сортировка
        </span>
        <ul v-if="showSortsItems" class="sorts-container shadow">
          <li @click="setSort(0)">Сначала машины не на связи</li>
          <hr class="m-0">
          <li @click="setSort(1)">Сначала машины на связи</li>
          <hr class="m-0">
          <li @click="setSort(2)">Машины от A-Z</li>
          <hr class="m-0">
          <li @click="setSort(3)">Машины от Z-A</li>
          <hr class="m-0">
          <li @click="setSort(4)">Номера машин от A-Z</li>
          <hr class="m-0">
          <li @click="setSort(5)">Номера машин от Z-A</li>
        </ul>
      </div>
    </div>
    <div class="w-100 m-0">
      <MapCard
        v-for="car in cars"
        :key="car.id"
        class="location-map-card"
        :car="car"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'MapRightPanel',
  data () {
    return {
      toggle: false,
      filter: '',
      filterFlag: 0,
      showSortsItems: false,
      sortFlag: 0,
      firstIsLoading: true
    }
  },
  computed: {
    cars () {
      let cars = []
      if (this.filterFlag === 0) {
        cars = this.filtredCars
      }
      if (this.filterFlag === 1) {
        cars = this.badCars
      }
      if (this.filterFlag === 2) {
        cars = this.untrackedCars
      }
      cars.sort((carA, carB) => {
        if (this.sortFlag === 0 || this.sortFlag === 1) {
          if (!carA.lastState.lat) {
            return 1
          }
          if (!carB.lastState.lat) {
            return -1
          }
          if (carA.lastState.lat.time < carB.lastState.lat.time) {
            return this.sortFlag === 0 ? -1 : 1
          }
          if (carA.lastState.lat.time > carB.lastState.lat.time) {
            return this.sortFlag === 0 ? 1 : -1
          }
          return 0
        } else if (this.sortFlag === 2 || this.sortFlag === 3) {
          if (carA.brand < carB.brand) {
            return this.sortFlag === 2 ? -1 : 1
          }
          if (carA.brand > carB.brand) {
            return this.sortFlag === 2 ? 1 : -1
          }
          return 0
        } else if (this.sortFlag === 4 || this.sortFlag === 5) {
          if (carA.registration_plate < carB.registration_plate) {
            return this.sortFlag === 4 ? -1 : 1
          }
          if (carA.registration_plate > carB.registration_plate) {
            return this.sortFlag === 4 ? 1 : -1
          }
          return 0
        }
      })
      return cars
    },
    filtredCars () {
      const filter = this.filter.toUpperCase().trim()
      return this.carsWithImei.filter(car => !filter ||
        car.registration_plate.toUpperCase().includes(filter) ||
        car.brand.toUpperCase().includes(filter) ||
        car.model.toUpperCase().includes(filter) ||
        car.imei.toString().includes(filter)
      )
    },
    badCars () {
      const now = Date.now()
      return this.filtredCars.filter(car => {
        if (car.lastState && car.lastState.lat) {
          const date = new Date(car.lastState.lat.time)
          if (now - date > 60 * 60000) {
            return car
          }
        }
      })
    },
    untrackedCars () {
      return this.filtredCars.filter(car => !car.lastState || !car.lastState.lat)
    },
    ...mapState('taxi/mapCars', {
      carsWithImei: state => state.items.filter(car => car.imei),
      isLoading: state => state.isLoading
    }),
    ...mapGetters('taxi/mapCars', [
      'selectedCar'
    ])
  },
  watch: {
    isLoading () {
      if (!this.isLoading) {
        this.firstIsLoading = false
      }
    }
  },
  mounted () {
    if (this.carsWithImei.length > 0) {
      this.firstIsLoading = false
    }
  },
  methods: {
    setSort (sortId) {
      this.sortFlag = sortId
      this.showSortsItems = false
    }
  }
}
</script>

<style scoped>
  .location-map-card {
    /* margin-top: 1rem; */
  }
  .map-search {
    position: sticky;
    top: 0;
    z-index: 9;
    padding-top: 0.4rem;
    background-color: white;
  }
  .filter-item {
    font-size: 14px;
  }
  .sorts-container {
    position: absolute;
    width: max-content;
    right: 0;
    z-index: 1;
    background: white;
    padding: 15px;
    border-radius: 10px;
  }
  .sorts-container li {
    list-style-type: none;
    font-size: 16px;
    padding: 5px 20px;
    cursor: pointer;
  }
  .sorts-container li:hover {
    background: #cbcbcb;
  }
</style>
