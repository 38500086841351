<template>
  <div>
    <div>
      <label>Название</label>
      <textarea v-model="title" class="form-control" />
    </div>
    <div style="padding:10px 0">
      Отображать на карте с машинами <input v-model="showOnMap" type="checkbox">
    </div>
    <div>
      <Sketch v-model="color" :disableAlpha="true" />
    </div>
    <div class="area-block">
      <div class="area-block-title">
        Срабатывание при въезде <input v-model="triggerEntry" type="checkbox">
      </div>
      <div v-if="triggerEntry" class="area-settings">
        <div>
          <label>Задержка срабатывания (мин)</label>
          <div class="input-group mb-3">
            <input v-model="triggerTimeoutEntry" type="number" class="form-control">
          </div>
        </div>
        <div>
          <label>Сообщение</label>
          <textarea v-model="triggerMessageEntry" class="form-control" />
        </div>
      </div>
    </div>
    <div class="area-block">
      <div class="area-block-title">
        Срабатывание при выезде <input v-model="triggerEscape" type="checkbox">
      </div>
      <div v-if="triggerEscape" class="area-settings">
        <div>
          <label>Задержка срабатывания (мин)</label>
          <div class="input-group mb-3">
            <input v-model="triggerTimeoutEscape" type="number" class="form-control">
          </div>
        </div>
        <div>
          <label>Сообщение</label>
          <textarea v-model="triggerMessageEscape" class="form-control" />
        </div>
      </div>
    </div>
    <a class="btn btn-primary m-1" @click="save()">Сохранить</a>
    <a class="btn btn-danger m-1" @click="remove()">Удалить</a>
  </div>
</template>

<script>
import { Sketch } from 'vue-color'
import RestrictedArea from '../api'
import Swal from 'sweetalert2'

export default {
  name: 'AreaPropEditor',
  components: {
    Sketch
  },
  props: {
    mapArea: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      showOnMap: true,
      triggerEntry: false,
      triggerEscape: false,
      triggerTimeoutEntry: 10,
      triggerTimeoutEscape: 10,
      triggerMessageEntry: '',
      triggerMessageEscape: '',
      color: '#008000',
      title: ''
    }
  },
  watch: {
    mapArea: {
      immediate: true,
      handler (mapArea) {
        this.showOnMap = mapArea.show_on_map
        this.color = mapArea.color || '#008000'
        this.title = mapArea.title
        this.triggerEntry = mapArea.trigger_entry
        this.triggerEscape = mapArea.trigger_escape
        this.triggerTimeoutEntry = mapArea.trigger_timeout_entry
        this.triggerTimeoutEscape = mapArea.trigger_timeout_escape
        this.triggerMessageEntry = mapArea.trigger_message_entry
        this.triggerMessageEscape = mapArea.trigger_message_escape
      }
    }
  },
  methods: {
    save () {
      let color = this.color
      if (typeof color !== 'string') {
        color = color.hex
      }
      RestrictedArea.updateItem({
        id: this.mapArea.id,
        title: this.title,
        color,
        show_on_map: this.showOnMap,
        trigger_entry: this.triggerEntry,
        trigger_escape: this.triggerEscape,
        trigger_timeout_entry: this.triggerTimeoutEntry,
        trigger_timeout_escape: this.triggerTimeoutEscape,
        trigger_message_entry: this.triggerMessageEntry,
        trigger_message_escape: this.triggerMessageEscape
      })
        .then(({ model }) => this.$emit('update', model))
        .catch(err => {
          console.error('Ошибка обновения полигона, обновите страницу')
          console.error(err)
        })
    },
    remove () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить полигон?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          RestrictedArea.removeItem(this.mapArea.id)
            .then(() => {
              this.$emit('delete', this.mapArea)
            })
            .catch(err => {
              console.error('Ошибка удаления полигона, обновите страницу')
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    }
  }
}
</script>

<style scoped>
  .area-block {
    padding-top: 10px;
  }
  .area-block-title {
    padding-bottom: 10px;
  }
  .area-settings {
    margin-left: 15px;
  }
  ::v-deep .vc-sketch {
    width: 200px;
    padding: 0;
    border: none;
    box-shadow: none;
  }
</style>
