<template>
  <div>
    <MapMarker :car="car" />
    <LFeatureGroup
      v-if="car.lastState && car.lastState.lat && car.lastState.lng"
    >
      <LMarker
        :z-index-offset="1"
        :lat-lng="[car.lastState.lat.value, car.lastState.lng.value]"
      >
        <LTooltip
          :options="{
            permanent: true,
            interactive: false,
            opacity: 0.8
          }"
        >
          <div class="selected-marker-tooltop">
            <ul>
              <li>Автомобиль: {{ car.brand }} {{ car.model }}</li>
              <li>Гос.номер: {{ car.registration_plate }}</li>
              <li>Пробег: {{ car.lastState && car.lastState.totalOdometer ? Math.round(car.lastState.totalOdometer.value / 1000) + 'км' : '' }}</li>
            </ul>
          </div>
        </LTooltip>
      </LMarker>
      <LPolyline v-if="showTrack" :lat-lngs="track" color="#0597f2" />
    </LFeatureGroup>
  </div>
</template>

<script>
import {
  LMarker,
  LTooltip,
  LPolyline,
  LFeatureGroup
} from 'vue2-leaflet'

export default {
  name: 'SelectedMarker',
  components: {
    LMarker,
    LTooltip,
    LPolyline,
    LFeatureGroup
  },
  props: {
    car: {
      type: Object,
      default: null
    },
    showTrack: Boolean,
    track: {
      type: Array,
      default: Array
    }
  }
}
</script>

<style scoped>
  .overlay-content {
    background-color: white;
    border-radius: 15px;
    padding: 0.3rem;
    pointer-events: none;
  }
  .selected-marker-tooltop ul {
    list-style-type: none;
    padding: 0;
    text-align: left;
    margin-bottom: 4px;
    margin-right: 0;
  }
  @media screen and (max-width: 576px) {
    .selected-marker-tooltop ul {
      font-size: 6px;
    }
  }
</style>
