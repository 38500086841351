/* eslint-disable camelcase */

import fines from './fines'

export default {
  namespaced: true,
  modules: {
    fines
  }
}
