<template>
  <div class="page">
    <div class="row mx-0">
      <div class="col">
        <div>Начало</div>
        <input
          v-model="dateStart"
          class="w-100 form-control"
          type="date"
          placeholder="Начало"
        >
      </div>
      <div class="col">
        <div>Окончание</div>
        <input
          v-model="dateEnd"
          class="w-100 form-control"
          type="date"
          placeholder="Окончание"
        >
      </div>
      <button class="input-group-btn btn btn-primary my-4 align-content-center col-auto" @click="loadItems()">
        <span>Показать</span>
      </button>
    </div>
    <a
      :href="'/api/service-car/analysis-export?' +
        '&startDateTime=' + encodeURIComponent(getDateWithTimezone(dateStart)) +
        '&endDateTime=' + encodeURIComponent(getDateWithTimezone(dateEnd))"
      class="input-group-btn btn btn-primary mr-2"
    >
      <font-awesome-icon class="text-white" icon="file-download" />
      Скачать отчет
    </a>
    <table class="table table-hover w-100 mt-3 d-none d-md-table">
      <TableSort class="sticky-1" :sorts="sorts" @sortsUpdate="loadWithSort" />
      <tbody>
        <tr v-for="car of cars" :key="car.id" @click="$router.push(`/maintenance/car/${car.id}`)">
          <td>{{ car.brand }}</td>
          <td>{{ car.model }}</td>
          <td>{{ car.reg_plate_number }} {{ car.reg_plate_region }}</td>
          <td>
            <ServiceCountState
              :count="Number(car.countService)"
              :start-date-time="dateStart"
              :end-date-time="dateEnd"
            />
          </td>
          <td>
            <StockCountState
              :count="Number(car.countStock)"
              :start-date-time="dateStart"
              :end-date-time="dateEnd"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div v-for="car of cars" :key="car.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
      <table v-if="cars.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Марка автомобиля</td>
            <td style="border-top: none">{{ car.brand }}</td>
          </tr>
          <tr>
            <td>Модель автомобиля</td>
            <td>{{ car.model }}</td>
          </tr>
          <tr>
            <td>Рег. номер</td>
            <td>{{ car.reg_plate_number }} {{ car.reg_plate_region }}</td>
          </tr>
          <tr>
            <td>Количество ТО</td>
            <td>{{ car.countService ? car.countService : 0 }}</td>
          </tr>
          <tr>
            <td>Количество запчастей</td>
            <td>{{ car.countStock ? car.countStock : 0 }}</td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="{ name: 'servePage', params: { carId: car.id } }"
        class="btn btn-primary btn-md create-serve ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { djsMixin } from '@/mixins/djsMixin'
import Swal from 'sweetalert2'
import ServiceCarAPI from '../api/serviceCar'
import dayjs from 'dayjs'
import ServiceCountState from '@/modules/maintenance/components/ServiceCountState'
import StockCountState from '@/modules/maintenance/components/StockCountState'

export default {
  name: 'AnalysisMaintenance',
  components: {StockCountState, ServiceCountState},
  mixins: [djsMixin],
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'Марка автомобиля',
          storeName: 'servecar-brand',
          values: ['', 'brand', '-brand'],
          order: 2
        }, {
          name: 'Модель автомобиля',
          storeName: 'servecar-model',
          values: ['', 'model', '-model'],
          order: 3
        }, {
          name: 'Рег. номер',
          storeName: 'servecar-registration_plate',
          values: ['', 'reg_plate_number', '-reg_plate_number'],
          order: 4
        }, {
          name: 'Количество ТО',
          storeName: 'servecar-nextService',
          values: ['', 'countService', '-countService'],
          order: 0
        }, {
          name: 'Количество запчастей',
          storeName: 'servecar-nextService',
          values: ['', 'countStock', '-countStock'],
          order: 1
        }
      ],
      dateStart: null,
      dateEnd: null,
      cars: [],
      sort: []
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'Тех. обслуживание'
      }
    ])
  },
  beforeDestroy () {
    this.abortLoading()
  },
  beforeMount () {
    this.dateStart = dayjs(new Date().setMonth((new Date()).getMonth() - 1))
    this.dateStart = this.dateStart.format('YYYY-MM-DD')
    this.dateEnd = dayjs(new Date()).format('YYYY-MM-DD')
  },
  methods: {
    ...mapActions('maintenance/serviceCar', [
      'setSearchText',
      'setSearch',
      'getAnalysis',
      'abortLoading',
      'removeItem',
      'setSorts'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    getDateWithTimezone (date) {
      return dayjs(date).format('YYYY-MM-DDTHH:mm:ssZZ')
    },
    loadWithSort (sorts) {
      this.sort = sorts.map(sort => sort.values[sort.index])
      this.loadItems()
    },
    loadItems () {
      if (this.dateStart && this.dateEnd) {
        ServiceCarAPI.getAnalysis({
          sort: this.sort,
          startDateTime: this.getDateWithTimezone(this.dateStart),
          endDateTime: this.getDateWithTimezone(this.dateEnd)
        })
          .then(cars => this.cars = cars)
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Ошибка',
              text: error.message
            })
          })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Не введены даты'
        })
      }
    },
    searchServe (e) {
      this.setSearchText(e.target.value)
      this.setSearch({ key: 'brand', value: e.target.value })
      this.setSearch({ key: 'model', value: e.target.value })
      this.setSearch({ key: 'reg_plate_number', value: e.target.value })
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  padding-top: 1rem;
  height: 100%;
  overflow-y: auto;
}
@media screen and (max-width: 1199px) {
  .page {
    padding: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .page {
    padding: 0.5rem;
  }
}
.highlighter ::v-deep .text__highlight {
  border-radius: 0px;
  padding: 0.2em 0 0.2em 0;
  background: #fff2ae;
}
.fixed-search {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
  background: white;
}
</style>
