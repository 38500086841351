import APITemplate from '@/api/APITemplate'

const url = '/api/contract'

const template = APITemplate(url)
export default {
    ...template,
    getPhotocontrolList(config) {
        const params = new URLSearchParams()
        params.append('expand', 'car')
        if (config && config.page !== undefined && config.pageSize !== undefined) {
            params.append('pagination[page]', (config.page - 1).toString())
            params.append('pagination[pageSize]', config.pageSize)
        }
        if (config && config.searchText !== undefined) {
            params.append('search', config.searchText.trim())
        }
        if (config && config.carId !== undefined) {
            params.append('carId', config.carId)
        }
        return template.fetchGet(`${url}/photocontrol-list?${params}`)
    }
}
