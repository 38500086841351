<template>
  <div v-if="driver" class="page">
    <h5 class="my-2">Найдено {{ driver.activeFines.length }} активных из {{ driver.fines.length }} штрафов</h5>
    <div v-for="fine in driver.fines" :key="fine.id" class="mobile-table-view card mt-4 mb-2 p-2">
      <table class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none" />
            <td style="border-top: none">
              <StatusState class="float-right" :archived_at="fine.archived_at" />
            </td>
          </tr>
          <tr>
            <td style="border-top: none">Причина</td>
            <td style="border-top: none">{{ fine.cause }}</td>
          </tr>
          <tr>
            <td>Сумма</td>
            <td>{{ fine.amount }}</td>
          </tr>
          <tr>
            <td>Номер постановления</td>
            <td>{{ fine.num_post }}</td>
          </tr>
          <tr>
            <td>Дата</td>
            <td>{{ fine.date }}</td>
          </tr>
          <tr v-if="fine.date_discount">
            <td>Дата окончания скидки</td>
            <td>{{ fine.date_discount }}</td>
          </tr>
          <tr v-if="fine.driver_id">
            <td>Водитель</td>
            <td>
              <router-link :to="'/drivers/' + fine.driver_id">
                {{ getDriver(fine.driver) }}
              </router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { djsMixin } from '@/mixins/djsMixin'
import StatusState from '../components/StatusState'

export default {
  name: 'FinesDriverShow',
  components: {StatusState},
  mixins: [djsMixin],
  props: {
    driver: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    getDriver () {
      return (driver) => {
        if (!driver) {
          return ''
        }
        return driver.last_name + ' ' + driver.first_name + ' ' + driver.middle_name
      }
    }
  },
  mounted () {
    this.loadData()
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('fines/fines', [
      'abortLoading'
    ]),
    loadData () {
      this.driver.fines.sort((fine) => (fine.archived_at) ? 1 : -1)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding-top: 1rem;
  height: 100%;
  overflow-y: auto;
}
@media screen and (max-width: 1199px) {
  .page {
    padding: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .page {
    padding: 0.5rem;
  }
}
</style>
