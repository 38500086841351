import moduleTemplate from '@/store/moduleTemplate'
import filesAPI from '@/api/files'
const template = moduleTemplate(filesAPI)
let request = null
template.types = {
  ...template.types,
  loadInstructions: 'loadInstructions',
}
template.mutations = {
  ...template.mutations,
  [template.types.loadInstructions] (state, { items }) {
    state.instructions = items
  },
}
template.state = {
  ...template.state,
  instructions: []
}

template.actions = {
  ...template.actions,
  loadInstructions ({ state, dispatch, commit }) {
    if (state.isLoading) {
      dispatch('abortLoading')
    }
    request = filesAPI.getInstructions()
    commit(template.types.setIsLoading, true)
    return dispatch('getInstructions', template.types.loadInstructions)
  },
  getInstructions ({ commit }, type) {
    return request.promise
      .then(params => {
        request = null
        commit(template.types.setIsLoading, false)
        commit(type, params)
        return params.items
      })
  },
  // eslint-disable-next-line no-unused-vars
  uploadInstruction ({ state, dispatch }, files) {
    const formData = new FormData()
    files.forEach(element => {
      formData.append('upfiles[]', element)
    })
    formData.append('type', 'instruction')
    filesAPI.uploadTemplates(formData)
      .then(() => {
        dispatch('loadInstructions')
      })
  }
}
export default template
