<template>
  <div class="page-login">
    <div class="text-center mb-4">
      <h1 class="h3 mb-3 font-weight-normal">Смена пароля</h1>
    </div>

    <div v-if="!token">
      <div class="form-label-group mb-3">
        <label class="w-100">Восстановление пароля по электронной почте
          <input
            v-model="email"
            type="email"
            class="form-control"
            placeholder="Email"
            required
            autofocus
            @keyup.enter="resetPassword()"
          >
        </label>
        <div :style="{ display: firstInput || email ? 'none' : 'block'}" class="invalid-feedback">
          Необходимо заполнить «Эл. почта».
        </div>
        <div :style="{ display: firstInput || !email || validEmail ? 'none' : 'block'}" class="invalid-feedback">
          Значение «Эл. почта» не является правильным email адресом.
        </div>
      </div>
      <button class="btn btn-lg btn-primary btn-block mb-3" type="button" @click="resetPassword">Запросить</button>
    </div>

    <div v-else class="form-label-group">
      <label class="w-100">Введите новый пароль
        <input
          v-model="newPassword"
          type="password"
          class="form-control"
          required
          autofocus
          @keyup.enter="setNewPassword()"
        >
      </label>
      <div :style="{ display: firstInput || newPassword ? 'none' : 'block'}" class="invalid-feedback">
        Необходимо заполнить «Пароль».
      </div>
      <div :style="{ display: firstInput || newPassword.length >= 5 ? 'none' : 'block'}" class="invalid-feedback">
        Значение «Пароль» должно содержать минимум 5 символов.
      </div>
      <button class="btn btn-lg btn-primary btn-block mt-3" type="button" @click="setNewPassword">Сменить</button>
    </div>
    <router-link to="/login" class="btn btn-link">Вход</router-link>
  </div>
</template>

<script>
import userApi from '@/api/user'

export default {
  props: {
    token: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      email: '',
      newPassword: '',
      firstInput: true
    }
  },
  computed: {
    validEmail () {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.email.toLowerCase())
    }
  },
  watch: {
    newPassword () {
      this.firstInput = false
    },
    email () {
      this.firstInput = false
    }
  },
  methods: {
    resetPassword () {
      userApi.resetPassword({
        email: this.email
      })
        .then(() => global.Swal.fire('Инструкция по восстановлению пароля выслана на почту'))
        .then(() => this.$router.push('/').catch(error => error))
        .catch(error => {
          global.Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            text: error
          })
          throw error
        })
    },
    setNewPassword () {
      userApi.setNewPassword({
        token: this.token,
        password: this.newPassword
      })
        .then(() => global.Swal.fire('Пароль изменен'))
        .then(() => this.$router.push('/').catch(error => error))
        .catch(error => {
          global.Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            text: error
          })
          throw error
        })
    }
  }
}
</script>

<style scoped>
  .page-login {
    max-width: 420px;
    width: 100%;
    position: fixed;
    left: 50%;
    top: 33%;
    transform: translate(-50%, -33%);
    padding: 10px;
  }

  label {
    padding: 10px 0px 0px 0px;
  }
</style>
