<template>
  <div>
    <table class="table table-hover closed-table w-100 d-none d-md-table">
      <thead class="sticky-2">
        <tr>
          <th scope="col">№</th>
          <th>Автомобиль</th>
          <th>Водитель</th>
          <th>Организация</th>
          <th>Начало аренды</th>
          <th>Автомобиль принят</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="closedContracts.length === 0">
          <td colspan="6" style="text-align: center">Ничего не найдено</td>
        </tr>
        <tr
          v-for="contract of closedContracts"
          :key="contract.id"
          class="tablerow"
          role="button"
          tabindex="0"
          @click="$router.push(`/rent/${contract.id}`)"
        >
          <td scope="row" class="closed-number-col">{{ contract.number }}</td>
          <td>
            <TextHighlight class="highlighter" :queries="searchTextValue">
              {{ contract.car.brand }} {{ contract.car.model }} - {{ contract.car.registration_plate }}
            </TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchTextValue">
              {{ contract.driver.last_name }} {{ contract.driver.first_name }} {{ contract.driver.middle_name }}
            </TextHighlight>
          </td>
          <td>{{ contract.company ? contract.company.name : '-' }}</td>
          <td>{{ contract.transfer_datetime | diffForHumans }}</td>
          <td>{{ contract.archived_at | diffForHumans }}</td>
        </tr>
      </tbody>
    </table>
    <div v-for="contract of closedContracts" :key="contract.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
      <table v-if="closedContracts.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">№</td>
            <td style="border-top: none">
              <TextHighlight class="highlighter" :queries="searchTextValue">{{ contract.number }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Автомобиль</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchTextValue">
                {{ contract.car.brand }} {{ contract.car.model }} - {{ contract.car.registration_plate }}
              </TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Водитель</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchTextValue">
                {{ contract.driver.last_name }} {{ contract.driver.first_name }} {{ contract.driver.middle_name }}
              </TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Организация</td>
            <td>{{ contract.company ? contract.company.name : '-' }}</td>
          </tr>
          <tr>
            <td>Автомобиль принят</td>
            <td>{{ contract.receiving_datetime | diffForHumans }}</td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="{ name: 'rentPage', params: { contractId: contract.id } }"
        class="btn btn-primary btn-md ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>

import { djsMixin } from '@/mixins/djsMixin'

export default {
  name: 'ClosedRents',
  mixins: [djsMixin],
  props: {
    closedContracts: {
      type: Array,
      default: Array
    },
    searchText: {
      type: String,
      default: ''
    }
  },
  computed: {
    searchTextValue () {
      return this.searchText ?? ''
    }
  }
}
</script>
