import APITemplate from '@/api/APITemplate'

const headers = {
  accept: 'application/json',
  'content-type': 'application/json'
}

export default {
  ...APITemplate('/api/car'),
  getItemsWith (expand) {
    const controller = new AbortController()
    return {
      promise: fetch('/api/car?expand=' + expand.toString(), {
        method: 'GET',
        credentials: 'include',
        redirect: 'error',
        signal: controller.signal,
        headers
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Ошибка при загрузке.')
          }
          return new Promise(resolve => {
            response.json().then(items => {
              resolve({
                items
              })
            })
          })
        }),
      abort () {
        controller.abort()
      }
    }
  },
  getTrack (id, timeFrom, timeTo) {
    const request = `/api/car/track?id=${id}&s_datetime=${encodeURIComponent(timeFrom)}&e_datetime=${encodeURIComponent(timeTo)}`
    const controller = new AbortController()
    return {
      promise: fetch(request, {
        method: 'GET',
        credentials: 'include',
        redirect: 'error',
        signal: controller.signal,
        headers
      })
        .then(response => {
          if (response.status !== 200) {
            throw new Error('Ошибка при загрузке.')
          }
          return new Promise(resolve => {
            response.json().then(track => {
              resolve({
                track
              })
            })
          })
        }),
      abort () {
        controller.abort()
      }
    }
  },
  getCarsForWatch () {
    return fetch('/api/car/get-cars-for-watch', {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers
    }).then(response => response.json())
  },

  /**
   * Проверка номера на валидность.
   * @param {string=} region Регион. Пример: 72
   * @param {string=} number Номер. Пример: A222AA или AA222
   * @returns {boolean} Результат проверки
   */
  validateRegistrationPlate (region, number) {
    const letters = 'АВЕКМНОРСТУХABEKMHOPCTYX'
    return region.match(/^\d{2,3}$/ui) &&
      (number.match(
        new RegExp(
          `^(([${letters}]{2}\\d{3})|([${letters}]\\d{3}[${letters}]{2}))$`,
          'ui'
        )
      ))
  }
}
