<template>
  <div v-if="driver">
    <ActionBar
      :isEditing="isEditing"
      @changeAction="changeCharacteristic"
      @cancelAction="cancelCharacteristicChanges"
      @deleteAction="deleteCharacteristic"
      @saveAction="updateCharacteristic"
    />
    <br>
    <CharacteristicEdit
      v-if="isEditing"
      :driver-characteristics="addedCharacteristics"
      class="characteristic-show"
    />
    <CharacteristicView
      v-else
      :driver-characteristics="driver.characteristics"
      class="characteristic-show"
    />
  </div>
</template>

<script>

import DriversAPI from '@/api/drivers'
import CharacteristicEdit from './Edit'
import CharacteristicView from './View'
import Swal from 'sweetalert2'

export default {
  name: 'CharacteristicPage',
  components: {
    CharacteristicView,
    CharacteristicEdit
  },
  props: {
    driverId: {
      type: [Number, String],
      default: () => null
    }
  },
  data () {
    return {
      driver: null,
      isEditing: false,
      isUpdating: false,
      prevCharacteristics: [],
      addedCharacteristics: []
    }
  },
  computed: {
  },
  mounted () {
    this.reloadDriver(this.driverId)
  },
  methods: {
    changeCharacteristic () {
      this.prevCharacteristics = [...this.driver.characteristics]
      this.isEditing = true
    },
    cancelCharacteristicChanges () {
      this.addedCharacteristics = [...this.prevCharacteristics]
      this.isEditing = false
    },
    deleteCharacteristic () {
      this.prevCharacteristics = []
      this.addedCharacteristics = []
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить характеристику водителя?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          this.updateCharacteristic()
        }
      })
    },
    updateCharacteristic () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      DriversAPI.updateItem({...this.driver, addedCharacteristics: this.addedCharacteristics})
        .then(driver => {
          this.reloadDriver(driver?.model?.id)
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    reloadDriver (driverId) {
      DriversAPI.getItem({ id: driverId, queryWith: ['characteristics'] })
        .then(driver => {
          this.driver = driver
          this.addedCharacteristics = [...this.driver.characteristics]
        })
    }
  }
}
</script>
<style scoped>
  .characteristic-show {
    margin-top: 4rem;
  }
</style>
