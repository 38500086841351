import Vue from 'vue'
import Vuex from 'vuex'
import taxi from './modules/taxi'
import modules from '@/modules/store'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    taxi,
    ...modules
  },
  strict: process.env.NODE_ENV !== 'production'
})

window.store = store

export default store
