import Swal from 'sweetalert2'
import filesAPI from '@/api/files'

const types = {
  pushItems: 'pushItems',
  setIsLoading: 'setIsLoading',
  clearItems: 'clearItems',
  setModel: 'setModel',
  removeScan: 'removeScan',
  addScans: 'addScans',
  updateScan: 'updateScan',
  rollback: 'rollback'
}

export default {
  namespaced: true,
  /**
   * The Vuex 'state' object.
   * @name state
   * @type {object}
   * @property {Array} items Хранит массив объектов {documentId, scans }
   * @property {string} currentModel Хранит текущую модель (документ водителя/ документ автомобиля)
   * для получения соответсвующих ей сканов используя api
   */
  state: {
    items: [],
    isLoading: false,
    currentModel: null
  },
  actions: {
    removeItem ({ commit }, id) {
      filesAPI.removeItem(id).then(() => {
        commit(types.removeScan, id)
      })
    },
    uploadItem ({ commit }, formData) {
      const id = formData.get('id')
      filesAPI.uploadScan(formData).then(response => {
        commit(types.addScans, { uploadedScans: response, id })
      })
    },
    updateItem ({ commit }, scanChanges) {
      filesAPI.updateItem(scanChanges).then(response => {
        commit(types.updateScan, response.model)
      }).catch(err => {
        console.error(err)
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: err.message
        })
      })
    }
  },
  getters: {
    scansByDocument: state => id => {
      const finded = state.items.find(item => item.documentId === id)
      return finded ? finded.scans : []
    }
  },
  mutations: {
    [types.pushItems] (state, {
      scans,
      documentId
    }) {
      state.items.push({ documentId, scans })
    },
    [types.removeScan] (state, id) {
      state.items.forEach(item => {
        item.scans = item.scans.filter(scan => scan.id !== id)
      })
    },
    [types.updateScan] (state, scanChanges) {
      state.items.forEach(item => {
        const finded = item.scans.find(scan => scan.id === scanChanges.id)
        if (finded) {
          finded.name = scanChanges.name
        }
      })
    },
    [types.addScans] (state, { uploadedScans, id }) {
      const finded = state.items.find(item => item.documentId === Number(id))
      if (finded) {
        finded.scans.push(...uploadedScans)
      }
    },
    [types.setIsLoading] (state, value) {
      state.isLoading = value
    },
    [types.clearItems] (state) {
      state.items = []
    },
    [types.setModel] (state, value) {
      state.currentModel = value
    }
  },
  types
}
