import { render, staticRenderFns } from "./EquipmentsPage.vue?vue&type=template&id=313dd4bb&scoped=true&"
import script from "./EquipmentsPage.vue?vue&type=script&lang=js&"
export * from "./EquipmentsPage.vue?vue&type=script&lang=js&"
import style0 from "./EquipmentsPage.vue?vue&type=style&index=0&id=313dd4bb&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../shared/frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "313dd4bb",
  null
  
)

export default component.exports