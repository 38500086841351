<template>
  <div class="page page-companies">
    <router-link to="/companies/create" class="btn btn-primary w-100">Добавить</router-link>
    <table class="table table-hover w-100 mt-3 d-none d-md-table">
      <TableSort class="sticky-1" :sorts="sorts" @sortsUpdate="loadWithSort" />
      <tbody>
        <tr
          v-for="company of companies"
          :key="company.id"
          class="tablerow"
          @click="$router.push(`/companies/${company.id}`)"
        >
          <td>{{ company.name }}</td>
          <td>{{ company.position }}</td>
          <td>{{ company.sign_name }}</td>
          <td>{{ company.inn }}</td>
        </tr>
      </tbody>
    </table>
    <div v-for="company of companies" :key="company.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
      <table v-if="companies.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Организация</td>
            <td style="border-top: none">{{ company.name }}</td>
          </tr>
          <tr>
            <td>Исполнительный орган</td>
            <td>{{ company.position }}</td>
          </tr>
          <tr>
            <td>Фамилия И.О.</td>
            <td>{{ company.sign_name }}</td>
          </tr>
          <tr>
            <td>ИНН</td>
            <td>{{ company.inn }}</td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="{ name: 'companyPage', params: { id: company.id } }"
        class="btn btn-primary btn-md create-car ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Companies',
  data () {
    return {
      sorts: [
        {
          name: 'Организация',
          storeName: 'companiesName',
          values: ['', 'name', '-name'],
          order: 0
        }, {
          name: 'Исполнительный орган',
          storeName: 'companiesPosition',
          values: ['', 'position', '-position'],
          order: 1
        }, {
          name: 'Фамилия И.О.',
          storeName: 'companiesIOLastName',
          values: ['', 'sign_name', '-sign_name'],
          order: 2
        }, {
          name: 'ИНН',
          storeName: 'companiesInn',
          values: ['', 'inn', '-inn'],
          order: 3
        }
      ]
    }
  },
  computed: {
    ...mapState('taxi/companies', {
      companies: state => state.items,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading
    })
  },
  mounted () {
    this.loadItems()
    this.setTitle([
      {
        title: 'Организации'
      }
    ])
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('taxi/companies', [
      'loadItems',
      'abortLoading',
      'setSorts'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems()
    }
  }
}
</script>

<style scoped>
  .tablerow {
    cursor: pointer;
  }
</style>
