<template>
  <div class="page">
    <HeaderTitle />
    <div v-if="feedbacks && feedbacks.length > 0">
      <table class="table table-hover w-100 mt-3 d-none d-md-table">
        <TableSort class="sticky-1" :sorts="sorts" @sortsUpdate="loadWithSort" />
        <tbody>
          <tr v-for="feedback of feedbacks" :key="feedback.id" @click="$router.push(`/feedback/${feedback.id}`)">
            <td>{{ feedback.created_at | formatDateTime }}</td>
            <td>{{ feedback.message }}</td>
            <td><FeedbackStatus :status="feedback.status" /></td>
          </tr>
        </tbody>
      </table>
      <div v-for="feedback of feedbacks" :key="feedback.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
        <table class="table borderless">
          <tbody>
            <tr>
              <td style="border-top: none">Дата запроса</td>
              <td style="border-top: none">{{ feedback.created_at | formatDateTime }}</td>
            </tr>
            <tr>
              <td>Сообщение</td>
              <td>{{ feedback.message }}</td>
            </tr>
            <tr>
              <td>Статус</td>
              <td><FeedbackStatus :status="feedback.status" /></td>
            </tr>
          </tbody>
        </table>
        <router-link
          :to="{ name: 'FeedbackShow', params: { feedbackId: String(feedback.id) } }"
          class="btn btn-primary btn-md create-fines ml-auto"
        >
          Подробно
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import FeedbackStatus from '../../components/FeedbackStatus'

export default {
  name: 'FeedbackList',
  components: {FeedbackStatus},
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
            name: 'Дата запроса',
          storeName: 'feedback-created_at',
          values: ['', 'created_at', '-created_at'],
          order: 0
        },
        {
          name: 'Сообщение',
          storeName: 'feedback-message',
          values: ['', 'message', '-message'],
          order: 1
        },
        {
          name: 'Статус',
          storeName: 'feedback-status',
          values: ['', 'status', '-status'],
          order: 2
        }
      ],
      searchedText: null,
      interval: null
    }
  },
  computed: {
    ...mapState('feedback/feedback', {
      feedbacksItems: state => state.items,
      pageCount: state => state.pageCount,
      searchText: state => state.searchText,
      isLoading: state => state.isLoading
    }),
    ...mapState('taxi/user', {
      users: state => state.items,
      authedUser: state => state.items.find(user => user.id === state.authedId)
    }),
    feedbacks () {
      return this.feedbacksItems.filter(feedback => feedback.user_id === this.authedUser.id)
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'Обратная связь'
      }
    ])
    this.loadUsers()
    this.loadAuthedUser()
    if (!this.interval) {
      this.loadWithSort(this.sorts)
    }
  },
  methods: {
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('feedback/feedback', [
      'loadItems',
      'setSorts'
    ]),
    ...mapActions('taxi/user', {
      loadUsers: 'loadItems',
      abortLoading: 'abortLoading',
      loadAuthedUser: 'loadAuthedUser'
    }),
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page) })
    },
  }
}
</script>
