<template>
  <div class="wrapper">
    <aside class="base-sidebar pt-0" :class="{ 'togled-left-bar': showFullSideMenu }">
      <div class="sidebar-sticky">
        <router-link
          to="/"
          class="top-bar-title d-flex text-dark"
        >
          Stattika
        </router-link>
        <ul class="nav flex-column">
          <li
            v-for="link of links"
            :key="link.text"
            :class="['nav-item', link.divider ? 'divider' : '', link.subactive ? 'subactive' : '']"
          >
            <router-link
              v-if="!link.divider && !link.hide"
              :to="link.url"
              :class="{ active: link.active || (link.sublinks && link.sublinks.find((el) => el.active)) }"
              class="nav-menu-item align-middle"
            >
              <div class="row">
                <div class="col-sm-2">
                  <font-awesome-icon class="sidebar-icon" :icon="link.icon" />
                </div>
                <div class="col link-text">{{ link.text }}</div>
              </div>
              <div v-if="link.sublinks && link.subactive" class="sublinks-wrapper" style="margin-top: 0.3rem">
                <li
                  v-for="sublink of link.sublinks"
                  :key="sublink.text"
                  :class="['nav-item', link.divider ? 'divider' : '']"
                >
                  <router-link
                    v-if="!sublink.divider && !sublink.hide"
                    :to="sublink.url"
                    :class="{ active: sublink.active }"
                    class="nav-sublink align-middle"
                  >
                    <div class="row">
                      <div class="col-sm-2" />
                      <div class="col sublink-text">{{ sublink.text }}</div>
                    </div>
                    <span v-if="sublink.active" class="sr-only">(current)</span>
                  </router-link>
                </li>
              </div>
              <span v-if="link.active" class="sr-only">(current)</span>
            </router-link>
          </li>
          <li class="nav-subitem bottom-sidebar-item">
            <div
              class="togle-button-wrapper nav-menu-item align-middle noselect"
              role="button"
              tabindex="0"
              @click="showFullSideMenu = !showFullSideMenu"
            >
              <div class="row">
                <div class="col-sm-2">
                  <font-awesome-icon v-if="showFullSideMenu" class="sidebar-icon" icon="angle-double-left" />
                  <font-awesome-icon v-else class="sidebar-icon" icon="angle-double-right" />
                </div>
                <div class="col link-text">Свернуть</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </aside>
    <MobileSideBar v-if="showSideMenu" @close-menu="showSideMenu = false" />

    <div class="wrapper-vertical">
      <header class="navbar-expand-lg navbar-light bg-light top-bar">
        <div class="d-flex align-items-center">
          <button
            class="hamburger hamburger--arrow"
            type="button"
            :class="{ 'is-active': showSideMenu }"
            @click="showSideMenu = !showSideMenu"
          >
            <span class="hamburger-box">
              <span class="hamburger-inner" />
            </span>
          </button>
          <router-link
            to="/"
            class="top-bar-title mobile-header-title mx-2 text-dark"
          >
            Stattika
          </router-link>
          <!-- <div class="bell-item noselect" role="button" tabindex="0" @click="showEvents = !showEvents" style="display: none">
            <div class="numberCircle">3</div>
            <font-awesome-icon class="notifications-bell-icon" icon="bell" :style="{ color: '#404040' }" />
          </div> -->
          <div class="header-title">
            <div v-for="(item, key) in title" :key="key">
              <span v-if="key !== 0" class="px-2">›</span>
              <router-link v-if="item.url" :to="item.url" class="text-dark">{{ item.title }}</router-link>
              <div v-else style="display: contents" class="text-dark">{{ item.title }}</div>
            </div>
          </div>
        </div>
        <div>
          <div
            v-click-outside="hideLogout"
            class="float-right noselect"
            role="button"
            tabindex="0"
            @click="showLogout = !showLogout"
          >
            <font-awesome-icon class="user-icon" icon="user-circle" :style="{ color: '#404040' }" />
          </div>
          <router-link
            v-if="hasPermission('/feedback-admin')"
            to="/feedback-admin"
            class="float-right noselect"
            role="button"
            tabindex="0"
          >
            <font-awesome-icon class="bell-icon" icon="bell" :style="{ color: '#404040' }" />
            <div v-if="countNewNotify > 0" class="notification-badge">
              <span class="badge badge-danger">{{ countNewNotify }}</span>
            </div>
          </router-link>
        </div>
      </header>
      <main class="content">
        <UserContextMenu
          v-if="showLogout"
          ref="logoutPopup"
          class="logout-card"
          @close="showLogout = false"
        />
        <slot />
      </main>
      <footer>
        <div
          type="button"
          class="feedback-btn ajax-modal-button"
          @click="showModal = true"
        >
          <div class="feedback-btn__content">
            <font-awesome-icon class="feedback-btn__content__icon" icon="envelope" />
            <span class="feedback-btn__content__text btn-link">Обратная<br>связь</span>
          </div>
        </div>
      </footer>
    </div>


    <div v-if="showModal" class="modal-fade">
      <div class="modal-backdrop">
        <div
          class="modal"
          role="dialog"
          aria-labelledby="modalTitle"
          aria-describedby="modalDescription"
        >
          <header
            id="modalTitle"
            class="modal-header"
          >
            <slot name="header">
              Обратная связь
            </slot>
            <button
              type="button"
              class="btn-close"
              aria-label="Close modal"
              @click="showModal = false"
            >
              x
            </button>
          </header>

          <section
            id="modalDescription"
            class="modal-body"
          >
            <slot name="body">
              <div>
                <label>ФИО</label>
                <input
                  v-model="feedback.fullname"
                  type="text"
                  class="form-control"
                  placeholder
                  required
                >
              </div>
              <div>
                <label>Эл. почта</label>
                <input
                  v-model="feedback.email"
                  type="text"
                  class="form-control"
                  placeholder
                  required
                >
              </div>
              <div>
                <label>Телефон</label>
                <input
                  v-model="feedback.phone"
                  type="text"
                  class="form-control"
                  placeholder
                  required
                >
              </div>
              <div>
                <label>Сообщение</label>
                <textarea
                  v-model="feedback.message"
                  rows="5"
                  type="text"
                  class="form-control"
                  placeholder
                  required
                />
              </div>
              <div>
                <br>
                <input
                  id="upload"
                  ref="fileInput"
                  multiple
                  type="file"
                >
              </div>
            </slot>
          </section>

          <footer class="modal-footer d-flex">
            <button
              type="button"
              class="btn btn-primary"
              aria-label="Close modal"
              @click="createFeedback"
            >
              Отправить
            </button>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { mapActions, mapState } from 'vuex'
import leftMenuLinks from './leftMenuLinks'
import UserContextMenu from '@/components/UserContextMenu'
import userApi from '@/api/user'
import FeedbackAPI from '@/api/feedback'
import Swal from 'sweetalert2'

export default {
  name: 'BaseWorkspaceLayout',
  components: {
    UserContextMenu
  },
  directives: {
    ClickOutside
  },
  data () {
    return {
      showLogout: false,
      showSideMenu: false,
      showFullSideMenu: false,
      permissions: null,
      showModal: false,
      countNewNotify: 0,
      feedback: {
        fullname: null,
        email: null,
        phone: null,
        message: null
      },
      interval: null
    }
  },
  computed: {
    links () {
      const links = JSON.parse(JSON.stringify(leftMenuLinks))
      let showDivider = false
      for (const link of links) {
        if (link.divider) {
          if (!showDivider) {
            link.divider = false
          }
          showDivider = false
        }
        if (this.permissions && (!this.permissions[link.url] && !this.permissions['/*'])) {
          link.hide = true
        } else {
          showDivider = true
        }

        if (link.subactiveRoutes &&
          link.subactiveRoutes.find(x => this.$route.path.includes(x))) {
          link.subactive = true
        }

        if (this.$route.path.includes(link.url)) {
          link.subactive = true
        }

        if (link.url === this.$route.path) {
          link.active = true
        }

        if (!link.sublinks) {
          continue
        }

        link.sublinks.forEach(el => {
          if (this.permissions && (!this.permissions[el.url] && !this.permissions['/*'])) {
            el.hide = true
          }
          if (el.url === this.$route.path) {
            el.active = true
            link.active = true
          } else {
            el.active = false
          }
        })
      }
      return links
    },
    hasPermission () {
      return (link) => {
        return (this.permissions && (this.permissions[link.url] || this.permissions['/*']))
      }
    },
    ...mapState('taxi', {
      title: state => state.title
    })
  },
  watch: {
    $route () {
      this.showFullSideMenu = false
    }
  },
  beforeMount () {
    userApi.getFrontendPermission()
      .then(permissions => {
        this.permissions = permissions
        if (!this.interval) {
          this.getNewFeedbackCount()
        }
      })
  },
  mounted () {
    this.loadAuthedUser()
  },
  beforeDestroy () {
    clearTimeout(this.interval)
    this.interval = null
  },
  methods: {
    getNewFeedbackCount () {
      if (this.hasPermission('/feedback-admin')) {
        FeedbackAPI.getCountNew()
          .then(notifications => {
            if (notifications.items.feedback) {
              this.countNewNotify = parseInt(notifications.items.feedback)
              this.interval = setTimeout(() => { this.getNewFeedbackCount() }, 5000)
            }
          })
      }
    },
    hideLogout (e) {
      if (this.$refs.logoutPopup && !e.path.includes(this.$refs.logoutPopup.$el)) {
        this.showLogout = false
      }
    },
    createFeedback () {
      FeedbackAPI.createItem({
        ...this.feedback,
        user_agent: navigator.userAgent,
        url: window.location.href,
        resolution: window.screen.availHeight + 'x' + window.screen.availWidth
      })
        .then(response => {
          let feedback = response.model
          this.showModal = false
          let files = this.$refs.fileInput.files
          const formData = new FormData()
          files.forEach (file => {
            formData.append('File[file][]', file)
          })
          formData.append('id', feedback.id)
          FeedbackAPI.uploadFiles(formData)
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi/user', [
      'loadAuthedUser'
    ])
  }
}
</script>

<style lang="scss" scoped>
  // HAMBURGER STYLING
  $hamburger-layer-width: 20px;
  $hamburger-layer-height: 2px;
  $hamburger-layer-spacing: 3px;
  $hamburger-padding-x: 0;
  $hamburger-padding-y: 0;
  $hamburger-layer-color: #55606a;
  @import "@/assets/scss/globals.scss";
  @import "hamburgers/_sass/hamburgers/hamburgers.scss";
  @import "bootstrap";
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

  .wrapper {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
  }
  .top-bar-title {
    font-family: Poppins, sans-serif;
    font-size: 24px;
    text-align: center;
    margin-bottom: 3px;
    height: 43px;
    place-content: center;
    align-items: center;
    text-decoration: none !important;
  }
  .header-title {
    display: flex;
    margin-left: 15px;
    font-size: 20px;
  }

  .mobile-header-title {
    display: none;
  }

  .base-sidebar {
    flex-basis: 250px;
    padding: 15px 0 0;
    font-size: 0.875rem;
    font-weight: 400;
    background-color: #fafafa;
    border-right: 1px solid #ddd;
    overflow-y: auto;
    li {
      width: 100%;
    }
    .nav-menu-item {
      @extend .nav-link;
      color: #707070;
      &:hover {
        color: #4f4f4f;
      }
      &.active .sidebar-icon {
        color: #0597f2;
      }
      &.active .link-text {
        font-weight: 600;
        color: #0597f2;
      }
    }
    .nav-sublink {
      @extend .nav-link;
      color: #707070;
      &:hover {
        color: #4f4f4f;
      }
      &.active .sublink-icon {
        color: #0597f2;
      }
      &.active .sublink-text {
        font-weight: 600;
        color: #0597f2;
      }
    }
  }

  .wrapper-vertical {
    position: relative;
    display: flex;
    flex-grow: 0;
    overflow: hidden;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
  }

  @media screen and (max-width: 768px) {
    .link-text {
      display: block;
    }
    .base-sidebar {
      display: none;
    }
    .hamburger {
      display: block !important;
    }
    .mobile-header-title {
      display: flex;
    }
  }
  @media screen and (max-width: 1199px) {
    .header-title {
      display: none;
    }
  }

  header {
    width: 100%;
    height: 43px;
    align-items: center;
    padding: 0;
    display: flex;
  }

  .hamburger {
    padding: 0;
    margin-left: 14px;
    display: none;
  }
  .logout-card {
    z-index: 9999;
    margin-right: calc(100% - 100vw + 0.2rem);
  }
  .events {
    z-index: 9999;
  }
  .divider {
    margin: 0.25rem 0 0.25rem 0;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }
  .bell-item {
    position: relative;
    width: 25px;
    height: 25px;
    vertical-align: middle !important;
    margin-left: auto;
  }
  .notifications-bell-icon {
    height: 25px !important;
    width: 25px !important;
    position: absolute;
  }
  .top-bar {
    height: #{$bwlayuotHeader};
    flex-shrink: 0;
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: space-between;
  }
  .user-icon {
    height: 25px;
    width: 25px;
    margin-right: 15px;
  }
  .bell-icon {
    height: 20px;
    width: 20px;
    margin-right: 15px;
  }
  .sidebar-icon {
    width: 18px;
    height: 18px;
    margin-right: 30px;
    vertical-align: -3px;
  }
  .content {
    font-weight: 400;
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .noselect {
    user-select: none;
  }
  .togled-left-bar {
    width: 220px;
    z-index: 9999;
    .link-text {
      display: block;
    }
  }
  .bottom-sidebar-item {
    position: absolute;
    bottom: 0.3rem;
    display: none;
  }


  .feedback-btn {
    position: fixed;
    right: 5px;
    bottom: 5px;
    z-index: 100;

    height: 50px;
    width: 50px;
    cursor: pointer;

    background: #38a3fd;
    border: 1px solid #38a3fd;
    border-radius: 50%;
    box-shadow: 0 8px 10px rgba(56,163,253,0.3);
    text-align: center;
    transition: .3s;
  }

  .feedback-btn:hover {
    height:66px;
    width:66px;
    background: #fff;
    animation: email-an linear 1s infinite;
    transition: .3s;
  }

  .feedback-btn__content {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    position: relative;
    overflow: hidden;
  }

  .feedback-btn__content:hover {
    height:66px;
    width:66px;
  }

  .feedback-btn__content__icon {
    color: #fff;
    font-size: 25px;
    transition: .3s;
    line-height: 48px;
    margin-top: 12px;
  }

  .feedback-btn:hover .feedback-btn__content__icon {
    display: none;
  }

  .feedback-btn__content__text {
    opacity: 0;
    font-size: 0;
    position: absolute;
    left: 0px;
    top: 22px;
    line-height: 12px;
    font-weight: 600;
    text-transform: uppercase;
    transition: opacity .3s linear;
  }

  .feedback-btn:hover .feedback-btn__content__text {
    opacity: 1;
    right: 1px;
    font-size: 12px;
    text-decoration: none;
  }

  @keyframes email-an {
    0% {box-shadow:0 8px 10px rgba(56,163,253,0.3),0 0 0 0 rgba(56,163,253,0.2),0 0 0 0 rgba(56,163,253,0.2)}
    40% {box-shadow:0 8px 10px rgba(56,163,253,0.3),0 0 0 10px rgba(56,163,253,0.2),0 0 0 0 rgba(56,163,253,0.2)}
    80% {box-shadow:0 8px 10px rgba(56,163,253,0.3),0 0 0 20px rgba(56,163,253,0.2),0 0 0 26.7px rgba(56,163,253,0.067)}
    100% {box-shadow:0 8px 10px rgba(56,163,253,0.3),0 0 0 20px rgba(56,163,253,0.2),0 0 0 40px rgba(56,163,253,0.0)}
  }



  .modal-backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal {
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    max-width: 500px;
    max-height: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
  }

  .modal-header,
  .modal-footer {
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #007bff;
    justify-content: space-between;
  }

  .modal-footer {
    border-top: 1px solid #eeeeee;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }

  .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
    color: #007bff;
    background: transparent;
  }

  .btn-green {
    color: white;
    background: #007bff;
    border: 1px solid #007bff;
    border-radius: 2px;
  }

  .modal-fade-enter,
  .modal-fade-leave-to {
    opacity: 0;
  }

  .modal-fade-enter-active,
  .modal-fade-leave-active {
    transition: opacity .5s ease;
  }

  .notification-badge {
    position: absolute;
    display: flex;
    justify-content: center;
    top: 1px;
  }
  .notification-badge .badge {
    font-size: 10px;
  }
</style>
