
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/css/global.css'
import 'vue-select/dist/vue-select.css'
import '@sweetalert2/theme-bootstrap-4/bootstrap-4.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './filters'
import './components'
import './layouts'
import './font-awesome-init'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import VSelect from 'vue-select'
import Swal from 'sweetalert2/src/sweetalert2.js'
import { DraggableTree } from 'vue-draggable-nested-tree'

Vue.config.productionTip = false
Vue.component('VSelect', VSelect)
Vue.component('DraggableTree', DraggableTree)
window.Swal = Swal

dayjs.extend(customParseFormat)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
