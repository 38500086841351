import userApi from '@/api/user'
import moduleTemplate from '@/store/moduleTemplate'

const template = moduleTemplate(userApi)

const types = {
  setAuthedId: 'setAuthedId',
  pushOrUpdateItem: 'pushOrUpdateItem'
}

template.state = {
  ...template.state,
  authedId: null
}

template.mutations = {
  ...template.mutations,
  [types.setAuthedId] (state, value) {
    state.authedId = value
  },
  [types.pushOrUpdateItem] (state, value) {
    const index = state.items.findIndex(item => item.id === value.id)
    if (index === -1) {
      state.items.push(value)
      return
    }
    state.items[index] = value
  }
}

template.actions = {
  ...template.actions,
  loadAuthedUser ({ commit }) {
    return userApi.getCurrentUser()
      .then(user => {
        if (!user) {
          return user
        }
        commit(types.setAuthedId, user.id)
        commit(types.pushOrUpdateItem, user)
        return user
      })
  }
}
export default template
