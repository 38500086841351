<template>
  <div v-if="accident" class="page">
    <HeaderTitle />
    <ActionBar
      :isEditing="isEditing"
      @changeAction="changeAccident"
      @cancelAction="cancelAccidentChanges"
      @deleteAction="deleteAccident"
      @saveAction="updateAccident"
    />
    <br>
    <AccidentShow v-if="!isEditing" :accident="accident" />
    <AccidentEdit
      v-else
      :accident="accident"
      :cars="cars"
      :drivers="drivers"
    />
    <br>
    <h5>Сканы</h5>
    <ScansGallery :documentId="accident.id" />
  </div>
</template>

<script>

import Swal from 'sweetalert2'
import {mapActions, mapMutations, mapState} from 'vuex'
import AccidentApi from '../api/accident'
import deepAssign from 'assign-deep'
import AccidentShow from './AccidentShow'
import AccidentEdit from './AccidentEdit'
import ScansGallery from '../../../components/common/scans/ScansGallery'

export default {
  name: 'AccidentPage',
  components: {
    ScansGallery,
    AccidentEdit,
    AccidentShow,
  },
  props: {
    accidentId: {
      type: String,
      default: () => null
    }
  },
  data () {
    return {
      isEditing: false,
      accident: null,
      prevAccident: null,
      isUpdating: false
    }
  },
  computed: {
    ...mapState('taxi/cars', {
      cars: state => state.items
    }),
    ...mapState('taxi/drivers', {
      drivers: state => state.items.filter(e => !e.archived_at)
    }),
  },
  mounted () {
    this.loadData()
    this.loadCars({
      expand: [
        'displayedName'
      ]
    }).then(cars => {
      cars.forEach(car => {
        car.displayedName = `${car.brand} ${car.model} ${car.registration_plate}`
      })
    })
    this.loadDrivers({
      expand: [
        'fullName'
      ]
    }).then(drivers => {
      drivers.forEach(driver => {
        driver.fullName = `${driver.last_name} ${driver.first_name} ${driver.middle_name}`
      })
    })
  },
  methods: {
    ...mapActions('taxi/cars', {
      loadCars: 'loadItems'
    }),
    ...mapActions('taxi/drivers', {
      loadDrivers: 'loadItems'
    }),
    ...mapActions('accident/accident', [
      'abortLoading'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),

    loadScans () {
      this.clearItems()
      this.setModel('Accident')
      AccidentApi.getScans(this.accident.id).then(result => {
        this.accident.scans = result
        this.pushItems({ scans: result, documentId: this.accident.id })
      })
        .catch(err => {
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'Ошибка сервера',
            text: err.message
          })
        })
    },
    loadData () {
      AccidentApi.getItem({
        id: this.accidentId,
        queryWith: ['car', 'driver']
      })
        .then(response => {
          this.accident = response
          if (!response) {
            return false
          }
          this.loadScans()
          this.setTitle([
            {
              title: 'ДТП',
              url: '/accidents'
            }, {
              title: `${response.car.brand} ${response.car.model} ${response.car.registration_plate}`
            }
          ])
        })
    },
    changeAccident () {
      this.prevAccident = deepAssign({}, this.accident)
      this.isEditing = true
    },
    deleteAccident () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить ДТП?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          AccidentApi.removeItem(this.accident.id)
            .then(() => {
              this.$router.push('/accidents')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    cancelAccidentChanges () {
      this.accident = this.prevAccident
      this.isEditing = false
    },
    updateAccident () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true

      AccidentApi.updateItem(this.accident)
        .then(accident => {
          this.accident = { ...this.accident, ...accident.model }
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapMutations('taxi/scans', [
      'pushItems',
      'clearItems',
      'setModel'
    ]),
  }
}
</script>
