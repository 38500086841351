import moduleTemplate from '@/store/moduleTemplate'
import carsAPI from '@/api/cars'

const types = {
  selectCar: 'selectCar',
  addTrack: 'addTrack',
  loadTrack: 'loadTrack',
  addToTrackOfSelected: 'addToTrackOfSelected',
  clearTrackOfSelected: 'clearTrackOfSelected',
  clearTrack: 'clearTrack',
  updateCars: 'updateCars',
  setLoading: 'setLoading',
  addToTracks: 'addToTracks',
  removeFromTracks: 'removeFromTracks',
  clearTracks: 'clearTracks',
  setSpeedMode: 'setSpeedMode',
  fillColors: 'fillColors',
  setFactMileage: 'setFactMileage'
}

const defaultColors = [
  '#0597f2', '#006400', '#4b0082', '#b10606', '#724b03', '#0f6b6b', '#0000ff', '#881e88', '#938d40'
]

const template = moduleTemplate(carsAPI)

template.state = {
  ...template.state,
  selectedId: null,
  track: [],
  selectedTrack: [],
  tracks: [],
  colorIndex: 0,
  speedMode: false,
  colors: [],
  factMileage: false
}

template.state.expandAllItems = ['lastState']

template.getters = {
  selectedCar: state => {
    return state.items.find(car => car.id === state.selectedId)
  },
  getSpeedMode: state => {
    return state.speedMode
  },
  getFactMileage: state => {
    return state.factMileage
  }
}

template.mutations = {
  ...template.mutations,
  [types.setLoading](state, value) {
    state.isLoading = value
  },
  [types.fillColors](state, colors) {
    if (state.colors.length) {
      return
    }
    state.colors = colors.length > 0 ? colors : defaultColors
  },
  [types.updateCars](state, cars) {
    state.items = cars
    state.isLoading = false
  },
  [template.types.loadItems](state, {
    items,
    page,
    pageCount,
    pageSize,
    totalCount
  }) {
    state.items = items
    // записать обновленную позицию
    const selectedCar = state.items.find(car => car.id === state.selectedId)
    if (selectedCar) {
      const peekedPoint = state.selectedTrack[0]
      if (selectedCar && selectedCar.lastState &&
        selectedCar.lastState.lat?.value &&
        selectedCar.lastState.lng?.value &&
        ((peekedPoint &&
        selectedCar.lastState.lat?.value != peekedPoint[0] &&
        selectedCar.lastState.lng?.value != peekedPoint[0]) ||
        !peekedPoint)
      ) {
        state.selectedTrack.push([selectedCar.lastState.lat.value, selectedCar.lastState.lng.value])
      }
    }
    state.page = page
    state.pageCount = pageCount
    state.pageSize = pageSize
    state.totalCount = totalCount
  },
  [types.addTrack] (state, { track }) {
    if (state.selectedId) {
      const addedTrack = []
      track.reverse().forEach(trackPoint => {
        const peekedPoint = state.selectedTrack[0]
        if (trackPoint &&
          trackPoint[0] &&
          trackPoint[1] &&
          peekedPoint &&
          trackPoint[0] != peekedPoint[0] &&
          trackPoint[1] != peekedPoint[0]
        ) {
          addedTrack.unshift([trackPoint[0].toString(), trackPoint[1].toString()])
        }
      })
      state.selectedTrack = addedTrack.concat(state.selectedTrack)
    }
  },
  [types.selectCar] (state, id) {
    state.selectedId = id
    const selectedCar = state.items.find(car => car.id === state.selectedId)
    if (selectedCar && selectedCar.lastState.lat?.value) {
      state.selectedTrack.push([selectedCar.lastState.lat.value, selectedCar.lastState.lng.value])
    }
  },
  [types.loadTrack] (state, { track }) {
    state.track = track
  },
  [types.clearTrackOfSelected] (state) {
    state.selectedTrack = []
  },
  [types.clearTrack] (state) {
    state.track = []
  },
  [types.addToTracks] (state, {track, id, timeFrom, timeTo }) {
    const needle = state.tracks.find(el => el.carId == id && el.timeFrom == timeFrom)
    if (needle) {
      return
    }
    track.forEach((el, index, array) => {
      let angle = 0
      if (index != track.length - 1) {
        let x1 = el[0]
        let y1 = el[1]
        let x2 = array[index + 1][0]
        let y2 = array[index + 1][1]
        var angleRadians = Math.atan2(y2 - y1, x2 - x1)
        angle = (angleRadians * 180 / Math.PI + 360) % 360
      }
      el.push(angle)
    })
    state.tracks.push({
      carId: id,
      timeFrom,
      timeTo,
      track,
      latLng: track.map(tr => [tr[0], tr[1]]),
      color: state.colors[state.colorIndex % state.colors.length]
    })
    state.colorIndex++
  },
  [types.removeFromTracks] (state, {carId, timeFrom}) {
    const needleIndex = state.tracks.findIndex(el => el.carId == carId && el.timeFrom == timeFrom)
    if (needleIndex >= 0) {
      state.tracks.splice(needleIndex, 1)
    }
  },
  [types.clearTracks] (state) {
    state.tracks = []
  },
  [types.setSpeedMode] (state, value) {
    state.speedMode = value
  },
  [types.setFactMileage] (state, value) {
    state.factMileage = value
  }
}

template.actions = {
  ...template.actions,
  selectCar ({ commit }, id) {
    if (id) {
      commit(types.clearTrackOfSelected)
    }
    commit(types.selectCar, id)
  },
  getTrack ({ commit }, { id, timeFrom, timeTo }) {
    return carsAPI.getTrack(id, timeFrom, timeTo)
      .promise
      .then(track => {
        commit(types.loadTrack, track)
        commit(types.addToTracks, { ...track, id, timeFrom, timeTo})
        return track
      })
  },
  addToTracks({commit, rootGetters}, {id, timeFrom, timeTo}) {
    commit(types.fillColors, rootGetters['configs/configs/trackColors'])
    return carsAPI.getTrack(id, timeFrom, timeTo)
      .promise
      .then(track => {
        commit(types.clearTrackOfSelected)
        commit(types.clearTrack)
        commit(types.addToTracks, { ...track, id, timeFrom, timeTo})
        return track
      })
  },
  getCarsForWatch ({ commit }) {
    commit(types.setLoading, true)
    return carsAPI.getCarsForWatch()
    .then(cars => {
      commit(template.types.loadItems, { items: cars })
      commit(types.setLoading, false)
      return cars
    })
  },
  getStartTrack ({ commit }, { id, timeFrom, timeTo }) {
    return carsAPI.getTrack(id, timeFrom, timeTo)
      .promise
      .then(track => {
        if (id) {
          commit(types.clearTrackOfSelected)
        }
        commit(types.selectCar, id)
        commit(types.addTrack, track)
        return track
      })
  },
  clearTrack ({ commit }) {
    commit(types.clearTrackOfSelected)
    commit(types.clearTrack)
    commit(types.clearTracks)
  },
  removeFromTracks ({ commit }, {carId, timeFrom}) {
    commit(types.removeFromTracks, {carId, timeFrom})
  },
  clearTracks ({commit}) {
    commit(types.clearTracks)
  },
  setSpeedMode ({commit}, value) {
    commit(types.setSpeedMode, value)
  },
  setFactMileage ({commit}, value) {
    commit(types.setFactMileage, value)
  }
}

export default template
