<template>
  <div v-if="facility && configsLoaded" class="page">
    <HeaderTitle />
    <ActionBar
      :isEditing="isEditing"
      @changeAction="changeFacility"
      @cancelAction="cancelFacilityChanges"
      @deleteAction="deleteFacility"
      @saveAction="updateFacility"
    />
    <a
      v-if="!isEditing"
      :href="'/api/stock/export?facilityId=' + facility.id"
      class="input-group-btn btn btn-info m-1"
      style="float: right"
    >
      <font-awesome-icon class="text-white" icon="file-download" />
      Скачать отчет
    </a>
    <a
      v-if="!isEditing"
      :href="'/stock/create?facilityId=' + facility.id"
      class="input-group-btn btn btn-success m-1"
      style="float: right"
    >
      <font-awesome-icon class="text-white" icon="plus" />
      Добавить предмет
    </a>
    <FacilityShow
      v-if="!isEditing"
      :facility="facility"
      :showPriceAndSum="showPriceAndSum"
    />
    <FacilityEdit
      v-else
      :facility="facility"
    />
  </div>
</template>

<script>

import FacilityEdit from '@/modules/facility/views/Edit.vue'
import FacilityShow from '@/modules/facility/views/Show.vue'
import {mapActions, mapGetters, mapState} from 'vuex'
import Swal from 'sweetalert2'
import deepAssign from 'assign-deep'
import FacilityApi from '../api/facility'

export default {
  name: 'FacilityPage',
  components: {
    FacilityEdit,
    FacilityShow,
  },
  props: {
    facilityId: {
      type: String,
      default: () => 0
    }
  },
  data () {
    return {
      isEditing: false,
      prevFacility: null,
      isUpdating: false,
      facility: null
    }
  },
  computed: {
    ...mapGetters('configs/configs', [
      'showPriceAndSum'
    ]),
    ...mapState('configs/configs', {
      configsLoaded: state => !state.isLoading
    })
  },
  watch: {
    facility: {
      handler (facility) {
        if (facility) {
          this.setTitle([
            {
              title: 'Объекты',
              url: '/facility'
            }, {
              title: `${facility.name}`
            }
          ])
        }
      },
      immediate: true
    }
  },
  beforeMount () {
    this.loadConfigs()
  },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions('taxi', [
      'setTitle'
    ]),
    loadData () {
      FacilityApi.getItem({
        id: this.facilityId,
      })
        .then(response => {
          this.facility = response
          if (!response) {
            return false
          }
        })
    },
    changeFacility () {
      this.prevFacility = deepAssign({}, this.facility)
      this.isEditing = true
    },
    deleteFacility () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить объект?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          FacilityApi.removeItem(this.facility.id)
            .then(() => {
              this.$router.push('/facility')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    cancelFacilityChanges () {
      this.facility = this.prevFacility
      this.isEditing = false
    },
    updateFacility () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true

      FacilityApi.updateItem(this.facility)
        .then(facility => {
          this.facility = { ...this.facility, ...facility.model }
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('configs/configs', {
      loadConfigs: 'loadItems'
    })
  }
}
</script>
