<template>
  <div class="col-lg-5 m-1">
    <div class="position-relative">
      <img
        style="cursor: pointer"
        class="rounded p-0 photo-img pointer"
        :src="path + src"
        @click="$emit('imageView')"
      >
      <button
        v-if="editMode"
        class="position-absolute btn btn-danger btn-sm"
        style="top: 0; right: 0;"
        @click="deletePhoto"
      >
        <font-awesome-icon icon="trash-alt" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'ImageView',
  props: {
    src: {
      type: Number,
      default: null
    },
    path: {
      type: String,
      default: '/api/file/link?id='
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isShow: false
    }
  },
  methods: {
    deletePhoto (event) {
      event.preventDefault()
      this.$emit('deleteFile', this.src)
    }
  }
})
</script>

<style scoped>
  .show-photo {
    object-fit: contain;
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 80%;
  }
  .show-content {
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    margin-right: -50%;
    position: fixed;
  }
  .showBg {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 100;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
  }
  .photo-img {
    width: 100% !important;
  }
</style>
