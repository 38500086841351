<template>
  <div class="view-contracts">
    <table v-if="contract" class="table">
      <tbody>
        <tr>
          <td>Водитель</td>
          <td v-if="contract.driver">
            <router-link
              class="text-decoration-none text-dark"
              :to="`/drivers/${contract.driver_id}`"
            >
              {{ contract.driver.last_name }} {{ contract.driver.first_name }} {{ contract.driver.middle_name }}
              <font-awesome-icon class="text-secondary" icon="external-link-alt" />
            </router-link>
          </td>
        </tr>
        <tr>
          <td>Автомобиль</td>
          <td v-if="contract.car">
            <router-link
              class="text-decoration-none text-dark"
              :to="`/cars/${contract.car_id}`"
            >
              {{ contract.car.brand }} {{ contract.car.model }} - {{ contract.car.registration_plate }}
              <font-awesome-icon class="text-secondary" icon="external-link-alt" />
            </router-link>
          </td>
        </tr>
        <tr>
          <td>Организация</td>
          <td>
            <router-link
              class="text-decoration-none text-dark"
              :to="`/companies/${contract.company_id}`"
            >
              {{ companyName }}
              <font-awesome-icon class="text-secondary" icon="external-link-alt" />
            </router-link>
          </td>
        </tr>
        <tr v-if="contract.proxy && contract.proxy.user">
          <td>Доверенное лицо</td>
          <td>{{ contract.proxy.user.last_name }} {{ contract.proxy.user.first_name }} {{ contract.proxy.user.middle_name }}</td>
        </tr>
        <tr>
          <td>Дата/ время передачи автомобиля</td>
          <td>{{ contract.transfer_datetime | diffForHumans }}</td>
        </tr>
        <tr>
          <td>Дата/ время возврата автомобиля</td>
          <td v-if="contract.receiving_datetime">{{ contract.receiving_datetime | diffForHumans }}</td>
          <td v-else>Не назначена</td>
        </tr>
        <tr v-if="contract.archived_at">
          <td>Дата/ время принятия автомобиля (фактическая)</td>
          <td>{{ contract.archived_at | diffForHumans }}</td>
        </tr>
        <tr>
          <td>Арендная плата</td>
          <td v-if="contract.price">{{ contract.price }} &#8381;</td>
          <td v-else>Не назначена</td>
        </tr>
        <tr>
          <td>Период оплаты</td>
          <td>{{ contract.payment_period | formatedPeriod }}</td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4">
  </div>
</template>

<script>
import { djsMixin } from '@/mixins/djsMixin'
export default {
  name: 'ContractRentView',
  filters: {
    formatedPeriod (period) {
      switch (period) {
        case 'day':
          return 'Посуточно'
        case 'month':
          return 'Помесячно'
        case 'year':
          return 'Год'
      }
      return ''
    }
  },
  mixins: [djsMixin],
  props: {
    contract: {
      type: Object,
      default: null
    },
    companies: {
      type: Array,
      default: Array
    }
  },
  computed: {
    company () {
      return this.companies.find(company => company.id === this.contract.company_id)
    },
    companyName () {
      return this.company ? this.company.name : ''
    }
  }
}
</script>

<style scoped>
  .view-contracts {
    margin-top: 4rem;
  }
</style>
