let result = []

if (process.env.VUE_APP_MODULE_RESTRICTED_AREA === 'true') {
  result = [
    ...result,
    ...require('./restrictedArea/router').default
  ]
}

if (process.env.VUE_APP_MODULE_FUEL === 'true') {
  result = [
    ...result,
    ...require('./fuel/router').default
  ]
}

if (process.env.VUE_APP_MODULE_MAINTENANCE === 'true') {
  result = [
    ...result,
    ...require('./maintenance/router').default,
    ...require('./stock/router').default
  ]
}

if (process.env.VUE_APP_MODULE_FINES === 'true') {
  result = [
    ...result,
    ...require('./fines/router').default
  ]
}

if (process.env.VUE_APP_MODULE_ACCIDENT === 'true') {
  result = [
    ...result,
    ...require('./accidents/router').default
  ]
}

if (process.env.VUE_APP_MODULE_BLACKLIST === 'true') {
  result = [
    ...result,
    ...require('./blacklist/router').default
  ]
}

result = [
  ...result,
  ...require('./configs/router').default,
  ...require('./feedback/router').default,
  ...require('./photocontrol/router').default,
  ...require('./logs/router').default,
  ...require('./facility/router').default
]
export default result
