<template>
  <div>
    <table class="table borderless">
      <tbody>
        <tr>
          <td>Компонент</td>
          <td>
            <VSelect
              id="selectorTemplate"
              v-model="config.component_name"
              :options="configComponentDefinitions"
              :reduce="template => template.name"
              label="title"
            />
          </td>
        </tr>
        <tr>
          <td>Фильтр</td>
          <td>
            <vueJsonEditor
              v-model="config.filter"
              class="filter-json"
              :show-btns="false"
              :expandedOnStart="true"
              :modes="['tree', 'code']"
            />
          </td>
        </tr>
        <tr>
          <td>Json</td>
          <td>
            <vueJsonEditor
              v-model="config.value"
              class="config-json"
              :show-btns="false"
              :expandedOnStart="true"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import vueJsonEditor from 'vue-json-editor'

export default {
  name: 'ConfigEdit',
  components: {
    vueJsonEditor
  },
  props: {
    config: {
      type: Object,
      default: () => null
    },
    configComponentDefinitions: {
      type: Array,
      default: () => []
    }
  },
}
</script>

<style>
  .config-json .jsoneditor-vue {
    height: 500px;
  }
  .filter-json .jsoneditor-vue {
    height: 200px;
  }
</style>
