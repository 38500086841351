<template>
  <div class="scans">
    <div class="upload-from-dialog">
      <button class="btn btn-primary float-right" @click="$refs.scan.click()">Загрузить...</button>
    </div>
    <input
      ref="scan"
      type="file"
      style="display:none"
      multiple
      @change="addFileFromDialog"
    >

    <ScanCard
      v-for="scan of scansByDocument(documentId)"
      :key="scan.id"
      :scan="scan"
      @detail-scan="detailScan(scan)"
      @remove-scan="removeScan(scan)"
      @update-scan="updateScan"
      @upload-scan="uploadScan(scan)"
    />
    <LightBox v-if="showDetail" :scan="currentScan" @close="showDetail = false" />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ScanCard from './ScanCard'
import Swal from 'sweetalert2'

export default {
  name: 'ScansGallery',
  components: {
    ScanCard
  },
  props: {
    documentId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      dragging: false,
      showDetail: false,
      currentScan: null
    }
  },
  computed: {
    ...mapGetters('taxi/scans', [
      'scansByDocument'
    ]),
    ...mapState('taxi/scans', [
      'currentModel'
    ])
  },
  methods: {
    detailScan (scan) {
      this.currentScan = scan
      this.showDetail = true
    },
    addFileFromDialog (e) {
      this.addFiles(e.target.files)
      this.$refs.scan.value = null
    },
    addFileFromDrop (e) {
      this.addFiles(e.dataTransfer.files)
    },
    addFiles (files) {
      const formData = new FormData()
      files.forEach(element => {
        formData.append('upfiles[]', element)
      })
      formData.append('model', this.currentModel)
      formData.append('id', this.documentId)
      this.dragging = false
      this.uploadItem(formData)
    },
    updateScan ({ scan }) {
      this.updateItem(scan)
    },
    removeScan (scan) {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить скан?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          this.removeItem(scan.id)
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    ...mapActions('taxi/scans', [
      'uploadItem',
      'removeItem',
      'updateItem'
    ])
  }
}
</script>

<style scoped>
  .scans {
    min-height: 10rem;
  }
  .upload-from-dialog {
    float: right;
    position: relative;
    width: 100%;
    margin-bottom: .7rem;
    z-index: 10 !important;
  }
</style>
