<template>
  <div>
    <div class="row">
      <div class="col-md-6 mb-2">
        <label>Код подразделения</label>
        <input id="code" v-model="data.code" class="form-control">
      </div>
    </div>
    <div class="row">
      <div class="col-md-8 mb-2">
        <label>Кем выдан (заполняется автоматически)</label>
        <textarea id="name" v-model="data.authority" class="form-control" />
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
const token = process.env.VUE_APP_DADATA_AUTH_TOKEN

export default {
  name: 'Authority',
  props: {
    documentData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      data: {
        authority: this.documentData.authority,
        code: this.documentData.code
      }
    }
  },
  watch: {
    data: {
      handler () {
        this.update()
      },
      deep: true
    },
    documentData: {
      handler () {
        this.data.authority = this.documentData.authority
        this.data.code = this.documentData.code
      },
      deep: true
    }
  },
  mounted () {
    $('#code').suggestions({
      token: token,
      type: 'fms_unit',
      formatResult: this.formatResult,
      onSelect: this.showSuggestion,
      onSelectNothing: this.clearSuggestion
    })
  },
  methods: {
    showSuggestion (suggestion) {
      this.data.authority = suggestion.data.name
      this.data.code = suggestion.data.code
      this.update()
    },
    formatResult (value, currentValue, suggestion) {
      suggestion.value = suggestion.data.code
      return suggestion.data.code + ' — ' + suggestion.data.name
    },
    update () {
      this.$emit('update', {
        authority: this.data.authority,
        code: this.data.code
      })
    }
  }
}
</script>
