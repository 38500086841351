<template>
  <div class="edit-form-companies">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Выберите ОПФ</td>
          <td>
            <select v-model="company.type" class="form-control">
              <option
                v-for="(type, index) in types"
                :key="index"
                :value="index"
              >
                {{ type.title }}
              </option>
            </select>
          </td>
        </tr>
        <component
          :is="currentFormComponent"
          v-if="currentFormComponent"
          :company="company"
          :companies="companies"
          @update="handleData"
        />
      </tbody>
    </table>
    <hr class="mb-4">
  </div>
</template>

<script>
import types from '@/views/companies/types'
import {mapActions} from 'vuex'

const components = {}
for (const typeName in types) {
  components[types[typeName].edit.name] = types[typeName].edit.component
}

export default {
  name: 'CompanyEdit',
  components,
  props: {
    id: {
      type: Number,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    companies: {
      type: Array,
      default: null
    },
  },
  data () {
    return {
      types
    }
  },
  computed: {
    currentFormComponent () {
      return types[this.company.type]?.edit?.name
    }
  },
  methods: {
    handleData ({ data }) {
      Object.assign(this.company, { ...data})
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>

<style scoped>
  .edit-form-companies {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
</style>
