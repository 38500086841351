/* eslint-disable camelcase */

import feedback from './feedback'

export default {
  namespaced: true,
  modules: {
    feedback,
  }
}
