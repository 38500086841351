<template>
  <div>
    <table class="table borderless">
      <tbody>
        <tr>
          <td>Название</td>
          <td>
            <LookupInput
              v-model="stock.title"
              :options="stockTitles"
            />
          </td>
        </tr>
        <tr>
          <td><label for="selectorFacility">Объект</label></td>
          <td>
            <VSelect
              id="selectorFacility"
              v-model="stock.facility_id"
              :options="facilities"
              :reduce="facility => facility.id"
              label="name"
              placeholder
            />
          </td>
        </tr>
        <tr>
          <td>Поступление</td>
          <td>
            <input
              v-model="stock.receipt"
              type="number"
              class="form-control"
              placeholder="Значение будет округлено до 2-х знаков после запятой"
              title="Поступление"
              required
            >
          </td>
        </tr>
        <tr>
          <td>Количество</td>
          <td>
            <input
              v-model="stock.quantity"
              type="number"
              class="form-control"
              placeholder="Значение будет округлено до 2-х знаков после запятой"
              title="Количество"
              required
            >
          </td>
        </tr>
        <tr>
          <td>Поставщик</td>
          <td>
            <input
              v-model="stock.supplier"
              type="text"
              class="form-control"
              placeholder
            >
          </td>
        </tr>
        <tr>
          <td>Дата производства</td>
          <td>
            <input
              v-model="stock.date"
              type="date"
              class="form-control"
              placeholder
            >
          </td>
        </tr>
        <tr v-if="showPriceAndSum">
          <td>Стоимость 1 шт</td>
          <td>
            <input
              v-model="stock.price"
              type="number"
              class="form-control"
              title="Стоимость 1 шт"
              placeholder="Значение будет округлено до 2-х знаков после запятой"
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import LookupInput from '@/components/LookupInput'
import {mapActions, mapState} from 'vuex'
import moment from 'moment'

export default {
  name: 'StockEdit',
  components: {LookupInput},
  props: {
    stock: {
      type: Object,
      default: () => null
    },
    showPriceAndSum: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState('stock/stock', {
      stocks: state => state.items.filter(item => item.archived_at === null),
    }),
    ...mapState('facility/facility', {
      facilities: state => state.items
    }),
    stockTitles () {
     const titles = this.stocks.map(stock => stock.title)
      let i = 0,
        current,
        length = titles.length,
        unique = []
      for (; i < length; i++) {
        current = titles[i]
        if (!~unique.indexOf(current)) {
          unique.push(current)
        }
      }
      return unique
    }
  },
  mounted () {
    this.loadStock()
    this.loadFacility()
    this.formattedDate()
  },
  methods: {
    formattedDate() {
      if (!this.stock?.date) {
        return
      }
      this.stock.date = moment(this.stock.date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD')
    },
    ...mapActions('stock/stock', {
      loadStock: 'loadItems',
    }),
    ...mapActions('facility/facility', {
      loadFacility: 'loadItems',
    }),
  }
}
</script>
