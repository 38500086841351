<template>
  <div class="edis-osago">
    <div class="row">
      <div class="col-md-6 mb-2">
        <label>Номер</label>
        <IMaskComponent
          v-model="data.number"
          type="text"
          class="form-control"
          :mask="'[000000000000000]'"
          placeholder="000000000000000"
          required
        />
      </div>
      <div class="col-md-6 mb-2">
        <label>Срок действия до</label>
        <input
          v-model="data.date_end"
          type="date"
          class="form-control"
          required
        >
      </div>
    </div>
  </div>
</template>

<script>
const type = 'diagnostic_card'

export default {
  name: 'DiagnosticCardEdit',
  props: {
    documentData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      data: {
        number: '',
        date_end: ''
      }
    }
  },
  watch: {
    data: {
      handler () {
        this.update()
      },
      deep: true
    }
  },
  mounted () {
    if (this.documentData) {
      this.data = this.documentData
    }
    this.update()
  },
  methods: {
    update () {
      this.$emit('update', {
        type: type,
        data: this.data
      })
    }
  }
}
</script>

<style scoped>
  .edis-osago input {
    margin-bottom: 5px;
  }
  .edis-osago textarea {
    margin-bottom: 5px;
  }
</style>
