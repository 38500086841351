var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"day-wrapper"},[_c('button',{staticClass:"btn btn-default summary",attrs:{"data-toggle":"collapse","data-target":'#' + _vm.dayListId,"aria-expanded":_vm.standAlone,"aria-controls":_vm.dayListId}},[(_vm.date && !_vm.standAlone)?_c('span',[_vm._v(" "+_vm._s(_vm.date))]):_vm._e(),_c('TripsTotal',{attrs:{"trips":_vm.trips}})],1),_c('div',{staticClass:"collapse",class:{show: _vm.standAlone},attrs:{"id":_vm.dayListId}},[_c('br'),_c('ul',{staticClass:"list-group"},_vm._l((_vm.trips),function(trip,i){return _c('li',{key:i,staticClass:"list-group-item trip-card",attrs:{"role":"button","tabindex":"0"},on:{"click":function($event){return _vm.selectTrack(trip)}}},[_c('div',{staticClass:"trip-card-body"},[_c('div',{staticClass:"col-trip"},[_c('font-awesome-icon',{style:({
                color: trip.type === 'parked' ? '#62ADBF' : '#92BF4E',
                width: '30px',
                height: '30px',
              }),attrs:{"icon":trip.type === 'parked' ? 'parking' : 'car'}})],1),_c('div',{staticClass:"trip-data"},[(trip.type === 'parked')?[_c('div',{staticClass:"parked"},[_c('span',[_vm._v(_vm._s(_vm.formatTime(trip.startTime)))]),_c('hr',{staticStyle:{"border-top-width":"3px","border-top-color":"grey"},attrs:{"width":"15%"}}),_c('span',[_vm._v(_vm._s(_vm.formatTime(trip.endDate)))])])]:[_c('div',{staticClass:"traveled"},[_c('span',[_vm._v(_vm._s(Math.round((_vm.factMileage ? trip.startOdometerCorrected : trip.startOdometer) / 1000))+" км.")]),_c('span',[_vm._v(_vm._s(Math.round((_vm.factMileage ? trip.endOdometerCorrected : trip.endOdometer) / 1000))+" км.")])]),_c('div',{staticClass:"traveled-path"},[_c('div',{staticClass:"edge-icon",style:({'border-color': _vm.getColor(trip), color: _vm.getColor(trip)})},[_vm._v(" A ")]),_c('hr',{style:({'border-color': _vm.getColor(trip)}),attrs:{"width":"55%"}}),_c('div',{staticClass:"edge-icon",style:({'border-color': _vm.getColor(trip), color: _vm.getColor(trip)})},[_vm._v(" B ")])]),_c('div',{staticClass:"traveled"},[_c('span',[_vm._v(_vm._s(_vm.formatTime(trip.startTime)))]),_c('span',[_vm._v(_vm._s(_vm.formatTime(trip.endDate)))])])]],2),_c('div',{staticClass:"spaced-items"},[_c('div',{staticClass:"col-trip"},[_c('font-awesome-icon',{style:({ color: '#55606a', 'margin-right': '0.1rem' }),attrs:{"icon":"clock"}}),_c('span',[_vm._v(_vm._s(_vm.formatDuration(trip.dailyDuration)))])],1),_c('div',{staticClass:"col-trip",class:{ undisplayed: trip.type === 'parked' }},[_c('font-awesome-icon',{style:({
                  color: '#55606a',
                  'margin-right': '0.1rem'
                }),attrs:{"icon":"road"}}),_c('span',[_vm._v(_vm._s(trip.deltaOdometer ? Math.round(trip.deltaOdometer / 1000) : 0)+" км")])],1),_c('div',{staticClass:"col-trip",class:{ undisplayed: trip.type === 'parked' }},[_c('font-awesome-icon',{style:({ color: '#55606a', 'margin-right': '0.1rem' }),attrs:{"icon":"tachometer-alt"}}),_c('span',{staticClass:"icon-text"},[_vm._v(_vm._s(trip.maxSpeed || 0)+" км/ч")])],1)]),_c('font-awesome-icon',{staticClass:"track-icon",class:{ 'selected-trip-icon': _vm.isSelectedTrack(trip) },style:({
              color: 'rgba(64, 64, 64, 0.2)',
              width: '30px',
              height: '30px',
              'margin-left': 'auto',
              transition: 'visibility 0.05s linear,opacity 0.05s linear !important',
              visibility: 'hidden',
              opacity: '0'
            }),attrs:{"icon":"route"}})],1)])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }