<template>
  <div>
    <h5>Фотоконтроль</h5>
    <div class="row m-0">
      <div class="col-md-6 col">
        <h6>При выдаче</h6>
        <DropImages
          :images="photoAfter"
          type="photocontrol-after"
          :document-id="contract.id"
          @addFiles="addFiles"
          @deleteFile="deleteAfterPhoto"
        />
      </div>
      <div class="col-md-6 col">
        <h6>При сдаче</h6>
        <DropImages
          :images="photoBefore"
          type="photocontrol-before"
          :document-id="contract.id"
          @addFiles="addFiles"
          @deleteFile="deleteBeforePhoto"
        />
      </div>
    </div>
  </div>
</template>

<script>

import { djsMixin } from '@/mixins/djsMixin'
import ContractsAPI from '@/api/contracts'
import DropImages from '../../DropImages'
import {mapActions} from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'PhotoControl',
  components: {DropImages},
  mixins: [djsMixin],
  props: {
    contract: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      photoAfter: [],
      photoBefore: [],
    }
  },
  computed: {
    searchTextValue () {
      return this.searchText ?? ''
    }
  },
  watch: {
    'contract': {
      handler () {
        if (this.contract) {
          this.updatePhotos(this.contract)
        }
      },
      immediate: true
    }
  },
  methods: {
    updatePhotos (contract) {
      this.photoAfter = contract.photoAfter
      this.photoBefore = contract.photoBefore
    },
    addFiles (images, form) {
      if (this.contract.id) {
        images.forEach(file => {
          const formData = new FormData(form)
          formData.append('upfiles[]', file)
          ContractsAPI.uploadPhoto(formData)
            .then(contract => {
              this.updatePhotos(contract)
            })
        })
      }
    },
    deleteAfterPhoto (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить фото?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          this.removeItem(id)
            .then(() => {
              this.photoAfter = this.photoAfter.filter(photo => photo.id !== id)
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    deleteBeforePhoto (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить фото?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          this.removeItem(id)
            .then(() => {
              this.photoBefore = this.photoBefore.filter(photo => photo.id !== id)
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    ...mapActions('taxi/scans', ['removeItem'])
  }
}
</script>
