import moduleTemplate from '@/store/moduleTemplate'
import serviceCar from '../api/serviceCar'

const template = moduleTemplate(serviceCar)

template.types = {
  ...template.types,
  setSearchText: 'setSearchText'
}
template.mutations = {
  ...template.mutations,
  [template.types.setSearchText] (state, value) {
    state.searchText = value
  }
}
template.state = {
  ...template.state,
  searchText: '',
  expandAllItems: ['lastState', 'nextServiceRecord']
}

template.actions = {
  ...template.actions,
  setSearchText ({ commit }, searchText) {
    commit(template.types.setSearchText, searchText)
  }
}
export default template
