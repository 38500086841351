<template>
  <div class="page">
    <div class="d-flex flex-column sticky-1">
      <div class="input-group">
        <div class="input-group-prepend">
          <span id="map-search-icon-addon" class="input-group-text">
            <font-awesome-icon icon="search" :style="{ color: 'gray' }" />
          </span>
        </div>
        <input
          type="text"
          class="form-control map-search"
          placeholder="Поиск по фамилии, имени, отчеству"
          aria-label="Поиск по фамилии, имени, отчеству"
          aria-describedby="map-search-icon-addon"
          :value="searchText"
          @input="searchDriver"
        >
      </div>
      <div class="pt-2">
        <router-link to="/black-list/add" class="btn btn-primary w-100">Добавить водителя в черный список</router-link>
      </div>
    </div>
    <table v-if="drivers.length > 0" class="table table-hover w-100 mt-3 d-none d-md-table">
      <TableSort class="sticky-2" :sorts="sorts" @sortsUpdate="loadWithSort" />
      <tbody>
        <tr
          v-for="driver of drivers"
          :key="driver.id"
          role="button"
          tabindex="0"
          @click="$router.push(`/drivers/${driver.id}`)"
        >
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ driver.last_name }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ driver.first_name }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ driver.middle_name }}</TextHighlight>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-for="driver of drivers" :key="driver.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
      <table v-if="drivers.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Фамилия</td>
            <td style="border-top: none">
              <TextHighlight class="highlighter" :queries="searchText">{{ driver.last_name }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Имя</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ driver.first_name }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Отчество</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ driver.middle_name }}</TextHighlight>
            </td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="{ name: 'driverPage', params: { id: driver.id } }"
        class="btn btn-primary btn-md create-driver ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'BlackList',
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'Фамилия',
          storeName: 'lastNameIndex',
          values: ['', 'last_name', '-last_name'],
          order: 0
        }, {
          name: 'Имя',
          storeName: 'firstNameIndex',
          values: ['', 'first_name', '-first_name'],
          order: 1
        }, {
          name: 'Отчество',
          storeName: 'middleNameIndex',
          values: ['', 'middle_name', '-middle_name'],
          order: 2
        }
      ]
    }
  },
  computed: {
    ...mapState('taxi/drivers', {
      drivers: state => state.items,
      searchText: state => state.searchText,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading
    })
  },
  watch: {
    page () {
      this.loadItems({
        page: Number(this.page),
        expand: ['user'],
        extra: {
          key: 'filter[and][][is_blacklisted]',
          value: 1
        },
      })
    }
  },
  mounted () {
    this.loadItems({
      extra: {
        key: 'filter[and][][is_blacklisted]',
        value: 1
      },
    })
    this.setTitle([
      {
        title: 'ЧС водителей'
      }
    ])
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('taxi/drivers', [
      'loadItems',
      'abortLoading',
      'removeItem',
      'setSearch',
      'setSearchText',
      'setSorts'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    searchDriver (e) {
      this.setSearchText(e.target.value)
      this.setSearch({ key: 'first_name', value: e.target.value })
      this.setSearch({ key: 'last_name', value: e.target.value })
      this.setSearch({ key: 'middle_name', value: e.target.value })
      this.loadItems({
        page: Number(this.page),
        expand: ['user'],
        extra: {
          key: 'filter[and][][is_blacklisted]',
          value: 1
        },
      })
    },
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({
        page: Number(this.page),
        expand: ['user'],
        extra: {
          key: 'filter[and][][is_blacklisted]',
          value: 1
        },
      })
    }
  }
}
</script>

<style scoped>
  th {
    font-weight: 600;
    font-size: 15.7px;
  }
  .input-group-text {
    background-color: white;
  }
  .input-group input {
    border-left: none;
  }
  .highlighter >>> .text__highlight {
    border-radius: 0px;
    padding: 0.2em 0 0.2em 0;
    background: #fff2ae;
  }
</style>
