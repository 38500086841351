<template>
  <div class="position-relative h-100 w-100">
    <div class="page page-no-permissions">
      <h1 class="h1">Нет доступа</h1>
      <h2 class="h3 mt-5">Если вам нужен доступ к этой странице, обратитесь к администратору системы.</h2>
      <router-link to="/">На главную</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NoPermissions'
}
</script>

<style scoped>
  .page-no-permissions {
    max-width: 1000px;
    width: 100%;
    position: absolute;
    left: 50%;
    top: 33%;
    text-align: center;
    transform: translate(-50%, -33%);
    padding: 10px;
  }
</style>
