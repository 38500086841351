<template>
  <table class="table">
    <tbody>
      <tr>
        <td class="table-col">Номер</td>
        <td>{{ documentData.number }}</td>
      </tr>
      <tr v-if="documentData.date_end">
        <td>Срок действия до</td>
        <td>{{ documentData.date_end | formatDate }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'DiagnosticCardView',
  props: {
    documentData: {
      type: Object,
      default: null
    }
  },
}
</script>

<style scoped>
  .table-col {
    width: 50%;
  }
</style>
