import MapRestrictedArea from './views/MapRestrictedArea'

export default [
  {
    path: '/map-restricted-area',
    component: MapRestrictedArea,
    meta: {
      title: 'Геозоны',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
