var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page page-service-works"},[_c('div',{staticClass:"d-flex flex-column sticky-1"},[_c('div',{staticClass:"input-group without-separator"},[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('font-awesome-icon',{staticStyle:{"color":"gray"},attrs:{"icon":"search"}})],1)]),_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":"Поиск по имени и интервалу","aria-label":"Поиск по имени и интервалу"},domProps:{"value":_vm.searchText},on:{"input":_vm.searchServiceWork}})]),_c('div',{staticClass:"pt-2"},[_c('router-link',{staticClass:"btn btn-primary w-100",attrs:{"to":"/maintenance/service-works/create"}},[_vm._v("Добавить работу")])],1)]),_c('br'),_c('div',{staticClass:"draggable-cards-wrapper"},[_c('DraggableTree',{staticClass:"tree4",attrs:{"data":_vm.works,"draggable":"","cross-tree":"","indent":30,"space":0},on:{"change":_vm.changeItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var store = ref.store;
return _c('div',{},[(!data.isDragPlaceHolder)?[_c('div',{staticClass:"row text-dark py-2"},[_c('div',{staticClass:"col"},[(data.children && data.children.length)?_c('font-awesome-icon',{staticClass:"mx-2",style:({
                  color: 'gray',
                  'margin-left': 'auto',
                  cursor: 'pointer'
                }),attrs:{"icon":data.open ? 'chevron-up' : 'chevron-down'},on:{"click":function($event){return store.toggleOpen(data)}}}):_vm._e(),_c('TextHighlight',{attrs:{"queries":_vm.searchText}},[_vm._v(" "+_vm._s(data.name)+" ")]),_c('TextHighlight',{staticClass:"badge",staticStyle:{"line-height":"unset"},attrs:{"queries":_vm.searchText}},[_vm._v(" "+_vm._s(data.interval ? ("Каждые " + (data.interval) + " км.") : "")+" ")])],1),_c('div',{staticClass:"col-auto right-icons"},[_c('router-link',{staticClass:"btn",attrs:{"to":("/maintenance/service-works/create/" + (data.id))}},[_c('font-awesome-icon',{attrs:{"icon":"plus"}})],1),_c('router-link',{staticClass:"btn",attrs:{"to":("/maintenance/service-works/update/" + (data.id))}},[_c('font-awesome-icon',{attrs:{"icon":"pen"}})],1),_c('div',{staticClass:"btn",attrs:{"role":"button","tabindex":"0"},on:{"click":function($event){return _vm.removeItem(data.id)}}},[_c('font-awesome-icon',{attrs:{"icon":"trash-alt"}})],1)],1)])]:_vm._e()],2)}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }