<template>
  <div class="page">
    <HeaderTitle />
    <FacilityEdit
      :facility="facility"
    />
    <button class="btn btn-primary float-right" @click="createFacility()">Добавить</button>
    <router-link to="/facility" class="float-right mr-2">
      <button class="btn btn-secondary ml-2">Отменить</button>
    </router-link>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import FacilityApi from '../api/facility'
import {mapActions} from 'vuex'
import FacilityEdit from './Edit'

export default {
  name: 'FacilityCreate',
  components: {FacilityEdit},
  data () {
    return {
      isCreating: false,
      facility: {}
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'Объекты',
        url: '/facility'
      },
      {
        title: 'Добавить'
      }
    ])
  },
  methods: {
    createFacility () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      FacilityApi.createItem(this.facility)
        .then(facility => {
          this.isCreating = false
          this.$router.push(`/facility/${facility.model.id}`)
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
