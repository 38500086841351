<template>
  <div class="page" @scroll="onScroll">
    <div class="d-flex flex-column sticky-1">
      <div class="input-group without-separator">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <font-awesome-icon icon="search" style="color:gray" />
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Поиск по марке, модели, рег. номеру"
          aria-label="Поиск по марке, модели, рег. номеру"
          :value="searchText"
          @input="searchServe"
        >
      </div>
    </div>
    <table class="table table-hover w-100 mt-3 d-none d-md-table">
      <TableSort class="sticky-2" :sorts="sorts" @sortsUpdate="loadWithSort" />
      <tbody>
        <tr v-for="car of cars" :key="car.id" @click="$router.push(`/maintenance/car/${car.id}`)">
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.brand }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.model }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.registration_plate }}</TextHighlight>
          </td>
          <td>
            <ServiceState
              v-if="car.nextServiceRecord"
              :distance="car.nextServiceRecord.distRemains"
              :interval="car.nextServiceRecord.interval"
            />
          </td>
          <td>
            <DocumentState
              v-if="car.documents"
              :document="car.documents.find(document => document.type === 'osago')"
              :days="car.osagoRemains"
            />
          </td>
          <td>
            <DocumentState
              v-if="car.documents"
              :document="car.documents.find(document => document.type === 'diagnostic_card')"
              :days="car.diagnosticCardRemains"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div v-for="car of cars" :key="car.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
      <table v-if="cars.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Марка автомобиля</td>
            <td style="border-top: none">
              <TextHighlight class="highlighter" :queries="searchText">{{ car.brand }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Модель автомобиля</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ car.model }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Рег. номер</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ car.registration_plate }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Следующее ТО</td>
            <td>
              <ServiceState
                v-if="car.nextServiceRecord"
                :distance="car.nextServiceRecord.distRemains"
                :interval="car.nextServiceRecord.interval"
              />
            </td>
          </tr>
          <tr>
            <td>ОСАГО</td>
            <td>
              <DocumentState
                :document="car.documents.find(document => document.type === 'osago')"
                :days="car.osagoRemains"
              />
            </td>
          </tr>
          <tr>
            <td>Диагн. карта</td>
            <td>
              <DocumentState
                :document="car.documents.find(document => document.type === 'diagnostic_card')"
                :days="car.diagnosticCardRemains"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="{ name: 'servePage', params: { carId: car.id } }"
        class="btn btn-primary btn-md create-serve ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { djsMixin } from '@/mixins/djsMixin'
import DocumentState from '@/views/carDocuments/DocumentState'
import ServiceState from '../components/ServiceState'

export default {
  name: 'Serve',
  components: {
    DocumentState,
    ServiceState
  },
  mixins: [djsMixin],
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'Марка автомобиля',
          storeName: 'servecar-brand',
          values: ['', 'brand', '-brand'],
          order: 3
        }, {
          name: 'Модель автомобиля',
          storeName: 'servecar-model',
          values: ['', 'model', '-model'],
          order: 4
        }, {
          name: 'Рег. номер',
          storeName: 'servecar-registration_plate',
          values: ['', 'reg_plate_number', '-reg_plate_number'],
          order: 5
        }, {
          name: 'Следующее ТО',
          storeName: 'servecar-nextService',
          values: ['', 'nextServiceDist', '-nextServiceDist'],
          order: 0
        }, {
          name: 'ОСАГО',
          storeName: 'servecar-osago',
          values: ['', 'nextOsagoRemains', '-nextOsagoRemains'],
          order: 1,
          index: 2
        }, {
          name: 'Диагн. карта',
          storeName: 'servecar-diagnostic',
          values: ['', 'nextDiagnosticCard', '-nextDiagnosticCard'],
          order: 2,
          index: 2
        }
      ],
      searchedText: null,
      interval: null,
      scrollToOldValue: true
    }
  },
  computed: {
    ...mapState('maintenance/serviceCar', {
      cars: state => state.items,
      pageCount: state => state.pageCount,
      searchText: state => state.searchText,
      isLoading: state => state.isLoading
    })
  },
  mounted () {
    this.setTitle([
      {
        title: 'Тех. обслуживание'
      }
    ])
    if (!this.interval) {
      this.loadWithSearch()
    }
  },
  beforeDestroy () {
    this.abortLoading()
    clearTimeout(this.interval)
    this.interval = null
  },
  methods: {
    ...mapActions('maintenance/serviceCar', [
      'loadItems',
      'setSearchText',
      'setSearch',
      'abortLoading',
      'removeItem',
      'setSorts'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    loadWithSearch () {
      if (!this.isLoading && this.searchedText !== this.searchText) {
        this.searchedText = this.searchText
        return this.loadItems({ page: Number(this.page) })
          .then(() => {
            this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
          })
      }
      this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
    },
    searchServe (e) {
      this.setSearchText(e.target.value)
      this.setSearch({ key: 'brand', value: e.target.value })
      this.setSearch({ key: 'model', value: e.target.value })
      this.setSearch({ key: 'reg_plate_number', value: e.target.value })
    },
    leftUntil (planItem) {
      if (!this.car || !this.car.lastState || !this.car.lastState.totalOdometer) {
        return 'н/д о пробеге'
      }
      if (!planItem.total_odometer_stamp) {
        return 'н/д'
      }

      const odometerDiff = planItem.interval - (Math.ceil(this.car.lastState.totalOdometer.value / 1000) - planItem.total_odometer_stamp)
      switch (Math.sign(odometerDiff)) {
        case 1:
          return `осталось ${odometerDiff} км`
        case -1:
          return `просрочено на ${Math.abs(odometerDiff)} км`
        case 0:
          return 'осталось 0 км'
        case NaN:
          return 'н/д'
      }
    },
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page) })
        .then(() => {
          if (this.scrollToOldValue) {
            this.scrollToOldValue = false
            this.$el.scrollTo(0, localStorage.serveSrollPositions)
          }
        })
    },
    onScroll (e) {
      if (e.target.scrollTop) {
        localStorage.serveSrollPositions = e.target.scrollTop
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .page {
    padding-top: 1rem;
    height: 100%;
    overflow-y: auto;
  }
  @media screen and (max-width: 1199px) {
    .page {
      padding: 1rem;
    }
  }
  @media screen and (max-width: 576px) {
    .page {
      padding: 0.5rem;
    }
  }
  .highlighter ::v-deep .text__highlight {
    border-radius: 0px;
    padding: 0.2em 0 0.2em 0;
    background: #fff2ae;
  }
  .fixed-search {
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 10px;
    background: white;
  }
</style>
