<template>
  <div class="header-title-mobile">
    <span v-for="(item, key) in title" :key="key">
      <span v-if="key !== 0" class="px-2 d-inline-flex">›</span>
      <router-link
        v-if="item.url"
        :to="item.url"
        class="text-dark d-inline-flex"
      >
        {{ item.title }}
      </router-link>
      <span v-else class="text-dark d-inline-flex">{{ item.title }}</span>
    </span>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'

export default Vue.extend({
  name: 'HeaderTitle',
  computed: {
    ...mapState('taxi', {
      title: state => state.title
    })
  }
})
</script>

<style scoped>
  .header-title-mobile {
    font-size: 150%;
  }
  .header-title-mobile {
    display: none;
  }
  @media screen and (max-width: 1199px) {
    .header-title-mobile {
      display: block;
      margin-top: 0;
      margin-bottom: 25px;
    }
  }
  @media screen and (max-width: 576px) {
    .header-title-mobile {
      font-size: 125%;
    }
  }
</style>
