<template>
  <div>
    <div>
      <div v-if="car.lastState">
        Связь: <WatchState :location="lastState(car)" />
      </div>
      <div v-if="car.lastState && car.lastState.ignition">
        Зажигание: {{ car.lastState.ignition.value == 1 ? 'есть' : 'нет' }}
      </div>
      <div
        v-if="car.lastState && car.lastState.lat"
        class="d-flex align-items-center"
      >
        Скорость:
        <font-awesome-icon
          v-if="car.lastState && car.lastState.speed && car.lastState.speed.value > minSpeed"
          icon="car"
          class="mx-1"
          :style="{ color: '#92BF4E', width: '20px', height: '20px' }"
        />
        <font-awesome-icon
          v-else
          icon="parking"
          class="mx-1"
          :style="{ color: '#62ADBF', width: '20px', height: '20px' }"
        />
        {{ Math.round(car.lastState.speed.value) }} км/ч
      </div>
      <div v-if="car">
        Пробег:
        <span v-if="car.lastState && car.lastState.totalOdometer">
          {{ ' ' + Math.round(car.lastState.totalOdometer.value / 1000) + " км" }}
          (по датчику {{ ' ' + Math.round(car.lastState.totalOdometer.sensor / 1000) + " км" }})
        </span>
        <span v-else class="text-danger">
          <font-awesome-icon class="mr-1" icon="exclamation-circle" />Нет данных
        </span>
      </div>
      <div v-if="car.lastState && car.lastState.lat && car.lastState.lng">
        Координаты: {{ car.lastState.lat.value }}, {{ car.lastState.lng.value }}
      </div>
      <div v-if="car.lastState && car.lastState.lat">
        Дата последней отправки данных: {{ toLocalTime(car.lastState.lat.time) }}
      </div>
      <div v-if="car.imei">IMEI: {{ car.imei }} {{ trackDevice ? trackDevice.phone : '' }}</div>
    </div>
    <hr>
    <div>
      <h5 class="text-center" style="font-size: 18px">Маршрут</h5>
      <div class="my-1">Начало</div>
      <div class="row">
        <div class="col">
          <input
            v-model="timeFrom"
            class="w-100 form-control"
            type="datetime-local"
            placeholder="Начало маршрута"
          >
        </div>
      </div>
      <div class="my-2">Окончание</div>
      <div class="row">
        <div class="col">
          <input
            v-model="timeTo"
            class="w-100 form-control"
            type="datetime-local"
            placeholder="Начало маршрута"
          >
        </div>
      </div>
      <button class="input-group-btn btn btn-primary my-4 align-content-center w-100" @click="getTrackByTime()">
        <span v-if="!loadingTrack">Показать</span>
        <div v-else class="spinner-border spinner-border-sm text-light" role="status">
          <span class="sr-only">Загрузка...</span>
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
import trackDeviceApi from '@/api/trackDevice'

export default {
  props: {
    car: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      timeFrom: null,
      timeTo: null,
      loadingTrack: false,
      minSpeed: parseFloat(process.env.VUE_APP_MIN_SPEED),
      trackDevice: null
    }
  },
  computed: {
    lastState () {
      return (car) => {
        if (car.lastState.constructor === Array) {
          return {}
        }
        return car.lastState
      }
    }
  },
  mounted () {
    this.timeFrom = dayjs().format('YYYY-MM-DDT00:00')
    this.timeTo = dayjs().add(1, 'day').format('YYYY-MM-DDT00:00')
    trackDeviceApi.getItem({ id: this.car.tracking_device_id })
      .then(response => {
        this.trackDevice = response
      })
  },
  methods: {
    ...mapActions('taxi/mapCars', [
      'getStartTrack',
      'clearTrack',
      'getTrack'
    ]),
    toLocalTime (date) {
      return new Date(date).toLocaleString()
    },
    getTrackByTime () {
      if (!this.loadingTrack) {
        this.loadingTrack = true
        // eslint-disable-next-line no-unused-expressions
        this.clearTrack
        const timeFrom = dayjs(this.timeFrom).format('YYYY-MM-DDTHH:mm:ssZZ')
        const timeTo = dayjs(this.timeTo).format('YYYY-MM-DDTHH:mm:ssZZ')
        if (new Date(this.timeTo) < new Date()) {
          this.getTrack({
            id: this.car.id,
            timeFrom,
            timeTo
          })
            .then(track => {
              this.loadingTrack = false
              if (!track.track.length) {
                Swal.fire({
                  icon: 'warning',
                  title: 'Нет данных',
                  text: 'Не удалось найти маршрут за данный период'
                })
              }
            })
            .catch(error => {
              this.loadingTrack = false
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: error.message
              })
            })
        } else {
          this.getStartTrack({
            id: this.car.id,
            timeFrom,
            timeTo
          })
            .then(track => {
              this.loadingTrack = false
              if (!track.track.length) {
                Swal.fire({
                  icon: 'warning',
                  title: 'Нет данных',
                  text: 'Не удалось найти маршрут за данный период'
                })
              }
            })
            .catch(error => {
              this.loadingTrack = false
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: error.message
              })
            })
        }
      }
    }
  }
}
</script>
