<template>
  <div class="page">
    <HeaderTitle />
    <StockEdit
      :stock="stock"
      :showPriceAndSum="showPriceAndSum"
    />
    <button class="btn btn-primary float-right" @click="createStock()">Добавить</button>
    <router-link to="/facility" class="float-right mr-2">
      <button class="btn btn-secondary ml-2">Отменить</button>
    </router-link>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import StockApi from '../api/stock'
import StockEdit from './Edit'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'StockCreate',
  components: {StockEdit},
  data () {
    return {
      isCreating: false,
      stock: {}
    }
  },
  computed: {
    ...mapGetters('configs/configs', [
      'showPriceAndSum'
    ])
  },
  beforeMount() {
    this.loadConfigs()
  },
  mounted () {
    this.loadItems()
    this.setTitle([
      {
        title: 'Список',
        url: '/facility'
      },
      {
        title: 'Добавить'
      }
    ])
  },
  methods: {
    createStock () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      StockApi.createItem(this.stock)
        .then(stock => {
          this.isCreating = false
          this.$router.push(`/stock/${stock.model.id}`)
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    loadItems () {
      if (this.$route.query.facilityId) {
        this.stock.facility_id = parseInt(this.$route.query.facilityId)
      }
    },
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('configs/configs', {
      loadConfigs: 'loadItems'
    })
  }
}
</script>
