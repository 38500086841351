<template>
  <div class="float-right">
    <button v-if="!isEditing" class="m-1 btn btn-danger" @click="$emit('deleteAction')">
      <font-awesome-icon class="text-white" icon="trash-alt" />
      Удалить
    </button>
    <button v-if="!isEditing" class="m-1 btn btn-primary" @click="$emit('changeAction')">
      <font-awesome-icon class="text-white" icon="edit" />
      Изменить
    </button>
    <button v-if="isEditing" class="m-1 btn btn-secondary" @click="$emit('cancelAction')">Отменить</button>
    <button v-if="isEditing" class="m-1 btn btn-primary" @click="$emit('saveAction')">Сохранить</button>
  </div>
</template>

<script>
export default {
  name: 'ActionBar',
  props: {
    isEditing: {
      type: Boolean,
      default: false
    }
  }
}
</script>
