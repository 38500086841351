<template>
  <div v-if="config.contract" class="page">
    <HeaderTitle />
    <ActionBar
      v-show="!isEnding"
      :isEditing="isEditing"
      @changeAction="changeContract"
      @cancelAction="cancelContractChanges"
      @deleteAction="deleteContract"
      @saveAction="updateContract"
    />

    <button
      v-if="isEnding"
      class="m-1 btn btn-primary button-cancel-archivate"
      @click="isEnding = false"
    >
      Отменить
    </button>
    <button
      v-if="config.contract.status === 'active'"
      class="m-1 btn btn-primary button-archivate"
      @click="archivateContract()"
    >
      <font-awesome-icon class="text-white" icon="stop" />
      Завершить аренду
    </button>
    <button
      v-if="config.contract.status === 'closed'"
      class="m-1 btn btn-primary button-archivate"
      @click="unArchivateContract()"
    >
      Возобновить аренду
    </button>
    <button
      v-if="config.contract.status === 'draft'"
      class="m-1 btn btn-primary button-activate"
      :disabled="notifications.length > 0"
      @click="activateContract()"
    >
      Активировать
    </button>
    <a
      v-if="!isEnding && config.contract.template"
      :href="`/api/contract/download?id=${contractId}`"
      class="m-1 btn btn-secondary button-download"
      role="button"
    >
      <font-awesome-icon class="text-white" icon="file-download" />
      Скачать договор
    </a>
    <a
      v-if="config.contract.status === 'closed' && config.contract.receiveListTemplate"
      :href="`/api/contract/download-receive-list?id=${contractId}`"
      class="m-1 btn btn-secondary button-download"
      role="button"
    >
      <font-awesome-icon class="text-white" icon="file-download" />
      Скачать акт возврата
    </a>
    <RentEditForm v-if="isEditing && !isEnding" />
    <RentShowForm v-if="!isEditing && !isEnding" :contract="config.contract" :companies="config.companies" />
    <div v-if="config.contract.status === 'draft'" class="notifications-container">
      <div
        v-for="notification in notifications"
        :key="notification"
        class="alert alert-warning notification-warning"
        role="alert"
      >
        {{ notification }}
      </div>
    </div>
    <div v-if="isEnding" class="end-rent-date-container">
      <label for="inputEndRDate">Дата/ время приема автомобиля (фактическая)</label>
      <input
        id="inputEndRDate"
        v-model="config.contract.archived_at"
        type="datetime-local"
        class="form-control"
        min="2010-06-01T08:30"
        max="2050-06-30T16:30"
      >
    </div>
    <hr>
    <h5>Сканы договора</h5>
    <ScansGallery :documentId="config.contract.id" />
    <PhotoControl :contract="config.contract" />
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import ContractsAPI from '@/api/contracts'
import DriversAPI from '@/api/drivers'
import CarsAPI from '@/api/cars'
import FilesAPI from '@/api/files'
import CompaniesAPI from '@/api/companies'
import ProxiesAPI from '@/api/proxies'
import deepAssign from 'assign-deep'
import { djsMixin } from '@/mixins/djsMixin'
import {mapActions, mapMutations} from 'vuex'
import ScansGallery from '../../components/common/scans/ScansGallery'
import PhotoControl from '../../components/contracts/rent/PhotoControl'

export default {
  name: 'RentPage',
  components: {PhotoControl, ScansGallery},
  mixins: [djsMixin],
  props: {
    contractId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isUpdating: false,
      config: {
        contract: null,
        drivers: '',
        templates: {
          contracts: []
        },
        cars: [],
        companies: [],
        proxies: []
      },
      notifications: [],
      isEditing: false,
      isEnding: false,
      pervContract: null
    }
  },
  mounted () {
    this.loadItems()
    this.clearItems()
    this.setModel('Contract')
  },
  provide () {
    return { config: this.config }
  },
  methods: {
    changeContract () {
      this.pervContract = deepAssign({}, this.config.contract)
      this.isEditing = true
    },
    deleteContract () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить контракт?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          ContractsAPI.removeItem(this.contractId)
            .then(() => {
              this.$router.push('/rent')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    cancelContractChanges () {
      this.config.contract = this.pervContract
      this.isEditing = false
    },
    updateContract () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true

      ContractsAPI.updateItem(this.config.contract)
        .then(contract => {
          this.config.contract = contract.model
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    archivateContract () {
      if (this.isUpdating) {
        return
      }
      if (!this.isEnding) {
        this.config.contract.archived_at = this.now()
        this.isEnding = true
        return
      }
      this.isUpdating = true
      ContractsAPI.archivate({
        id: this.contractId,
        archivedAt: this.config.contract.archived_at
      }).then(() => {
        this.isEnding = false
        this.isUpdating = false
        this.loadItems()
      })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    unArchivateContract () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      ContractsAPI.unArchivate({ id: this.contractId })
        .then(() => {
          this.isEnding = false
          this.isUpdating = false
          this.loadItems()
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    activateContract () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      ContractsAPI.activate(this.contractId).then(() => {
        this.isUpdating = false
        this.loadItems()
      })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    loadItems () {
      DriversAPI.getItems().then(drivers => {
        this.config.drivers = drivers.items
        this.config.drivers.forEach(driver => {
          driver.fullName = `${driver.last_name} ${driver.first_name} ${driver.middle_name}`
        })
      })
      CarsAPI.getItems().then(cars => {
        this.config.cars = cars.items
        this.config.cars.forEach(car => {
          car.displayedName = `${car.brand} ${car.model} ${car.registration_plate}`
        })
      })
      FilesAPI.getContractTemplates().then(templateFiles => {
        this.config.templates.contracts = this.config.templates.contracts.concat(templateFiles.items)
        this.config.templates.contracts.forEach(template => {
          template.nameWithDate = `${template.name} ${template.created_at}`
        })
      })
      FilesAPI.getReceiveListsTemplates().then(templateFiles => {
        this.config.templates.receiveLists = templateFiles.items
        this.config.templates.receiveLists.forEach(template => {
          template.nameWithDate = `${template.name} ${template.created_at}`
        })
      })
      this.loadContract({ id: this.contractId })
        .then(contract => {
          this.config.contract = contract
          if (contract.template.archived_at != null) {
            contract.template.nameWithDate = `${contract.template.name} ${contract.template.created_at}`
            this.config.templates.contracts.push(contract.template)
          }
          this.pushItems({ scans: contract.scans, documentId: contract.id })
          this.setTitle([
            {
              title: 'Аренда',
              url: '/rent'
            },
            {
              title: contract.number ? '№ ' + contract.number : 'Черновик'
            }
          ])
          ContractsAPI.checkDocuments({ id: this.contractId })
            .then(notifications => {
              this.notifications = notifications
            })
        })
        .catch(() => {
          this.$router.replace('/not-found')
        })
      CompaniesAPI.getItems({ queryWith: ['proxiesCompany'] }).promise.then(companies => {
        this.config.companies = companies.items
      })
      ProxiesAPI.getItems().promise.then(proxies => {
        this.config.proxies = proxies.items.map(proxy => {
          proxy.fullName = `${proxy.user.last_name} ${proxy.user.first_name} ${proxy.user.middle_name}`
          return proxy
        })
      })
    },
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('taxi/contracts', {
      loadContract: 'loadItem'
    }),
    ...mapMutations('taxi/scans', [
      'pushItems',
      'clearItems',
      'setModel'
    ])
  }
}
</script>

<style scoped>
  .button-download {
    min-width: 12rem;
    margin-right: 3px;
  }
  .button-archivate {
    min-width: 12rem;
  }
  .end-rent-date-container {
    margin-top: 3rem;
  }
  .notification-warning {
    padding: 0.45rem 1.25rem;
    margin-right: 0.5rem;
    color: #f2b950;
    width: fit-content;
    background-color: rgba(242, 185, 80, 0.15);
  }
  .notifications-container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
</style>
