import FeedbackList from './views/user/List'
import FeedbackShow from './views/user/Show'
import FeedbackAdminList from './views/admin/List'
import FeedbackAdminPage from './views/admin/Page'

export default [
  {
    path: '/feedback',
    component: FeedbackList,
    meta: {
      title: 'Обратная связь',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/feedback-admin',
    component: FeedbackAdminList,
    meta: {
      title: 'Обратная связь',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/feedback/:feedbackId',
    component: FeedbackShow,
    name: 'FeedbackShow',
    props: true,
    meta: {
      title: 'Обратная связь',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/feedback-admin/:feedbackId',
    component: FeedbackAdminPage,
    name: 'FeedbackAdminShow',
    props: true,
    meta: {
      title: 'Обратная связь',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
