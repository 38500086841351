const result = {}

if (process.env.VUE_APP_MODULE_FUEL === 'true') {
  result.fuel = require('./fuel/store/index').default
}

if (process.env.VUE_APP_MODULE_MAINTENANCE === 'true') {
  result.maintenance = require('./maintenance/store/index').default
  result.stock = require('./stock/store/index').default
}

if (process.env.VUE_APP_MODULE_FINES === 'true') {
  result.fines = require('./fines/store/index').default
}

if (process.env.VUE_APP_MODULE_ACCIDENT === 'true') {
  result.accident = require('./accidents/store/index').default
}

result.configs = require('./configs/store/index').default
result.feedback = require('./feedback/store/index').default
result.facility = require('./facility/store/index').default

export default result
