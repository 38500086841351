<template>
  <span class="device-status">
    <font-awesome-icon
      class="device-status-icon"
      icon="circle"
      :style="{ color }"
    /> {{ stateText }}
  </span>
</template>

<script>
export default {
  name: 'WatchState',
  props: {
    location: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      color: '',
      stateText: ''
    }
  },
  watch: {
    location: {
      handler () {
        this.setStatus()
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    setStatus () {
      if (!this.location || !this.location?.lat?.time) {
        this.color = 'rgba(20,20,20,0.2)'
        this.stateText = 'Не отслеживается'
        return
      }
      const date = new Date(this.location.lat.time)
      const now = Date.now()
      const diff = now - date
      if (diff > 60 * 60000) { // Больше часа
        this.color = '#e95b5a'
        this.stateText = `${Math.round(diff / (60 * 60000))}ч. назад`
        return
      }
      if (now - date > 60000) { // Больше минуты
        this.color = '#E8CF72'
        this.stateText = `${Math.round(diff / 60000)}м. назад`
        return
      }
      this.color = '#43E872'
      this.stateText = 'Недавно'
    }
  }
}
</script>
