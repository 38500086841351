<template>
  <div>
    <table class="table borderless">
      <tbody>
        <tr>
          <td>Название</td>
          <td>
            <input
              v-model="facility.name"
              type="text"
              class="form-control"
              placeholder
              required
            >
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'FacilityEdit',
  props: {
    facility: {
      type: Object,
      default: () => null
    },
  },
}
</script>
