<template>
  <div class="page">
    <HeaderTitle />
    <div class="pt-2">
      <router-link to="/accidents/create" class="btn btn-primary w-100 mb-2">Добавить ДТП</router-link>
    </div>
    <AccidentListView />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { djsMixin } from '@/mixins/djsMixin'
import AccidentListView from '../components/AccidentListView'

export default {
  name: 'AccidentList',
  components: {AccidentListView},
  mixins: [djsMixin],
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'Автомобиль',
          storeName: 'fines-car-brand',
          values: ['', 'brand', '-brand'],
          order: 0
        },
        {
          name: 'Водитель',
          storeName: 'fines-driver',
          values: ['', 'brand', '-brand'],
          order: 1
        },
        {
          name: 'Дата ДТП',
          storeName: 'date',
          values: ['', 'date', '-date'],
          order: 2
        }
      ],
      searchedText: null,
      interval: null
    }
  },
  computed: {
    ...mapState('accident/accident', {
      accidents: state => state.items,
      pageCount: state => state.pageCount,
      searchText: state => state.searchText,
      isLoading: state => state.isLoading
    }),
    getDriver () {
      return (accident) => {
        if (!accident.driver) {
          return ''
        }
        return accident.driver.last_name + ' ' + accident.driver.first_name + ' ' + accident.driver.middle_name
      }
    },
  },
  mounted () {
    this.setTitle([
      {
        title: 'ДТП'
      }
    ])
    if (!this.interval) {
      this.loadWithSearch()
    }
  },
  beforeDestroy () {
    this.abortLoading()
    clearTimeout(this.interval)
    this.interval = null
  },
  methods: {
    ...mapActions('accident/accident', [
      'loadItems',
      'setSearchText',
      'setSearch',
      'abortLoading',
      'setSorts'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    loadWithSearch () {
      if (!this.isLoading && this.searchedText !== this.searchText) {
        this.searchedText = this.searchText
        return this.loadItems({ page: Number(this.page), expand: ['car', 'driver'] })
          .then(() => {
            this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
          })
      }
      this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
    },
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page), expand: ['car', 'driver']})
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding-top: 1rem;
  height: 100%;
  overflow-y: auto;
}
@media screen and (max-width: 1199px) {
  .page {
    padding: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .page {
    padding: 0.5rem;
  }
}
.highlighter ::v-deep .text__highlight {
  border-radius: 0px;
  padding: 0.2em 0 0.2em 0;
  background: #fff2ae;
}
.fixed-search {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
  background: white;
}
.alert-state {
  margin: 0;
  width: fit-content;
  height: fit-content;
  line-height: 30px;
  padding: 0 15px;
  min-width: 10rem;
  text-align: center;
  border-radius: 15px;
  border: none;
}
.alert-danger {
  color: #BF543B;
  background-color: rgba(191, 84, 59, 0.15);
}
.alert-success {
  color: #92BF4E;
  background-color: rgba(146, 191, 78, 0.15);
}
</style>
