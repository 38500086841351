import APITemplate from '@/api/APITemplate'

const url = '/api/odometer-correction'

const template = APITemplate(url)
export default {
  ...template,
  getHistoryOdometer (data) {
    return fetch('/api/odometer-correction/history-odometer', {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers,
      body: JSON.stringify(data)
    }).then(template.saveHandler)
  }
}
