<template>
  <div class="page">
    <div class="card-body p-0 mt-3">
      <ul class="nav nav-tabs justify-content-center">
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: activeTab === 'ТО' }"
            href="#service-tab"
            @click.prevent="activeTab = 'ТО'"
          >ТО</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: activeTab === 'ОСАГО' }"
            href="#osago-tab"
            @click.prevent="activeTab = 'ОСАГО'"
          >ОСАГО</a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            :class="{ active: activeTab === 'Диагностическая карта' }"
            href="#diagnostic-tab"
            @click.prevent="activeTab = 'Диагностическая карта'"
          >Диагностическая карта</a>
        </li>
      </ul>
      <div class="tab-content">
        <div id="service-tab" class="tab-pane fade" :class="{ 'active show': activeTab === 'ТО' }">
          <div class="row w-100 m-0">
            <div v-for="(day, keyDay) in getDays" :key="day" class="col-12 col-xl p-0 planning-item-col">
              <div class="planning-item-header bg-light p-2 text-center border-bottom" style="white-space: nowrap">{{ day }}</div>
              <div class="d-flex d-xl-block w-100 flex-wrap text-center p-1">
                <div v-for="(car, keyCar) in loadFastEndingServiceForDay(keyDay)" :key="keyCar">
                  <RegistrationPlate
                    role="button"
                    tabindex="0"
                    size="small"
                    :reg-number="car.reg_plate_number"
                    :reg-region="car.reg_plate_region"
                    @click="() => {
                      showModalTO = true
                      carToModal = car
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="osago-tab" class="tab-pane fade" :class="{ 'active show': activeTab === 'ОСАГО' }">
          <div class="row w-100 m-0">
            <div v-for="(month, keyMonth) in getMonth" :key="month.month" class="col-12 col-xl p-0 planning-item-col">
              <div class="bg-light py-2 text-center border-bottom planning-item-header">
                <div>
                  {{ month.month }}
                  <br class="d-none d-xl-block">
                  <br v-if="!month.year" class="d-none d-xl-block">
                  {{ month.year }}
                </div>
              </div>
              <div class="d-flex d-xl-block flex-wrap text-center">
                <div v-for="(car, keyCar) in loadFastEndingOsagoForMouth(keyMonth)" :key="keyCar">
                  <RegistrationPlate
                    role="button"
                    tabindex="0"
                    size="small"
                    :reg-number="car.reg_plate_number"
                    :reg-region="car.reg_plate_region"
                    @click="() => {
                      showModalOsago = true
                      carToModal = car
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="diagnostic-tab" class="tab-pane fade" :class="{ 'active show': activeTab === 'Диагностическая карта' }">
          <div class="row w-100 m-0">
            <div v-for="(month, keyMonth) in getMonth" :key="month.month" class="col-12 col-xl p-0 planning-item-col">
              <div class="bg-light py-2 text-center border-bottom planning-item-header">
                <div>
                  {{ month.month }}
                  <br class="d-none d-xl-block">
                  <br v-if="!month.year" class="d-none d-xl-block">
                  {{ month.year }}
                </div>
              </div>
              <div class="d-flex d-xl-block flex-wrap text-center">
                <div v-for="(car, keyCar) in loadFastEndingDiagnosticForMouth(keyMonth)" :key="keyCar">
                  <RegistrationPlate
                    role="button"
                    tabindex="0"
                    size="small"
                    :reg-number="car.reg_plate_number"
                    :reg-region="car.reg_plate_region"
                    @click="() => {
                      showModalDiagnosticCard = true
                      carToModal = car
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModalTO && carToModal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div
              class="btn float-right text-secondary"
              role="button"
              tabindex="0"
              @click="showModalTO = false"
            >
              <font-awesome-icon icon="times" />
            </div>
            <router-link
              class="my-2 h4 text-decoration-none"
              role="button"
              tabindex="0"
              :to="'/maintenance/car/' + carToModal.id"
            >
              <div class="my-2 text-secondary" style="display: contents">{{ carToModal.brand }} {{ carToModal.model }} {{ carToModal.registration_plate }}</div>
              <font-awesome-icon
                class="mx-2"
                icon="external-link-alt"
                style="width: 0.7em"
                :style="{ color: 'gray' }"
              />
            </router-link>
            <div class="tab-content mt-5">
              <div class="tab-pane fade show active">
                <table class="table plan-items-table">
                  <thead>
                    <tr>
                      <th>Вид работы</th>
                      <th>Следующее ТО</th>
                    </tr>
                  </thead>
                  <tbody v-if="carToModal && getServicePan(carToModal).length">
                    <tr v-for="planItem in getServicePan(carToModal)" :key="planItem.name">
                      <td>{{ planItem.name }}</td>
                      <td>
                        <ServiceState
                          :distance="leftBeforeNext(planItem)"
                          :afterService="afterService(planItem)"
                          :interval="planItem.interval"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModalOsago && carToModal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div
              class="btn float-right text-secondary"
              role="button"
              tabindex="0"
              @click="showModalOsago = false"
            >
              <font-awesome-icon icon="times" />
            </div>
            <router-link
              class="my-2 h4 text-decoration-none"
              role="button"
              tabindex="0"
              :to="'/cars/' + carToModal.id"
            >
              <div class="my-2 text-secondary" style="display: contents">
                {{ carToModal.brand }} {{ carToModal.model }} {{ carToModal.registration_plate }}
              </div>
              <font-awesome-icon
                class="mx-2"
                icon="external-link-alt"
                style="width: 0.7em"
                :style="{ color: 'gray' }"
              />
            </router-link>
            <div v-if="getOsago(carToModal)" class="my-2">
              <div>Серия и номер: {{ getOsago(carToModal).data.series }} {{ getOsago(carToModal).data.number }}</div>
              <div>Дата начала: {{ getOsago(carToModal).data.date_start | formatDate }}</div>
              <div>Дата окончания: {{ getOsago(carToModal).data.date_end | formatDate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModalDiagnosticCard && carToModal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div
              class="btn float-right text-secondary"
              role="button"
              tabindex="0"
              @click="showModalDiagnosticCard = false"
            >
              <font-awesome-icon icon="times" />
            </div>
            <router-link
              class="my-2 h4 text-decoration-none"
              role="button"
              tabindex="0"
              :to="'/cars/' + carToModal.id"
            >
              <div class="my-2 text-secondary" style="display: contents">
                {{ carToModal.brand }} {{ carToModal.model }} {{ carToModal.registration_plate }}
              </div>
              <font-awesome-icon
                class="mx-2"
                icon="external-link-alt"
                style="width: 0.7em"
                :style="{ color: 'gray' }"
              />
            </router-link>
            <div v-if="getOsago(carToModal)" class="my-2">
              <div>номер: {{ getDiagnosticCard(carToModal).data.number }}</div>
              <div>Дата окончания: {{ getDiagnosticCard(carToModal).data.date_end | formatDate }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { djsMixin } from '@/mixins/djsMixin'
import serviceWorkAPI from '@/modules/maintenance/api/serviceWork'
import { mapActions, mapState } from 'vuex'
import dayjs from 'dayjs'
import ServiceState from '../components/ServiceState'
import RegistrationPlate from '../../../components/car/RegistrationPlate'

export default {
  name: 'Planning',
  components: { RegistrationPlate, ServiceState },
  mixins: [djsMixin],
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      activeTab: 'ТО',
      showModalTO: false,
      showModalOsago: false,
      showModalDiagnosticCard: false,
      carToModal: null,
      serviceWorks: [],
      countDay: 7,
      countMonth: 12,
      kilometersPerDay: 200
    }
  },
  computed: {
    ...mapState('taxi/cars', {
      cars: state => state.items,
      searchText: state => state.searchText,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading
    }),
    loadFastEndingServiceForDay () {
      return (day) => {
        return this.cars.filter(car => {
          if (day !== 0) {
            return ((car.nextServiceRecord?.distRemains < day * this.kilometersPerDay) && (car.nextServiceRecord?.distRemains > (day - 1) * this.kilometersPerDay))
          }
          return car.nextServiceRecord?.distRemains < day * this.kilometersPerDay
        })
      }
    },
    loadFastEndingOsagoForMouth () {
      return (month) => {
        return this.cars.filter(car => {
          const startMonthDate = dayjs().add(month - 1, 'month').startOf('month')
          const endMonthDate = dayjs().add(month - 1, 'month').endOf('month')
          const now = dayjs()
          const diffStart = Math.round((startMonthDate - now) / (1000 * 60 * 60 * 24))
          const diffEnd = Math.round((endMonthDate - now) / (1000 * 60 * 60 * 24))
          if (month !== 0) {
            return (car.osagoRemains && (car.osagoRemains <= diffEnd) && (car.osagoRemains >= diffStart))
          }
          return car.osagoRemains < 0
        })
      }
    },
    loadFastEndingDiagnosticForMouth () {
      return (month) => {
        return this.cars.filter(car => {
          const startMonthDate = dayjs().add(month - 1, 'month').startOf('month')
          const endMonthDate = dayjs().add(month - 1, 'month').endOf('month')
          const now = dayjs()
          const diffStart = Math.round((startMonthDate - now) / (1000 * 60 * 60 * 24))
          const diffEnd = Math.round((endMonthDate - now) / (1000 * 60 * 60 * 24))
          if (month !== 0) {
            return (car.diagnosticCardRemains && (car.diagnosticCardRemains <= diffEnd) && (car.diagnosticCardRemains >= diffStart))
          }
          return car.diagnosticCardRemains < 0
        })
      }
    },
    getDays () {
      const days = []
      for (let i = 0; i <= this.countDay; i++) {
        if (i === 0) {
          days.push('Просрочено')
        } else if (i === 1) {
          days.push('Сегодня')
        } else {
          days.push(dayjs().add(i - 1, 'day').format('DD.MM.YYYY'))
        }
      }
      return days
    },
    getMonth () {
      const months = []
      const monthNames = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь',
        'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
      ]
      for (let i = 0; i <= this.countMonth; i++) {
        const date = dayjs().add(i - 1, 'month')
        if (i === 0) {
          months.push({
            month: 'Просрочено',
            year: null
          })
        } else {
          months.push({
            month: monthNames[date.month()],
            year: date.year()
          })
        }
      }
      return months
    },
    getServicePan () {
      return car => {
        return this.serviceWorks.map(serviceWork => {
          const searchedIndex = car.servicePlan.findIndex(el => el.id == serviceWork.id)
          return searchedIndex === -1 ? serviceWork : car.servicePlan[searchedIndex]
        })
      }
    },
    getOsago () {
      return (car) => {
        return car.documents.find(document => document.type === 'osago')
      }
    },
    getDiagnosticCard () {
      return (car) => {
        return car.documents.find(document => document.type === 'diagnostic_card')
      }
    }
  },
  watch: {
    page () {
      this.loadItems({ page: Number(this.page) })
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'Тех. обслуживание',
        url: '/maintenance'
      }, {
        title: 'Планирование'
      }
    ])
  },
  beforeMount () {
    this.loadItems({
      page: Number(this.page),
      expand: ['servicePlan', 'lastState']
    })
    serviceWorkAPI.getActiveServiceWorks()
      .then(serviceWorks => {
        this.serviceWorks = serviceWorks
      })
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('taxi/cars', [
      'abortLoading',
      'loadItems'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    afterService (planItem) {
      if (!this.carToModal.lastState || !this.carToModal.lastState.totalOdometer ||
        !this.carToModal.lastState.totalOdometer.value) {
        return 'н/д о пробеге'
      }

      if (!planItem.total_odometer_stamp) {
        return 'н/д'
      }

      return Math.ceil(this.carToModal.lastState.totalOdometer.value / 1000) - planItem.total_odometer_stamp
    },
    leftBeforeNext (planItem) {
      if (!this.carToModal.lastState || !this.carToModal.lastState.totalOdometer ||
        !this.carToModal.lastState.totalOdometer.value) {
        return null
      }

      if (!planItem.total_odometer_stamp) {
        return null
      }

      return planItem.interval - (Math.round(this.carToModal.lastState.totalOdometer.value / 1000) - planItem.total_odometer_stamp)
    }
  }
}
</script>

<style lang="scss" scoped>
  .planning-item-header {
    font-size: 1rem;
  }
  .planning-item-col {
    border: 1px solid #dee2e6;
    border-right: none;
  }
  .planning-item-col:last-child {
    border-right: 1px solid #dee2e6;
  }
  .badge-plate {
    font-size: 90%;
  }
  @media screen and (max-width: 1500px) {
    .badge-plate {
      font-size: 68%;
      margin: 0;
    }
    .planning-item-header {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 1199px) {
    .page {
      padding: 1rem;
    }
    .planning-item-header {
      font-size: 16px;
    }
    .planning-item-col {
      border-right: 1px solid #dee2e6;
      border-bottom: none;
    }
    .planning-item-col:last-child {
      border-bottom: 1px solid #dee2e6;
    }
    .badge-plate {
      font-size: 100%;
      margin: 3px;
    }
  }
  @media screen and (max-width: 576px) {
    .page {
      padding: 0.5rem;
    }
  }
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }
  .modal-enter {
    opacity: 0;
  }
  .modal-leave-active {
    opacity: 0;
  }
  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    transform: scale(1.1);
  }
  .car-name:hover {
    text-decoration: none;
  }
</style>
