<template>
  <div class="page page-contracts">
    <HeaderTitle />
    <RentEditForm class="contract-create" />
    <button class="btn btn-primary" @click="createContract()">Добавить</button>
    <router-link to="/rent">
      <button class="btn btn-secondary ml-2">Отменить</button>
    </router-link>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import ContractsAPI from '@/api/contracts'
import DriversAPI from '@/api/drivers'
import CarsAPI from '@/api/cars'
import FilesAPI from '@/api/files'
import CompaniesAPI from '@/api/companies'
import { mapActions } from 'vuex'
import ProxiesAPI from '@/api/proxies'

export default {
  name: 'CreateRent',
  data () {
    return {
      isCreating: false,
      config: {
        contract: {
          driver_id: '',
          car_id: '',
          file_id: '',
          transfer_datetime: '',
          receiving_datetime: '',
          price: '',
          payment_period: 'day',
          transfer_spot: 'Тюменская обл, г Тюмень, ул Госпаровская, д 2 к 1 стр 4',
          receiving_spot: 'Тюменская обл, г Тюмень, ул Госпаровская, д 2 к 1 стр 4'
        },
        drivers: [],
        templates: [],
        cars: [],
        companies: [],
        proxies: []
      }
    }
  },
  mounted () {
    this.loadItems()
    this.setTitle([
      {
        title: 'Аренда',
        url: '/rent'
      },
      {
        title: 'Добавить'
      }
    ])
  },
  provide () {
    return { config: this.config }
  },
  methods: {
    createContract () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      ContractsAPI.createItem(this.config.contract)
        .then(contract => {
          this.isCreating = false
          this.$router.push(`/rent/${contract.model.id}`)
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    loadItems () {
      DriversAPI.getItems().promise.then(drivers => {
        this.config.drivers = drivers.items
        const front = this.config.drivers[0]
        if (front) {
          this.config.contract.driver_id = front.id
        }
        this.config.drivers.forEach(driver => {
          driver.fullName = `${driver.last_name} ${driver.first_name} ${driver.middle_name}`
        })
      })
      CarsAPI.getItems().promise.then(cars => {
        this.config.cars = cars.items
        this.config.cars.forEach(car => {
          car.displayedName = `${car.brand} ${car.model} ${car.registration_plate}`
        })
      })
      FilesAPI.getContractTemplates().promise.then(templateFiles => {
        this.config.templates.contracts = templateFiles.items
        if (this.config.templates.contracts[0]) {
          this.config.contract.file_id = this.config.templates.contracts[0].id
        }
        this.config.templates.contracts.forEach(template => {
          template.nameWithDate = `${template.name} ${template.created_at}`
        })
      })
      FilesAPI.getReceiveListsTemplates().promise.then(templateFiles => {
        this.config.templates.receiveLists = templateFiles.items
        if (this.config.templates.receiveLists[0]) {
          this.config.contract.receive_list_id = this.config.templates.receiveLists[0].id
        }
        this.config.templates.receiveLists.forEach(template => {
          template.nameWithDate = `${template.name} ${template.created_at}`
        })
      })
      CompaniesAPI.getItems().promise.then(companies => {
        this.config.companies = companies.items
      })
      ProxiesAPI.getItems().promise.then(proxies => {
        this.config.proxies = proxies.items.map(proxy => {
          proxy.fullName = `${proxy.user.last_name} ${proxy.user.first_name} ${proxy.user.middle_name}`
          return proxy
        })
      })
      // Установить статус по умолчанию
      this.config.contract.status = 'draft'
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>

<style scoped>
  .page-contracts {
    background-color: white;
    padding: 2rem;
    padding-bottom: 1rem;
    border-radius: 10px;
  }
  .contract-create {
    margin-top: 3rem;
  }
</style>
