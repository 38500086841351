import BlackList from './views/List'
import AddBlacklist from './views/Add.vue'

export default [{
    path: '/black-list',
    component: BlackList,
    meta: {
      title: 'Черный список',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/black-list/add',
    component: AddBlacklist,
    meta: {
      title: 'Черный список',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
