<template>
  <div class="page page-company">
    <HeaderTitle />
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Выберите ОПФ</td>
          <td>
            <select v-model="company.type" class="form-control">
              <option
                v-for="(type, index) in types"
                :key="index"
                :value="index"
              >
                {{ type.title }}
              </option>
            </select>
          </td>
        </tr>
        <component
          :is="currentFormComponent"
          v-if="currentFormComponent"
          :company="company"
          :companies="companies"
          @update="handleData"
        />
      </tbody>
    </table>
    <div class="mt-4">
      <button class="btn btn-primary mr-2" @click="createCompany()">Добавить</button>
      <router-link to="/companies" class="btn btn-secondary">Отменить</router-link>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import companiesAPI from '@/api/companies'
import { mapActions, mapState } from 'vuex'
import types from '@/views/companies/types'

const components = {}
for (const typeName in types) {
  components[types[typeName].edit.name] = types[typeName].edit.component
}

export default {
  name: 'CreateCompany',
  components,
  data () {
    return {
      company: {
        type: null
      },
      types
    }
  },
  computed: {
    currentFormComponent () {
      return types[this.company.type]?.edit?.name
    },
    ...mapState('taxi/companies', {
      companies: state => state.items
    }),
  },
  mounted () {
    this.setTitle([
      {
        title: 'Организации',
        url: '/companies'
      },
      {
        title: 'Добавить'
      }
    ])
  },
  methods: {
    createCompany () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      companiesAPI.createItem(this.company)
        .then(company => {
          this.isCreating = false
          this.$router.push(`/companies/${company.model.id}`)
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    handleData ({ data }) {
      Object.assign(this.company, { ...data})
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
<style scoped>
  .page-company {
    padding-top: 2.5rem;
    padding-bottom: 8rem;
  }
  h2 {
    margin-bottom: 3rem;
  }
  .label-col {
    width: 25%;
  }
</style>
