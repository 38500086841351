<template>
  <div class="page">
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#profile">Профиль</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#notifications">Уведомления</a>
      </li>
    </ul>
    <div class="tab-content">
      <div id="profile" class="tab-pane fade show active">
        <div class="row">
          <div class="col">
            <HeaderTitle />
          </div>
          <div class="col">
            <ActionBar
              v-if="authedUser.roles['Администратор']"
              :isEditing="isEditing"
              @changeAction="changeUser"
              @cancelAction="cancelUserChanges"
              @deleteAction="deleteUser"
              @saveAction="updateUser"
            />
          </div>
        </div>
        <Edit v-if="isEditing" v-model="user" />
        <Show v-else :user="authedUser.id == userId ? authedUser : user" />
        <div v-if="user && !isEditing">
          <button
            v-if="authedUser.id == userId"
            class="btn btn-outline-danger"
            @click="newPassword(user)"
          >
            Установить новый пароль
          </button>
          <button
            v-else-if="authedUser.roles['Администратор']"
            class="btn btn-outline-danger"
            @click="changePassword(user)"
          >
            Задать пароль
          </button>
        </div>
      </div>
      <div id="notifications" class="tab-pane fade p-3">
        <hr>
        <div class="my-3">Уведомления на электронную почту при истечении срока</div>
        <hr>
        <div class="mx-2">
          <div>
            <input v-model="settings.notifications.to" class="my-1" type="checkbox"> ТО
          </div>
          <div>
            <input v-model="settings.notifications.osago" class="my-1" type="checkbox"> ОСАГО
          </div>
          <div>
            <input v-model="settings.notifications.diagnosticCard" class="my-1" type="checkbox"> Диагностическая карта
          </div>
        </div>
        <hr>
        <div class="my-3">Уведомления по геозонам</div>
        <hr>
        <div class="mx-2">
          <div>
            <input v-model="settings.notifications.areaEntry" class="my-1" type="checkbox"> Срабатывание при въезде
          </div>
          <div>
            <input v-model="settings.notifications.areaDeparture" class="my-1" type="checkbox"> Срабатывание при выезде
          </div>
        </div>
        <hr>
        <div class="my-3">Уведомления о штрафах </div>
        <hr>
        <div class="mx-2">
          <div>
            <input v-model="settings.notifications.fines" class="my-1" type="checkbox"> штрафы ГИБДД
          </div>
        </div>
        <button class="btn btn-primary mt-3 float-right" @click="saveNotificationSettings">Сохранить</button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import userApi from '@/api/user'
import deepAssign from 'assign-deep'
import { mapActions, mapState } from 'vuex'
import Edit from './Edit'
import Show from './Show'

export default {
  name: 'UserPage',
  components: {
    Edit,
    Show
  },
  props: {
    userId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isUpdating: false,
      prevUser: null,
      user: {},
      isEditing: false,
      settings: {
        notifications: {
          to: false,
          osago: false,
          diagnosticCard: false,
          areaEntry: false,
          areaDeparture: false,
          fines: false
        }
      }
    }
  },
  computed: {
    ...mapState('taxi/user', {
      authedUser: state => state.items.find(user => user.id === state.authedId)
    })
  },
  watch: {
    userId: {
      immediate: true,
      handler () {
        this.loadAuthedUser()
        this.loadData()
      }
    }
  },
  methods: {
    newPassword (user) {
      if (!user.email) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Необходимо задать email пользователю'
        })
        return
      }
      userApi.resetPassword({
        email: user.email
      })
        .then(() => global.Swal.fire('Инструкция по восстановлению пароля выслана на почту'))
        .catch(error => {
          global.Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            text: error
          })
          throw error
        })
    },
    updateUser () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      userApi.setUserRoles({
        id: this.user.id,
        roles: this.user.selectedRoles
      })
        .then(() => {
          userApi.updateItem(this.user)
            .then(user => {
              this.isEditing = false
              this.isUpdating = false
              this.user = user.model
              this.user.selectedRoles = Object.keys(this.user.roles)
              this.user.accessibleRoles = Object.values(this.user.accessibleRoles)
              if (this.user.id === this.authedUser.id) {
                this.loadAuthedUser()
              }
            })
            .catch(err => {
              console.error(err)
              this.isUpdating = false
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    loadData () {
      userApi.getItem({ id: this.userId })
        .then(user => {
          user.selectedRoles = Object.keys(user.roles)
          user.accessibleRoles = Object.values(user.accessibleRoles)
          const title = (user.last_name || '-') + ' ' + (user.first_name || '-') + ' ' + (user.middle_name || '-')
          this.user = user
          if (user.settings) {
            this.settings = user.settings
          }
          this.setTitle([
            {
              title: 'Пользователи',
              url: '/users'
            }, {
              title: title === '- - -' ? user.username : title
            }
          ])
        })
    },
    changeUser () {
      this.prevUser = deepAssign({}, this.user)
      this.isEditing = true
    },
    cancelUserChanges () {
      this.user = this.prevUser
      this.isEditing = false
    },
    deleteUser () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить пользователя?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          userApi.removeItem(this.userId)
            .then(() => {
              this.$router.push('/users')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    /**
     * Изменить пароль пользователя от имени Администратора
     */
    changePassword () {
      Swal.fire({
        title: 'Введите новый пароль',
        input: 'password',
        inputValidator: (value) => {
          if (!value) {
            return 'Пароль не может быть пустым!'
          }
        }
      }).then(password => {
        if (!password.dismiss) {
          userApi.changePassword({id: this.userId, password: password.value})
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    saveNotificationSettings () {
      this.user.settings = this.settings
      this.updateUser()
    },
    ...mapActions('taxi/user', [
      'loadAuthedUser'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
