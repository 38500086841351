<template>
  <div class="page">
    <HeaderTitle />
    <div class="d-flex justify-content-end">
      <router-link to="/maintenance/service-works" class="btn btn-secondary mr-2">Отменить</router-link>
      <button class="btn btn-primary" @click="updateRecord">Сохранить</button>
    </div>
    <div v-if="work" class="row">
      <div class="col-12">
        <label>Название</label>
        <input
          v-model="work.name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Интервал обслуживания</label>
        <input
          v-model="work.interval"
          type="text"
          class="form-control"
          placeholder
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label v-if="work.children.length">Вложенные работы</label>
        <div class="draggable-cards-wrapper">
          <DraggableTree
            class="tree4"
            :data="work.children"
            draggable=""
            cross-tree
            :indent="30"
            :space="0"
            @change="changeItems"
          >
            <div slot-scope="{data, store}">
              <template v-if="!data.isDragPlaceHolder">
                <div class="row text-dark py-2">
                  <div class="col">
                    <font-awesome-icon
                      v-if="data.children && data.children.length"
                      :icon="data.open ? 'chevron-up' : 'chevron-down'"
                      class="mx-2"
                      :style="{
                        color: 'gray',
                        'margin-left': 'auto',
                        cursor: 'pointer'
                      }"
                      @click="store.toggleOpen(data)"
                    />
                    {{ data.name }}
                    <span class="badge" style="line-height: unset">
                      {{ data.interval ? `Каждые ${data.interval} км.` : "" }}
                    </span>
                  </div>
                  <div class="col-auto">
                    <font-awesome-icon
                      icon="plus"
                      :style="{
                        color: 'gray',
                        'margin-left': 'auto',
                        cursor: 'pointer'
                      }"
                      @click="$router.push(`/maintenance/service-works/create/${data.id}`)"
                    />
                    <font-awesome-icon
                      icon="pen"
                      :style="{
                        color: 'gray',
                        'margin-left': '2.0rem',
                        cursor: 'pointer'
                      }"
                      @click="$router.push(`/maintenance/service-works/update/${data.id}`)"
                    />
                    <font-awesome-icon
                      icon="trash-alt"
                      :style="{
                        color: 'gray',
                        'margin-left': '1.5rem',
                        cursor: 'pointer'
                      }"
                      @click="removeItem(data.id)"
                    />
                  </div>
                </div>
              </template>
            </div>
          </DraggableTree>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapState, mapActions } from 'vuex'
import serviceWorkApi from '../../api/serviceWork'

export default {
  name: 'UpdateServiceWork',
  props: {
    id: {
      type: String,
      default: '0'
    }
  },
  data () {
    return {
      isUpdating: false,
      serviceWork: null
    }
  },
  computed: {
    ...mapState('maintenance/serviceWork', {
      workState (state) {
        for (let item of state.items) {
          let search = this.findItemById(item)
          if (search) {
            return search
          }
        }
        return null
      },
      isLoading: state => state.isLoading
    }),
    work: {
      get () {
        if (!this.serviceWork) {
          return JSON.parse(JSON.stringify(this.workState ? this.workState : null))
        }
        return this.serviceWork
      },
      set (value) {
        this.serviceWork = value
      }
    },
    dragOptions () {
      return {
        animation: 0,
        group: 'description',
        disabled: !this.editable,
        ghostClass: 'ghost'
      }
    }
  },
  beforeMount () {
    this.loadItems({ sort: 'sort' })
    this.setTitle([
      {
        title: 'Тех. обслуживание',
        url: '/maintenance'
      }, {
        title: 'Работы',
        url: '/maintenance/service-works'
      }, {
        title: 'Изменить'
      }
    ])
  },
  methods: {
    updateRecord () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      serviceWorkApi.updateItem({
        id: this.work.id,
        name: this.work.name,
        interval: this.work.interval,
        created_at: this.work.created_at,
        archived_at: this.work.archived_at,
        sort: this.work.sort
      })
        .then(() => {
          this.isUpdating = false
          this.$router.push('/maintenance/service-works')
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('maintenance/serviceWork', ['loadItem']),
    changeItems (node) {
      node.parent.children.forEach((v, i) => {
        this.updateItem({
          id: v.id,
          name: v.name,
          parent_work_id: v.parent.id ? v.parent.id : null,
          interval: v.interval,
          created_at: v.created_at,
          archived_at: v.archived_at,
          sort: i
        })
      })
    },
    removeItem (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите архивировать работу?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Архивировать'
      }).then(event => {
        if (event.value) {
          serviceWorkApi.archivateItem(id)
            .then(() => {
              this.loadItems({ sort: 'sort' })
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    findItemById (item) {
      if (item.id == this.id) {
        return item
      }
      if (item.children) {
        for (let child of item.children) {
          let find = this.findItemById(child)
          if (find) {
            return find
          }
        }
      }
      return null
    },
    ...mapActions('maintenance/serviceWork', [
      'getWorkById',
      'loadItems',
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
