<template>
  <div class="page page-drivers">
    <HeaderTitle />
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#data">Основная информация</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#documents">Документы</a>
      </li>
      <li v-if="hasPermission('/rent')" class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#rent">Аренда</a>
      </li>
      <li v-if="hasFinesModule && hasPermission('/fines')" class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#fines">Штрафы ГИБДД</a>
      </li>
      <li v-if="hasAccidentModule && hasPermission('/accidents')" class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#carAccident">ДТП</a>
      </li>
      <li v-if="hasFuelModule && hasPermission('/fueling')" class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#waybills">Путевые листы</a>
      </li>
      <li v-if="hasPermission('/characteristic')" class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#characteristic">Характеристика</a>
      </li>
    </ul>
    <div class="tab-content">
      <div id="data" class="tab-pane fade show active">
        <ActionBar
          :isEditing="isEditing"
          @changeAction="changeDriver"
          @cancelAction="cancelDriverChanges"
          @deleteAction="deleteDriver"
          @saveAction="updateDriver"
        />
        <br>
        <DriverEdit
          v-if="isEditing"
          class="driver-view-edit"
          :driver="driver"
          :users="users"
        />
        <DriverView
          v-else
          class="driver-view-edit"
          :driver="driver"
        />
      </div>
      <div id="documents" class="tab-pane fade p-3">
        <div class="driverDocs">
          <h5>
            Документы водителя<router-link :to="`/drivers/${id}/docs/create`" class="btn btn-primary float-right">
              <font-awesome-icon class="text-white" icon="plus" />
              Добавить
            </router-link>
          </h5>
          <br>
          <LightBox
            v-if="showDetailImage"
            :scan="currentScan"
            @actionClose="showDetailImage = false"
          />
          <div v-if="driver.documents">
            <Document
              v-for="document of driver.documents"
              :key="document.id"
              :document="document"
              @actionDetailImage="detailImage(currentScan)"
              @actionDelte="deleteDocument(document.id)"
              @actionUpdate="$router.push(`/drivers/${id}/docs/${document.id}/update`)"
            />
          </div>
        </div>
      </div>
      <div v-if="hasPermission('/rent')" id="rent" class="tab-pane fade p-3">
        <div v-if="activeRent">
          <h5>Активная аренда</h5>
          <RentShowForm :contract="activeRent" :companies="companies" class="mt-4" />
          <hr>
          <h5>Сканы договора</h5>
          <ScansGallery :documentId="activeRent.id" />
          <PhotoControl :contract="activeRent" />
        </div>
        <div v-if="closedRents.length">
          <h5>Завершенные аренды</h5>
          <ClosedRents :closed-contracts="closedRents" />
        </div>
      </div>
      <div v-if="hasFinesModule && hasPermission('/fines')" id="fines" class="tab-pane fade p-3">
        <FinesDriverShow v-if="driver.fines && driver.fines.length" :driver="driver" />
      </div>
      <div v-if="hasAccidentModule && hasPermission('/accidents')" id="carAccident" class="tab-pane fade p-3">
        <AccidentListView :driver-id="driver.id" />
      </div>
      <div v-if="hasFuelModule && hasPermission('/fueling')" id="waybills" class="tab-pane fade p-3">
        <!--        <FuelStatistic v-if="car.id" :car-model="car" />-->
      </div>
      <div id="characteristic" class="tab-pane fade p-3">
        <CharacteristicPage :driver-id="driver.id" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import Swal from 'sweetalert2'
import deepAssign from 'assign-deep'
import DocumentsAPI from '@/api/driver-documents'
import CompaniesAPI from '@/api/companies'
import DriversAPI from '@/api/drivers'
import userApi from '@/api/user'
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import DriverEdit from './Edit'
import DriverView from './View'
import ScansGallery from '../../components/common/scans/ScansGallery'
import ClosedRents from '../../components/contracts/rent/ClosedRents'
import FinesDriverShow from '../../modules/fines/components/FinesDriverShow'
import PhotoControl from '../../components/contracts/rent/PhotoControl'
import AccidentListView from '../../modules/accidents/components/AccidentListView'
import CharacteristicPage from '../characteristic/Page'
// import FuelStatistic from "../../modules/fuel/components/FuelStatistics";

export default {
  name: 'DriverPage',
  components: {
    AccidentListView,
    PhotoControl,
    FinesDriverShow,
    DriverEdit,
    DriverView,
    // FuelStatistic,
    ClosedRents,
    ScansGallery,
    CharacteristicPage
  },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isUpdating: false,
      prevDriver: null,
      driver: {
        id: this.id,
        last_name: '',
        middle_name: '',
        first_name: '',
        phone: '',
        documents: [],
        characteristics: []
      },
      isEditing: false,
      currentScan: null,
      showDetailImage: false,
      companies: [],
      permissions: []
    }
  },
  computed: {
    ...mapState('taxi/user', {
      users: state => state.items,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading,
      authedUser: state => state.items.find(user => user.id === state.authedId)
    }),
    ...mapGetters('taxi/contracts', [
      'activeContracts',
      'closedContracts',
      'draftContracts'
    ]),
    activeRent () {
      return this.activeContracts.find(contract => contract.driver_id == this.id)
    },
    closedRents () {
      return this.closedContracts.filter(contract => contract.driver_id == this.id)
    },
    hasFuelModule () {
      return process.env.VUE_APP_MODULE_FUEL === 'true'
    },
    hasFinesModule () {
      return process.env.VUE_APP_MODULE_FINES === 'true'
    },
    hasAccidentModule () {
      return process.env.VUE_APP_MODULE_ACCIDENT === 'true'
    },
    hasPermission () {
      return (link) => {
        return (this.permissions && (this.permissions[link.url] || this.permissions['/*']))
      }
    }
  },
  watch: {
    driver: {
      handler (driver, driverOld) {
        if (driver?.id && driver?.id !== driverOld?.id) {
          this.setTitle([
            {
              title: 'Водители',
              url: '/drivers'
            }, {
              title: driver.last_name + ' ' + driver.first_name + ' ' + driver.middle_name
            }
          ])
        }
      },
      immediate: true
    }
  },
  beforeMount() {
    userApi.getFrontendPermission()
      .then(permissions => {
        this.permissions = permissions
        if (this.hasPermission('/rent')) {
          this.loadActiveRents()
          this.loadClosedRents()
        }
      })
  },
  mounted () {
    this.loadData()
    this.loadItems()
    this.loadAuthedUser()
    CompaniesAPI.getItems().promise.then(companies => {
      this.companies = companies.items
    })
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    updateDriver () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      DriversAPI.updateItem(this.driver)
        .then(driver => {
          this.driver = { ...this.driver, ...driver.model }
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    loadData () {
      DriversAPI.getItem({
        id: this.id,
        queryWith: ['documents', 'user', 'fines', 'activeFines', 'characteristics'],
      })
        .then(response => {
          this.driver = response
          this.loadScans()
        })
    },
    loadScans () {
      this.clearItems()
      this.setModel('DriverDocument')
      this.driver.documents.forEach(element => {
        DocumentsAPI.getScans(element.id).then(result => {
          element.scans = result
          this.pushItems({ scans: result, documentId: element.id })
        })
          .catch(err => {
            console.error(err)
            Swal.fire({
              icon: 'error',
              title: 'Ошибка сервера',
              text: err.message
            })
          })
      })
    },
    deleteDocument (documentId) {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить документ?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          DocumentsAPI.removeItem(documentId)
            .then(() => {
              this.loadData()
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    changeDriver () {
      this.prevDriver = deepAssign({}, this.driver)
      this.isEditing = true
    },
    cancelDriverChanges () {
      this.driver = this.prevDriver
      this.isEditing = false
    },
    deleteDriver () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить водителя?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          DriversAPI.removeItem(this.driver.id)
            .then(() => {
              this.$router.push('/drivers')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    ...mapActions('taxi', ['setTitle']),
    ...mapMutations('taxi/scans', [
      'pushItems',
      'clearItems',
      'setModel'
    ]),
    ...mapActions('taxi/user', [
      'loadItems',
      'abortLoading',
      'loadAuthedUser'
    ]),
    ...mapActions('taxi/contracts', [
      'loadActiveRents',
      'loadClosedRents'
    ]),
  }
}
</script>

<style scoped>
  .page-drivers {
    width: 98%;
    padding-bottom: 4rem;
    padding-top: 2.5rem;
  }
</style>
