/* eslint-disable camelcase */

import serviceCar from './serviceCar'
import serviceWork from './serviceWork'

export default {
  namespaced: true,
  modules: {
    serviceCar,
    serviceWork
  }
}
