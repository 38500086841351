<template>
  <div>
    <table class="table table-hover w-100 mt-3 d-none d-md-table">
      <TableSort class="sticky-1" :sorts="sorts" @sortsUpdate="loadWithSort" />
      <tbody>
        <tr v-for="accident of accidents" :key="accident.id" @click="$router.push(`/accidents/${accident.id}`)">
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ accident.car.brand }} {{ accident.car.model }} {{ accident.car.registration_plate }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ getDriver(accident) }}</TextHighlight>
          </td>
          <td>
            <TextHighlight v-if="accident.date" class="highlighter" :queries="searchText">{{ accident.date | formatDateTime }}</TextHighlight>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-for="accident of accidents" :key="accident.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
      <table v-if="accident.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Автомобиль</td>
            <td style="border-top: none">
              <TextHighlight class="highlighter" :queries="searchText">{{ accident.car.brand }} {{ accident.car.model }} {{ accident.car.registration_plate }}</TextHighlight>
            </td>
          </tr>
          <tr v-if="accident.driver">
            <td>Водитель</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ getDriver(accident) }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Дата ДТП</td>
            <td>
              <TextHighlight v-if="accident.date" :queries="searchText">{{ accident.date | formatDateTime }}</TextHighlight>
            </td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="{ name: 'AccidentPage', params: { accidentId: String(accident.id) } }"
        class="btn btn-primary btn-md create-fines ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { djsMixin } from '@/mixins/djsMixin'

export default {
  name: 'AccidentListView',
  mixins: [djsMixin],
  props: {
    carId: {
      type: Number,
      default: () => null
    },
    driverId: {
      type: Number,
      default: () => null
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'Автомобиль',
          storeName: 'fines-car-brand',
          values: ['', 'brand', '-brand'],
          order: 0
        },
        {
          name: 'Водитель',
          storeName: 'fines-driver',
          values: ['', 'brand', '-brand'],
          order: 1
        },
        {
          name: 'Дата ДТП',
          storeName: 'date',
          values: ['', 'date', '-date'],
          order: 2
        }
      ],
      interval: null
    }
  },
  computed: {
    ...mapState('accident/accident', {
      accidentsItems: state => state.items,
      pageCount: state => state.pageCount,
      searchText: state => state.searchText,
      isLoading: state => state.isLoading
    }),
    accidents () {
      return this.accidentsItems.filter(accident => {
        if (this.carId && accident.car.id != this.carId) {
          return false
        }
        return !(this.driverId && accident.driver.id != this.driverId)

      })
    },
    getDriver () {
      return (accident) => {
        if (!accident.driver) {
          return ''
        }
        return accident.driver.last_name + ' ' + accident.driver.first_name + ' ' + accident.driver.middle_name
      }
    },
  },
  mounted () {
    this.loadItems({ page: Number(this.page), expand: ['car', 'driver'] })
  },
  beforeDestroy () {
    this.abortLoading()
    clearTimeout(this.interval)
    this.interval = null
  },
  methods: {
    ...mapActions('accident/accident', [
      'loadItems',
      'setSearchText',
      'setSearch',
      'abortLoading',
      'setSorts'
    ]),
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page), expand: ['car', 'driver']})
    },
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding-top: 1rem;
  height: 100%;
  overflow-y: auto;
}
@media screen and (max-width: 1199px) {
  .page {
    padding: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .page {
    padding: 0.5rem;
  }
}
.highlighter ::v-deep .text__highlight {
  border-radius: 0px;
  padding: 0.2em 0 0.2em 0;
  background: #fff2ae;
}
.fixed-search {
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 10px;
  background: white;
}
.alert-state {
  margin: 0;
  width: fit-content;
  height: fit-content;
  line-height: 30px;
  padding: 0 15px;
  min-width: 10rem;
  text-align: center;
  border-radius: 15px;
  border: none;
}
.alert-danger {
  color: #BF543B;
  background-color: rgba(191, 84, 59, 0.15);
}
.alert-success {
  color: #92BF4E;
  background-color: rgba(146, 191, 78, 0.15);
}
</style>
