import APITemplate from '@/api/APITemplate'
import Swal from 'sweetalert2'

const url = '/api/stock'

const template = APITemplate(url)
export default {
  ...template,
  exchange (stock, exchangeData) {
    if (!stock) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Предмет не выбран!'
        })
        return false
      }
      exchangeData.quantity = parseFloat(exchangeData.quantity)
      if (!exchangeData.quantity || exchangeData.quantity <= 0) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Не введено количество товара!'
        })
        return false
      }

      if (exchangeData.quantity > stock.quantity) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Введеное количество товара превышает оставшееся на складе!'
        })
        return false
      }
      if (!exchangeData.facilityTo) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Объект для начисления не выбран!'
        })
        return false
      }
      if (!exchangeData.facilityFrom) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Объект для списания не выбран!'
        })
        return false
      }
      if (exchangeData.facilityFrom.id === exchangeData.facilityTo.id) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Объект для списания и объект для получения не должны совпадать!'
        })
        return false
      }
    const request = '/api/stock/exchange'
    return fetch(request, {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers,
      body: JSON.stringify(exchangeData)
    }).then(response => {
      if (response.status === 500) {
        return response.json().then((data) => {
          throw new Error(data)
        })
      }
      return true
    }).catch(error => {
      Swal.fire({
        icon: 'error',
        title: 'Ошибка',
        html: error.message
      })
      return false
    })
  }
}
