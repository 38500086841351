<template>
  <div class="page">
    <HeaderTitle />
    <div class="row">
      <div class="col-lg-3 mb-2">
        <label>Марка</label>
        <LookupInput
          v-if="cars"
          v-model="car.brand"
          :options="Object.keys(brandAndModels)"
        />
        <input
          v-else
          v-model="car.brand"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
      <div class="col-lg-4 mb-2">
        <label>Модель</label>
        <LookupInput
          v-if="cars"
          v-model="car.model"
          :options="brandAndModels[car.brand] || []"
        />
        <input
          v-else
          v-model="car.model"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 mb-2">
        <label>Рег. номер</label>
        <div class="row w-100 m-0">
          <input
            v-model="car.reg_plate_number"
            type="text"
            class="form-control col-8"
            placeholder="Номер"
            maxlength="6"
            required
          >
          <input
            v-model="car.reg_plate_region"
            type="text"
            class="form-control col-4"
            placeholder="Регион"
            maxlength="3"
            required
          >
        </div>
      </div>
      <div class="col-lg-2 mb-6">
        <label>Год выпуска</label>
        <input
          v-model="car.manufacturing_year"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
      <div class="col-lg-2 mb-6">
        <label>Код радио</label>
        <input
          v-model="car.radio_code"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <h5 class="py-1">Устройство наблюдения (необязательно)</h5>
    <div class="row">
      <div class="col-lg-6 mb-2">
        <label>Название устройства наблюдения</label>
        <input
          v-model="device.trackingDevice.name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-2">
        <label>IMEI</label>
        <input
          v-model="device.trackingDevice.imei"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-2">
        <label>Тип прошивки</label>
        <VSelect
          v-model="device.trackingDevice.firmware_id"
          :options="device.firmwares"
          :reduce="firmware => firmware.id"
          label="name"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 mb-2">
        <label>Номер телефона</label>
        <IMaskComponent
          v-model="device.trackingDevice.phone"
          type="text"
          class="form-control"
          :mask="'[+7 (000) 000-00-00]'"
          placeholder="+7 (999) 999-99-99"
          required
        />
      </div>
    </div>
    <hr class="mb-4">
    <button class="btn btn-primary mr-2" @click="create()">Добавить</button>
    <router-link to="/cars" class="btn btn-secondary">Отменить</router-link>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions, mapState } from 'vuex'
import carsAPI from '@/api/cars'
import trackDeviceApi from '@/api/trackDevice'
import LookupInput from '../../components/LookupInput'

export default {
  name: 'CreateCar',
  components: { LookupInput },
  data () {
    return {
      isCreating: false,
      car: {
        brand: '',
        model: '',
        reg_plate_number: '',
        reg_plate_region: '',
        manufacturing_year: '',
        data: 'not fillable now',
        tracking_device_id: null
      },
      device: {
        firmwares: [],
        trackingDevice: {
          imei: null,
          name: null,
          firmware_id: null,
          phone: null
        }
      }
    }
  },
  computed: {
    ...mapState('taxi/cars', {
      cars: state => state.items
    }),
    brandAndModels () {
      const usedBrands = []
      this.cars.forEach(car => {
        if (!usedBrands[car.brand]) {
          usedBrands[car.brand] = []
        }
        if (!usedBrands[car.brand].includes(car.model)) {
          usedBrands[car.brand].push(car.model)
        }
      })
      return usedBrands
    }
  },
  watch: {
    'device.trackingDevice.imei': {
      handler () {
        this.generateNameTrackingDevice()
      }
    },
    'device.trackingDevice.firmware_id': {
      handler () {
        this.generateNameTrackingDevice()
      }
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'Автомобили',
        url: '/cars'
      },
      {
        title: 'Добавить'
      }
    ])
    trackDeviceApi.getFirmwares().then(response => {
      this.device.firmwares = response
    })
    this.loadItems()
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('taxi/cars', [
      'createItem'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('taxi/cars', [
      'abortLoading',
      'loadItems'
    ]),
    generateNameTrackingDevice () {
      this.device.trackingDevice.name = ''
      if (this.device.trackingDevice?.imei || this.device.trackingDevice.firmware_id) {
        if (this.device.trackingDevice.firmware_id) {
          this.device.trackingDevice.name += this.device.firmwares[this.device.trackingDevice.firmware_id - 1].name
        }
        if (this.device.trackingDevice?.imei && this.device.trackingDevice.firmware_id) {
          this.device.trackingDevice.name += '-'
        }
        if (this.device.trackingDevice?.imei) {
          this.device.trackingDevice.name += this.device.trackingDevice.imei
        }
      }
    },
    create () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      if (this.device.trackingDevice.name || this.device.trackingDevice.imei || this.device.trackingDevice.firmware_id) {
        trackDeviceApi.createItem(this.device.trackingDevice)
          .then(trackDevice => {
            this.isCreating = false
            this.car.tracking_device_id = trackDevice.model.id
            this.createCar()
          })
          .catch(err => {
            console.error(err)
            this.isCreating = false
            Swal.fire({
              icon: 'error',
              title: 'Ошибка',
              html: err.message
            })
          })
      } else {
        this.createCar()
      }
    },
    createCar () {
      if (!carsAPI.validateRegistrationPlate(this.car.reg_plate_region, this.car.reg_plate_number)) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Регистрационный номер не соответствует формату'
        })
        this.isCreating = false
        return
      }
      carsAPI.createItem(this.car)
        .then(() => {
          this.isCreating = false
          this.$router.push('/cars')
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    }
  }
}
</script>
