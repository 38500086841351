<template>
  <div class="page">
    <HeaderTitle />
    <ConfigEdit
      :config="config"
      :configComponentDefinitions="configComponentDefinitions"
    />
    <button class="btn btn-primary float-right" @click="createContract()">Добавить</button>
    <router-link to="/components" class="float-right mr-2">
      <button class="btn btn-secondary ml-2">Отменить</button>
    </router-link>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import ConfigsAPI from '../../api/configs'
import {mapActions, mapState} from 'vuex'
import ConfigEdit from './ConfigEdit'

export default {
  name: 'CreateCreate',
  components: {ConfigEdit},
  data () {
    return {
      isCreating: false,
      config: {}
    }
  },
  computed: {
    ...mapState('configs/configComponentDefinition', {
      configComponentDefinitions: state => state.items,
    }),
  },
  mounted () {
    this.loadItems()
    this.setTitle([
      {
        title: 'Настройки',
        url: '/configs'
      },
      {
        title: 'Добавить'
      }
    ])
  },
  methods: {
    createContract () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      ConfigsAPI.createItem(this.config)
        .then(config => {
          this.isCreating = false
          this.$router.push(`/configs/${config.model.id}`)
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('configs/configComponentDefinition', [
      'loadItems'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
