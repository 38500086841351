import AccidentList from './views/AccidentList'
import AccidentCreate from './views/AccidentCreate'
import AccidentPage from './views/AccidentPage'

export default [
  {
    path: '/accidents',
    component: AccidentList,
    name: 'AccidentList',
    meta: {
      title: 'ДТП',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/accidents/create',
    component: AccidentCreate,
    name: 'AccidentCreate',
    props: true,
    meta: {
      title: 'Добавление ДТП',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/accidents/:accidentId',
    component: AccidentPage,
    name: 'AccidentPage',
    props: true,
    meta: {
      title: 'ДТП',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
