<template>
  <div class="row g-3 my-3">
    <form
      ref="form"
      action="/file/add"
      class="upload-form"
      method="post"
      enctype="multipart/form-data"
    >
      <input name="type" :value="type" hidden>
      <input
        v-if="documentId"
        name="id"
        :value="documentId"
        hidden
      >
      <input
        :id="'uploadfile' + type"
        ref="fileInput"
        type="file"
        :accept="'image/*'"
        multiple
        hidden
        @change="requestUploadFile"
      >
    </form>
    <div
      class="drop"
      :class="getClasses"
      @dragover.prevent="dragOver"
      @dragleave.prevent="dragLeave"
      @drop.prevent="drop($event)"
    >
      <div v-if="!images.length">
        <label :for="'uploadfile' + type">
          <div class="btn btn-primary">Загрузите изображение</div>
        </label>
        <div class="text-center">или перетащите сюда</div>
      </div>
      <div v-else class="text-center">
        <label :for="'uploadfile' + type">
          <div class="btn btn-primary">Загрузите изображение</div>
        </label>
        <div class="row mt-3 justify-content-md-center">
          <ImageView
            v-for="(photo, key) in images"
            :key="key"
            :src="photo.id"
            :edit-mode="true"
            @deleteFile="(id) => $emit('deleteFile', id)"
            @imageView="imageView(key)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ImageView from '@/components/ImageView'
import imageViewer from 'vue-image-viewer'
Vue.use(imageViewer)
export default Vue.extend({
  name: 'DropImages',
  components: { ImageView },
  props: {
    images: {
      type: Array,
      default: Array
    },
    type: {
      type: String,
      default: null
    },
    documentId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isDragging: false
    }
  },
  computed: {
    getClasses () {
      return { isDragging: this.isDragging }
    }
  },
  watch: {
    images: {
      handler () {
        const images = this.images.map((image) => {
          image.url = '/api/file/link?id=' + image.id
          return image
        })
        this.$imageViewer.images(images)
      },
      immediate: true,
    }
  },
  methods: {
    imageView(index) {
      this.$imageViewer.index(index)
      this.$imageViewer.show()
    },
    dragOver () {
      this.isDragging = true
    },
    dragLeave () {
      this.isDragging = false
    },
    async drop (e) {
      const files = [...e.dataTransfer.files]
      const images = files.filter(file => file.type.indexOf('image/') >= 0)
      this.$emit('addFiles', images, this.$refs.form)
      this.isDragging = false
    },
    requestUploadFile () {
      const src = this.$el.querySelector('#uploadfile' + this.type)
      this.drop({ dataTransfer: src })
    },
    getBase64 (file) {
      const reader = new FileReader()
      return new Promise(resolve => {
        reader.onload = ev => {
          if (ev.target) {
            resolve(ev.target.result)
          }
        }
        reader.readAsDataURL(file)
      })
    }
  }
})
</script>

<style scoped>
.drop{
  margin: auto;
  width: 100%;
  background-color: transparent;
  border: 3px dashed rgb(152, 200, 255);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  transition: background-color .2s ease-in-out;
  font-family: sans-serif;
  overflow: hidden;
  position: relative;
}
.isDragging{
  background-color: #6facff;
  border-color: #1585ff;
}
#uploadfile{
  display: none;
}
</style>
