<template>
  <table class="table">
    <tbody>
      <tr>
        <td class="table-col">Кем выдан</td>
        <td>{{ documentData.authority }}</td>
      </tr>
      <tr>
        <td>Серия/ номер</td>
        <td>{{ documentData.number }}</td>
      </tr>
      <tr>
        <td>Код подразреления</td>
        <td>{{ documentData.code }}</td>
      </tr>
      <tr>
        <td>Дата рождения</td>
        <td>{{ documentData.birth_date | diffForHumans }}</td>
      </tr>
      <tr>
        <td>Зарегистрирован по адресу</td>
        <td>{{ documentData.registration }}</td>
      </tr>
      <tr>
        <td>Место рождения</td>
        <td>{{ documentData.birth_place }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { djsMixin } from '@/mixins/djsMixin'

export default {
  name: 'DocumentPassportView',
  mixins: [djsMixin],
  props: {
    documentData: {
      type: Object,
      default: null
    }
  },
}
</script>

<style scoped>
  .table-col {
    width: 50%;
  }
</style>
