import PassportEdit from './passport/Edit'
import PassportView from './passport/View'
import DrivingLicenceView from './drivingLicence/View'
import DrivingLicenceEdit from './drivingLicence/Edit'

export default {
  passport: {
    title: 'Паспорт',
    edit: {
      name: 'DocumentPassportEdit',
      component: PassportEdit
    },
    view: {
      name: 'DocumentPassportView',
      component: PassportView
    },
    tabs: {
      title: 'Паспорт',
      scansTittle: 'Сканы паспорта'
    }
  },
  driving_licence: {
    title: 'Водительское удостоверение',
    edit: {
      name: 'DocumentDrivingLicenceEdit',
      component: DrivingLicenceEdit
    },
    view: {
      name: 'DocumentDrivingLicenceView',
      component: DrivingLicenceView
    },
    tabs: {
      title: 'Водительское удостоверение',
      scansTittle: 'Сканы в/у'
    }
  }
}
