<template>
  <div class="show-form-track-devices">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Название</td>
          <td>{{ trackDevice.name }}</td>
        </tr>
        <tr>
          <td class="label-col">IMEI</td>
          <td>{{ trackDevice.imei }}</td>
        </tr>
        <tr>
          <td class="label-col">Прошивка</td>
          <td>{{ trackDevice.firmware ? trackDevice.firmware.name : '' }}</td>
        </tr>
        <tr>
          <td class="label-col">Номер телефона</td>
          <td>{{ trackDevice.phone }}</td>
        </tr>
        <tr v-if="trackDevice.notes">
          <td>Заметки</td>
          <td>
            {{ trackDevice.notes }}
          </td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4">
  </div>
</template>

<script>
export default {
  name: 'TrackDeviceView',
  props: {
    trackDevice: {
      type: Object,
      default: null
    },
    id: {
      type: Number,
      default: null
    }
  },
}
</script>

<style scoped>
  .show-form-track-devices {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
</style>
