<template>
  <div class="page">
    <HeaderTitle />
    <AccidentEdit
      :accident="accident"
      :cars="cars"
      :drivers="drivers"
    />
    <button class="btn btn-primary float-right" @click="createAccident()">Добавить</button>
    <router-link to="/components" class="float-right mr-2">
      <button class="btn btn-secondary ml-2">Отменить</button>
    </router-link>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import AccidentApi from '../api/accident'
import {mapActions, mapState} from 'vuex'
import AccidentEdit from './AccidentEdit'

export default {
  name: 'AccidentCreate',
  components: {AccidentEdit},
  data () {
    return {
      isCreating: false,
      accident: {}
    }
  },
  computed: {
    ...mapState('taxi/cars', {
      cars: state => state.items
    }),
    ...mapState('taxi/drivers', {
      drivers: state => state.items.filter(e => !e.archived_at)
    }),
  },
  mounted () {
    this.loadCars({
        expand: [
          'displayedName'
        ]
    }).then(cars => {
      cars.forEach(car => {
        car.displayedName = `${car.brand} ${car.model} ${car.registration_plate}`
      })
    })
    this.loadDrivers({
      expand: [
        'fullName'
      ]
    }).then(drivers => {
      drivers.forEach(driver => {
        driver.fullName = `${driver.last_name} ${driver.first_name} ${driver.middle_name}`
      })
    })
    this.setTitle([
      {
        title: 'ДТП',
        url: '/accidents'
      },
      {
        title: 'Добавить'
      }
    ])
  },
  methods: {
    createAccident () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      AccidentApi.createItem(this.accident)
        .then(accident => {
          this.isCreating = false
          this.$router.push(`/accidents/${accident.model.id}`)
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi/cars', {
      loadCars: 'loadItems'
    }),
    ...mapActions('taxi/drivers', {
      loadDrivers: 'loadItems'
    }),
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
