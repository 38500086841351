<template>
  <div class="view-companies">
    <table class="table">
      <tbody>
        <CommonView :company="company" :companies="companies" />
        <tr style="opacity: 0.6">
          <td colspan="2">
            Поля ОПФ
          </td>
        </tr>
        <tr>
          <td class="label-col">ОГРН</td>
          <td>{{ company.ogrn }}</td>
        </tr>
        <tr>
          <td class="label-col">КПП</td>
          <td>{{ company.kpp }}</td>
        </tr>
        <tr>
          <td class="label-col">В лице</td>
          <td>{{ company.manager }}</td>
        </tr>
        <tr>
          <td class="label-col">Должность</td>
          <td>{{ company.position }}</td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4">
  </div>
</template>

<script>

import CommonView from '../common/View'

export default {
  name: 'LlcView',
  components: {
    CommonView
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    companies: {
      type: Array,
      default: null
    },
  }
}
</script>

<style scoped>
  .view-companies {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
</style>
