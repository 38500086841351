<template>
  <div v-if="feedback" class="page">
    <HeaderTitle />
    <br>
    <table class="table borderless">
      <tbody>
        <tr>
          <td style="border-top: none">Статус</td>
          <td style="border-top: none"><FeedbackStatus :status="feedback.status" /></td>
        </tr>
        <tr>
          <td>Дата заявки</td>
          <td>{{ feedback.created_at | formatDateTime }}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{{ feedback.email }}</td>
        </tr>
        <tr>
          <td>Телефон</td>
          <td>{{ feedback.phone }}</td>
        </tr>
        <tr>
          <td>Сообщение</td>
          <td>{{ feedback.message }}</td>
        </tr>
      </tbody>
    </table>
    <br>
    <h5>Прикрепленные файлы</h5>
    <FileListViewer :files="feedback.files" :can-delete="false" @archivateFile="archivateFile" />
  </div>
</template>

<script>

import {mapActions} from 'vuex'
import FeedbackAPI from '../../api/feedback'
import FileListViewer from '../../../../components/FileListViewer'
import FilesAPI from '@/api/files'
import FeedbackStatus from '../../components/FeedbackStatus'

export default {
  name: 'FeedbackShow',
  components: {FeedbackStatus, FileListViewer},
  props: {
    feedbackId: {
      type: String,
      default: () => null
    }
  },
  data () {
    return {
      isEditing: false,
      feedback: null,
      isUpdating: false
    }
  },
  mounted () {
    this.loadData()
  },
  methods: {
    ...mapActions('configs/configs', [
      'abortLoading'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    loadData () {
      FeedbackAPI.getItem({
        id: this.feedbackId,
        queryWith: ['files']
      })
        .then(response => {
          this.feedback = response
          if (!response) {
            return false
          }
          this.feedback.files = this.feedback.files.filter(file => file.archived_at == null)
          this.setTitle([
            {
              title: 'Обратная связь',
              url: '/feedback'
            }
          ])
        })
    },
    archivateFile (id) {
      FilesAPI.archivateItem(id).then(() => {
        this.loadData()
      })
    },
  }
}
</script>
