import Vue from 'vue'
import ActionBar from './ActionBar'
import LightBox from './LightBox'
import Document from './Document'
import Mselect from './Mselect'
import TableSort from './TableSort'

Vue.component('Mselect', Mselect)
Vue.component('ActionBar', ActionBar)
Vue.component('LightBox', LightBox)
Vue.component('Document', Document)
Vue.component('TableSort', TableSort)
