<template>
  <div class="edit-form-track-devices">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Название</td>
          <td>
            <input
              v-model="trackDevice.name"
              type="text"
              class="form-control"
              placeholder
              required
            >
          </td>
        </tr>
        <tr>
          <td class="label-col">IMEI</td>
          <td>
            <input
              v-model="trackDevice.imei"
              type="text"
              class="form-control"
              placeholder
              required
            >
          </td>
        </tr>
        <tr>
          <td class="label-col">Прошивка</td>
          <td>
            <VSelect
              v-model="trackDevice.firmware_id"
              :options="firmwares"
              label="name"
              :reduce="firmware => firmware.id"
            />
          </td>
        </tr>
        <tr>
          <td class="label-col">Номер телефона</td>
          <td>
            <IMaskComponent
              v-model="trackDevice.phone"
              type="text"
              class="form-control"
              :mask="'[+7 (000) 000-00-00]'"
              placeholder="+7 (999) 999-99-99"
              required
            />
          </td>
        </tr>
        <tr>
          <td>Заметки</td>
          <td>
            <textarea
              v-model="trackDevice.notes"
              rows="5"
              type="text"
              class="form-control"
              placeholder
              required
            >></textarea>
          </td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4">
  </div>
</template>

<script>
export default {
  name: 'TrackDeviceEdit',
  props: {
    trackDevice: {
      type: Object,
      default: null
    },
    firmwares: {
      type: Object,
      default: null
    }
  },
}
</script>

<style scoped>
  .edit-form-track-devices {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
</style>
