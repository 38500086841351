/* eslint-disable camelcase */

import drivers from './taxi/drivers'
import cars from './taxi/cars'
import contracts from './taxi/contracts'
import scans from './taxi/scans'
import mapCars from './taxi/mapCars'
import companies from './taxi/companies'
import templates from './taxi/templates'
import user from './taxi/user'
import ioParameters from './taxi/ioParameters'
import trips from './taxi/trips'
import waybill from './taxi/waybill'
import trackDevice from './taxi/trackDevice'
import instruction from './taxi/instruction'
import characteristics from './taxi/characteristics'

const state = {
  title: '',
  modulesLoaded: []
}

const getters = {
}

const mutations = {
  setTitle (state, title) {
    state.title = title
    document.title = 'Stattika › ' + title.map(item => item.title).join(' › ')
  }
}

const actions = {
  setTitle ({ commit }, title) {
    commit('setTitle', title)
  }
}

export default {
  namespaced: true,
  modules: {
    drivers,
    cars,
    contracts,
    scans,
    user,
    mapCars,
    companies,
    templates,
    ioParameters,
    trips,
    waybill,
    trackDevice,
    instruction,
    characteristics
  },
  state,
  actions,
  mutations,
  getters
}
