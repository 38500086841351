<template>
  <div :class="`alert ${stateClass} alert-state text-center`" role="alert">
    {{ count }} ТО
  </div>
</template>

<script>
export default {
  name: 'ServiceCountState',
  props: {
    count: {
      type: Number,
      default: 0
    },
    startDateTime: {
      type: String,
      default: null
    },
    endDateTime: {
      type: String,
      default: null
    },
  },
  computed: {
    stateClass () {
      if (this.count > 0 && this.count < this.countDay / 30 * 3) {
        return 'alert-secondary'
      }
      if (this.count >= this.countDay / 30 * 5) {
        return 'alert-danger'
      }
      if (this.count >= this.countDay / 30 * 3) {
        return 'alert-warning'
      }

      return 'alert-success'
    },
    countDay () {
      const date1 = new Date(this.startDateTime)
      const date2 = new Date(this.endDateTime)
      const oneDay = 1000 * 60 * 60 * 24
      const diffInTime = date2.getTime() - date1.getTime()
      return Math.round(diffInTime / oneDay)
    }
  }
}
</script>

<style scoped>
  .alert-state {
    margin: 0;
    height: 30px;
    width: fit-content;
    height: fit-content;
    line-height: 30px;
    padding: 0px 15px;
    min-width: 10rem;
    border-radius: 15px;
    border: none;
  }
  .alert-danger {
    color: #BF543B;
    background-color: rgba(191, 84, 59, 0.15);
  }
  .alert-success {
    color: #92BF4E;
    background-color: rgba(146, 191, 78, 0.15);
  }
  .alert-warning {
    color: #F2B950;
    background-color: rgba(242, 185, 80, 0.15);
  }
  .alert-info {
    color: #62ADBF;
    background-color: rgba(98, 173, 191, 0.15);
  }
  .alert-secondary {
    color: #414A35;
    background-color: rgba(65, 74, 53, 0.15);
  }
</style>
