<template>
  <div :class="`alert ${statusClass} alert-state text-center`" role="alert">
    {{ statusMessage }}
  </div>
</template>

<script>
export default {
  name: 'FeedbackStatus',
  props: {
    status: {
      type: String,
      default: 'created'
    }
  },
  computed: {
    statusClass () {
      if (this.status === 'rejected') {
        return 'alert-danger'
      }
      if (this.status === 'closed') {
        return 'alert-success'
      }
      if (this.status === 'in_progress') {
        return 'alert-warning'
      }
      return 'alert-info'
    },
    statusMessage () {
      if (this.status === 'rejected') {
        return 'Отклонен'
      }
      if (this.status === 'closed') {
        return 'Закрыт'
      }
      if (this.status === 'in_progress') {
        return 'Выполняется'
      }
      return 'Зарегистрирован'
    }
  }
}
</script>

<style scoped>
  .alert-state {
    margin: 0;
    height: 30px;
    width: 10rem;
    line-height: 30px;
    padding: 0px 15px;
    max-width: 15rem;
    border-radius: 15px;
    border: none;
  }
  .alert-danger {
    color: #BF543B;
    background-color: rgba(191, 84, 59, 0.15);
  }
  .alert-success {
    color: #92BF4E;
    background-color: rgba(146, 191, 78, 0.15);
  }
  .alert-warning {
    color: #F2B950;
    background-color: rgba(242, 185, 80, 0.15);
  }
  .alert-info {
    color: #62ADBF;
    background-color: rgba(98, 173, 191, 0.15);
  }
</style>
