<template>
  <div>
    <div class="show-form-cars">
      <div class="row w-100">
        <div class="col-md mb-2">
          <label>Пользователь</label>
          <VSelect
            v-model="proxy.user_id"
            class="col p-0"
            :options="users"
            :reduce="user => user.id"
            label="fullName"
          />
        </div>
        <div class="col-md mb-2">
          <label>В лице:</label>
          <input
            v-model="proxy.representative"
            type="text"
            class="form-control"
            placeholder
            required
          >
        </div>
      </div>
      <div class="row w-100">
        <div class="col-md mb-2">
          <label>На основании:</label>
          <input
            v-model="proxy.based"
            type="text"
            class="form-control"
            placeholder
            required
          >
        </div>
        <div class="col-md mb-2">
          <label>Должность для подписи:</label>
          <input
            v-model="proxy.position"
            type="text"
            class="form-control"
            placeholder
            required
          >
        </div>
      </div>
      <div class="row w-100">
        <div class="col-md mb-2">
          <label>ФИО для подписи:</label>
          <input
            v-model="proxy.signature"
            type="text"
            class="form-control"
            placeholder
            required
          >
        </div>
      </div>
      <hr class="mb-4">
    </div>
  </div>
</template>

<script>
import { djsMixin } from '@/mixins/djsMixin'

export default {
  name: 'ProxyEditForm',
  mixins: [djsMixin],
  props: {
    proxy: {
      type: Object,
      default: null
    },
    prevProxy: {
      type: Object,
      default: null
    },
    users: {
      type: Array,
      default: Array
    }
  },
  data () {
    return {
    }
  },
}
</script>

<style scoped>
  .show-form-cars {
    margin-top: 4rem;
  }
</style>
