import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '@/views/Login'
import PasswordReset from '@/views/PasswordReset'
import Http404 from '@/views/Http404'
import Templates from '@/views/Templates'
import Instruction from '@/views/Instruction'
import Watch from '@/views/Watch'

import store from '../store'

import companies from './companies'
import user from './user'
import cars from './cars'
import trackDevice from './trackDevice'
import drivers from './drivers'
import rents from './rents'
import modules from '@/modules/router'
import userApi from '@/api/user'
import NoPermissions from '../views/NoPermissions'
import leftMenuLinks from '../layouts/leftMenuLinks'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: Login,
    meta: {
      title: 'Вход'
    }
  }, {
    path: '/password-reset',
    component: PasswordReset,
    props: route => ({
      token: route.query.token
    }),
    meta: {
      title: 'Сброс пароля'
    }
  }, {
    path: '/'
  },
  ...companies, // companies routes
  ...user, // users routes
  ...trackDevice, // tracking devices routes
  ...cars, // cars and car documents routes
  ...drivers, // drivers and driver documents routes
  ...rents, // rents routes
  {
    path: '/watch',
    component: Watch,
    meta: {
      title: 'Наблюдение',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/watch/:carId',
    component: Watch,
    props: true,
    meta: {
      title: 'Наблюдение',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/templates',
    component: Templates,
    meta: {
      title: 'Шаблоны',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/instruction',
    component: Instruction,
    meta: {
      title: 'Инструкции',
      layout: 'BaseWorkspaceLayout'
    }
  },
  ...modules,
  {
    path: '/no-permissions',
    component: NoPermissions,
    meta: {
      title: 'Нет доступа',
      layout: 'BaseWorkspaceLayout'
    }
  },
  {
    path: '*',
    component: Http404,
    meta: {
      title: 'Страница не найдена'
    }
  }
]

/**
 * Страница на которую будут перенаправлятся неавторизованные пользователи.
 */
const loginUrl = 'login'

/**
 * Страницы доступные неавторизованным пользователям.
 */
const allowActions = [
  '/login',
  '/logout',
  '/password-reset',
  '/set-password'
]

const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('taxi/user/loadAuthedUser').then(user => {
    if (!user && !allowActions.includes(to.path)) {
      return next(loginUrl)
    }
    if (to.path === '/users/create' && !user.roles['Администратор']) {
      return next('/users')
    }
    if (to.path === '/') {
      const links = JSON.parse(JSON.stringify(leftMenuLinks))
      userApi.getFrontendPermission()
        .then(permissions => {
          for (const link of links) {
            if (permissions && (permissions[link.url] || permissions['/*'])) {
              return next(link.url)
            }
          }
          return next('/users/' + user.id)
        })
    } else if (user) {
      userApi.hasFrontendPermission(to.fullPath)
        .then(permissions => {
          if (!permissions) {
            return next({ path: '/no-permissions', replace: true })
          }
        })
        .catch(() => {
          return next({ path: '/no-permissions', replace: true })
        })
    }
    return next()
  })
})
router.afterEach(to => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
})

export default router
