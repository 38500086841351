<template>
  <div>
    <table class="table borderless">
      <tbody>
        <tr>
          <td style="border-top: none">Компонент</td>
          <td style="border-top: none">{{ config.title ? config.title : config.component_name }}</td>
        </tr>
        <tr>
          <td>Фильтр</td>
          <td>
            <json-viewer
              :value="config.filter ? config.filter : []"
              :expand-depth="5"
              copyable
              boxed
              sort
            />
          </td>
        </tr>
        <tr>
          <td>Автор</td>
          <td>
            <div v-if="config.author">
              {{ config.author.last_name }} {{ config.author.first_name }}
            </div>
          </td>
        </tr>
        <tr>
          <td>Дата создания</td>
          <td>{{ config.created_at | formatDateTime }}</td>
        </tr>
        <tr>
          <td>Автор последнего изменения</td>
          <td>
            <div v-if="config.modifier">
              {{ config.modifier.last_name }} {{ config.modifier.first_name }}
            </div>
          </td>
        </tr>
        <tr>
          <td>Дата последнего изменения</td>
          <td>
            <div v-if="config.updated_at">
              {{ config.updated_at | formatDateTime }}
            </div>
          </td>
        </tr>
        <tr>
          <td>Json</td>
          <td>
            <json-viewer
              :value="config.value ? config.value : []"
              :expand-depth="5"
              copyable
              boxed
              sort
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import JsonViewer from 'vue-json-viewer'

export default {
  name: 'ConfigShow',
  components: {
    JsonViewer
  },
  props: {
    config: {
      type: Object,
      default: () => null
    }
  }
}
</script>
