<template>
  <div class="page page-track-devices">
    <HeaderTitle />
    <ActionBar
      :isEditing="isEditing"
      @changeAction="changeTrackDevice"
      @saveAction="updateTrackDevice"
      @deleteAction="deleteTrackDevice"
      @cancelAction="cancelTrackDeviceChanges"
    />
    <br>
    <TrackDeviceEdit
      v-if="isEditing"
      class="device-view-edit"
      :trackDevice="trackDevice"
      :firmwares="firmwares"
    />
    <TrackDeviceView
      v-else
      class="device-view-edit"
      :trackDevice="trackDevice"
    />
  </div>
</template>

<script>
/* eslint-disable camelcase */

import Swal from 'sweetalert2'
import deepAssign from 'assign-deep'
import trackDeviceApi from '@/api/trackDevice'
import { mapActions } from 'vuex'
import TrackDeviceEdit from './Edit'
import TrackDeviceView from './View'

export default {
  name: 'TrackDevicePage',
  components: {
    TrackDeviceEdit,
    TrackDeviceView
  },
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isUpdating: false,
      prevTrackDevice: null,
      trackDevice: {},
      firmwares: [],
      isEditing: false
    }
  },
  mounted () {
    this.loadData()
    trackDeviceApi.getFirmwares().then(response => {
      this.firmwares = response
    })
  },
  methods: {
    updateTrackDevice () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      trackDeviceApi.updateItem(this.trackDevice)
        .then(response => {
          this.trackDevice = response.model
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    loadData () {
      trackDeviceApi.getItem({ id: this.id })
        .then(response => {
          this.trackDevice = response
          this.setTitle([
            {
              title: 'Наблюдение',
              url: '/watch'
            },
            {
              title: 'Устройства',
              url: '/devices'
            },
            {
              title: response.name
            }
          ])
        })
    },
    changeTrackDevice () {
      this.prevTrackDevice = deepAssign({}, this.trackDevice)
      this.isEditing = true
    },
    cancelTrackDeviceChanges () {
      this.trackDevice = this.prevTrackDevice
      this.isEditing = false
    },
    deleteTrackDevice () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить устройство?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          trackDeviceApi.removeItem(this.trackDevice.id)
            .then(() => {
              this.$router.push('/devices')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>

<style scoped>
  .page-track-devices {
    width: 98%;
    padding-bottom: 4rem;
    padding-top: 2.5rem;
  }
</style>
