<template>
  <div>
    <div class="show-form-cars">
      <div class="row w-100">
        <div class="col-md mb-2">
          <label>Марка</label>
          <input
            v-model="car.brand"
            type="text"
            class="form-control"
            placeholder
            required
          >
        </div>
        <div class="col-md mb-2">
          <label>Модель</label>
          <input
            v-model="car.model"
            type="text"
            class="form-control"
            placeholder
            required
          >
        </div>
      </div>
      <div class="row w-100">
        <div class="col-md-6 mb-2">
          <label>Рег. номер</label>
          <div class="row w-100 m-0">
            <input
              v-model="car.reg_plate_number"
              type="text"
              class="form-control col-8"
              placeholder="Номер"
              maxlength="6"
              required
            >
            <input
              v-model="car.reg_plate_region"
              type="text"
              class="form-control col-4"
              placeholder="Регион"
              maxlength="3"
              required
            >
          </div>
        </div>
        <div class="col-md-3 mb-6">
          <label>Год выпуска</label>
          <input
            v-model="car.manufacturing_year"
            type="text"
            class="form-control"
            placeholder
            required
          >
        </div>
        <div class="col-md-3 mb-6">
          <label>Код радио</label>
          <input
            v-model="car.radio_code"
            type="text"
            class="form-control"
            placeholder
            required
          >
        </div>
      </div>
      <div class="row w-100">
        <div class="col-md mb-2">
          <label>Пробег</label>
          <div class="row w-100 m-0">
            <div class="form-control col bg-light text-nowrap">
              <span v-if="car.lastState && car.lastState.totalOdometer">
                {{ Math.round(car.lastState.totalOdometer.value / 1000) + " км" }}
                (по датчику {{ ' ' + Math.round(car.lastState.totalOdometer.sensor / 1000) + " км" }})
              </span>
              <span v-else>Нет данных</span>
            </div>
            <button
              class="col-auto btn btn-light border ml-2"
              @click="showMileagePanel = !showMileagePanel"
            >
              Задать пробег
            </button>
          </div>
        </div>
        <div class="col-md mb-2">
          <label>Устройство геопозиционирования</label>
          <div class="row w-100 m-0">
            <div class="form-control col bg-light">{{ getImei }}</div>
            <button
              class="col-auto btn btn-light border ml-2"
              @click="showDevicePanel = true"
            >
              Изменить
            </button>
          </div>
        </div>
      </div>
      <div class="row w-100">
        <div class="col-md-6 col mb-2">
          <label>Собственник</label>
          <VSelect
            v-model="car.owner_id"
            class="col p-0"
            :options="companies"
            :reduce="company => company.id"
            label="name"
          >
            <template v-slot:option="option">{{ option.name }}</template>
          </VSelect>
          <label>Топливная карта</label>
          <input
            v-model="car.fuel_card"
            type="text"
            class="form-control"
            placeholder
            required
          >
          <div v-if="hasFinesModule" class="my-2">
            <input v-model="car.check_fines" type="checkbox" class="mr-2">
            <label>Проверка штрафов ГИБДД</label>
          </div>
        </div>
        <div class="col-md-6 col mb-2">
          <label>Заметки</label>
          <textarea
            v-model="car.notes"
            rows="5"
            type="text"
            class="form-control"
            placeholder
            required
          />
        </div>
      </div>
      <hr class="mb-4">
    </div>
    <div v-if="showDevicePanel" class="side-panel">
      <font-awesome-icon
        icon="times"
        class="float-right text-secondary"
        role="button"
        tabindex="0"
        @click="showDevicePanel = false"
      />
      <div class="side-title">Добавить устройство:</div>
      <hr>
      <div v-if="creatingDevice">
        <div class="mb-2">
          IMEI
          <input
            v-model="device.trackingDevice.imei"
            type="text"
            class="form-control"
            placeholder
            required
          >
        </div>
        <div class="mb-2">
          Тип прошивки
          <VSelect
            v-model="device.trackingDevice.firmware_id"
            :options="device.firmwares"
            :reduce="firmware => firmware.id"
            label="name"
          />
        </div>
        <div class="mb-2">
          Название устройства наблюдения
          <input
            v-model="device.trackingDevice.name"
            type="text"
            class="form-control"
            placeholder
            required
          >
        </div>
        <div class="mb-2">
          Номер телефона
          <IMaskComponent
            v-model="device.trackingDevice.phone"
            type="text"
            class="form-control"
            :mask="'[+7 (000) 000-00-00]'"
            placeholder="+7 (999) 999-99-99"
            required
          />
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-danger mx-1" @click="creatingDevice = false">Отменить</button>
          <button class="btn btn-success mx-1" @click="createTrackDevice()">Создать</button>
        </div>
      </div>
      <div v-else>
        <div class="mb-2">
          Выберите устройство
          <VSelect
            v-model="car.tracking_device_id"
            class="col p-0"
            :options="trackingDevicesList"
            :reduce="device => device.id"
            label="imei"
          >
            <template v-slot:option="option">
              {{ option.imei }}
              <sub>{{ option.name }}</sub>
            </template>
          </VSelect>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn btn-success mx-1" @click="creatingDevice = true">Создать устройство</button>
          <button class="btn btn-primary mx-1" @click="changeTrackDevice()">Выбрать</button>
        </div>
      </div>
    </div>
    <div v-if="showMileagePanel" class="side-panel">
      <font-awesome-icon
        icon="times"
        class="float-right text-secondary"
        role="button"
        tabindex="0"
        @click="showMileagePanel = false"
      />
      <div class="side-title">Пробег</div>
      <hr>
      <div class="mb-6 my-2">
        Задать текущий пробег
        <div class="input-group mb-3">
          <input
            v-model="odometerCorrection.actual_odometer"
            type="text"
            class="form-control"
            placeholder
            required
          >
          <div class="input-group-append">
            <button class="input-group-btn btn btn-success" @click="createOdometerCorrection()">Задать</button>
          </div>
        </div>
      </div>
      <div class="mb-2">
        История изменений
        <span v-if="!odometerHistoryItems" class="d-flex justify-content-center">Не найдено изменений пробега</span>
        <ul class="list-group">
          <li v-for="(odometer, key) in odometerHistoryItems" :key="key" class="list-group-item">
            <div class="row mx-1">
              <span class="text-dark font-weight-bold">{{ odometer.mileage }} км</span>
            </div>
            <div class="row mx-1">
              {{ odometer.date }} {{ odometer.author }}
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import userApi from '@/api/user'
import CarsAPI from '@/api/cars'
import { djsMixin } from '@/mixins/djsMixin'
import trackDeviceApi from '@/api/trackDevice'
import odometerCorrectionApi from '@/api/odometerCorrection'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'CarEditForm',
  mixins: [djsMixin],
  props: {
    id: {
      type: String,
      default: null
    },
    car: {
      type: Object,
      default: null
    },
    trackingDevicesList: {
      type: Array,
      default: null
    },
    prevCar: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isCreating: false,
      isUpdating: false,
      showDevicePanel: false,
      showMileagePanel: false,
      creatingDevice: false,
      device: {
        firmwares: [],
        trackingDevice: {
          imei: null,
          name: null,
          firmware_id: null
        }
      },
      odometerCorrection: {
        tracking_device_id: null,
        actual_odometer: null,
        timestamp: this.now(),
        device_odometer: null,
        car_id: null,
        author_id: null
      },
      odometerHistoryItems: null
    }
  },
  computed: {
    ...mapState('taxi/companies', {
      companies: state => state.items
    }),
    hasFinesModule () {
      return process.env.VUE_APP_MODULE_FINES === 'true'
    },
    getImei () {
      return this.trackingDevicesList.find(e => {
        return e.id === this.car.tracking_device_id
      })?.imei
    }
  },
  watch: {
    'device.trackingDevice.imei': {
      handler () {
        this.generateNameTrackingDevice()
      }
    },
    'device.trackingDevice.firmware_id': {
      handler () {
        this.generateNameTrackingDevice()
      }
    }
  },
  beforeDestroy () {
    this.abortLoading()
  },
  mounted () {
    this.loadItems()
    trackDeviceApi.getFirmwares().then(response => {
      this.device.firmwares = response
    })
    this.getHistory()
  },
  methods: {
    ...mapActions('taxi/companies', [
      'loadItems',
      'abortLoading'
    ]),
    generateNameTrackingDevice () {
      this.device.trackingDevice.name = ''
      if (this.device.trackingDevice?.imei || this.device.trackingDevice.firmware_id) {
        if (this.device.trackingDevice.firmware_id) {
          this.device.trackingDevice.name += this.device.firmwares[this.device.trackingDevice.firmware_id - 1].name
        }
        if (this.device.trackingDevice?.imei && this.device.trackingDevice.firmware_id) {
          this.device.trackingDevice.name += '-'
        }
        if (this.device.trackingDevice?.imei) {
          this.device.trackingDevice.name += this.device.trackingDevice.imei
        }
      }
    },
    getHistory () {
      odometerCorrectionApi.getHistoryOdometer({ id: this.car.id })
        .then(history => {
          if (history.length !== 0) {
            this.odometerHistoryItems = history
          } else {
            this.odometerHistoryItems = null
          }
        })
    },
    createTrackDevice () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      trackDeviceApi.createItem(this.device.trackingDevice)
        .then(trackDevice => {
          this.isCreating = false
          this.trackingDevicesList.push(trackDevice.model)
          this.showDevicePanel = false
          this.creatingDevice = false
          this.device.trackingDevice.name = ''
          this.device.trackingDevice.imei = ''
          this.device.trackingDevice.firmware_id = null
          this.car.tracking_device_id = trackDevice.model.id
          this.changeTrackDevice()
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    changeTrackDevice () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      if (this.car.tracking_device_id) {
        this.prevCar.tracking_device_id = this.car.tracking_device_id
        CarsAPI.updateItem(this.prevCar)
          .then(() => {
            this.showDevicePanel = false
            const trackingDevice = this.trackingDevicesList.find(e => {
              return e.id === this.car.tracking_device_id
            })
            this.car.trackingDevice = trackingDevice
            this.prevCar.trackingDevice = trackingDevice
            this.isUpdating = false
          })
          .catch(err => {
            console.error(err)
            this.isUpdating = false
            Swal.fire({
              icon: 'error',
              title: 'Ошибка',
              html: err.message
            })
          })
      }
    },
    createOdometerCorrection () {
      if (this.isCreating) {
        return
      }
      if (!this.car.trackingDevice) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: 'Нет устройства геопозиционирования для коррекции'
        })
        return
      }
      this.isCreating = true
      userApi
        .getCurrentUser()
        .then(user => {
          this.isCreating = true
          this.odometerCorrection.author_id = user.id
          this.odometerCorrection.car_id = this.car.id
          this.odometerCorrection.tracking_device_id = this.car.trackingDevice.id
          odometerCorrectionApi.createItem({
            ...this.odometerCorrection,
            timestamp: this.toUTCtranslate(this.odometerCorrection.timestamp)
          })
            .then(odometer => {
              this.car.lastState.totalOdometer.value = parseInt(odometer.model?.actual_odometer) * 1000 ?? 0
              this.prevCar.lastState.totalOdometer.value = parseInt(odometer.model?.actual_odometer) * 1000 ?? 0
              this.car.lastState.totalOdometer.sensor = odometer.model?.device_odometer ?? 0
              this.prevCar.lastState.totalOdometer.sensor = odometer.model?.device_odometer ?? 0
              this.isCreating = false
              this.showMileagePanel = false
              this.actualOdometer = this.odometerCorrection.actual_odometer
              this.odometerCorrection.actual_odometer = null
              this.getHistory()
            })
            .catch(err => {
              console.error(err)
              this.isCreating = false
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    }
  }
}
</script>

<style scoped>
  .show-form-cars {
    margin-top: 4rem;
  }
  .side-panel {
    position: fixed;
    z-index: 999999;
    top: 0;
    right: 0;
    background: #ffffff;
    transition: right 1.0s;
    width: 700px;
    max-width: 100%;
    height: 100vh;
    box-shadow: 10px 0 20px rgba(0,0,0,0.4);
    color: #616161;
    padding: 40px 20px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  .side-title {
    font-size: 20px;
  }
</style>
