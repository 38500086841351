/* eslint-disable camelcase */
import APITemplate from '@/api/APITemplate'
const headers = {
    accept: 'application/json',
    'content-type': 'application/json'
}

export default {
    ...APITemplate('/api/car-driver'),
    archivateItem(id) {
        return fetch(`/api/car-driver/archivate?id=${id}`, {
            method: 'PUT',
            credentials: 'include',
            redirect: 'error',
            headers
        }).then(response => response.json())
    },
    unRentItem(id) {
      return fetch(`/api/car-driver/un-rent?id=${id}`, {
        method: 'PUT',
        credentials: 'include',
        redirect: 'error',
        headers
      }).then(response => response.json())
    },
    getItemsWith(expand) {
        const controller = new AbortController()
        return {
            promise: fetch('/api/car-driver?expand=' + expand.toString(), {
                method: 'GET',
                credentials: 'include',
                redirect: 'error',
                headers
            })
        .then(response => {
            if (response.status !== 200) {
                throw new Error('Ошибка при загрузке.')
            }
          return new Promise((resolve) => {
                response.json().then(items => {
                    resolve({
                        items
                    })
                })
            })
          }),
        abort() {
            controller.abort()
            }
        }
    }
}
