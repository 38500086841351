<template>
  <div class="page">
    <HeaderTitle />
    <div class="form-row row">
      <div class="form-group col">
        <label for="selectorDriver">Водитель</label>
        <VSelect
          id="selectorDriver"
          v-model="driverId"
          :options="drivers"
          :reduce="driver => driver.id"
          label="fullName"
          required
        />
      </div>
    </div>
    <div class="form-row row">
      <div class="form-group col">
        <label for="blacklistReason">Причина добавления в ЧС</label>
        <textarea
          id="blacklistReason"
          v-model="reason"
          rows="5"
          type="text"
          placeholder
          required
          class="form-control"
        />
      </div>
    </div>
    <div class="mt-4">
      <button class="btn btn-primary mr-2" @click="addDriver()">Добавить</button>
      <router-link to="/black-list" class="btn btn-secondary">Отменить</router-link>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DriversAPI from '@/api/drivers'
import Swal from 'sweetalert2'

export default {
  name: 'AddBlacklist',
  data () {
    return {
      driverId: null,
      reason: '',
      drivers: [],
      isUpdating: false
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'ЧС водителей',
        url: '/black-list'
      }, {
        title: 'Добавление водителя в ЧС'
      }
    ])
    DriversAPI.getItems({
      extra: {
        key: 'filter[and][][is_blacklisted]',
        value: 0
      }
    }).promise.then(drivers => {
      this.drivers = drivers.items
      this.drivers.forEach(driver => {
        driver.fullName = `${driver.last_name} ${driver.first_name} ${driver.middle_name}`
      })
    })
  },
  methods: {
    addDriver () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      DriversAPI
        .getItem({id: this.driverId})
        .then(driver => {
          DriversAPI.updateItem({...driver, is_blacklisted: 1, blacklist_reason: this.reason})
            .then(() => {
              this.isUpdating = false
              this.$router.push('/black-list')
            })
            .catch(err => {
              console.error(err)
              this.isUpdating = false
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })

    },
    ...mapActions('taxi', [
      'setTitle'
    ]),
  }
}
</script>
