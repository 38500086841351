import Vue from 'vue'
import MapMarker from './MapMarker'
import SelectedMarker from './SelectedMarker'
import MapCard from './mapCard/Card'
import WatchState from './WatchState'
import CardSelected from './mapCard/CardSelected'
import SpeedPolyline from './SpeedPolyline'

import 'leaflet/dist/leaflet.css'

import { Icon } from 'leaflet'

/* Фикс отображения маркера */
delete Icon.Default.prototype._getIconUrl
// leaflet/dist/images/marker-icon.png

Icon.Default.mergeOptions({
  iconRetinaUrl: require('@/assets/icons/defaultMarker.svg'),
  iconUrl: require('@/assets/icons/defaultMarker.svg'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.component('MapMarker', MapMarker)
Vue.component('SelectedMarker', SelectedMarker)
Vue.component('MapCard', MapCard)
Vue.component('WatchState', WatchState)
Vue.component('CardSelected', CardSelected)
Vue.component('SpeedPolyline', SpeedPolyline)
