<template>
  <div class="page contracts-templates">
    <button class="btn btn-primary w-100" @click="$refs.files.click()">Загрузить шаблон</button>
    <input
      ref="files"
      type="file"
      style="display:none"
      multiple
      @change="addFilesFromDialog"
    >

    <nav class="nav nav-tabs mt-3">
      <a
        class="nav-item nav-link"
        :class="{ 'active': currentTab === 'contractTemplates' }"
        role="button"
        tabindex="0"
        @click="setContractTemplatesTab"
      >
        Договоры
      </a>
      <a
        class="nav-item nav-link"
        :class="{ 'active': currentTab === 'receiveListsTemplates' }"
        role="button"
        tabindex="0"
        @click="setReceiveListsTemplatesTab"
      >
        Акты возврата
      </a>
      <a
        class="nav-item nav-link"
        :class="{ 'active': currentTab === 'waybillTemplates' }"
        role="button"
        tabindex="0"
        @click="setWaybillTemplatesTab"
      >
        Путевые листы
      </a>
    </nav>
    <div class="tab-content">
      <FileListViewer :files="templateFiles" @archivateFile="archivateFile" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FilesAPI from '@/api/files'
import FileListViewer from '../components/FileListViewer'

export default {
  name: 'Templates',
  components: {FileListViewer},
  data () {
    return {
      files: [],
      inputFile: '',
      dragging: false,
      preDragging: false
    }
  },
  computed: {
    ...mapState('taxi/templates', {
      isLoading: state => state.isLoading,
      contractTemplates: state => state.contractTemplates,
      waybillTemplates: state => state.waybillTemplates,
      receiveListsTemplates: state => state.receiveListsTemplates,
      currentTab: state => state.currentTab
    }),
    templateFiles () {
      if (this.currentTab === 'waybillTemplates') {
        return this.waybillTemplates
      }
      else if (this.currentTab === 'receiveListsTemplates') {
        return this.receiveListsTemplates
      }
      return this.contractTemplates
    }
  },
  watch: {
    currentTab () {
      this.loadCurrentTab()
    }
  },
  mounted () {
    this.loadAllTabs()
    this.setTitle([
      {
        title: 'Шаблоны'
      }
    ])
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('taxi/templates', [
      'loadCurrentTab',
      'setSearchText',
      'loadAllTabs',
      'setContractTemplatesTab',
      'setWaybillTemplatesTab',
      'setReceiveListsTemplatesTab',
      'abortLoading',
      'uploadTemplates'
    ]),
    addFilesFromDialog (e) {
      this.uploadTemplates(e.target.files)
      this.$refs.files.value = null
    },

    getTemplate (id) {
      FilesAPI.getItem({ id }).then(template => {
        this.files.unshift(template)
      })
    },
    archivateFile () {
        this.loadAllTabs()
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>

<style scoped>
  .tab-content {
    z-index: 5;
  }
  .contracts-templates {
    width: 98%;
  }
  .date {
    min-width: 8rem;
  }
  .file-card {
    margin-top: 1rem;
  }
</style>
