import FinesList from './views/FinesList'
import FinesCar from './views/FinesCar'

export default [
  {
    path: '/fines',
    component: FinesList,
    meta: {
      title: 'Штрафы ГИБДД',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/fines/car/:carId',
    component: FinesCar,
    name: 'finesPage',
    props: true,
    meta: {
      title: 'Тех. обслуживание',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
