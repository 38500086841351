/* eslint-disable camelcase */

import accident from './accident'

export default {
  namespaced: true,
  modules: {
    accident
  }
}
