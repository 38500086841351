<template>
  <div>
    <div v-if="statusPage === Statuses.LIST">
      <ProxiesList
        :proxies="proxies"
        @chooseProxy="chooseProxy"
        @goAddPage="proxy = newProxy; statusPage = Statuses.CREATE"
      />
    </div>
    <div v-else-if="(statusPage === Statuses.EDIT || statusPage === Statuses.VIEW || statusPage === Statuses.CREATE)">
      <button
        class="btn btn-secondary mx-1 float-left"
        @click="statusPage = Statuses.LIST"
      >
        Назад
      </button>
      <div class="float-right">
        <button
          v-if="statusPage === Statuses.VIEW"
          class="btn btn-danger mx-1"
          @click="deleteProxy"
        >
          <font-awesome-icon class="text-white" icon="trash-alt" />
          Удалить
        </button>
        <button
          v-if="statusPage === Statuses.VIEW"
          class="btn btn-primary mx-1"
          @click="editProxy(proxy)"
        >
          <font-awesome-icon class="text-white" icon="edit" />
          Изменить
        </button>
        <button
          v-if="statusPage === Statuses.EDIT || statusPage === Statuses.CREATE"
          class="btn btn-secondary mx-1"
          @click="cancelProxy()"
        >
          Отменить
        </button>
        <button
          v-if="statusPage === Statuses.EDIT || statusPage === Statuses.CREATE"
          class="btn btn-primary mx-1"
          @click="updateProxy"
        >
          Сохранить
        </button>
      </div>
      <br>
      <ProxyShowForm
        v-if="statusPage === Statuses.VIEW"
        :proxy="proxy"
      />
      <ProxyEditForm
        v-else
        :proxy="proxy"
        :prev-proxy="prevProxy"
        :users="users"
      />
    </div>
  </div>
</template>

<script>

import { djsMixin } from '@/mixins/djsMixin'
import ProxyShowForm from './ShowForm'
import ProxiesList from './List'
import {mapActions} from 'vuex'
import ProxyEditForm from './EditForm'
import Swal from 'sweetalert2'
import ProxiesAPI from '@/api/proxies'
import companiesAPI from '@/api/companies'

const Statuses = Object.freeze({
  LIST: 'list',
  VIEW: 'view',
  EDIT: 'edit',
  CREATE: 'create',
})

export default {
  name: 'ProxiesPage',
  components: {ProxyEditForm, ProxiesList, ProxyShowForm },
  mixins: [djsMixin],
  props: {
    proxies: {
      type: Array,
      default: Array
    },
    companyId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      proxy: this.newProxy,
      newProxy: {
        id: null,
        user_id: null,
        company_id: this.companyId,
        representative: null,
        based: null,
        position: null,
        signature: null,
      },
      users: [],
      prevProxy: null,
      statusPage: Statuses.LIST,
      Statuses
    }
  },
  mounted () {
    this.loadItems()
      .then(users => {
        this.users = JSON.parse(JSON.stringify(users)).map(user => {
          user.fullName = `${user.last_name} ${user.first_name} ${user.middle_name}`
          return user
        })
      })
  },
  methods: {
    ...mapActions('taxi/user', [
      'loadItems',
      'loadAuthedUser'
    ]),
    loadData () {
      companiesAPI.getItem({ id: this.companyId, queryWith: ['proxiesCompany'] })
        .then(response => {
          this.proxies = response.proxiesCompany
        })
    },
    chooseProxy (proxy) {
      this.statusPage = Statuses.VIEW
      this.proxy = proxy
    },
    editProxy (proxy) {
      this.statusPage = Statuses.EDIT
      this.proxy = proxy
      this.prevProxy = JSON.parse(JSON.stringify(proxy))
    },
    cancelProxy () {
      this.statusPage = Statuses.VIEW
      this.proxy = this.prevProxy
    },

    deleteProxy () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить доверенное лицо?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          ProxiesAPI.removeItem(this.proxy.id)
            .then(() => {
              this.statusPage = Statuses.LIST
              this.loadData()
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    updateProxy () {
      if (this.statusPage === Statuses.EDIT) {
        ProxiesAPI.updateItem(this.proxy)
          .then((proxy) => {
            this.proxy = proxy.model
            this.statusPage = Statuses.VIEW
            this.loadData()
          })
          .catch(err => {
            console.error(err)
            this.isUpdating = false
            Swal.fire({
              icon: 'error',
              title: 'Ошибка',
              html: err.message
            })
          })
      } else {
        ProxiesAPI.createItem({
          ...this.proxy,
          company_id: this.companyId
        })
          .then((proxy) => {
            this.proxy = proxy.model
            this.statusPage = Statuses.VIEW
            this.loadData()
          })
          .catch(err => {
            console.error(err)
            this.isUpdating = false
            Swal.fire({
              icon: 'error',
              title: 'Ошибка',
              html: err.message
            })
          })
      }

    },
  },
}
</script>

<style scoped>
  .page-cars {
    width: 98%;
    padding-bottom: 4rem;
    padding-top: 2.5rem;
  }
</style>
