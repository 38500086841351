import APITemplate from '@/api/APITemplate'

const url = '/api/tracking-device'
const template = APITemplate(url)

export default {
  ...template,
  getFirmwares () {
    return fetch('/api/firmware', {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(template.errorHandler)
  }
}
