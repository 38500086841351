<template>
  <div>
    <table class="table borderless">
      <tbody>
        <tr>
          <td>Машинное имя</td>
          <td>
            <input
              v-model="component.name"
              type="text"
              class="form-control"
              placeholder
              required
            >
          </td>
        </tr>
        <tr>
          <td>Название</td>
          <td>
            <input
              v-model="component.title"
              type="text"
              class="form-control"
              placeholder
              required
            >
          </td>
        </tr>
        <tr>
          <td>Подсказки</td>
          <td>
            <vueJsonEditor
              v-model="component.hints"
              class="component-hints"
              :show-btns="false"
              :expandedOnStart="true"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import vueJsonEditor from 'vue-json-editor'

export default {
  name: 'ComponentEdit',
  components: {
    vueJsonEditor
  },
  props: {
    component: {
      type: Object,
      default: () => null
    }
  },
}
</script>

<style>
.component-hints .jsoneditor-vue {
  height: 500px;
}
</style>
