<template>
  <div v-if="trips.length > 0" class="total-trips">
    <div class="trip-card-body">
      <div class="col-trip no-margin-left">
        <font-awesome-icon
          icon="parking"
          :style="{ color: '#62ADBF', width: '30px', height: '30px' }"
        />
      </div>
      <div>
        <font-awesome-icon
          icon="clock"
          :style="{ color: '#55606a', 'margin-right': '0.1rem' }"
        />
        <span>{{ totalParkingTime }}</span>
      </div>
      <div class="col-trip">
        <font-awesome-icon
          icon="car"
          :style="{ color: '#92BF4E', width: '30px', height: '30px' }"
        />
      </div>
      <div>
        <font-awesome-icon
          icon="clock"
          :style="{ color: '#55606a', 'margin-right': '0.1rem' }"
        />
        <span>{{ totalTravelTime }}</span>
      </div>
      <div class="col-trip">
        <font-awesome-icon
          icon="road"
          :style="{ color: '#55606a', 'margin-right': '0.1rem' }"
        />
        <span>{{ totalTravelDistance }} км</span>
      </div>
    </div>
  </div>
  <div v-else class="total-trips">
    <span class="d-flex justify-content-center">Не найдено маршрутов за указанный период</span>
  </div>
</template>

<script>
import { formatDifference } from './functions'

export default {
  name: 'TripsTotal',
  props: {
    trips: {
      type: Array,
      default: Array
    }
  },
  computed: {
    totalParkingTime () {
      return formatDifference(
        this.trips.reduce(
          (acc, el) => el.type === 'parked' ? acc + el.dailyDuration : acc,
          0
        )
      )
    },
    totalTravelTime () {
      return formatDifference(
        this.trips.reduce(
          (acc, el) => el.type === 'traveled' ? acc + el.dailyDuration : acc,
          0
        )
      )
    },
    totalTravelDistance () {
      return this.trips.length ? Math.round(this.trips[0].dailyOdometer / 1000) : 0
    }
  }
}
</script>
<style lang="scss" scoped>
  .trip-card-body {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.25rem;
    justify-content: center;
  }
  .total-trips {
    border: none;
  }
  .col-trip {
    display: flex;
    align-content: center;
    margin: 0 0.4rem;
    flex-flow: nowrap;
    align-items: center;
    margin-left: 15px
  }
  .no-margin-left {
    margin-left: 0;
  }
</style>
