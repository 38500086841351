<template>
  <div class="history-container">
    <div class="checkbox-selector">Скоростной режим <input v-model="speedMode" type="checkbox"></div>
    <div class="checkbox-selector">Фактический пробег <input v-model="factMileage" type="checkbox"></div>
    <ul v-if="activeDay" ref="datePillsContainer" class="nav nav-pills">
      <li class="nav-item noselect">
        <a
          class="nav-link"
          :class="{active: periodMode}"
          @click.prevent="periodMode = !periodMode"
        >
          Период
        </a>
      </li>
      <li
        v-for="(day, index) in weekDaysArray()"
        :key="index"
        :ref="day.ref"
        class="nav-item noselect"
      >
        <a
          class="nav-link"
          :class="{ active: !periodMode && activeDay.text === day.text }"
          @click.prevent="selectDay(day)"
        >{{ day.text }}</a>
      </li>
    </ul>
    <hr>
    <TripsDay
      v-if="activeDay && !periodMode"
      :car="car"
      :trips="trips"
      :date="activeDay.text"
      :standAlone="true"
    />
    <TripsPeriod
      v-else-if="periodMode"
      :car="car"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import dayjs from 'dayjs'
import TripsDay from './trip/Day'
import TripsPeriod from './trip/Period'

export default {
  name: 'MapCardHistory',
  components: {
    TripsDay,
    TripsPeriod
  },
  props: {
    car: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      activeDay: null,
      periodMode: false,
    }
  },
  computed: {
    speedMode: {
      get () {
        return this.getSpeedMode
      },
      set (value) {
        this.setSpeedMode(value)
      }
    },
    factMileage: {
      get () {
        return this.getFactMileage
      },
      set (value) {
        this.setFactMileage(value)
      }
    },
    ...mapState('taxi/trips', {
      trips: state => state.items,
      isLoading: state => state.isLoading
    }),
    ...mapState('taxi/mapCars', {
      track: state => state.track,
      tracks: state => state.tracks
    }),
    ...mapGetters('taxi/mapCars', [
      'getSpeedMode', 'getFactMileage'
    ]),
    ...mapGetters('configs/configs', {
      defaultFactMileage: 'factMileage'
    })
  },
  watch: {
    car: {
      immediate: true,
      handler (car, carOld) {
        if ((car && carOld && car.id !== carOld.id) || (car && !carOld)) {
          this.startWatch()
        }
      }
    }
  },
  mounted () {
    this.selectDay(this.weekDaysArray().shift())
    this.setFactMileage(this.defaultFactMileage)
  },
  beforeDestroy () {
    this.clearTrack()
    this.destroyWatcher()
  },
  methods: {
    ...mapActions('taxi/trips', [
      'loadItems',
      'mergeWithCarData'
    ]),
    ...mapActions('taxi/mapCars', [
      'clearTrack',
      'selectCar',
      'setSpeedMode',
      'setFactMileage'
    ]),
    weekDaysArray () {
      const result = []
      const now = new Date()
      now.setDate(now.getDate() + 1)
      for (let i = 14; i > 0; --i) {
        const endDateTime = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' +
          now.getDate() + 'T00:00:00' + dayjs().format('ZZ')
        now.setDate(now.getDate() - 1)
        const startDateTime = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' +
          now.getDate() + 'T00:00:00' + dayjs().format('ZZ')
        const text = now.toLocaleDateString('ru-RU', {
          month: 'long',
          day: 'numeric'
        })
        result.push({
          text,
          ref: `refDayHistory${text.replace(/\s/g, '')}`,
          startDateTime,
          endDateTime
        })
      }
      return result
    },
    selectDay (day) {
      this.periodMode = false
      this.clearTrack()
      this.selectCar(this.car.id)
      this.loadItems({
        imei: this.car.imei,
        startDateTime: day.startDateTime,
        endDateTime: day.endDateTime
      })
      this.activeDay = day
      const out = this.$refs.datePillsContainer

      if (!this.$refs[this.activeDay.ref]) {
        return
      }

      const tar = this.$refs[this.activeDay.ref][0]
      let q = 0
      for (const day of this.weekDaysArray()) {
        if (this.$refs[day.ref][0] === tar) {
          break
        }
        q += this.$refs[day.ref][0].clientWidth
      }
      out.scrollTo(Math.max(0, q - (out.clientWidth - tar.clientWidth) / 2), 0)
    },
    startWatch () {
      this.destroyWatcher()
      if (!this.isLoading && this.activeDay) {
        this.loadItems({
          imei: this.car.imei,
          startDateTime: this.activeDay.startDateTime,
          endDateTime: this.activeDay.endDateTime
        })
        this._timeout = setTimeout(() => this.startWatch(), 10000)
        return
      }
      this._timeout = setTimeout(() => this.startWatch(), 5000)
    },
    destroyWatcher () {
      clearTimeout(this._timeout)
      this._timeout = null
    }
  }
}
</script>

<style lang="scss" scoped>
  .nav-link {
    border-radius: 50px;
    padding: 0.1rem 1.5rem;
    white-space: nowrap;
    color: #1a97f2;
    border: 1px solid #1a97f2;
    margin-right: 0.2rem;
    cursor: pointer;
  }

  .nav-link.active {
    background-color: #1a97f2;
  }
  .nav-pills {
    flex-wrap: nowrap;
    overflow-x: auto;
    margin: 0;
    padding: 5px;
  }
  .noselect {
    user-select: none;
  }
  .checkbox-selector {
    text-align: center;
  }
</style>
