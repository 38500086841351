import Page from '@/views/trackDevices/Page'
import Create from '@/views/trackDevices/Create'
import TrackDevices from '@/views/trackDevices/TrackDevices'

export default [
  {
    path: '/devices',
    component: TrackDevices,
    meta: {
      title: 'Устройства',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/devices/create',
    component: Create,
    meta: {
      title: 'Устройства',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/devices/:id',
    component: Page,
    name: 'devicePage',
    props: true,
    meta: {
      title: 'Устройства',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
