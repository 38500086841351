<template>
  <div v-if="stock">
    <table class="table borderless">
      <tbody>
        <tr>
          <td style="border-top: none">Название</td>
          <td style="border-top: none">{{ stock.title }}</td>
        </tr>
        <tr>
          <td>Поступление</td>
          <td>{{ stock.receipt }}</td>
        </tr>
        <tr>
          <td>Количество</td>
          <td>{{ stock.quantity }}</td>
        </tr>
        <tr>
          <td>Поставщик</td>
          <td>{{ stock.supplier }}</td>
        </tr>
        <tr>
          <td>Дата производства</td>
          <td>{{ stock.date }}</td>
        </tr>
        <tr>
          <td>Объект</td>
          <td>{{ stock.facility.name }}</td>
        </tr>
        <template v-if="showPriceAndSum">
          <tr>
            <td>Стоимость 1 шт</td>
            <td>{{ stock.price }}</td>
          </tr>
          <tr>
            <td>Сумма, руб</td>
            <td>{{ stock.stockSum }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'StockShow',
  props: {
    stock: {
      type: Object,
      default: () => null
    },
    showPriceAndSum: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
