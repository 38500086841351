<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-auto img-col">
          <img
            v-if="scan && scan.extention && imageExt.includes(scan.extention)"
            role="button"
            tabindex="0"
            width="60px"
            height="60px"
            :src="`/api/file/link?id=${scan.id}`"
            @click="$emit('detail-scan', scan)"
            @mouseover="imgHover = true"
            @mouseleave="imgHover = false"
          >
          <router-link v-else :to="`/api/file/tab-view?id=${scan.id}`" target="_blank">
            <div
              style="width: 60px; height: 60px;"
              role="button"
              tabindex="0"
              @mouseover="fileHover = true"
              @mouseleave="fileHover = false"
            >
              <font-awesome-icon
                v-if="scan.extention === 'pdf'"
                icon="file-pdf"
                size="4x"
                :style="{ color: '#fa0f00' }"
                class="icon watch"
              />
              <font-awesome-icon
                v-else
                icon="file"
                size="4x"
                :style="{ color: 'rgb(64, 64, 64)' }"
                class="icon watch"
              />
            </div>
          </router-link>
          <font-awesome-icon
            v-if="imgHover"
            icon="eye"
            size="2x"
            class="icon watch"
          />
          <font-awesome-icon
            v-if="fileHover"
            icon="external-link-alt"
            size="2x"
            class="icon watch"
          />
        </div>
        <div class="col-sm my-auto">
          <input
            v-if="isEditing"
            v-model="scanChanges.name"
            type="text"
            class="form-control"
            placeholder
            required
          >
          <span v-else>{{ scan.name }}</span>
        </div>
        <div v-if="isEditing" class="col-sm my-auto">
          <font-awesome-icon
            icon="check"
            class="icon close-button"
            @click="updateScan()"
          />
          <font-awesome-icon icon="undo" class="icon edit-button" @click="cancelScan()" />
        </div>
        <div v-else class="col-sm my-auto">
          <font-awesome-icon
            icon="times"
            class="icon close-button"
            @click="$emit('remove-scan')"
          />
          <font-awesome-icon icon="pen" class="icon edit-button" @click="changeScan()" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import deepAssign from 'assign-deep'

export default {
  name: 'ScanCard',
  props: {
    scan: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      imgHover: false,
      fileHover: false,
      scanChanges: { name: null },
      isEditing: false,
      imageExt: ['jpg', 'png']
    }
  },
  methods: {
    changeScan () {
      this.isEditing = true
      this.scanChanges = deepAssign({}, this.scan)
    },
    cancelScan () {
      this.scanChanges = null
      this.isEditing = false
    },
    updateScan () {
      this.$emit('update-scan', { scan: this.scanChanges })
      this.isEditing = false
    }
  }
}
</script>

<style scoped>
  .icon {
    float: right;
    margin-right: 1rem;
    cursor: pointer;
  }
  .card {
    margin-top: 0.4rem;
  }
  .col-sm {
    z-index: 2;
  }
  .img-col {
    padding: 0.3rem;
    z-index: 2;
  }
  .watch {
    position: absolute;
    pointer-events: none;
    color: black;
    opacity: 0.65;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
</style>
