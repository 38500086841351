<template>
  <div>
    <div class="my-1">Начало</div>
    <div class="row">
      <div class="col">
        <input
          v-model="dateStart"
          class="w-100 form-control"
          type="date"
          placeholder="Начало"
        >
      </div>
    </div>
    <div class="my-2">Окончание</div>
    <div class="row">
      <div class="col">
        <input
          v-model="dateEnd"
          class="w-100 form-control"
          type="date"
          placeholder="Окончание"
        >
      </div>
    </div>
    <button class="input-group-btn btn btn-primary my-4 align-content-center w-100" @click="loadWaybill()">
      <span v-if="!loadingStatistic">Показать</span>
      <div v-else class="spinner-border spinner-border-sm text-light" role="status">
        <span class="sr-only">Загрузка...</span>
      </div>
    </button>
    <div v-if="trips">
      <div v-if="trips.length" class="mb-2">
        <a
          :href="'/api/waybill/get-rept?' +
            'id=' + car.id +
            '&startDateTime=' + encodeURIComponent(getDateWithTimezone(dateStart)) +
            '&endDateTime=' + encodeURIComponent(getDateWithTimezone(dateEnd))"
          class="input-group-btn btn btn-primary mr-2"
        >
          <font-awesome-icon class="text-white" icon="file-download" />
          Скачать отчет
        </a>
        <a
          :href="'/api/waybill/get-waybill?' +
            'car_id=' + car.id +
            '&startDateTime=' + encodeURIComponent(getDateWithTimezone(dateStart)) +
            '&endDateTime=' + encodeURIComponent(getDateWithTimezone(dateEnd))"
          class="input-group-btn btn btn-primary"
        >
          <font-awesome-icon class="text-white" icon="file-download" />
          Скачать путевые листы
        </a>
      </div>
      <div class="row w-100 m-0 p-2 border-top">
        <div class="col-5 font-weight-bold">Дата</div>
        <div class="col-5 font-weight-bold">Пройдено км</div>
      </div>
      <div v-if="!trips.length" class="text-center w-100 border-top py-3">Ничего не найдено</div>
      <FuelStatisticTrip
        v-for="(trip, key) in trips"
        :key="key"
        :trip="trip"
        :car-id="car.id"
      />
      <div v-if="trips.length">
        <hr>
        <div class="row w-100 m-0 p-2 font-weight-bold">
          <div class="col">
            Итого:
          </div>
          <div class="col">
            {{ sumOdometer }} км
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import dayjs from 'dayjs'
import Swal from 'sweetalert2'
import FuelStatisticTrip from './FuelStatisticTrip'

export default {
  name: 'FuelStatistic',
  components: { FuelStatisticTrip },
  props: {
    carModel: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      dateStart: null,
      dateEnd: null,
      loadingStatistic: false
    }
  },
  computed: {
    car () {
      return this.carModel ? this.carModel : this.carMap
    },
    getDateWithTimezone () {
      return (date) => {
        return dayjs(date).format('YYYY-MM-DDTHH:mm:ssZZ')
      }
    },
    sumOdometer () {
      let sum = 0
      this.trips.forEach(trip => {
        sum += trip.daily_odometer
      })
      return sum
    },
    ...mapState('taxi/waybill', {
      trips: state => state.items
    }),
    ...mapGetters('taxi/mapCars', {
      carMap: 'selectedCar'
    })
  },
  watch: {
    carMap (newCar, oldCar) {
      if (newCar?.id !== oldCar?.id) {
        this.clearItems()
      }
    }
  },
  beforeMount () {
    this.dateStart = dayjs(new Date().setMonth((new Date()).getMonth() - 1))
    this.dateStart = this.dateStart.format('YYYY-MM-DD')
    this.dateEnd = dayjs(new Date()).format('YYYY-MM-DD')
    this.loadWaybill()
  },
  beforeDestroy () {
    this.abortLoading()
    this.clearItems()
  },
  methods: {
    ...mapActions('taxi/waybill', [
      'abortLoading',
      'loadItems',
      'clearItems'
    ]),
    loadWaybill () {
      if (!this.car) {
        return Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          html: 'Не найдена машина'
        })
      }
      if (!this.loadingStatistic) {
        if (this.dateStart && this.dateEnd) {
          this.loadingStatistic = true
          this.clearItems()
          this.loadItems({
            id: this.car.id,
            startDateTime: this.getDateWithTimezone(this.dateStart),
            endDateTime: this.getDateWithTimezone(this.dateEnd)
          })
            .then(() => {
              this.loadingStatistic = false
            })
            .catch(error => {
              this.loadingStatistic = false
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                text: error.message
              })
            })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: 'Не введены даты'
          })
        }
      }
    }
  }
}
</script>
