<template>
  <div>
    <table class="table borderless">
      <tbody>
        <tr>
          <td style="border-top: none">Автомобиль</td>
          <td v-if="accident.car" style="border-top: none">{{ accident.car.brand }} {{ accident.car.model }} {{ accident.car.registration_plate }}</td>
        </tr>
        <tr>
          <td>Водитель</td>
          <td v-if="accident.driver">{{ accident.driver.last_name }} {{ accident.driver.first_name }} {{ accident.driver.middle_name }}</td>
        </tr>
        <tr>
          <td>Дата ДТП</td>
          <td>
            <div v-if="accident.date">
              {{ accident.date | formatDateTime }}
            </div>
          </td>
        </tr>
        <tr>
          <td>Описание</td>
          <td v-if="accident.description">{{ accident.description }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

export default {
  name: 'AccidentShow',
  props: {
    accident: {
      type: Object,
      default: () => null
    }
  }
}
</script>
