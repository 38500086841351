import moduleTemplate from '@/store/moduleTemplate'
import serviceWorkApi from '../api/serviceWork'

const template = moduleTemplate(serviceWorkApi)
template.types = {
  ...template.types,
  setSearchText: 'setSearchText'
}

template.mutations = {
  ...template.mutations,
  [template.types.setSearchText] (state, value) {
    state.searchText = value
  }
}

template.state = {
  ...template.state,
  searchText: ''
}

template.actions = {
  ...template.actions,
  // eslint-disable-next-line no-unused-vars
  updateItem ({ state, commit, dispatch }, params) {
    serviceWorkApi.updateItem(params)
  },
  // eslint-disable-next-line no-unused-vars
  getWorkById ({ state, commit, dispatch }, params) {
    return serviceWorkApi.getWorkById(params)
  },
  setSearchText ({ commit }, searchText) {
    commit(template.types.setSearchText, searchText)
  }
}

export default template
