/* eslint-disable */
const types = {
  loadItems: 'loadItems',
  setIsLoading: 'setIsLoading',
  setSearch: 'setSearch',
  clearSearch: 'clearSerach',
  setSorts: 'setSorts',
  loadItem: 'loadItem'
}

/**
 * Шаблон Vuex хранилища.
 * @param api
 * @deprecated
 * @returns {object} module
 */
export default (api) => {
  let request = null
  return {
    namespaced: true,

    /**
     * Vuex state
     * @property {Array} items Массив хранимых объектов, например: все автомобили, все водители и т.д.
     * @property {number} pageSize Размер страницы
     * @property {number} pageCount Количество страниц
     * @property {number} totalCount Всего элементов на всех страницах
     * @property {object} searches {key => value} значения для поиска с использованием фильтра
     * @property {boolean} isLoading Значение указывает загружаются ли сейчас объекты
     * @property {Array} expand Отражает какими полями будет дополнен ответ сервера
     * @property {object} extra {key => value} дополнительные параметры
     */
    state: {
      items: [],
      page: 1,
      pageSize: 10000000,
      pageCount: null,
      totalCount: null,
      searches: {},
      sort: [],
      isLoading: false,
      expandAllItems: [],
      expandSingleItem: [],
      extra: {}
    },

    mutations: {
      /**
       * @param state
       * @param data
       * @param data.items
       * @param data.page
       * @param data.pageCount
       * @param data.pageSize
       * @param data.totalCount
       * @deprecated
       */
      [types.loadItems] (state, {
        items,
        page,
        pageCount,
        pageSize,
        totalCount
      }) {
        state.items = items
        state.page = page
        state.pageCount = pageCount
        state.pageSize = pageSize
        state.totalCount = totalCount
      },
      /**
       * @param state
       * @param root0
       * @param root0.item
       * @deprecated
       */
      [types.loadItem] (state, { item }) {
        const updatingItem = state.items.find(el => el.id === item.id)

        if (!updatingItem) {
          state.items.push(item)
          return
        }

        Object.assign(updatingItem, item)
      },
      /**
       * @param state
       * @param data
       * @param data.key
       * @param data.value
       * @deprecated
       */
      [types.setSearch] (state, { key, value }) {
        state.searches[key] = value.trim()
      },
      /**
       * @param state
       * @param value
       * @deprecated
       */
      [types.setIsLoading] (state, value) {
        state.isLoading = value
      },
      /**
       * @param state
       * @param sorts
       * @deprecated
       */
      [types.setSorts] (state, sorts) {
        state.sort = sorts.map(sort => sort.values[sort.index])
      }
    },
    actions: {
      /**
       * @param data
       * @param data.state
       * @param data.commit
       * @param data.dispatch
       * @param params
       * @deprecated
       */
      loadItems ({ state, commit, dispatch }, params) {
        if (state.isLoading) {
          dispatch('abortLoading')
        }
        let page = state.page
        let pageSize = state.pageSize
        let searches = state.searches
        let expand = state.expandAllItems
        let sort = state.sort
        let extra = state.extra
        if (params && params.page) {
          page = params.page
        }
        if (params && params.pageSize) {
          pageSize = params.pageSize
        }
        if (params && params.searches) {
          searches = params.searches
        }
        if (params && params.expand) {
          expand = params.expand
        }
        if (params && params.sort) {
          sort = params.sort
        }
        if (params && params.extra) {
          extra = params.extra
        }

        request = api.getItems({
          page,
          pageSize,
          searches,
          expand,
          sort,
          extra
        })
        commit(types.setIsLoading, true)
        return request.promise
          .then(params => {
            request = null
            commit(types.setIsLoading, false)
            commit(types.loadItems, params)
            return params.items
          }, () => commit(types.setIsLoading, false))
      },
      /**
       * @param data
       * @param data.state
       * @param data.commit
       * @param data.dispatch
       * @param params
       * @deprecated
       */
      loadItem ({ state, commit, dispatch }, params) {
        if (state.isLoading) {
          dispatch('abortLoading')
        }
        let expand = state.expandSingleItem
        if (params && params.expand) {
          expand = params.expand
        }
        request = api.getItemPromise({ id: params.id, expand: expand })
        commit(types.setIsLoading, true)
        return request.promise
          .then(params => {
            request = null
            commit(types.setIsLoading, false)
            commit(types.loadItem, { item: params.items })
            return params.items
          })
      },
      /**
       * @param data
       * @param data.commit
       * @deprecated
       */
      abortLoading ({ commit }) {
        commit(types.setIsLoading, false)
        if (request) {
          request.abort()
          request = null
        }
      },
      /**
       * @param data
       * @param data.commit
       * @param items
       * @param items.key
       * @param items.value
       * @deprecated
       */
      setSearch ({ commit }, { key, value }) {
        commit(types.setSearch, { key, value })
      },
      /**
       * @param data
       * @param data.commit
       * @param sorts
       * @deprecated
       */
      setSorts ({ commit }, sorts) {
        commit(types.setSorts, sorts)
      },
      /**
       * @param data
       * @param data.commit
       * @deprecated
       */
      clearSearch ({ commit }) {
        commit(types.clearSearch)
      }
    },
    types
  }
}
