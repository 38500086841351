<template>
  <div class="common-view-fields">
    <tr style="opacity: 0.6">
      <td colspan="2">
        Общая информация
      </td>
    </tr>
    <tr>
      <td class="label-col">Наименование</td>
      <td>{{ company.name }}</td>
    </tr>
    <tr>
      <td class="label-col">ИНН</td>
      <td>{{ company.inn }}</td>
    </tr>
    <tr>
      <td class="label-col">Юр. адрес</td>
      <td>{{ company.address }}</td>
    </tr>
    <tr>
      <td class="label-col">Расч. счет</td>
      <td>{{ company.rs_number }}</td>
    </tr>
    <tr>
      <td class="label-col">Банк расч. счета</td>
      <td>{{ company.rs_bank_name }}</td>
    </tr>
    <tr>
      <td class="label-col">Кор. счет</td>
      <td>{{ company.ks }}</td>
    </tr>
    <tr>
      <td class="label-col">БИК</td>
      <td>{{ company.bik }}</td>
    </tr>
    <tr>
      <td class="label-col">ФИО для подписи</td>
      <td>{{ company.sign_name }}</td>
    </tr>
    <tr>
      <td class="label-col">Компания, принимающая оплату</td>
      <td>
        <router-link :to="'/companies/' + recipientCompany.id">
          {{ recipientCompany.name }}
        </router-link>
      </td>
    </tr>
  </div>
</template>

<script>

export default {
  name: 'CompanyView',
  props: {
    id: {
      type: Number,
      default: null
    },
    company: {
      type: Object,
      default: null
    },
    companies: {
      type: Array,
      default: null
    }
  },
  computed: {
    recipientCompany () {
      return this.companies.find(companyItem => companyItem.id === this.company.recipient_company_id) ?? this.company
    }
  }
}
</script>

<style scoped>
  .common-view-fields {
    display: contents;
  }
  .label-col {
    width: 25%;
  }
</style>
