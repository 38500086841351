<template>
  <div class="edit-licence">
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>Серия/ Номер</label>
        <input
          v-model="data.number"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>Дата выдачи</label>
        <input
          v-model="data.issue_date"
          type="date"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>Дата окончания срока действия</label>
        <input
          v-model="data.expiry_date"
          type="date"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <hr class="mb-4">
  </div>
</template>

<script>
const type = 'driving_licence'

export default {
  name: 'DocumentDrivingLicenceEdit',
  props: {
    documentData: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      data: {
        number: '',
        issue_date: '',
        expiry_date: ''
      }
    }
  },
  watch: {
    data: {
      handler () {
        this.update()
      },
      deep: true
    }
  },
  mounted () {
    if (this.documentData) {
      this.data = this.documentData
    }
    this.update()
  },
  methods: {
    update () {
      this.$emit('update', {
        type: type,
        data: this.data
      })
    }
  }
}
</script>

<style scoped>
  .edit-licence input {
    margin-bottom: 5px;
  }
  .edit-licence textarea {
    margin-bottom: 5px;
  }
</style>
