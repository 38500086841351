import { render, staticRenderFns } from "./AccidentListView.vue?vue&type=template&id=49e2722a&scoped=true&"
import script from "./AccidentListView.vue?vue&type=script&lang=js&"
export * from "./AccidentListView.vue?vue&type=script&lang=js&"
import style0 from "./AccidentListView.vue?vue&type=style&index=0&id=49e2722a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../shared/frontend/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e2722a",
  null
  
)

export default component.exports