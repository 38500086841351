<template>
  <div class="shadow-wrapper">
    <font-awesome-icon
      icon="times"
      size="2x"
      class="close-button"
      @click="$emit('close')"
    />
    <div class="wrapper card overflow-auto">
      <div class="card-body scroll">
        <img class="detail-image" :src="`/api/file/link?id=${scan.id}`">
      </div>
      <div class="card-footer text-muted">
        {{ scan.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LightBox',
  props: {
    scan: {
      type: Object,
      default: null
    }
  }
}
</script>

<style scoped>
  .shadow-wrapper {
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0, 0, 0, 0.75);
    padding: 30px;
    padding-left: 15%;
    padding-right: 15%;
    left: 0;
    top: 0;
    z-index: 9999;
  }
  .wrapper {
    margin: auto;
    max-height: 98%;
    min-height: 20rem;
    width: 100%;
    background-color: white;
  }
  .close-button {
    color: gray;
    float: right;
    position: fixed;
    right: 30px;
    top: 30px;
  }
  .close-button:hover {
    color: dimgray;
    cursor: pointer;
  }
  .detail-image {
    max-width: 100%;
    max-height: 500%;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .scroll {
    overflow-y: scroll;
  }
</style>
