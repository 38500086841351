<template>
  <div class="page">
    <HeaderTitle />
    <div class="row">
      <div class="col-12">
        <label>Название</label>
        <input
          v-model="serviceWork.name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label>Интервал обслуживания</label>
        <input
          v-model="serviceWork.interval"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="mt-3">
      <button class="btn btn-primary mr-2" @click="createServiceWork()">Добавить</button>
      <router-link to="/maintenance/service-works" class="btn btn-secondary">Отменить</router-link>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'
import serviceWorkApi from '../../api/serviceWork'

export default {
  name: 'CreateServiceWork',
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      isCreating: false,
      parentWork: null,
      serviceWork: {
        name: '',
        interval: '',
        sort: '',
        parent_work_id: null
      }
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'Тех. обслуживание',
        url: '/maintenance'
      }, {
        title: 'Работы',
        url: '/maintenance/service-works'
      }, {
        title: 'Добавить'
      }
    ])
  },
  beforeMount () {
    if (this.id) {
      this.getWorkById(this.id)
        .then(work => {
          this.parentWork = work
          this.serviceWork.parent_work_id = this.id
        })
    }
  },
  methods: {
    createServiceWork () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      serviceWorkApi.createItem(this.serviceWork)
        .then(() => {
          this.isCreating = false
          this.$router.push('/maintenance/service-works')
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('maintenance/serviceWork', ['getWorkById'])
  }
}
</script>
