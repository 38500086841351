import Page from '@/views/user/Page'
import Users from '@/views/user/Users'
import Create from '@/views/user/Create'

export default [
  {
    path: '/users',
    component: Users,
    meta: {
      title: 'Пользователи',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/users/create',
    component: Create,
    meta: {
      title: 'Пользователи',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/users/:userId',
    component: Page,
    name: 'userPage',
    props: true,
    meta: {
      title: 'Пользователи',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
