<template>
  <div>
    <LMap
      ref="leafletMap"
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      @update:zoom="zoom => currentZoom = zoom"
      @ready="initMap"
    >
      <LTileLayer
        :url="url"
        :attribution="attribution"
      />
      <LControlZoom position="topright" />
      <TrackHistory
        v-for="(track, key) in selectedTracks"
        :key="key"
        :trackPath="track.latLng"
        :color="track.color"
        :track="track.track"
        :zoom="currentZoom"
      />
      <VMarkerCluster
        ref="clusterRef"
        class="cluster"
        :options="{
          spiderfyOnMaxZoom: false,
          disableClusteringAtZoom: 15
        }"
      >
        <MapMarker v-for="car in cars.filter(car => selectedCar !== car)" :key="car.id" :car="car" />
      </VMarkerCluster>
      <SelectedMarker
        v-if="selectedCar"
        :car="selectedCar"
        :showTrack="!trackHistory || trackHistory.length <= 0"
        :track="selectedTrack"
      />
      <div class="button-scroll" title="К списку автомобилей" @click="scrollToMapPanel">
        <font-awesome-icon class="icon-scroll" icon="angle-double-down" />
      </div>
    </LMap>
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LControlZoom
} from 'vue2-leaflet'
import L, { latLng } from 'leaflet'
import VMarkerCluster from 'vue2-leaflet-markercluster'
import { mapState, mapGetters, mapActions } from 'vuex'
import TrackHistory from './TrackHistory'

export default {
  name: 'OLMap',
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    TrackHistory,
    VMarkerCluster
  },
  data () {
    return {
      zoom: 11,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      center: latLng(57.1418583, 65.5413183),
      currentZoom: 11.5,
      defaultZoom: 13,
      mapOptions: {
        zoomSnap: 0.5,
        zoomControl: false
      }
    }
  },
  computed: {
    ...mapState('taxi/mapCars', {
      cars: state => state.items,
      trackHistory: state => state.track,
      selectedTrack: state => state.selectedTrack,
      selectedTracks: state => state.tracks
    }),
    ...mapGetters('taxi/mapCars', [
      'selectedCar'
    ])
  },
  watch: {
    selectedCar (newCar, oldCar) {
      if (!oldCar || !newCar || oldCar?.id !== newCar?.id) {
        this.findCarOnMap()
      }
    },
    selectedTracks () {
      let tracksLength = this.selectedTracks.length
      if (tracksLength) {
        let track = this.selectedTracks[tracksLength - 1].latLng
        this.map.fitBounds(track, { padding: [0, 5], maxZoom: 13 })
      }
    }
  },
  beforeMount() {
    this.loadConfigs()
  },
  mounted () {
    this.reloadPolygons()
  },
  methods: {
    initMap () {
      this.map = this.$refs.leafletMap.mapObject
      this.map.invalidateSize()
      const drawnItems = new L.FeatureGroup([], { name: 'polygonLayer' })
      drawnItems.addTo(this.map)
    },
    initPolygons (polygons) {
      let drawnItems = null
      for (const key in this.map._layers) {
        const layer = this.map._layers[key]
        if (layer.options.name === 'polygonLayer') {
          drawnItems = layer
          break
        }
      }
      if (!drawnItems) {
        return
      }
      for (const key in drawnItems._layers) {
        drawnItems._layers[key].removeFrom(drawnItems)
      }
      for (const model of polygons) {
        if (!model.show_on_map) {
          continue
        }
        const layer = L.polygon(model.points, {
          color: model.color || '#008000',
          weight: 1,
          opacity: 0.7
        })
          .on('click', () => {
            this.selectedPolygon = model
          })
          .addTo(drawnItems)
        layer.model = model
      }
    },
    reloadPolygons () {
      if (process.env.VUE_APP_MODULE_RESTRICTED_AREA === 'true') {
        require('@/modules/restrictedArea/api')
          .default
          .getItems()
          .promise
          .then(({ items }) => this.initPolygons(items))
          .then(() => setTimeout(() => this.reloadPolygons(), 60 * 1000))
      }
    },
    findCarOnMap () {
      if (this.map && this.selectedCar?.lastState &&
        this.selectedCar?.lastState?.lat?.value &&
        this.selectedCar?.lastState?.lng?.value) {
        const targetPoint = this.map.project(
          latLng(this.selectedCar.lastState?.lat?.value, this.selectedCar.lastState?.lng?.value),
          this.currentZoom >= this.defaultZoom ? this.currentZoom : this.defaultZoom
        )
        const targetLatLng = this.map.unproject(targetPoint,
          this.currentZoom >= this.defaultZoom ? this.currentZoom : this.defaultZoom
        )
        this.map.setView(targetLatLng,
          this.currentZoom >= this.defaultZoom ? this.currentZoom : this.defaultZoom
        )
      }
    },
    scrollToMapPanel () {
      let panelEl = document.getElementById('map-panel')
      if (panelEl) {
        panelEl.scrollIntoView({ behavior: 'smooth' })
      }
    },
    ...mapActions('configs/configs', {
      loadConfigs: 'loadItems'
    })
  }
}
</script>

<style lang="scss">
  @import "~leaflet/dist/leaflet.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";

  .marker-cluster-medium,
  .marker-cluster-large,
  .marker-cluster-small {
    background-color: rgba(121, 217, 217, 0.6) !important;
    div {
      background-color: rgba(4, 157, 217, 0.6) !important;
      color: #fff !important;
    }
  }
  .button-scroll {
    display: none;
  }
  @media screen and (max-width: 768px) {
    .button-scroll {
      display: block;
      z-index:500;
      position:absolute;
      top:2%;
      left:5%;
      height: 50px;
      width: 50px;
      background: rgba(4, 157, 217, 0.8);
      border-radius: 50%;
      box-shadow: 0 8px 10px rgba(56, 163, 253, 0.3);
    }
    .icon-scroll {
      position: absolute;
      top: 20%;
      left: 31%;
      font-size: 32px;
      color:#fff;
    }
  }
</style>
