<template>
  <div class="page">
    <HeaderTitle />
    <div class="row">
      <div class="col-md-6 mb-6">
        <label>Выберите документ</label>
        <select v-model="currentFormComponent" class="form-control">
          <option
            v-for="({ edit, title }, type) of Types"
            :key="type"
            :value="edit.name"
          >
            {{ title }}
          </option>
        </select>
      </div>
    </div>
    <hr class="mb-4">
    <component
      :is="currentFormComponent"
      v-if="currentFormComponent"
      @update="handleData"
    />
    <button class="btn btn-primary" @click="createDocument()">Создать</button>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import Types from '@/views/driverDocuments/types'
import DocumentsAPI from '@/api/driver-documents'
import DriversAPI from '@/api/drivers'
import { mapActions } from 'vuex'

const components = {}
for (const typeName in Types) {
  components[Types[typeName].edit.name] = Types[typeName].edit.component
  components[Types[typeName].view.name] = Types[typeName].view.component
}

export default {
  name: 'CreateDocument',
  components,
  props: {
    driverId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isCreating: false,
      currentFormComponent: null,
      document: {
        type: '',
        data: '',
        driver_id: this.driverId
      },
      Types
    }
  },
  mounted () {
    DriversAPI.getItem({ id: this.driverId })
      .then(driver => {
        this.setTitle([
          {
            title: 'Водители',
            url: '/drivers'
          }, {
            title: driver.last_name + ' ' + driver.first_name + ' ' + driver.middle_name,
            url: '/drivers/' + this.driverId
          }, {
            title: 'Добавить документ'
          }
        ])
      })
  },
  methods: {
    handleData ({ type, data }) {
      this.document.type = type
      this.document.data = data
    },
    createDocument () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      DocumentsAPI.createItem(this.document)
        .then(document => {
          this.isCreating = false
          this.$router.push(`/drivers/${this.driverId}`)
          return document
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
