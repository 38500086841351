<template>
  <div :class="`alert ${stateClass} alert-state text-center`" role="alert">
    {{ stateMessage }}
  </div>
</template>

<script>
export default {
  name: 'CarState',
  props: {
    car: {
      type: Object,
      default: null
    }
  },
  computed: {
    stateClass () {
      if (this.car.isServe && this.car.state === 'rented') {
        return 'alert-danger'
      }
      if (this.car.state === 'rented') {
        return 'alert-success'
      }
      if (this.car.isServe) {
        return 'alert-warning'
      }
      return 'alert-info'
    },
    stateMessage () {
      if (this.car.isServe && this.car.state === 'rented') {
        return 'Обсл. в аренде'
      }
      if (this.car.state === 'rented') {
        return 'Арендуется'
      }
      if (this.car.isServe) {
        return 'Обслуживается'
      }
      return 'Не арендуется'
    }
  }
}
</script>

<style scoped>
  .alert-state {
    margin: 0;
    height: 30px;
    width: 10rem;
    line-height: 30px;
    padding: 0px 15px;
    max-width: 15rem;
    border-radius: 15px;
    border: none;
  }
  .alert-danger {
    color: #BF543B;
    background-color: rgba(191, 84, 59, 0.15);
  }
  .alert-success {
    color: #92BF4E;
    background-color: rgba(146, 191, 78, 0.15);
  }
  .alert-warning {
    color: #F2B950;
    background-color: rgba(242, 185, 80, 0.15);
  }
  .alert-info {
    color: #62ADBF;
    background-color: rgba(98, 173, 191, 0.15);
  }
</style>
