<template>
  <div>
    <div
      class="row w-100 m-0 p-2 border-top"
      role="button"
      tabindex="0"
      @click="showDetail = !showDetail"
    >
      <div class="col">
        {{ trip.start_date }}
      </div>
      <div class="col d-flex justify-content-between align-items-center">
        <div>
          {{ trip.daily_odometer }}
        </div>
        <font-awesome-icon
          :icon="showDetail ? 'chevron-up' : 'chevron-down'"
          class="text-secondary"
        />
      </div>
    </div>
    <table v-if="showDetail" class="table borderless" style="background: #f7f7f7">
      <tbody>
        <tr>
          <td>Показания одометра начальное</td>
          <td>{{ trip.start_odometer }}</td>
        </tr>
        <tr>
          <td>Показания одометра конечное</td>
          <td>{{ trip.end_odometer }}</td>
        </tr>
        <tr>
          <td>Начальный адерес</td>
          <td>{{ startAddress }}</td>
        </tr>
        <tr>
          <td>Конечный адрес</td>
          <td>{{ endAddress }}</td>
        </tr>
        <tr>
          <td colspan="2">
            <button
              class="btn btn-primary float-right"
              @click="getTrackDay(trip.start_date, trip.end_date)"
            >
              <span v-if="!loadingTrack">Показать</span>
              <div v-else class="spinner-border spinner-border-sm text-light" role="status">
                <span class="sr-only">Загрузка...</span>
              </div>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'FuelStatisticTrip',
  props: {
    trip: {
      type: Object,
      default: null
    },
    carId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      dateStart: null,
      showDetail: false,
      loadingTrack: false,
      startAddress: '',
      endAddress: ''
    }
  },
  watch: {
    'trip.startLocation': {
      handler () {
        if (this.trip.startLocation) {
          this.getAddressByCoords(this.trip.startLocation.lat, this.trip.startLocation.lng)
            .then(response => {
              this.startAddress = response
            })
        }
      },
      immediate: true
    },
    'trip.endLocation': {
      handler () {
        if (this.trip.endLocation) {
          this.getAddressByCoords(this.trip.endLocation.lat, this.trip.endLocation.lng)
            .then(response => {
              this.endAddress = response
            })
        }
      },
      immediate: true
    }
  },
  methods: {
    getAddressByCoords (lat, lng) {
      return this.getAddress({ lat: lat, lon: lng })
        .then(address => {
          let response = address.features
          if (response.length && response !== 'undefined') {
            response = address.features[0].properties
            address = ''
            if (response.city && response.district) {
              address += 'г. ' + response.city + ' ' + response.district
            }
            if (response.type === 'house') {
              address += ' ул. ' + response.street
              if (response.housenumber) {
                address += ' д. ' + response.housenumber
              } else {
                address += ' ' + response.name
              }
            } else if (response.type === 'street' || response.type === 'locality') {
              address += response.name
            }
            return address
          } else {
            return 'Не найдено'
          }
        })
    },
    getTrackDay (sTime, eTime) {
      if (!this.loadingTrack) {
        this.loadingTrack = true
        this.getTrack({
          id: this.carId,
          timeFrom: dayjs(sTime, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss'),
          timeTo: dayjs(eTime, 'DD-MM-YYYY').format('YYYY-MM-DDTHH:mm:ss')
        })
          .then(() => {
            this.loadingTrack = false
          })
          .catch(error => {
            this.loadingTrack = false
            Swal.fire({
              icon: 'error',
              title: 'Ошибка',
              text: error.message
            })
          })
      }
    },
    ...mapActions('taxi/mapCars', ['getTrack']),
    ...mapActions('taxi/waybill', ['getAddress'])
  }
}
</script>
