<template>
  <div>
    <div>
      <div class="my-1">Начало</div>
      <div class="row">
        <div class="col">
          <input
            v-model="timeFrom"
            class="w-100 form-control"
            type="date"
            placeholder="Начало маршрута"
          >
        </div>
      </div>
      <div class="my-2">Окончание</div>
      <div class="row">
        <div class="col">
          <input
            v-model="timeTo"
            class="w-100 form-control"
            type="date"
            placeholder="Начало маршрута"
          >
        </div>
      </div>
      <div class="my-2">Отображать в обратном порядке <input v-model="ascending" type="checkbox"></div>
      <button class="input-group-btn btn btn-primary my-4 align-content-center w-100" @click="getTripsByPeriod()">
        <span v-if="!loadingTrips">Показать</span>
        <div v-else class="spinner-border spinner-border-sm text-light" role="status">
          <span class="sr-only">Загрузка...</span>
        </div>
      </button>
    </div>
    <hr>
    <TripsDay
      v-for="{day, dayTrips} of groupedTrips"
      :key="day"
      :car="car"
      :trips="dayTrips"
      :date="day"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapActions, mapState } from 'vuex'
import Swal from 'sweetalert2'
import TripsDay from './Day'

export default {
  name: 'TripsPeriod',
  components: {
    TripsDay
  },
  props: {
    car: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      timeFrom: null,
      timeTo: null,
      loadingTrips: false,
      groupedTrips: [],
      maxPeriodDays: 10,
      ascending: true
    }
  },
  computed: {
    ...mapState('taxi/trips', {
      trips: state => state.items,
    })
  },
  mounted () {
    this.timeFrom = dayjs().format('YYYY-MM-DD')
    this.timeTo = dayjs().add(1, 'day').format('YYYY-MM-DD')
  },
  methods: {
    getTripsByPeriod () {
      if (this.loadingTrips) {
        return
      }
      const check = this.checkPeriod()
      if (!check.status) {
        Swal.fire({
          icon: 'error',
          title: 'Ошибка',
          text: check.message
        })
        return
      }
      this.loadingTrips = true
      this.clearTracks()
      this.groupedTrips = []
      const timeFrom = dayjs(this.timeFrom).format('YYYY-MM-DDTHH:mm:ssZZ')
      const timeTo = dayjs(this.timeTo).format('YYYY-MM-DDTHH:mm:ssZZ')
      this.loadItems({
        imei: this.car.imei,
        startDateTime: timeFrom,
        endDateTime: timeTo
      })
        .then(trips => {
          this.loadingTrips = false
          this.groupedTrips = this.groupTripsByDay(this.ascending ? trips : trips.toReversed())
        })
        .catch(error => {
          this.loadingTrips = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            text: error.message
          })
        })
    },
    groupTripsByDay (trips) {
      const groups = trips.reduce((groups, trip) => {
        const date = dayjs(trip.startTime).format('DD.MM.YYYYTHH:mm:ssZZ').split('T')[0]
        if (!groups[date]) {
          groups[date] = []
        }
        groups[date].push(trip)
        return groups
      }, {})
      const groupArrays = Object.keys(groups).map((date) => {
        return {
          day: date,
          dayTrips: groups[date]
        }
      })

      return groupArrays
    },
    checkPeriod () {
      if (!this.timeFrom || !this.timeTo) {
        return {
          status: false,
          message: 'Заполните Начало и Конец периода!'
        }
      }
      const fromTime = new Date(this.timeFrom).getTime()
      const toTime = new Date(this.timeTo).getTime()
      const days = (toTime - fromTime) / (1000 * 3600 * 24)
      if (days < 0) {
        return {
          status: false,
          message: 'Начало периода больше конца!'
        }
      }
      if (days > this.maxPeriodDays) {
        return {
          status: false,
          message: 'Максимальный размер периода - ' + this.maxPeriodDays + ' дней!'
        }
      }
      return {
        status: true,
        message: ''
      }
    },
    ...mapActions('taxi/mapCars', [
      'clearTracks'
    ]),
    ...mapActions('taxi/trips', [
      'loadItems'
    ])
  }
}
</script>
