<template>
  <div class="page">
    <HeaderTitle />
    <div v-if="feedbacks && feedbacks.length > 0">
      <table class="table table-hover w-100 mt-3 d-none d-md-table">
        <TableSort class="sticky-1" :sorts="sorts" @sortsUpdate="loadWithSort" />
        <tbody>
          <tr v-for="feedback of feedbacks" :key="feedback.id" @click="$router.push(`/feedback-admin/${feedback.id}`)">
            <td>{{ feedback.fullname }}</td>
            <td><FeedbackStatus :status="feedback.status" /></td>
            <td>{{ feedback.message }}</td>
            <td>{{ feedback.email }}</td>
            <td>{{ feedback.phone }}</td>
            <td>{{ feedback.created_at | formatDateTime }}</td>
            <td>
              <a v-if="feedback.seen_by_user_id" :href="'/users/' +feedback.seen_by_user_id">
                {{ fullNameByUser(feedback.seen_by_user_id) }}
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-for="feedback of feedbacks" :key="feedback.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
        <table class="table borderless">
          <tbody>
            <tr>
              <td style="border-top: none">ФИО</td>
              <td style="border-top: none">{{ feedback.fullname }}</td>
            </tr>
            <tr>
              <td>Статус</td>
              <td><FeedbackStatus :status="feedback.status" /></td>
            </tr>
            <tr>
              <td>Сообщение</td>
              <td>{{ feedback.message }}</td>
            </tr>
            <tr>
              <td>Эл.почта</td>
              <td>{{ feedback.email }}</td>
            </tr>
            <tr>
              <td>Телефон</td>
              <td>{{ feedback.phone }}</td>
            </tr>
            <tr>
              <td>Дата запроса</td>
              <td>{{ feedback.created_at | formatDateTime }}</td>
            </tr>
            <tr>
              <td>Просмотревший</td>
              <td>
                <a v-if="feedback.seen_by_user_id" :href="'/users/' +feedback.seen_by_user_id">
                  {{ fullNameByUser(feedback.seen_by_user_id) }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <router-link
          :to="{ name: 'FeedbackAdminShow', params: { feedbackId: String(feedback.id) } }"
          class="btn btn-primary btn-md create-fines ml-auto"
        >
          Подробно
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex'
import FeedbackStatus from '../../components/FeedbackStatus'

export default {
  name: 'FeedbackAdminList',
  components: {FeedbackStatus},
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'ФИО',
          storeName: 'feedback-fullname',
          values: ['', 'fullname', '-fullname'],
          order: 1
        },
        {
          name: 'Статус',
          storeName: 'feedback-status',
          values: ['', 'status', '-status'],
          order: 2
        },
        {
          name: 'Сообщение',
          storeName: 'feedback-message',
          values: ['', 'message', '-message'],
          order: 1
        },
        {
          name: 'Эл.почта',
          storeName: 'feedback-email',
          values: ['', 'email', '-email'],
          order: 1
        },
        {
          name: 'Телефон',
          storeName: 'feedback-phone',
          values: ['', 'phone', '-phone'],
          order: 1
        },
        {
          name: 'Дата запроса',
          storeName: 'feedback-created_at',
          values: ['', 'created_at', '-created_at'],
          order: 0
        },
        {
          name: 'Просмотревший',
          storeName: 'feedback-seen_by_user_id',
          values: ['', 'seen_by_user_id', '-seen_by_user_id'],
          order: 1
        },
      ],
      searchedText: null,
      interval: null
    }
  },
  computed: {
    ...mapState('feedback/feedback', {
      feedbacks: state => state.items,
      pageCount: state => state.pageCount,
      searchText: state => state.searchText,
      isLoading: state => state.isLoading
    }),
    ...mapState('taxi/user', {
      users: state => state.items,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading,
      authedUser: state => state.items.find(user => user.id === state.authedId)
    }),
    fullNameByUser () {
      return (userId) => {
        let user = this.users.find(user => user.id === userId)
        if (!user) {
          return null
        }
        return `${user.last_name} ${user.first_name} ${user.middle_name}`
      }
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'Обратная связь'
      }
    ])
    this.loadUsers()
    if (!this.interval) {
      this.loadWithSort(this.sorts)
    }
  },
  methods: {
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('feedback/feedback', [
      'loadItems',
      'setSorts'
    ]),
    ...mapActions('taxi/user', {
      loadUsers: 'loadItems',
      abortLoading: 'abortLoading',
      loadAuthedUser: 'loadAuthedUser'
    }),
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page) })
    },
  }
}
</script>
