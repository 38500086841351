<template>
  <div class="page" @scroll="onScroll">
    <div class="d-flex flex-column sticky-1">
      <div class="input-group without-separator">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <font-awesome-icon icon="search" style="color:gray" />
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Поиск по марке, модели, рег. номеру"
          aria-label="Поиск по марке, модели, рег. номеру"
          :value="searchText"
          @input="searchServe"
        >
      </div>
    </div>
    <table class="table table-hover w-100 mt-3 d-none d-md-table">
      <TableSort class="sticky-2" :sorts="sorts" @sortsUpdate="loadWithSort" />
      <tbody>
        <tr v-for="car of cars" :key="car.id" @click="$router.push(`/photocontrol/${car.id}`)">
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.brand }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.model }}</TextHighlight>
          </td>
          <td>
            <TextHighlight class="highlighter" :queries="searchText">{{ car.registration_plate }}</TextHighlight>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-for="car of cars" :key="car.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
      <table v-if="cars.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Марка автомобиля</td>
            <td style="border-top: none">
              <TextHighlight class="highlighter" :queries="searchText">{{ car.brand }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Модель автомобиля</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ car.model }}</TextHighlight>
            </td>
          </tr>
          <tr>
            <td>Рег. номер</td>
            <td>
              <TextHighlight class="highlighter" :queries="searchText">{{ car.registration_plate }}</TextHighlight>
            </td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="{ name: 'PhotocontrolPage', params: { carId: car.id } }"
        class="btn btn-primary btn-md create-serve ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { djsMixin } from '@/mixins/djsMixin'

export default {
  name: 'Serve',
  mixins: [djsMixin],
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'Марка автомобиля',
          storeName: 'servecar-brand',
          values: ['', 'brand', '-brand'],
          order: 3
        }, {
          name: 'Модель автомобиля',
          storeName: 'servecar-model',
          values: ['', 'model', '-model'],
          order: 4
        }, {
          name: 'Рег. номер',
          storeName: 'servecar-registration_plate',
          values: ['', 'reg_plate_number', '-reg_plate_number'],
          order: 5
        }
      ],
      searchedText: null,
      interval: null,
      scrollToOldValue: true
    }
  },
  computed: {
    ...mapState('maintenance/serviceCar', {
      cars: state => state.items,
      pageCount: state => state.pageCount,
      searchText: state => state.searchText,
      isLoading: state => state.isLoading
    })
  },
  mounted () {
    this.setTitle([
      {
        title: 'Фотоконтроль'
      }
    ])
    if (!this.interval) {
      this.loadWithSearch()
    }
  },
  beforeDestroy () {
    this.abortLoading()
    clearTimeout(this.interval)
    this.interval = null
  },
  methods: {
    ...mapActions('maintenance/serviceCar', [
      'loadItems',
      'setSearchText',
      'setSearch',
      'abortLoading',
      'removeItem',
      'setSorts'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    loadWithSearch () {
      if (!this.isLoading && this.searchedText !== this.searchText) {
        this.searchedText = this.searchText
        return this.loadItems({ page: Number(this.page) })
          .then(() => {
            this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
          })
      }
      this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
    },
    searchServe (e) {
      this.setSearchText(e.target.value)
      this.setSearch({ key: 'brand', value: e.target.value })
      this.setSearch({ key: 'model', value: e.target.value })
      this.setSearch({ key: 'reg_plate_number', value: e.target.value })
    },
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page) })
        .then(() => {
          if (this.scrollToOldValue) {
            this.scrollToOldValue = false
            this.$el.scrollTo(0, localStorage.serveSrollPositions)
          }
        })
    },
    onScroll (e) {
      if (e.target.scrollTop) {
        localStorage.serveSrollPositions = e.target.scrollTop
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .page {
    padding-top: 1rem;
    height: 100%;
    overflow-y: auto;
  }
  @media screen and (max-width: 1199px) {
    .page {
      padding: 1rem;
    }
  }
  @media screen and (max-width: 576px) {
    .page {
      padding: 0.5rem;
    }
  }
  .highlighter ::v-deep .text__highlight {
    border-radius: 0px;
    padding: 0.2em 0 0.2em 0;
    background: #fff2ae;
  }

</style>
