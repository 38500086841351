<template>
  <div class="page">
    <HeaderTitle />
    <br><br>
    <div class="row">
      <div class="col col-xl-7">
        <label>Фамилия</label>
        <input
          v-model="user.last_name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-9">
        <label>Имя</label>
        <input
          v-model="user.first_name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-9">
        <label>Отчество (не обязательно)</label>
        <input
          v-model="user.middle_name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-9">
        <label>Email</label>
        <input
          v-model="user.email"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-9">
        <label>Логин</label>
        <input
          v-model="user.username"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col col-xl-9">
        <label>Роли</label>
        <Mselect
          v-if="accessibleRoles && user.roles"
          v-model="user.roles"
          :options="accessibleRoles"
          option-key="name"
          option-label="name"
        />
      </div>
    </div>
    <div class="mt-4">
      <button class="btn btn-primary mr-2" @click="createUser()">Добавить</button>
      <router-link to="/users" class="btn btn-secondary">Отменить</router-link>
    </div>
  </div>
</template>

<script>
import userApi from '@/api/user'
import Swal from 'sweetalert2'
import { mapActions } from 'vuex'

export default {
  name: 'CreateUser',
  data () {
    return {
      isUpdating: false,
      user: {
        first_name: '',
        middle_name: '',
        last_name: '',
        username: '',
        email: '',
        roles: []
      },
      accessibleRoles: []
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'Пользователи',
        url: '/users'
      },
      {
        title: 'Добавить'
      }
    ])
    userApi
      .getAccessibleRoles()
      .then(roles => {
        this.accessibleRoles = Object.values(roles)
        this.user.roles.push(roles['Пользователь'].name)
      })
  },
  methods: {
    createUser () {
      userApi
        .createItem(this.user)
        .then(user => {
          this.$router.push(`/users/${user.model.id}`)
        })
        .catch(err => {
          console.error(err)
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>

<style scoped>
  .row {
    margin-bottom: 0.5rem;
  }
</style>
