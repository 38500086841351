<template>
  <div class="show-serve">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Автомобиль</td>
          <td v-if="car">
            <router-link
              class="text-decoration-none text-dark"
              :to="`/cars/${car.id}`"
            >
              {{ car.brand }} {{ car.model }} - {{ car.registration_plate }}
              <font-awesome-icon class="text-secondary" icon="external-link-alt" />
            </router-link>
          </td>
        </tr>
        <tr>
          <td>Текущий пробег, км</td>
          <td v-if="car">
            <span v-if="car.lastState && car.lastState.totalOdometer && car.lastState.totalOdometer.value">
              {{ Math.round(car.lastState.totalOdometer.value / 1000) + " км" }}
              (по датчику {{ ' ' + Math.round(car.lastState.totalOdometer.sensor / 1000) + " км" }})
            </span>
            <span v-else>н/д о пробеге</span>
          </td>
        </tr>
        <template v-if="showPriceAndSum">
          <tr>
            <td>Всего списано запчастей, шт</td>
            <td>
              {{ countStocks }}
            </td>
          </tr>
          <tr>
            <td>Суммарная стоимость списанных запчастей, руб</td>
            <td>
              {{ sumStocks }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <hr class="mb-4">
  </div>
</template>

<script>

export default {
  name: 'ServiceShow',
  props: {
    car: {
      type: Object,
      default: null
    },
    countStocks: {
      type: Number,
      default: 0
    },
    sumStocks: {
      type: Number,
      default: 0
    },
    showPriceAndSum: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>

<style scoped>
  .show-serve {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
</style>
