<template>
  <div class="page">
    <HeaderTitle />
    <div class="row">
      <div class="col-md-6 mb-2">
        <label>IMEI</label>
        <input
          v-model="trackDevice.imei"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mb-2">
        <label>Тип прошивки</label>
        <VSelect
          v-model="trackDevice.firmware_id"
          :options="firmwares"
          :reduce="firmware => firmware.id"
          label="name"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-2">
        <label>Название</label>
        <input
          v-model="trackDevice.name"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-2">
        <label>Номер телефона</label>
        <IMaskComponent
          v-model="trackDevice.phone"
          type="text"
          class="form-control"
          :mask="'[+7 (000) 000-00-00]'"
          placeholder="+7 (999) 999-99-99"
          required
        />
      </div>
    </div>
    <div class="mt-4">
      <button class="btn btn-primary mr-2" @click="createTrackDevice()">Добавить</button>
      <router-link to="/devices" class="btn btn-secondary">Отменить</router-link>
    </div>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import trackDeviceApi from '@/api/trackDevice'
import { mapActions } from 'vuex'

export default {
  name: 'CreateTrackDevice',
  data () {
    return {
      trackDevice: {},
      firmwares: []
    }
  },
  watch: {
    'trackDevice.imei' () {
      this.generateNameTrackingDevice()
    },
    'trackDevice.firmware_id' () {
      this.generateNameTrackingDevice()
    }
  },
  mounted () {
    trackDeviceApi.getFirmwares().then(response => {
      this.firmwares = response
    })
    this.setTitle([
      {
        title: 'Наблюдение',
        url: '/watch'
      },
      {
        title: 'Устройства',
        url: '/devices'
      },
      {
        title: 'Добавить'
      }
    ])
  },
  methods: {
    createTrackDevice () {
      if (this.isCreating) {
        return
      }
      this.isCreating = true
      trackDeviceApi.createItem(this.trackDevice)
        .then(trackDevice => {
          this.isCreating = false
          this.$router.push(`/devices/${trackDevice.model.id}`)
        })
        .catch(err => {
          console.error(err)
          this.isCreating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    generateNameTrackingDevice () {
      this.trackDevice.name = ''
      if (this.trackDevice?.imei || this.trackDevice.firmware_id) {
        if (this.trackDevice.firmware_id) {
          this.trackDevice.name += this.firmwares[this.trackDevice.firmware_id - 1].name
        }
        if (this.trackDevice?.imei && this.trackDevice.firmware_id) {
          this.trackDevice.name += '-'
        }
        if (this.trackDevice.imei) {
          this.trackDevice.name += this.trackDevice.imei
        }
      }
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
