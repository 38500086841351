import APITemplate from '@/api/APITemplate'

const url = '/api/feedback'
const template = APITemplate(url)

export default {
  ...template,
  getCountNew () {
    return template.fetchGet(`${url}/count-new?`)
  },
  getFiles (feedbackId) {
    const request = url + '/files?id=' + feedbackId
    return fetch(request, {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(template.errorHandler)
  },
  uploadFiles (body) {
    return fetch(url + '/upload-files', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      body
    })
      .then(response => {
        if (response.status !== 200) {
          response.json()
          throw new Error('Ошибка при загрузке.')
        }
        return response.json()
      })
  }
}
