<template>
  <pre v-if="log" class="log-viewer">{{ log }}</pre>
  <p v-else class="log-viewer">Не удалось прочитать файл лога</p>
</template>

<script>
import URL from 'url-parse'

export default {
  name: 'LogViewer',
  props: {
    /**
     * Путь апи лога.
     */
    apiUrl: {
      type: String,
      default: () => ''
    }
  },
  data () {
    return {
      /*
       * Текст лога
       */
      log: '',

      /**
       * Позиция в файле от которой нужно читать лог.
       */
      offset: -1,

      /**
       * Частота обновления лога в мс.
       */
      timeout: 5000,
    }
  },
  watch: {
    apiUrl: {
      immediate: true,
      handler () {
        clearTimeout(this.timeoutHandler)
        this.timeoutHandler = null
        this.log = ''
        this.offset = -1
        this.updateLog()
      }
    }
  },
  beforeDestroy () {
    clearTimeout(this.timeoutHandler)
    this.timeoutHandler = null
  },
  methods: {
    updateLog () {
      const url = new URL(this.apiUrl)
      const searchParams = new URLSearchParams(url.query)
      searchParams.set('offset', this.offset)
      url.query = '?' + searchParams.toString()
      fetch(url, {
        method: 'post',
        credentials: 'include',
        redirect: 'error',
        format: 'json'
      })
        .then(response => {
          if (response.ok) {
            return response.json()
          }
          throw new Error('Ошибка запроса лога')
        })
        .then(data => {
          this.offset = data.offset ?? -1
          data = data.log
          let isScrollToBottom = false
          if (this.log === '' || (this.$el.scrollHeight - this.$el.scrollTop === this.$el.clientHeight)) {
            isScrollToBottom = true
          }
          this.log += this.textConvert(data)
          if (isScrollToBottom) {
            this.$nextTick(() => {
              this.$el.scrollTop = this.$el.scrollHeight
            })
          }
          clearTimeout(this.timeoutHandler)
          this.timeoutHandler = setTimeout(() => this.updateLog(), this.timeout)
        })
        .catch(err => {
          clearTimeout(this.timeoutHandler)
          this.timeoutHandler = setTimeout(() => this.updateLog(), this.timeout)
          throw err
        })
    },
    textConvert (str) {
      /* eslint-disable */
      return str
        .replaceAll('[0m', '')
        .replaceAll(new RegExp('\[[0-9]+;[0-9]+m', 'gm'), '')
        .replaceAll(new RegExp('\[[0-9]+m', 'gm'), '')
      /* eslint-enable */
    }
  }
}
</script>

<style scoped>
.log-viewer {
  max-height: 600px;
  overflow-y: scroll;
}

pre {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
  padding: 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

</style>
