<template>
  <div
    v-if="partsRegNumber.length"
    class="badge-plate"
    :class="size"
    @click="$emit('click')"
  >
    <span
      v-for="(partRegNumber, key) of partsRegNumber"
      :key="key"
      class="badge-plate-number"
    >{{ partRegNumber }}</span>
    <span v-if="regRegion" class="badge-plate-region">{{ regRegion }}</span>
  </div>
</template>

<script>

export default {
  name: 'RegistrationPlate',
  props: {
    regNumber: {
      type: String,
      default: ''
    },
    regRegion: {
      type: String,
      default: ''
    },
    size: {
      /**
       * Варианты размеров:
       * - small
       * - normal
       */
      type: String,
      default: 'normal'
    }
  },
  data () {
    return {
      partsRegNumber: []
    }
  },
  watch: {
    regNumber: {
      handler () {
        if (this.regNumber) {
          this.partsRegNumber = Array.from(this.regNumber.matchAll(/([А-Я]+)(\d+)([А-Я]*)/g))
          if (this.partsRegNumber[0]) {
            this.partsRegNumber = this.partsRegNumber[0].filter(part => part && part !== this.regNumber)
          } else if (this.regNumber.length) {
            this.partsRegNumber = [this.regNumber]
          }
        }
      },
      immediate: true
    }
  },
  mounted () {
    if (!this.size || !['small', 'normal'].includes(this.size)) {
      this.size = 'normal'
    }
  }
}
</script>

<style scoped>
  .badge-plate {
    display: inline-block;
    padding: .2em .3em;
    margin-top: 3px;
    margin-bottom: 3px;
    border: 1px #ddd solid;
    border-radius: .25rem;
    background-color: #eee;
    color:#333;
    line-height: 1;
    vertical-align: baseline;
    text-align: center;
    white-space: nowrap;
  }
  .badge-plate-region {
    margin-left: 3px;
    border-left: 1px #ddd solid;
    padding-left: 3px;
  }
  .badge-plate-number {
    margin-left: 2px;
    margin-right: 2px;
  }
  .small .badge-plate {
    font-size: 85%;
  }
  .small .badge-plate-number {
    margin-left: 1px;
    margin-right: 1px;
  }
  .badge-plate-number:first-child {
    margin-left: 0;
  }
  .badge-plate-number:last-child {
    margin-right: 0;
  }
</style>
