import ConfigList from './views/ConfigList'
import ConfigPage from './views/configs/ConfigPage'
import ComponentsList from './views/ComponentsList'
import ComponentPage from './views/definitions/ComponentPage'
import ComponentCreate from './views/definitions/ComponentCreate'
import ConfigCreate from './views/configs/ConfigCreate'

export default [
  {
    path: '/configs',
    component: ConfigList,
    meta: {
      title: 'Настройки',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/configs/create',
    component: ConfigCreate,
    name: 'configCreate',
    props: true,
    meta: {
      title: 'Настройки',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/configs/:configId',
    component: ConfigPage,
    name: 'configPage',
    props: true,
    meta: {
      title: 'Настройки',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/components',
    component: ComponentsList,
    meta: {
      title: 'Компоненты',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/components/create',
    component: ComponentCreate,
    meta: {
      title: 'Компоненты',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/components/:componentId',
    component: ComponentPage,
    name: 'componentPage',
    props: true,
    meta: {
      title: 'Компонент',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
