<template>
  <div class="page">
    <router-link
      to="/users/create"
      class="btn btn-primary w-100"
      :class="authedUser.roles['Администратор'] ? '' : 'disabled'"
    >
      Добавить
    </router-link>

    <table v-if="users.length > 0" class="table table-hover w-100 mt-3 d-none d-md-table">
      <thead>
        <tr>
          <th>Фамилия</th>
          <th>Имя</th>
          <th>Отчество</th>
          <th>Логин</th>
          <th>Email</th>
          <th>Роли</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="user of users"
          :key="user.id"
          role="button"
          tabindex="0"
          @click="$router.push(`/users/${user.id}`)"
        >
          <td>{{ user.last_name }}</td>
          <td>{{ user.first_name }}</td>
          <td>{{ user.middle_name }}</td>
          <td>{{ user.username }}</td>
          <td>{{ user.email }}</td>
          <td>{{ Object.keys(user.roles).join(", ") }}</td>
        </tr>
      </tbody>
    </table>

    <div v-for="user of users" :key="user.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
      <table v-if="users.length > 0" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Фамилия</td>
            <td style="border-top: none">{{ user.last_name }}</td>
          </tr>
          <tr>
            <td>Имя</td>
            <td>{{ user.first_name }}</td>
          </tr>
          <tr>
            <td>Отчество</td>
            <td>{{ user.middle_name }}</td>
          </tr>
          <tr>
            <td>Логин</td>
            <td>{{ user.username }}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{{ user.email }}</td>
          </tr>
          <tr>
            <td>Роль</td>
            <td>{{ Object.keys(user.roles).join(", ") }}</td>
          </tr>
        </tbody>
      </table>
      <router-link
        :to="{ name: 'userPage', params: { userId: user.id } }"
        class="btn btn-primary btn-md create-user ml-auto"
      >
        Подробно
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Users',
  computed: {
    ...mapState('taxi/user', {
      users: state => state.items,
      pageCount: state => state.pageCount,
      isLoading: state => state.isLoading,
      authedUser: state => state.items.find(user => user.id === state.authedId)
    })
  },
  mounted () {
    this.loadItems()
    this.loadAuthedUser()
    this.setTitle([
      {
        title: 'Пользователи'
      }
    ])
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('taxi/user', [
      'loadItems',
      'abortLoading',
      'loadAuthedUser'
    ]),
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
