<template>
  <div class="page">
    <HeaderTitle />
    <div v-for="(photoControlItem, key) in photoControlList" :key="key">
      <table class="table">
        <tbody>
          <tr>
            <td class="label-col">Водитель</td>
            <td>
              <router-link
                class="text-decoration-none text-dark"
                :to="`/drivers/${photoControlItem['driverId']}`"
              >
                {{ photoControlItem['driverFullName'] }}
                <font-awesome-icon class="text-secondary" icon="external-link-alt" />
              </router-link>
            </td>
          </tr>
          <tr>
            <td>Договор</td>
            <td>
              <router-link
                class="text-decoration-none text-dark"
                :to="`/rent/${key}`"
              >
                {{ photoControlItem['contractNumber'] }}
                <font-awesome-icon class="text-secondary" icon="external-link-alt" />
              </router-link>
            </td>
          </tr>
          <tr>
            <td>Фото</td>
            <td>
              <div class="d-flex row">
                <div class="col-md-6 col-12">
                  <div>При выдаче</div>
                  <div class="row mt-3 justify-content-md-center">
                    <ImageView
                      v-for="(photo, PCAkey) in photoControlItem['photocontrol-after']"
                      :key="PCAkey"
                      :src="Number(photo.id)"
                      :edit-mode="false"
                      @deleteFile="(id) => $emit('deleteFile', id)"
                      @imageView="imageView(PCAkey, photoControlItem['photocontrol-after'])"
                    />
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div>При сдаче</div>
                  <div class="row mt-3 justify-content-md-center">
                    <ImageView
                      v-for="(photo, PCBkey) in photoControlItem['photocontrol-before']"
                      :key="PCBkey"
                      :src="Number(photo.id)"
                      :edit-mode="false"
                      @deleteFile="(id) => $emit('deleteFile', id)"
                      @imageView="imageView(PCBkey, photoControlItem['photocontrol-before'])"
                    />
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import photocontrolApi from '../api/photocontrol'
import { djsMixin } from '@/mixins/djsMixin'
import ImageView from '@/components/ImageView'

export default {
  name: 'PhotoControlView',
  components: {ImageView},
  mixins: [djsMixin],
  props: {
    carId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      car: null,
      photoControlList: []
    }
  },
  computed: {
    ...mapState('taxi/cars', {
      cars: state => state.items
    }),
  },
  mounted () {
    this.loadCars()
      .then(() => {
      photocontrolApi.getPhotocontrolList({
        carId: this.carId
      }).then(list => {
        const car = this.cars.find(car => car.id == this.carId)
        if (car) {
          this.setTitle([
            {
              title: 'Фотоконтроль',
              url: '/photocontrol'
            }, {
              title: car.brand + ' ' + car.model + ' ' + car.registration_plate,
              url: '/cars/' + car.id
            }
          ])
        }
        this.photoControlList = list.items
      })
    })
  },
  methods: {
    ...mapActions('taxi/cars', {
      loadCars: 'loadItems'
    }),
    ...mapActions('taxi', [
      'setTitle'
    ]),
    imageView(index, images) {
      images = images.map((image) => {
        image.url = '/api/file/link?id=' + image.id
        return image
      })
      this.$imageViewer.images(images)
      this.$imageViewer.index(index)
      this.$imageViewer.show()
    },
  }
}
</script>

<style scoped>
  .history-pane {
    padding-left: 0;
    padding-right: 0;
  }
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .tab-content {
    padding: 1rem 1rem 1rem 1rem;
  }
  .history-item {
    padding-left: min(1.60rem, 3%);
    padding-right: 2%;
  }

</style>
