/* eslint-disable camelcase */
import APITemplate from '@/api/APITemplate'

const url = '/api/driver'
const template = APITemplate(url)
template.getItems = config => {
  const params = new URLSearchParams()
  if (config && config.page !== undefined && config.pageSize !== undefined) {
    params.append('pagination[page]', (config.page - 1).toString())
    params.append('pagination[pageSize]', config.pageSize)
  }
  if (config && config.sort) {
    params.append('sort', config.sort.toString()+',-id')
  }
  if (config && config.expand) {
    params.append('expand', config.expand.toString())
  }
  if (config && config.searches) {
    Object.keys(config.searches).forEach(key => {
      params.append(`filter[or][][${key}][like]`, config.searches[key])
    })
  }
  // any param
  if (config && config.extra && config.extra.key && 'value' in config.extra) {
    params.append(config.extra.key, config.extra.value)
  }

  return template.fetchGet(`${url}/index?${params}`)
}
export default template
