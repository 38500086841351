<template>
  <div>
    <div v-for="file of files" :key="file.id" class="card file-card">
      <div class="card-body">
        <div class="row">
          <div class="col align-self-center">
            <font-awesome-icon
              :icon="getIconName(file.extention)"
              class="h3 m-0"
              style="color:#25559a"
            />
            {{ file.name }}
          </div>
          <div class="col-lg-auto mb-6 date">
            {{ file.created_at }}
            <div
              v-if="canDelete"
              class="btn"
              role="button"
              tabindex="0"
              @click="archivateFile(file.id)"
            >
              <font-awesome-icon icon="trash-alt" style="color:gray" />
            </div>
            <a
              v-if="canShow(file.extention)"
              class="btn"
              target="_blank"
              :href="`/api/file/show?id=${file.id}`"
            >
              <font-awesome-icon icon="eye" style="color:gray" />
            </a>
            <a class="btn" :href="`/api/file/link?id=${file.id}`">
              <font-awesome-icon icon="download" style="color:gray" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import FilesAPI from '@/api/files'
import Swal from 'sweetalert2'

export default {
  name: 'FileListViewer',
  props: {
    files: {
      type: Array,
      default: Array
    },
    canDelete: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getIconName () {
      return (extention) => {
        if (['doc', 'docm', 'docx', 'dot', 'dotx', 'odt'].includes(extention)) {
          return 'file-word'
        } else if (['tar.gz', 'zip', 'rar', '7z'].includes(extention)) {
          return 'file-archive'
        } else if (['bpm', 'gif', 'ico', 'jpeg', 'png', 'webp'].includes(extention)) {
          return 'file-image'
        } else if (['xls', 'xlsx'].includes(extention)) {
          return 'file-excel'
        } else if (['pdf'].includes(extention)) {
          return 'file-pdf'
        }
        return 'file'
      }
    },
    canShow () {
      return (extention) => {
        return ['bpm', 'gif', 'ico', 'jpeg', 'png', 'webp', 'pdf'].includes(extention)

      }
    }
  },
  methods: {
    archivateFile(id) {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите архивировать файл?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Архивировать'
      }).then(event => {
        if (event.value) {
          FilesAPI.archivateItem(id)
            .then(() => {
              this.$emit('archivateFile', id)
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
  }
}
</script>

<style>
.file-card {
  margin-top: 1rem;
}
</style>
