import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export const djsMixin = {
  filters: {
    diffForHumans: (date) => {
      if (!date) {
        return null
      }
      return dayjs(date).hour()
        ? dayjs(date).locale('ru').format('DD.MM.YYYY, HH:mm')
        : dayjs(date).locale('ru').format('DD.MM.YYYY')
    },
    fromUTC: (date) => {
      if (!date) {
        return null
      }
      return dayjs.utc(date).tz(dayjs.tz.guess())
    }
  },
  methods: {
    now: () => dayjs().format('YYYY-MM-DDTHH:mm'),
    toHumanFormat (date) {
      if (!date) {
        return null
      }

      return dayjs(date).locale('ru').format('DD.MM.YYYY, HH:mm') || null
    },
    fromUTCtranslate (date) {
      if (!date) {
        return null
      }
      return dayjs.utc(date).tz(dayjs.tz.guess()).format('YYYY-MM-DDTHH:mm')
    },
    toUTCtranslate (date) {
      if (!date) {
        return null
      }

      return dayjs(date).tz(dayjs.tz.guess()).utc().format('YYYY-MM-DDTHH:mm')
    }
  }
}
