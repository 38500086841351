<template>
  <div class="page page-service-works">
    <div class="d-flex flex-column sticky-1">
      <div class="input-group without-separator">
        <div class="input-group-prepend">
          <span class="input-group-text">
            <font-awesome-icon icon="search" style="color:gray" />
          </span>
        </div>
        <input
          type="text"
          class="form-control"
          placeholder="Поиск по имени и интервалу"
          aria-label="Поиск по имени и интервалу"
          :value="searchText"
          @input="searchServiceWork"
        >
      </div>
      <div class="pt-2">
        <router-link to="/maintenance/service-works/create" class="btn btn-primary w-100">Добавить работу</router-link>
      </div>
    </div>
    <br>
    <div class="draggable-cards-wrapper">
      <DraggableTree
        class="tree4"
        :data="works"
        draggable=""
        cross-tree
        :indent="30"
        :space="0"
        @change="changeItems"
      >
        <div slot-scope="{data, store}">
          <template v-if="!data.isDragPlaceHolder">
            <div class="row text-dark py-2">
              <div class="col">
                <font-awesome-icon
                  v-if="data.children && data.children.length"
                  :icon="data.open ? 'chevron-up' : 'chevron-down'"
                  class="mx-2"
                  :style="{
                    color: 'gray',
                    'margin-left': 'auto',
                    cursor: 'pointer'
                  }"
                  @click="store.toggleOpen(data)"
                />
                <TextHighlight :queries="searchText">
                  {{ data.name }}
                </TextHighlight>
                <TextHighlight class="badge" style="line-height: unset" :queries="searchText">
                  {{ data.interval ? `Каждые ${data.interval} км.` : "" }}
                </TextHighlight>
              </div>
              <div class="col-auto right-icons">
                <router-link :to="`/maintenance/service-works/create/${data.id}`" class="btn">
                  <font-awesome-icon icon="plus" />
                </router-link>
                <router-link :to="`/maintenance/service-works/update/${data.id}`" class="btn">
                  <font-awesome-icon icon="pen" />
                </router-link>
                <div
                  class="btn"
                  role="button"
                  tabindex="0"
                  @click="removeItem(data.id)"
                >
                  <font-awesome-icon icon="trash-alt" />
                </div>
              </div>
            </div>
          </template>
        </div>
      </DraggableTree>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import serviceWorkApi from '@/modules/maintenance/api/serviceWork'
import Swal from 'sweetalert2'

export default {
  name: 'ServiceWorks',
  data () {
    return {
      editable: true,
      isDragging: false,
      delayedDragging: false,
      worksCopy: null,
      searchedText: null,
      interval: null
    }
  },
  computed: {
    ...mapState('maintenance/serviceWork', {
      worksState: state => state.items.filter(e => !e.archived_at),
      isLoading: state => state.isLoading,
      searchText: state => state.searchText
    }),
    works: {
      get () {
        if (!this.worksCopy) {
          return JSON.parse(JSON.stringify(this.worksState))
        }

        return this.worksCopy
      },
      set (value) {
        value.forEach((v, i) => {
          this.updateItem({ ...v, sort: i })
        })
        this.worksCopy = value
      }
    }
  },
  mounted () {
    this.setTitle([
      {
        title: 'Тех. обслуживание',
        url: '/maintenance'
      }, {
        title: 'Работы'
      }
    ])
    if (!this.interval) {
      this.loadWithSearch()
    }
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    changeItems (node) {
      node.parent.children.forEach((v, i) => {
        this.updateItem({
          id: v.id,
          name: v.name,
          parent_work_id: v.parent.id ? v.parent.id : null,
          interval: v.interval,
          created_at: v.created_at,
          archived_at: v.archived_at,
          sort: i
        })
      })
    },
    removeItem (id) {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите архивировать работу?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Архивировать'
      }).then(event => {
        if (event.value) {
          serviceWorkApi.archivateItem(id)
            .then(() => {
              this.loadItems({ sort: 'sort' })
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    setOpenProp (works) {
      works.forEach(work => {
        work.open = false
        if (work.children.length) {
          this.setOpenProp(work.children)
        }
      })
    },
    loadWithSearch () {
      if (!this.isLoading && this.searchedText !== this.searchText) {
        this.searchedText = this.searchText
        return this.loadItems({
          page: Number(this.page)
        }).then(() => {
            this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
          })
      }
      this.interval = setTimeout(() => { this.loadWithSearch() }, 500)
    },
    searchServiceWork (e) {
      this.setSearchText(e.target.value)
      this.setSearch({ key: 'name', value: e.target.value })
      this.setSearch({ key: 'interval', value: e.target.value })
    },
    ...mapActions('taxi', ['setTitle']),
    ...mapActions('maintenance/serviceWork', [
      'loadItems',
      'updateItem',
      'setSorts',
      'abortLoading',
      'setSearch',
      'setSearchText'
    ])
  }
}
</script>

<style lang="scss" scoped>
  @media screen and (max-width: 1199px) {
    .page {
      padding: 1rem;
    }
  }
  @media screen and (max-width: 576px) {
    .page {
      padding: 0.5rem;
    }
  }
  .page-service-works {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
  .draggable-cards-wrapper {
    height: 100%;
    flex-direction: column;
    flex-flow: nowrap;
  }
  ::v-deep .tree-node-inner {
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 5px 10px;
  }
  ::v-deep .tree-node-inner:hover {
    background: #e3e3e3 !important;
    cursor: grabbing;
  }
  ::v-deep .draggable-placeholder-inner {
    background: #e3e3e3 !important;
  }
  .right-icons * {
    color: gray;
  }
</style>
