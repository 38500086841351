import moduleTemplate from '@/store/moduleTemplate'
import configComponentDefinition from '../api/configComponentDefinition'

const template = moduleTemplate(configComponentDefinition)

template.types = {
  ...template.types,
  setSearchText: 'setSearchText'
}

template.mutations = {
  ...template.mutations,
  [template.types.setSearchText] (state, value) {
    state.searchText = value
  }
}

template.state = {
  ...template.state,
  searchText: ''
}

template.actions = {
  ...template.actions,
  setSearchText ({ commit }, searchText) {
    commit(template.types.setSearchText, searchText)
  }
}

export default template
