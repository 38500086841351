<template>
  <div class="page">
    <HeaderTitle />
    <div class="pt-2">
      <router-link to="/components/create" class="btn btn-primary w-100">Добавить компонент</router-link>
    </div>
    <table class="table table-hover w-100 mt-3 d-none d-md-table">
      <TableSort class="sticky-1" :sorts="sorts" @sortsUpdate="loadWithSort" />
      <tbody>
        <tr v-for="component of components" :key="component.id" @click="$router.push(`/components/${component.id}`)">
          <td>{{ component.name }}</td>
          <td>{{ component.title }}</td>
        </tr>
      </tbody>
    </table>
    <div v-if="components.length">
      <div v-for="component of components" :key="component.id" class="mobile-table-view card mt-2 mb-2 p-2 d-md-none">
        <table class="table borderless">
          <tbody>
            <tr>
              <td style="border-top: none">Машинное имя компонента</td>
              <td>{{ component.name }}</td>
            </tr>
            <tr>
              <td>Название</td>
              <td>{{ component.title }}</td>
            </tr>
          </tbody>
        </table>
        <router-link
          :to="{ name: 'componentPage', params: { componentId: component.id } }"
          class="btn btn-primary btn-md create-fines ml-auto"
        >
          Подробно
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapState} from 'vuex'

export default {
  name: 'ComponentsList',
  props: {
    page: {
      type: String,
      default: () => '1'
    }
  },
  data () {
    return {
      sorts: [
        {
          name: 'Машинное имя компонента',
          storeName: 'definitions-name',
          values: ['', 'name', '-name'],
          order: 0
        },
        {
          name: 'Название',
          storeName: 'definitions-title',
          values: ['', 'title', '-title'],
          order: 1
        }
      ],
      searchedText: null,
      interval: null
    }
  },
  computed: {
    ...mapState('configs/configComponentDefinition', {
      components: state => state.items,
      pageCount: state => state.pageCount,
      searchText: state => state.searchText,
      isLoading: state => state.isLoading
    }),
  },
  mounted () {
    this.setTitle([
      {
        title: 'Компоненты'
      }
    ])
    if (!this.interval) {
      this.loadWithSort(this.sorts)
    }
  },
  methods: {
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('configs/configComponentDefinition', [
      'loadItems',
      'setSearchText',
      'setSearch',
      'abortLoading',
      'setSorts'
    ]),
    loadWithSort (sorts) {
      this.setSorts(sorts)
      this.loadItems({ page: Number(this.page) })
    },
  }
}
</script>
