<template>
  <div class="show-form-cars">
    <table class="table">
      <tbody>
        <tr>
          <td class="label-col">Марка</td>
          <td>{{ car.brand }}</td>
        </tr>
        <tr>
          <td>Модель</td>
          <td>{{ car.model }}</td>
        </tr>
        <tr>
          <td>Рег. номер</td>
          <td>
            <RegistrationPlate
              :reg-number="car.reg_plate_number"
              :reg-region="car.reg_plate_region"
            />
          </td>
        </tr>
        <tr>
          <td>Год выпуска</td>
          <td>{{ car.manufacturing_year }}</td>
        </tr>
        <tr>
          <td>Код радио</td>
          <td>{{ car.radio_code }}</td>
        </tr>
        <tr>
          <td>Пробег</td>
          <td v-if="car.lastState && car.lastState.totalOdometer">
            {{ Math.round(car.lastState.totalOdometer.value / 1000) + " км" }}
            (по датчику {{ ' ' + Math.round(car.lastState.totalOdometer.sensor / 1000) + " км" }})
          </td>
          <td v-else>Нет данных</td>
        </tr>
        <tr>
          <td>Устройство геопозиционирования</td>
          <td>
            <router-link
              v-if="car.trackingDevice"
              :to="'/devices/' + car.trackingDevice.id"
            >
              {{ car.trackingDevice.name }}
            </router-link>
          </td>
        </tr>
        <tr>
          <td>IMEI</td>
          <td>
            <router-link
              v-if="car.trackingDevice"
              :to="'/devices/' + car.trackingDevice.id"
            >
              {{ car.trackingDevice.imei }}
            </router-link>
          </td>
        </tr>
        <tr v-if="car.trackingDevice">
          <td>Номер телефона</td>
          <td>
            {{ car.trackingDevice.phone }}
          </td>
        </tr>
        <tr>
          <td>Собственник</td>
          <td>
            <router-link
              v-if="company"
              :to="'/companies/' + company.id"
            >
              {{ company.name }}
            </router-link>
          </td>
        </tr>
        <tr>
          <td>Статус</td>
          <td class="col-state"><CarState :car="car" /></td>
        </tr>
        <tr>
          <td>ОСАГО</td>
          <td class="col-state">
            <DocumentState
              v-if="car.documents"
              :document="car.documents.find(document => document.type === 'osago')"
              :days="car.osagoRemains"
            />
          </td>
        </tr>
        <tr>
          <td>Диагн. карта</td>
          <td class="col-state">
            <DocumentState
              v-if="car.documents"
              :document="car.documents.find(document => document.type === 'diagnostic_card')"
              :days="car.diagnosticCardRemains"
            />
          </td>
        </tr>
        <tr>
          <td>Топливная карта</td>
          <td>
            {{ car.fuel_card }}
          </td>
        </tr>
        <tr v-if="car.notes">
          <td>Заметки</td>
          <td>
            {{ car.notes }}
          </td>
        </tr>
        <tr v-if="hasFinesModule">
          <td>Проверка штрафов</td>
          <td>
            {{ car.check_fines ? 'Активна' : 'Не активна' }}
          </td>
        </tr>
      </tbody>
    </table>
    <hr class="mb-4">
  </div>
</template>

<script>
import CarState from '@/components/CarState'
import RegistrationPlate from './RegistrationPlate'
import companiesAPI from '@/api/companies'
import DocumentState from '../../views/carDocuments/DocumentState'

export default {
  name: 'CarShowForm',
  components: {
    DocumentState,
    CarState,
    RegistrationPlate
  },
  props: {
    id: {
      type: String,
      default: null
    },
    car: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      company: null
    }
  },
  computed: {
    hasFinesModule () {
      return process.env.VUE_APP_MODULE_FINES === 'true'
    }
  },
  watch: {
    car: {
      handler (car) {
        if (car?.owner_id) {
          this.loadData()
        }
      },
      immediate: true
    }
  },
  methods: {
    loadData () {
      if (this.car.owner_id) {
        companiesAPI.getItem({ id: this.car.owner_id })
          .then(response => {
            this.company = response
          })
      }
    }
  }
}
</script>

<style scoped>
  .show-form-cars {
    margin-top: 4rem;
  }
  .label-col {
    width: 25%;
  }
  .col-state {
    padding: 0 !important;
    vertical-align: middle;
  }
</style>
