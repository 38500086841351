import List from '@/modules/facility/views/List.vue'
import Page from '@/modules/facility/views/Page.vue'
import Create from '@/modules/facility/views/Create.vue'
import Exchange from '@/modules/facility/views/Exchange.vue'

export default [
  {
    path: '/facility',
    component: List,
    meta: {
      title: 'Объекты',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/facility/create',
    component: Create,
    props: true,
    meta: {
      title: 'Создать объект',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/facility/exchange',
    component: Exchange,
    props: true,
    meta: {
      title: 'Списание между объектами',
      layout: 'BaseWorkspaceLayout'
    }
  }, {
    path: '/facility/:facilityId',
    component: Page,
    props: true,
    meta: {
      title: 'Объекты',
      layout: 'BaseWorkspaceLayout'
    }
  }
]
