<template>
  <div>
    <div
      v-for="characteristic of driverCharacteristics"
      :key="characteristic.id"
    >
      <div
        v-if="boolCharacteristics.includes(characteristic.id)"
        class="d-flex mb-2"
      >
        <input
          :id="`bool-${characteristic.id}`"
          v-model="characteristic.value"
          type="checkbox"
          class="mr-2 mb-2"
        >
        <label :for="`bool-${characteristic.id}`">{{ characteristic.title }}</label>
      </div>
      <div
        v-if="numberCharacteristics.includes(characteristic.id)"
        class="d-flex mb-2 flex-column"
      >
        <label :for="`number-${characteristic.id}`">{{ characteristic.title }}</label>
        <input
          :id="`number-${characteristic.id}`"
          v-model="characteristic.value"
          type="number"
          class="form-control"
        >
      </div>
      <div
        v-if="textCharacteristics.includes(characteristic.id)"
        class="d-flex flex-column mb-2"
      >
        <label :for="`text-${characteristic.id}`">{{ characteristic.title }}</label>
        <textarea
          :id="`text-${characteristic.id}`"
          v-model="characteristic.value"
          maxlength="5000"
          rows="5"
          class="form-control"
        />
      </div>
    </div>
  </div>
</template>

<script>

import CharacteristicApi from '@/api/characteristics'

export default {
  name: 'CharacteristicEdit',
  props: {
    driverCharacteristics: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    boolCharacteristics () {
      return this.driverCharacteristics.filter(ch => ch.type == 'bool').map(ch => ch.id)
    },
    numberCharacteristics () {
      return this.driverCharacteristics.filter(ch => ch.type == 'number').map(ch => ch.id)
    },
    textCharacteristics () {
      return this.driverCharacteristics.filter(ch => ch.type == 'string').map(ch => ch.id)
    }
  },
  mounted () {
    CharacteristicApi.getItems()
      .promise
      .then(response => {
        if (this.driverCharacteristics.length == 0) {
          this.driverCharacteristics.push(...response.items)
        } else {
          for (let item of response.items) {
            if (this.driverCharacteristics.findIndex(drCh => drCh.id == item.id) < 0) {
              this.driverCharacteristics.push(item)
            }
          }
        }
        this.driverCharacteristics.sort((a,b) => a.sort - b.sort)
      })
    this.driverCharacteristics.sort((a,b) => a.sort - b.sort)
  }
}
</script>
<style scoped>
</style>
