import APITemplate from '@/api/APITemplate'
const template = APITemplate('/api/user')

export default {
  ...template,
  getCurrentUser () {
    return fetch('/api/user/me', {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  },
  setUserRoles (data) {
    return fetch('/api/user/set-roles', {
      method: 'PUT',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers,
      body: JSON.stringify(data)
    }).then(template.saveHandler)
  },
  resetPassword (formData) {
    return fetch('/api/user/password-reset', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers,
      body: JSON.stringify(formData)
    }).then(template.saveHandler)
  },
  setNewPassword (formData) {
    return fetch('/api/user/set-password', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers,
      body: JSON.stringify(formData)
    }).then(template.saveHandler)
  },
  getAccessibleRoles () {
    return fetch('/api/user/accessible-roles', {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  },
  hasFrontendPermission (url) {
    return fetch('/api/user/has-frontend-permission?url=' + url, {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  },
  hasPermission (url) {
    return fetch('/api/user/has-permission?url=' + url, {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  },
  getFrontendPermission () {
    return fetch('/api/user/get-frontend-permission', {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(response => response.json())
  },
  changePassword (formData) {
    return fetch('/api/user/change-password', {
      method: 'POST',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers,
      body: JSON.stringify(formData)
    }).then(response => response.json())
  }
}
