<template>
  <router-link
    class="text-decoration-none text-dark"
    :to="'/watch/' + car.id"
  >
    <div class="p-2 card-car w-100 m-0 d-flex justify-content-between">
      <div class="d-flex card-car-title">
        <font-awesome-icon
          v-if="car.lastState && car.lastState.speed && car.lastState.speed.value > minSpeed"
          icon="car"
          :style="{ color: '#92BF4E', width: '20px', height: '20px' }"
        />
        <font-awesome-icon
          v-else
          icon="parking"
          :style="{ color: '#62ADBF', width: '20px', height: '20px' }"
        />
        <RegistrationPlate
          class="ml-2"
          size="small"
          :reg-number="car.reg_plate_number"
          :reg-region="car.reg_plate_region"
        />
        <span class="pl-2">{{ car.brand }} {{ car.model }}</span>
      </div>
      <div class="text-right text-nowrap">
        <WatchState :location="lastState(car)" />
      </div>
    </div>
    <hr class="my-0">
  </router-link>
</template>

<script>
import RegistrationPlate from '@/components/car/RegistrationPlate'

export default {
  components: {
    RegistrationPlate
  },
  props: {
    car: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      minSpeed: parseFloat(process.env.VUE_APP_MIN_SPEED)
    }
  },
  computed: {
    lastState () {
      return (car) => {
        if (car.lastState.constructor === Array) {
          return {}
        }
        return car.lastState
      }
    }
  }
}
</script>

<style scoped>
  .card-car:hover {
    background: #e6e6e6;
  }
  @media screen and (max-width: 400px) {
    .card-car {
      display: block !important;
    }
  }
  .card-car-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>
