<template>
  <div class="page page-companies">
    <HeaderTitle />
    <ul class="nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#data">Основные данные</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" data-toggle="tab" href="#proxies">Доверенные лица</a>
      </li>
    </ul>
    <div class="tab-content">
      <div id="data" class="tab-pane fade show active">
        <ActionBar
          :isEditing="isEditing"
          @changeAction="changeCompany"
          @cancelAction="cancelCompanyChanges"
          @deleteAction="deleteCompany"
          @saveAction="updateCompany"
        />
        <br>
        <Edit
          v-if="isEditing"
          class="company-view-edit"
          :company="company"
          :companies="companies"
        />
        <component
          :is="currentViewComponent"
          v-else-if="currentViewComponent"
          :company="company"
          :companies="companies"
        />
      </div>
      <div id="proxies" class="tab-pane fade p-3">
        <ProxiesPage :proxies="company.proxiesCompany" :company-id="company.id" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable camelcase */

import Swal from 'sweetalert2'
import {mapActions, mapState} from 'vuex'
import deepAssign from 'assign-deep'
import Edit from './Edit'
import companiesAPI from '@/api/companies'
import ProxiesPage from '../proxies/Page'
import types from '@/views/companies/types'

const viewComponents = {}
for (const typeName in types) {
  viewComponents[types[typeName].view.name] = types[typeName].view.component
}

export default {
  name: 'CompanyPage',
  components: {
    ProxiesPage,
    Edit,
    ...viewComponents
  },
  props: {
    id: {
      type: String,
      default: null
    },
  },
  data () {
    return {
      isUpdating: false,
      prevCompany: null,
      company: {},
      isEditing: false
    }
  },
  computed: {
    currentViewComponent () {
      return types[this.company.type]?.view?.name
    },
    ...mapState('taxi/companies', {
      companies: state => state.items
    }),
  },
  watch: {
    company: {
      handler (company, companyOld) {
        if (company?.id && company?.id !== companyOld?.id) {
          this.setTitle([
            {
              title: 'Организации',
              url: '/companies'
            },
            {
              title: company.name
            }
          ])
        }
      },
      immediate: true
    },
    '$route': 'loadData'
  },
  mounted () {
    this.loadData()
    this.loadItems({ expand: ['proxiesCompany']})
  },
  methods: {
    updateCompany () {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      companiesAPI.updateItem(this.company)
        .then(company => {
          this.company = company.model
          this.isUpdating = false
          this.isEditing = false
        })
        .catch(err => {
          console.error(err)
          this.isUpdating = false
          Swal.fire({
            icon: 'error',
            title: 'Ошибка',
            html: err.message
          })
        })
    },
    loadData () {
      companiesAPI.getItem({ id: this.id, queryWith: ['proxiesCompany'] })
        .then(response => {
          this.company = response
        })
    },
    changeCompany () {
      this.prevcompany = deepAssign({}, this.company)
      this.isEditing = true
    },
    cancelCompanyChanges () {
      this.company = this.prevcompany
      this.isEditing = false
    },
    deleteCompany () {
      Swal.fire({
        icon: 'warning',
        title: 'Вы действительно хотите удалить компанию?',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonColor: '#c82333',
        cancelButtonText: 'Отменить',
        confirmButtonText: 'Удалить'
      }).then(event => {
        if (event.value) {
          companiesAPI.removeItem(this.company.id)
            .then(() => {
              this.$router.push('/companies')
            })
            .catch(err => {
              console.error(err)
              Swal.fire({
                icon: 'error',
                title: 'Ошибка',
                html: err.message
              })
            })
        }
      })
    },
    ...mapActions('taxi', [
      'setTitle'
    ]),
    ...mapActions('taxi/companies', [
      'loadItems',
    ]),
  },
}
</script>

<style scoped>
  .page-companies {
    width: 98%;
    padding-bottom: 4rem;
    padding-top: 2.5rem;
  }
</style>
