<template>
  <div style="display: contents">
    <CommonEdit :company="company" :companies="companies" />
    <tr style="opacity: 0.6">
      <td colspan="2">
        Поля ОПФ
      </td>
    </tr>
    <tr>
      <td class="label-col">Номер ОГРНИП</td>
      <td>
        <input
          v-model="company.ogrnip"
          type="text"
          class="form-control"
          placeholder
          required
        >
      </td>
    </tr>
  </div>
</template>

<script>
import CommonEdit from '@/views/companies/common/Edit'

export default {
  name: 'IeEdit',
  components: {
    CommonEdit
  },
  props: {
    company: {
      type: Object,
      default: null
    },
    companies: {
      type: Array,
      default: null
    },
  },
  watch: {
    company: {
      handler () {
        this.$emit('update', {
          ...this.company
        })
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
