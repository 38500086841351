import APITemplate from '@/api/APITemplate'

const template = APITemplate('/api/driver-document')

export default {
  ...template,
  getScans (documentId) {
    const request = '/api/driver-document/scans?id=' + documentId
    return fetch(request, {
      method: 'GET',
      credentials: 'include',
      redirect: 'error',
      headers: template.headers
    }).then(template.errorHandler)
  }
}
