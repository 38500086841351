<template>
  <div>
    <HeaderTitle />
    <FinesCarShow :car-id="carId" @load-car="setHeader" />
  </div>
</template>

<script>
import { djsMixin } from '@/mixins/djsMixin'
import FinesCarShow from '../components/Show'
import {mapActions} from 'vuex'

export default {
  name: 'FinesCar',
  components: {FinesCarShow},
  mixins: [djsMixin],
  props: {
    carId: {
      type: String,
      default: () => null
    }
  },
  methods: {
    setHeader (car) {
      this.setTitle([
        {
          title: 'Штрафы ГИБДД',
          url: '/fines'
        }, {
          title: car.brand + ' ' + car.model + ' ' + car.registration_plate
        }
      ])
    },
    ...mapActions('taxi', [
      'setTitle'
    ]),
  }
}
</script>

<style lang="scss" scoped>
.page {
  padding-top: 1rem;
  height: 100%;
  overflow-y: auto;
}
@media screen and (max-width: 1199px) {
  .page {
    padding: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .page {
    padding: 0.5rem;
  }
}
</style>
