import moduleTemplate from '@/store/moduleTemplate'
import configs from '../api/configs'

const template = moduleTemplate(configs)

template.types = {
  ...template.types,
  setSearchText: 'setSearchText'
}

template.getters = {
  trackColors: state => {
    return state.items.find(config => config.component_name == 'TrackOptions')?.value?.trackColors || []
  },
  speedColors: state => {
    return state.items.find(config => config.component_name == 'TrackOptions')?.value?.speedColors || []
  },
  factMileage: state => {
    return state.items.find(config => config.component_name == 'TrackOptions')?.value?.factMileage || false
  },
  showPriceAndSum: state => {
    return state.items.find(config => config.component_name == 'StockSettings')?.value?.showPriceAndSum || false
  },
  daysToDelete: state => {
    return state.items.find(config => config.component_name == 'ServiceSettings')?.value?.daysToDelete || 90
  }
}

template.mutations = {
  ...template.mutations,
  [template.types.setSearchText] (state, value) {
    state.searchText = value
  }
}

template.state = {
  ...template.state,
  searchText: ''
}

template.actions = {
  ...template.actions,
  setSearchText ({ commit }, searchText) {
    commit(template.types.setSearchText, searchText)
  }
}

export default template
