<template>
  <div v-if="selectedCar" class="wrapper">
    <div class="sticky-header" style="z-index: 1">
      <div class="text-center h5 p-3 pl-5">
        {{ selectedCar.brand }} {{ selectedCar.model }} {{ selectedCar.registration_plate }}
        <span class="car-buttons">
          <div
            class="btn"
            role="button"
            tabindex="0"
            @click="findCar"
          >
            <font-awesome-icon icon="search-location" />
          </div>
          <router-link
            :to="'/cars/' + selectedCar.id"
            class="btn"
            role="button"
            tabindex="0"
          >
            <font-awesome-icon icon="external-link-alt" />
          </router-link>
          <div
            class="btn"
            role="button"
            tabindex="0"
            @click="$router.push('/watch')"
          >
            <font-awesome-icon icon="times" />
          </div>
        </span>
      </div>
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs justify-content-center">
          <li class="nav-item">
            <a class="nav-link active" data-toggle="tab" href="#info">Инфо</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#history">История</a>
          </li>
          <li v-if="hasFuelModule && canUseFuelTab" class="nav-item">
            <a class="nav-link" data-toggle="tab" href="#fuel">Топливо</a>
          </li>
          <li class="nav-item">
            <a
              v-if="canUseCommandTab"
              class="nav-link"
              data-toggle="tab"
              href="#commands"
            >Команды</a>
          </li>
        </ul>
      </div>
    </div>
    <div class="card-body">
      <div class="tab-content">
        <div id="info" class="tab-pane fade active show">
          <CardInfo :car="selectedCar" />
        </div>
        <div id="history" class="tab-pane fade">
          <CardHistory :car="selectedCar" />
        </div>
        <div v-if="hasFuelModule && canUseFuelTab" id="fuel" class="tab-pane fade">
          <FuelStatistic />
        </div>
        <div v-if="canUseCommandTab" id="commands" class="tab-pane fade">
          <CardCommands :car="selectedCar" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
import CardInfo from './CardInfo'
import CardHistory from './CardHistory'
import CardCommands from './CardCommands'

const components = {
  CardInfo,
  CardHistory,
  CardCommands
}
import userApi from '@/api/user'
if (process.env.VUE_APP_MODULE_FUEL === 'true') {
  components.FuelStatistic = require('@/modules/fuel/components/FuelStatistics').default
}

export default {
  name: 'CardSelected',
  components,
  data () {
    return {
      canUseFuelTab: false,
      canUseCommandTab: false
    }
  },
  computed: {
    ...mapGetters('taxi/mapCars', ['selectedCar']),
    hasFuelModule () {
      return process.env.VUE_APP_MODULE_FUEL === 'true'
    }
  },
  watch: {
    selectedCar: {
      handler (car, carOld) {
        if (car?.id !== carOld?.id) {
          this.getStartTrack({
            id: car.id,
            timeFrom: dayjs().subtract(1, 'hour').format('YYYY-MM-DDTHH:mm:ssZZ'),
            timeTo: dayjs().format('YYYY-MM-DDTHH:mm:ssZZ')
          })
        }
      },
      immediate: true
    }
  },
  beforeMount () {
    userApi.hasPermission('/waybill/get-statics')
      .then(permissions => {
        this.canUseFuelTab = permissions
      })
      .catch(() => {
        this.canUseFuelTab = false
      })
    userApi.hasPermission('/device-command-task/index')
      .then(permissions => {
        this.canUseCommandTab = permissions
      })
      .catch(() => {
        this.canUseCommandTab = false
      })
  },
  methods: {
    ...mapActions('taxi/mapCars', [
      'getStartTrack',
      'clearTrack'
    ]),
    findCar () {
      this.clearTrack()
      this.$emit('findCar')
    }
  }
}
</script>

<style lang="scss" scoped>
  .card-header {
    background: white !important;
  }
  .card-header {
    padding: 0;
  }
  .sticky-header {
    position: sticky;
    top: 0;
    background: white;
  }
  .card-header-tabs {
    margin: 0;
  }
  .wrapper {
    background: white;
  }
  .back-button {
    color: #55606a;
    opacity: 0.7;
    width: 22px;
    height: 22px;
    position: absolute;
    right: 1rem;
    top: 1rem;
    cursor: pointer;
  }
  .back-button:hover {
    opacity: 1 !important;
  }
  .car-brief-area {
    text-align: center;
  }

  .car-buttons {
    margin: 0 5px;
  }
  .car-buttons .btn {
    color: grey;
  }
</style>
