<template>
  <div class="page contracts-templates">
    <button class="btn btn-primary w-100" @click="$refs.files.click()">Загрузить инструкцию</button>
    <input
      ref="files"
      type="file"
      style="display:none"
      multiple
      @change="addFilesFromDialog"
    >
    <FileListViewer :files="instructions" @archivateFile="archivateFile" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import FilesAPI from '@/api/files'
import FileListViewer from '../components/FileListViewer'

export default {
  name: 'Instruction',
  components: {FileListViewer},
  data () {
    return {
      files: [],
      inputFile: '',
      dragging: false,
      preDragging: false
    }
  },
  computed: {
    ...mapState('taxi/instruction', {
      isLoading: state => state.isLoading,
      instructions: state => state.instructions,
    })
  },
  mounted () {
    this.loadInstructions()
    this.setTitle([
      {
        title: 'Инструкции'
      }
    ])
  },
  beforeDestroy () {
    this.abortLoading()
  },
  methods: {
    ...mapActions('taxi/instruction', [
      'loadInstructions',
      'abortLoading',
      'uploadInstruction'
    ]),
    addFilesFromDialog (e) {
      this.uploadInstruction(e.target.files)
      this.$refs.files.value = null
    },
    getInstruction (id) {
      FilesAPI.getItem({ id })
        .then(template => {
          this.files.unshift(template)
        })
    },
    archivateFile () {
        this.loadInstructions()
    },
    ...mapActions('taxi', [
      'setTitle'
    ])
  }
}
</script>
