<template>
  <div v-if="feedback" class="page">
    <div v-if="feedback.comment || (feedback.adminFiles && feedback.adminFiles.length)">
      <h5>Ответ пользователю</h5>
      <table v-if="feedback.comment" class="table borderless">
        <tbody>
          <tr>
            <td style="border-top: none">Комментарий</td>
            <td style="border-top: none">{{ feedback.comment }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="feedback.adminFiles && feedback.adminFiles.length">
        <h5>Прикрепленные файлы</h5>
        <FileListViewer :files="feedback.adminFiles" />
      </div>
    </div>
    <br>
    <h5>Данные с формы</h5>
    <table class="table borderless">
      <tbody>
        <tr>
          <td style="border-top: none">Просмотревший</td>
          <td style="border-top: none">
            <a v-if="feedback.seen_by_user_id" :href="'/users/' +feedback.seen_by_user_id">
              {{ fullNameByUser(feedback.seen_by_user_id) }}
            </a>
          </td>
        </tr>
        <tr>
          <td>Пользователь</td>
          <td>
            <a v-if="feedback.user_id" :href="'/users/' +feedback.user_id">
              {{ fullNameByUser(feedback.user_id) }}
            </a>
          </td>
        </tr>
        <tr>
          <td>ФИО</td>
          <td>{{ feedback.fullname }}</td>
        </tr>
        <tr>
          <td>Email</td>
          <td>{{ feedback.email }}</td>
        </tr>
        <tr>
          <td>Телефон</td>
          <td>{{ feedback.phone }}</td>
        </tr>
        <tr>
          <td>Сообщение</td>
          <td>{{ feedback.message }}</td>
        </tr>
        <tr>
          <td>IP-адрес</td>
          <td>{{ feedback.ip }}</td>
        </tr>
        <tr>
          <td>Дата создания</td>
          <td>{{ feedback.created_at | formatDateTime }}</td>
        </tr>
        <tr>
          <td>Ссылка на страницу</td>
          <td>
            <a v-if="feedback.seen_by_user_id" :href="feedback.url">
              {{ feedback.url }}
            </a>
          </td>
        </tr>
        <tr>
          <td>Разрешение окна браузера</td>
          <td>{{ feedback.resolution }}</td>
        </tr>
        <tr>
          <td>User Agent</td>
          <td>{{ feedback.user_agent }}</td>
        </tr>
        <tr>
          <td>Статус</td>
          <td>
            <FeedbackStatus :status="feedback.status" />
          </td>
        </tr>
      </tbody>
    </table>
    <br>
    <h5>Прикрепленные файлы</h5>
    <FileListViewer :files="feedback.files" />
  </div>
</template>

<script>

import {mapActions} from 'vuex'
import FileListViewer from '../../../../components/FileListViewer'
import FeedbackStatus from '../../components/FeedbackStatus'

export default {
  name: 'FeedbackAdminShow',
  components: {FeedbackStatus, FileListViewer},
  props: {
    users: {
      type: Array,
      default: Array
    },
    feedback: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      isEditing: false,
      isUpdating: false,
    }
  },
  computed: {
    fullNameByUser() {
      return (userId) => {
        let user = this.users.find(user => user.id === userId)
        if (!user) {
          return null
        }
        return `${user.last_name} ${user.first_name} ${user.middle_name}`
      }
    },
  },
  methods: {
    ...mapActions('taxi', [
      'setTitle'
    ]),
  }
}
</script>
